// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require('../assets/threedots.png');
export const folder = require('../assets/folder.png');
export const file = require('../assets/file.png');
export const backButton = require('../assets/Backbtn.png');
export const folderThreeDot = require('../assets/folderThreeDots.png');
export const DocUpload = require('../assets/DocUpload.png');
export const FolderImage = require('../assets/FolderImage.png');
export const Circle = require('../assets/Circle.png');
export const FileType = require('../assets/FileType.png');
export const Dot = require('../assets/tripleDot.png');
export const Delete = require('../assets/Delete.png');
export const TableImage = require('../assets/TableAvt.png');
export const UploadDocument = require('../assets/UploadDocument.png');
export const FolderDefaultImage = require('../assets/FolderDefaultImage.png');


// Customizable Area End
