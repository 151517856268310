import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { } from "./assets";
import { setStorageData, removeStorageData } from "../../../framework/src/Utilities";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Login.json')
// Customizable Area End

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  userName: string;
  password: string;
  showPassword: boolean;
  userType: string;
  localErrors:{
    Email: string,
    Password: string
  };
  errors: string;
  currentLanguage:'en'|'ar';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserLoginWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  loginApiCallId: any;
  passwordReg: RegExp;
  emailReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userName: '',
      password: '',
      showPassword: false,
      userType: '',
      localErrors: {Email: "", Password: ""},
      errors: "",
      currentLanguage:configJSON.EN
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleLoginResponse(apiRequestCallId, responseJson)
    }
  }
  // Customizable Area End
  async componentDidMount() {
    // Customizable Area Start
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language });
    // Customizable Area End
  }

  // Customizable Area Start

  handleLoginResponse = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.loginApiCallId) {
        if (!responseJson.errors) {
          await removeStorageData('email')
          await this.saveAccessToken(responseJson);
          const userType = responseJson.data.type;
          if (userType === 'admin_account') {
            this.handleNavigation('dashboard');
          } else {
            if (responseJson.data.attributes.signup_status === "completed")
              this.handleNavigation('UserDashboard');
            else {
              await setStorageData("signupStatus", responseJson.data.attributes.signup_status)
              this.handleNavigation('SellerRegistration');
            }
          }
        } else {
          this.setState({ errors: (Object.values(responseJson.errors[0])[0] as string )})
        }
      }
    }
  }

  handleNavigation(route: string) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  saveAccessToken = async (responseJson: any) => {
    await setStorageData("userId", responseJson.data.id)
    await setStorageData("accessToken", responseJson.meta.token);
    await setStorageData("userType", responseJson.data.attributes.user_type)
    await setStorageData("profilePic", responseJson.data.attributes.profile_image ?? "image")
  };
  

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ userName: text });
      this.resetError("Email");
    },
  };

  txtInputPasswordWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
      this.resetError("Password");
    },
  };

  resetError (field: string) {
    this.setState((prevState) => ({
      localErrors: {
        ...prevState.localErrors,
        [field]: '',
      },
      errors: ''
    }));
  }

  handleUserNameChange = (text: any) => {
    this.setState({
      userName: text
    })
    this.setState({ errors: '' }, ()=>this.resetError("Email"));
  }

  handlePasswordChange = (text: string) => {
    this.setState({
      password: text
    })
    this.setState({ errors: '' }, ()=>this.resetError("Password"));
  }

  handleClickShowPassword = () => {
    this.setState((preState) => ({
      showPassword: !preState.showPassword
    }))
  }

  errorHandle = (responseJson: any) => {
    this.parseApiErrorResponse(responseJson);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  handleLocalErrors() {
    const emailEmpty = this.isStringNullOrBlank(this.state.userName);
    const passwordEmpty = this.isStringNullOrBlank(this.state.password);
    const translation = languageTranslationData[this.state.currentLanguage].Seller
    
    if (emailEmpty && passwordEmpty) {
      this.setState({ localErrors: { Email: translation.EmailError, Password: translation.PasswordError }});
      return false;
    }
    if (emailEmpty) {
      this.setState({ localErrors: {...this.state.localErrors, Email: translation.EmailError }});
      return false;
    }
    if (passwordEmpty) {
      this.setState({ localErrors: {...this.state.localErrors, Password: translation.PasswordError}});
      return false;
    }
  }

  validate() {
    if (this.isStringNullOrBlank(this.state.userName) ||
      this.isStringNullOrBlank(this.state.password)) {
      this.handleLocalErrors();
      return false;
    }

    return true;
  }

  handleLogin(userType: string) {
    if (this.validate()) {
      this.sendData(userType)
    }
  }

  loginButton = {
    onClick: () => this.handleLogin('consumer')
  };

  providerLoginButton = {
    onClick: () => this.handleLogin('debt_owner')
  };

  superAdminLoginButton = {
    onClick: () => this.handleLogin('admin')
  }

  sendData(userType: string) {
    const headers = {
      "Content-Type": configJSON.loginApiContentType
    }
    const attributes = {
      user_name: this.state.userName,
      password: this.state.password,
      user_type: userType,
    };

    const bodyData = {
      "type": "email_account",
      attributes: attributes,
    };

    const httpBodyData = {
      data: bodyData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;

    if (userType === 'admin') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.superAdminLoginEndPoint
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginEndPoint
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
