import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import ServiceInternalFormController, { Props } from "./ServiceInternalFormController.web"
import { Box, Container, FormControl, FormHelperText, Select, Grid, MenuItem, OutlinedInput } from "@material-ui/core";
import Text from "../../../../packages/components/src/design-system/Text";
import Paper from '@material-ui/core/Paper';
import ButtonCustom from "../../../components/src/design-system/Button/Button";
const languageTranslation = require('../../languagesupport/src/LanguageData/ServiceInternalForm.json')
// Customizable Area End



const styles: StyleRules = {
    // Customizable Area Start
    labelText: {
        fontWeight: 700,
        fontSize: "12px",
        margin: "0px",
        color: "#fff",
        textAlign: 'unset'
    },
    errorText: {
        textAlign: 'unset'
    },
    container: {
        display: 'flex',
        gap: '20px',
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    mainBoxConatiner: {
        backgroundColor: "#1E293B",
        width: "100%",
        padding: "50px 100px",
        borderRadius: "20px",
        marginBottom: "32px"
    },
    lableTextBox: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "poppins",
        color: "#fff",
        position: "relative",
        bottom: "7px"
    },
    gridMainContainer: {
        display: 'flex',
        gap: '20px',
        justifyContent: "start",
        alignItems: "start"
    },
    formControllerBox: {
        margin: "12px 0px",
        width: '100%'
    },
    reqireText: {
        color: "red"
    },
    inputTextFiled: {
        height: "44px",
        borderRadius: "8px",
        border: '1px solid var(--neutrals-cool-gray-300, #CBD5E1)',
        background: 'var(--basic-white, #FFF)',
        width: "100%",
        fontFamily: "Inter"
    },
    documnetIcon: {
        justifyContent: "center",
        marginLeft: "16px",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#C2D5FF"
    },
    documentMainBox: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "16px 0",
        borderRadius: "8px",
        gap: "16px",
        marginTop: "5px",
        width: "100%"
    },
    inputBorderTextDrag: {
        backgroundColor: "white",
        height: "136px",
        borderRadius: "8px",
        marginTop: "10px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: "10px",
        width: "100%",
        overflowWrap: 'break-word',

    },
    documnetName: {
        fontFamily: "inter",
        fontSize: "16px",
        fontWeight: 400,
        color: "#0F172A"
    },
    documnetSizeText: {
        fontFamily: "inter",
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px"
    },
    optionBox: {
        marginRight: "16px",
        marginLeft: "auto"
    },
    dragAndGropText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    dragAndDropTitle: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#1E293B",
        textAlign: "center",
        paddingTop: "10px",
        marginTop: "20px"
    },
    dragAndDropDescription: {
        fontSize: "10px",
        fontWeight: 400,
        color: "#676767",
        textAlign: "center",
        paddingTop: "5px"
    },
    uploadButton: {
        textDecoration: "underline"
    },
    boxMargin: {
        marginTop: "20px"
    },
    boxWidth: {
        width: "100%"
    }
    // Customizable Area End
}


export class ServiceInternalForm extends ServiceInternalFormController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    renderFileUploadOthersPart = () => {
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslation[currentLanguage];
        return (
            <>
                <Grid className={classes.gridMainContainer}>
                    <Grid xs={6} item className={classes.boxMargin}>
                        <Box className={classes.boxWidth}>
                            <span className={classes.labelText}>{languageData.TaxRegistrationDocument}</span>
                            <div
                                onDragLeave={this.handleDragLeave}
                                onDragOver={this.handleDragOver}
                                onDrop={this.handleDrop}
                            >
                                {this.state.taxRegistration ? (
                                    <Box className={classes.inputBorderTextDrag}>
                                        {this.state.taxRegistration?.name}
                                    </Box>
                                ) : (
                                    <>
                                        <label
                                            className={classes.documentMainBox}
                                            htmlFor="uploadInput2"
                                        >
                                            <div className={classes.dragAndGropText}>
                                                <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.4559 8.01141L20.3669 7.9862C20.0729 7.90297 19.8935 7.60315 19.9861 7.30832L20.4559 8.01141ZM20.4559 8.01141L20.462 8.00474M20.4559 8.01141L20.462 8.00474M20.462 8.00474C20.7249 8.03375 20.9848 7.88125 21.0657 7.62202C21.7184 5.54042 23.7787 4.07851 26.0818 4.07851C26.3858 4.07851 26.6458 3.84263 26.6458 3.53554C26.6458 3.22845 26.3858 2.99258 26.0818 2.99258C23.1979 2.99258 20.7676 4.81556 19.9861 7.30823L20.462 8.00474Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                    <path d="M16.951 21.1581L16.8488 21.2658C16.6681 21.0942 16.6681 20.8128 16.8488 20.6413L19.2734 18.3399C19.2734 18.3398 19.2734 18.3398 19.2735 18.3398L16.951 21.1581ZM16.951 21.1581L16.8488 21.2658M16.951 21.1581L16.8488 21.2658M16.8488 21.2658C17.025 21.433 17.3078 21.4332 17.484 21.2658L16.8488 21.2658ZM21.6982 21.2657C21.7871 21.3501 21.9026 21.3912 22.0158 21.3912L17.4841 21.2657L19.5911 19.2657L21.6982 21.2657ZM22.0158 21.3912C22.1288 21.3912 22.2444 21.3505 22.3335 21.2657L22.0158 21.3912Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                    <path d="M19.1381 26.131C19.1381 26.38 19.3485 26.5687 19.5912 26.5687C19.8338 26.5687 20.0443 26.3803 20.0443 26.131V18.651C20.0443 18.402 19.8339 18.2133 19.5912 18.2133C19.3484 18.2133 19.1381 18.402 19.1381 18.651V26.131Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                    <path d="M8.90225 22.1757C8.90225 27.6829 13.6199 32.1499 19.4022 32.1499C25.1845 32.1499 29.9021 27.6828 29.9021 22.1757C29.9021 16.6685 25.1845 12.2016 19.4022 12.2016C13.6198 12.2016 8.90225 16.6686 8.90225 22.1757ZM10.0304 22.1757C10.0304 17.2823 14.2273 13.2877 19.4022 13.2877C24.577 13.2877 28.7739 17.2823 28.7739 22.1757C28.7739 27.0691 24.577 31.0638 19.4022 31.0638C14.2273 31.0638 10.0304 27.0692 10.0304 22.1757Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                    <path d="M31.8777 22.7907H29.3937C29.1652 22.7907 28.9797 22.6147 28.9797 22.3978C28.9797 22.1808 29.1652 22.0048 29.3937 22.0048H31.8777C35.3016 22.0048 38.0875 19.3604 38.0875 16.1104C38.0875 12.8603 35.3016 10.216 31.8777 10.216H31.8179C31.6979 10.216 31.5837 10.1665 31.5051 10.0804C31.4264 9.99421 31.3909 9.87997 31.4081 9.76713C31.445 9.52237 31.4637 9.27649 31.4637 9.03707C31.4637 6.22039 29.0492 3.92854 26.0818 3.92854C24.9273 3.92854 23.8264 4.27098 22.8978 4.91908C22.6938 5.06139 22.404 4.99824 22.286 4.78519C19.6563 0.0320603 12.7877 -0.606237 9.22672 3.52857C7.72662 5.2705 7.13721 7.53648 7.60951 9.74498C7.66155 9.98889 7.4649 10.2163 7.20379 10.2163H7.0379C3.61392 10.2163 0.828024 12.8606 0.828024 16.1107C0.828024 19.3607 3.61392 22.0051 7.0379 22.0051H9.52182C9.7504 22.0051 9.93582 22.1811 9.93582 22.3981C9.93582 22.615 9.75041 22.791 9.52182 22.791H7.0379C3.15729 22.791 0 19.7941 0 16.1106C0 12.5305 2.98249 9.59898 6.71291 9.4373C6.3625 7.14603 7.03258 4.83486 8.58562 3.0312C12.3982 -1.39606 19.7047 -0.899826 22.7933 4.0369C23.7786 3.45054 24.9061 3.14294 26.0816 3.14294C29.6768 3.14294 32.5257 6.0475 32.2764 9.44097C35.9725 9.63773 38.9154 12.5538 38.9154 16.1104C38.9154 19.7941 35.7581 22.7907 31.8775 22.7907L31.8777 22.7907Z" fill="#475569" />
                                                </svg>
                                                <div className={classes.dragAndDropTitle}>
                                                    <div>
                                                        {languageData.DragDropFiles}{" "}
                                                        <span className={classes.uploadButton}>
                                                            {languageData.Browse}
                                                        </span>
                                                    </div>
                                                    <div className={classes.dragAndDropDescription}>
                                                        {languageData.SupportedFormates}
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </>
                                )}
                                <input
                                    onChange={this.handleTaxChange}
                                    type="file"
                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    hidden
                                    id="uploadInput2"
                                />
                            </div>
                        </Box>
                    </Grid>

                    <Grid xs={6} item className={classes.boxMargin} >
                        <Box className={classes.boxWidth}>
                            <span className={classes.labelText}>{languageData.OtherDocuments}</span>
                            <div
                                onDragLeave={this.handleDragLeave}
                                onDragOver={this.handleDragOver}
                                onDrop={this.handleDrop}
                            >
                                {this.state.otherDocument ? (
                                    <Box className={classes.inputBorderTextDrag}>
                                        {this.state.otherDocument?.name}
                                    </Box>
                                ) : (
                                    <>
                                        <label
                                            className={classes.documentMainBox}
                                            htmlFor="uploadInput3"
                                        >
                                            <div className={classes.dragAndGropText}>
                                                <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.90225 22.1757C8.90225 27.6829 13.6199 32.1499 19.4022 32.1499C25.1845 32.1499 29.9021 27.6828 29.9021 22.1757C29.9021 16.6685 25.1845 12.2016 19.4022 12.2016C13.6198 12.2016 8.90225 16.6686 8.90225 22.1757ZM10.0304 22.1757C10.0304 17.2823 14.2273 13.2877 19.4022 13.2877C24.577 13.2877 28.7739 17.2823 28.7739 22.1757C28.7739 27.0691 24.577 31.0638 19.4022 31.0638C14.2273 31.0638 10.0304 27.0692 10.0304 22.1757Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                    <path d="M31.8777 22.7907H29.3937C29.1652 22.7907 28.9797 22.6147 28.9797 22.3978C28.9797 22.1808 29.1652 22.0048 29.3937 22.0048H31.8777C35.3016 22.0048 38.0875 19.3604 38.0875 16.1104C38.0875 12.8603 35.3016 10.216 31.8777 10.216H31.8179C31.6979 10.216 31.5837 10.1665 31.5051 10.0804C31.4264 9.99421 31.3909 9.87997 31.4081 9.76713C31.445 9.52237 31.4637 9.27649 31.4637 9.03707C31.4637 6.22039 29.0492 3.92854 26.0818 3.92854C24.9273 3.92854 23.8264 4.27098 22.8978 4.91908C22.6938 5.06139 22.404 4.99824 22.286 4.78519C19.6563 0.0320603 12.7877 -0.606237 9.22672 3.52857C7.72662 5.2705 7.13721 7.53648 7.60951 9.74498C7.66155 9.98889 7.4649 10.2163 7.20379 10.2163H7.0379C3.61392 10.2163 0.828024 12.8606 0.828024 16.1107C0.828024 19.3607 3.61392 22.0051 7.0379 22.0051H9.52182C9.7504 22.0051 9.93582 22.1811 9.93582 22.3981C9.93582 22.615 9.75041 22.791 9.52182 22.791H7.0379C3.15729 22.791 0 19.7941 0 16.1106C0 12.5305 2.98249 9.59898 6.71291 9.4373C6.3625 7.14603 7.03258 4.83486 8.58562 3.0312C12.3982 -1.39606 19.7047 -0.899826 22.7933 4.0369C23.7786 3.45054 24.9061 3.14294 26.0816 3.14294C29.6768 3.14294 32.5257 6.0475 32.2764 9.44097C35.9725 9.63773 38.9154 12.5538 38.9154 16.1104C38.9154 19.7941 35.7581 22.7907 31.8775 22.7907L31.8777 22.7907Z" fill="#475569" />
                                                    <path d="M20.4559 8.01141L20.3669 7.9862C20.0729 7.90297 19.8935 7.60315 19.9861 7.30832L20.4559 8.01141ZM20.4559 8.01141L20.462 8.00474M20.4559 8.01141L20.462 8.00474M20.462 8.00474C20.7249 8.03375 20.9848 7.88125 21.0657 7.62202C21.7184 5.54042 23.7787 4.07851 26.0818 4.07851C26.3858 4.07851 26.6458 3.84263 26.6458 3.53554C26.6458 3.22845 26.3858 2.99258 26.0818 2.99258C23.1979 2.99258 20.7676 4.81556 19.9861 7.30823L20.462 8.00474Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                    <path d="M16.951 21.1581L16.8488 21.2658C16.6681 21.0942 16.6681 20.8128 16.8488 20.6413L19.2734 18.3399C19.2734 18.3398 19.2734 18.3398 19.2735 18.3398L16.951 21.1581ZM16.951 21.1581L16.8488 21.2658M16.951 21.1581L16.8488 21.2658M16.8488 21.2658C17.025 21.433 17.3078 21.4332 17.484 21.2658L16.8488 21.2658ZM21.6982 21.2657C21.7871 21.3501 21.9026 21.3912 22.0158 21.3912L17.4841 21.2657L19.5911 19.2657L21.6982 21.2657ZM22.0158 21.3912C22.1288 21.3912 22.2444 21.3505 22.3335 21.2657L22.0158 21.3912Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                    <path d="M19.1381 26.131C19.1381 26.38 19.3485 26.5687 19.5912 26.5687C19.8338 26.5687 20.0443 26.3803 20.0443 26.131V18.651C20.0443 18.402 19.8339 18.2133 19.5912 18.2133C19.3484 18.2133 19.1381 18.402 19.1381 18.651V26.131Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                </svg>


                                                <div className={classes.dragAndDropTitle}>
                                                    <div>
                                                        {languageData.DragDropFiles}{" "}
                                                        <span className={classes.uploadButton}>
                                                            {languageData.Browse}
                                                        </span>
                                                    </div>
                                                    <div className={classes.dragAndDropDescription}>
                                                        {languageData.SupportedFormates}
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </>
                                )}
                                <input
                                    hidden
                                    id="uploadInput3"
                                    type="file"
                                    onChange={this.handleOtherDocumentChange}
                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                />
                            </div>
                        </Box>

                    </Grid>
                </Grid>
            </>
        )
    }

    renderFileUploadPart = () => {
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslation[currentLanguage];
        return (
            <>
                <Box className={classes.mainBoxConatiner}>
                    <span className={classes.lableTextBox}>{languageData.UploadDocuments}</span>
                    <Grid className={classes.gridMainContainer} >
                        <Grid xs={6} item>
                            <Box className={classes.boxWidth}>
                                <span className={classes.labelText}>{languageData.BusinessRegistrationDocument}</span>
                                <div
                                    onDragLeave={this.handleDragLeave}
                                    onDragOver={this.handleDragOver}
                                    onDrop={this.handleDrop}
                                >
                                    {this.state.selectedFile ? (
                                        <Box className={classes.inputBorderTextDrag}>
                                            {this.state.selectedFile?.name}
                                        </Box>
                                    ) : (
                                        <>
                                            <label
                                                className={classes.documentMainBox}
                                                htmlFor="uploadInput"
                                            >
                                                <div className={classes.dragAndGropText}>
                                                    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M31.8777 22.7907H29.3937C29.1652 22.7907 28.9797 22.6147 28.9797 22.3978C28.9797 22.1808 29.1652 22.0048 29.3937 22.0048H31.8777C35.3016 22.0048 38.0875 19.3604 38.0875 16.1104C38.0875 12.8603 35.3016 10.216 31.8777 10.216H31.8179C31.6979 10.216 31.5837 10.1665 31.5051 10.0804C31.4264 9.99421 31.3909 9.87997 31.4081 9.76713C31.445 9.52237 31.4637 9.27649 31.4637 9.03707C31.4637 6.22039 29.0492 3.92854 26.0818 3.92854C24.9273 3.92854 23.8264 4.27098 22.8978 4.91908C22.6938 5.06139 22.404 4.99824 22.286 4.78519C19.6563 0.0320603 12.7877 -0.606237 9.22672 3.52857C7.72662 5.2705 7.13721 7.53648 7.60951 9.74498C7.66155 9.98889 7.4649 10.2163 7.20379 10.2163H7.0379C3.61392 10.2163 0.828024 12.8606 0.828024 16.1107C0.828024 19.3607 3.61392 22.0051 7.0379 22.0051H9.52182C9.7504 22.0051 9.93582 22.1811 9.93582 22.3981C9.93582 22.615 9.75041 22.791 9.52182 22.791H7.0379C3.15729 22.791 0 19.7941 0 16.1106C0 12.5305 2.98249 9.59898 6.71291 9.4373C6.3625 7.14603 7.03258 4.83486 8.58562 3.0312C12.3982 -1.39606 19.7047 -0.899826 22.7933 4.0369C23.7786 3.45054 24.9061 3.14294 26.0816 3.14294C29.6768 3.14294 32.5257 6.0475 32.2764 9.44097C35.9725 9.63773 38.9154 12.5538 38.9154 16.1104C38.9154 19.7941 35.7581 22.7907 31.8775 22.7907L31.8777 22.7907Z" fill="#475569" />
                                                        <path d="M16.951 21.1581L16.8488 21.2658C16.6681 21.0942 16.6681 20.8128 16.8488 20.6413L19.2734 18.3399C19.2734 18.3398 19.2734 18.3398 19.2735 18.3398L16.951 21.1581ZM16.951 21.1581L16.8488 21.2658M16.951 21.1581L16.8488 21.2658M16.8488 21.2658C17.025 21.433 17.3078 21.4332 17.484 21.2658L16.8488 21.2658ZM21.6982 21.2657C21.7871 21.3501 21.9026 21.3912 22.0158 21.3912L17.4841 21.2657L19.5911 19.2657L21.6982 21.2657ZM22.0158 21.3912C22.1288 21.3912 22.2444 21.3505 22.3335 21.2657L22.0158 21.3912Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                        <path d="M19.1381 26.131C19.1381 26.38 19.3485 26.5687 19.5912 26.5687C19.8338 26.5687 20.0443 26.3803 20.0443 26.131V18.651C20.0443 18.402 19.8339 18.2133 19.5912 18.2133C19.3484 18.2133 19.1381 18.402 19.1381 18.651V26.131Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                        <path d="M8.90225 22.1757C8.90225 27.6829 13.6199 32.1499 19.4022 32.1499C25.1845 32.1499 29.9021 27.6828 29.9021 22.1757C29.9021 16.6685 25.1845 12.2016 19.4022 12.2016C13.6198 12.2016 8.90225 16.6686 8.90225 22.1757ZM10.0304 22.1757C10.0304 17.2823 14.2273 13.2877 19.4022 13.2877C24.577 13.2877 28.7739 17.2823 28.7739 22.1757C28.7739 27.0691 24.577 31.0638 19.4022 31.0638C14.2273 31.0638 10.0304 27.0692 10.0304 22.1757Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                        <path d="M20.4559 8.01141L20.3669 7.9862C20.0729 7.90297 19.8935 7.60315 19.9861 7.30832L20.4559 8.01141ZM20.4559 8.01141L20.462 8.00474M20.4559 8.01141L20.462 8.00474M20.462 8.00474C20.7249 8.03375 20.9848 7.88125 21.0657 7.62202C21.7184 5.54042 23.7787 4.07851 26.0818 4.07851C26.3858 4.07851 26.6458 3.84263 26.6458 3.53554C26.6458 3.22845 26.3858 2.99258 26.0818 2.99258C23.1979 2.99258 20.7676 4.81556 19.9861 7.30823L20.462 8.00474Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                    </svg>
                                                    <div className={classes.dragAndDropTitle}>
                                                        <div>
                                                            {languageData.DragDropFiles}{" "}
                                                            <span className={classes.uploadButton}>
                                                                {languageData.Browse}
                                                            </span>
                                                        </div>
                                                        <div className={classes.dragAndDropDescription}>
                                                            {languageData.SupportedFormates}
                                                        </div>
                                                    </div>
                                                </div>

                                            </label>
                                        </>
                                    )}
                                    <input
                                        id="uploadInput"
                                        hidden
                                        onChange={this.handleFileChange}
                                        accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        type="file"
                                    />

                                </div>
                            </Box>
                        </Grid>

                        <Grid xs={6} item>
                            <Box className={classes.boxWidth}>
                                <span className={classes.labelText}>{languageData.LicenseOrCertification}</span>
                                <div
                                    onDragLeave={this.handleDragLeave}
                                    onDragOver={this.handleDragOver}
                                    onDrop={this.handleDrop}
                                >
                                    {this.state.License ? (
                                        <Box className={classes.inputBorderTextDrag}>
                                            {this.state.License?.name}
                                        </Box>
                                    ) : (
                                        <>
                                            <label
                                                className={classes.documentMainBox}
                                                htmlFor="uploadInput1"
                                            >
                                                <div className={classes.dragAndGropText}>
                                                    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M31.8777 22.7907H29.3937C29.1652 22.7907 28.9797 22.6147 28.9797 22.3978C28.9797 22.1808 29.1652 22.0048 29.3937 22.0048H31.8777C35.3016 22.0048 38.0875 19.3604 38.0875 16.1104C38.0875 12.8603 35.3016 10.216 31.8777 10.216H31.8179C31.6979 10.216 31.5837 10.1665 31.5051 10.0804C31.4264 9.99421 31.3909 9.87997 31.4081 9.76713C31.445 9.52237 31.4637 9.27649 31.4637 9.03707C31.4637 6.22039 29.0492 3.92854 26.0818 3.92854C24.9273 3.92854 23.8264 4.27098 22.8978 4.91908C22.6938 5.06139 22.404 4.99824 22.286 4.78519C19.6563 0.0320603 12.7877 -0.606237 9.22672 3.52857C7.72662 5.2705 7.13721 7.53648 7.60951 9.74498C7.66155 9.98889 7.4649 10.2163 7.20379 10.2163H7.0379C3.61392 10.2163 0.828024 12.8606 0.828024 16.1107C0.828024 19.3607 3.61392 22.0051 7.0379 22.0051H9.52182C9.7504 22.0051 9.93582 22.1811 9.93582 22.3981C9.93582 22.615 9.75041 22.791 9.52182 22.791H7.0379C3.15729 22.791 0 19.7941 0 16.1106C0 12.5305 2.98249 9.59898 6.71291 9.4373C6.3625 7.14603 7.03258 4.83486 8.58562 3.0312C12.3982 -1.39606 19.7047 -0.899826 22.7933 4.0369C23.7786 3.45054 24.9061 3.14294 26.0816 3.14294C29.6768 3.14294 32.5257 6.0475 32.2764 9.44097C35.9725 9.63773 38.9154 12.5538 38.9154 16.1104C38.9154 19.7941 35.7581 22.7907 31.8775 22.7907L31.8777 22.7907Z" fill="#475569" />
                                                        <path d="M20.4559 8.01141L20.3669 7.9862C20.0729 7.90297 19.8935 7.60315 19.9861 7.30832L20.4559 8.01141ZM20.4559 8.01141L20.462 8.00474M20.4559 8.01141L20.462 8.00474M20.462 8.00474C20.7249 8.03375 20.9848 7.88125 21.0657 7.62202C21.7184 5.54042 23.7787 4.07851 26.0818 4.07851C26.3858 4.07851 26.6458 3.84263 26.6458 3.53554C26.6458 3.22845 26.3858 2.99258 26.0818 2.99258C23.1979 2.99258 20.7676 4.81556 19.9861 7.30823L20.462 8.00474Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                        <path d="M19.1381 26.131C19.1381 26.38 19.3485 26.5687 19.5912 26.5687C19.8338 26.5687 20.0443 26.3803 20.0443 26.131V18.651C20.0443 18.402 19.8339 18.2133 19.5912 18.2133C19.3484 18.2133 19.1381 18.402 19.1381 18.651V26.131Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                        <path d="M16.951 21.1581L16.8488 21.2658C16.6681 21.0942 16.6681 20.8128 16.8488 20.6413L19.2734 18.3399C19.2734 18.3398 19.2734 18.3398 19.2735 18.3398L16.951 21.1581ZM16.951 21.1581L16.8488 21.2658M16.951 21.1581L16.8488 21.2658M16.8488 21.2658C17.025 21.433 17.3078 21.4332 17.484 21.2658L16.8488 21.2658ZM21.6982 21.2657C21.7871 21.3501 21.9026 21.3912 22.0158 21.3912L17.4841 21.2657L19.5911 19.2657L21.6982 21.2657ZM22.0158 21.3912C22.1288 21.3912 22.2444 21.3505 22.3335 21.2657L22.0158 21.3912Z" fill="#475569" stroke="#1E293B" stroke-width="0.3" />
                                                        <path d="M8.90225 22.1757C8.90225 27.6829 13.6199 32.1499 19.4022 32.1499C25.1845 32.1499 29.9021 27.6828 29.9021 22.1757C29.9021 16.6685 25.1845 12.2016 19.4022 12.2016C13.6198 12.2016 8.90225 16.6686 8.90225 22.1757ZM10.0304 22.1757C10.0304 17.2823 14.2273 13.2877 19.4022 13.2877C24.577 13.2877 28.7739 17.2823 28.7739 22.1757C28.7739 27.0691 24.577 31.0638 19.4022 31.0638C14.2273 31.0638 10.0304 27.0692 10.0304 22.1757Z" fill="#475569" stroke="#F9FFF9" stroke-width="0.3" />
                                                    </svg>

                                                    <div className={classes.dragAndDropTitle}>
                                                        <div>
                                                            {languageData.DragDropFiles}{" "}
                                                            <span className={classes.uploadButton}>
                                                                {languageData.Browse}
                                                            </span>
                                                        </div>
                                                        <div className={classes.dragAndDropDescription}>
                                                            {languageData.SupportedFormates}
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </>
                                    )}
                                    <input
                                        type="file"
                                        hidden
                                        id="uploadInput1"
                                        onChange={this.handleLicenseFileChange}
                                        accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    />
                                </div>
                            </Box>

                        </Grid>
                    </Grid>
                    {
                        this.renderFileUploadOthersPart()
                    }


                </Box>
            </>
        )
    }
    renderInputField = () => {
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslation[currentLanguage];
        return (
            <>
                <Box className={classes.mainBoxConatiner}>
                    <span className={classes.lableTextBox}>{languageData.DebtorContactInformation}</span>
                    <Grid className={classes.gridMainContainer} >
                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="FullName-helper-text"
                                    className={classes.labelText}
                                >
                                    {languageData.FullName}
                                    <span className={classes.reqireText}>*</span>
                                </FormHelperText>
                                <OutlinedInput
                                    id="FullName-input"
                                    aria-describedby="FullName-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.firstName}
                                    onChange={(event) => this.firstNameInputProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.full_name}
                                />
                                {this.state.errors.full_name && <FormHelperText className={classes.errorText} error>{this.state.errors.full_name}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="Address-helper-text"
                                    className={classes.labelText}
                                >
                                    {languageData.Address}

                                </FormHelperText>
                                <OutlinedInput
                                    id="Address-input"
                                    aria-describedby="Address-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.address}
                                    onChange={(event) => this.lastAddressProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.Address}

                                />
                                {this.state.errors.Address && <FormHelperText className={classes.errorText} error>{this.state.errors.Address}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={classes.gridMainContainer} >
                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="PhoneNumber-helper-text"
                                    className={classes.labelText}
                                >
                                    {languageData.PhoneNumber}
                                    <span className={classes.reqireText}>*</span>
                                </FormHelperText>
                                <OutlinedInput
                                    id="PhoneNumber-input"
                                    aria-describedby="PhoneNumber-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.phone}
                                    onChange={(event) => this.phoneProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.phone_number}
                                />
                                {this.state.errors.phone_number && <FormHelperText className={classes.errorText} error>{this.state.errors.phone_number}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="Email-helper-text"
                                    className={classes.labelText}
                                >
                                    {languageData.EmailAddress}
                                    <span className={classes.reqireText}>*</span>
                                </FormHelperText>
                                <OutlinedInput
                                    id="Email-input"
                                    aria-describedby="Email-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.email}
                                    onChange={(event) => this.emailProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.email}
                                />
                                {this.state.errors.email && <FormHelperText className={classes.errorText} error>{this.state.errors.email}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslation[currentLanguage];
        return (
            <>
                <Paper style={{ backgroundColor: '#F1F5F9' }}>
                    <Container maxWidth='lg' style={{ display: 'flex', flexDirection: 'column', marginTop: "100px" }}>

                        <Grid className={classes.container} >
                            <Grid xs={12} item>
                                <Text size="2xxl" weight="extraBold" variant="primary" family="poppins" align='center' >{languageData.FillAllTheRequiredData}</Text>
                            </Grid>

                            {this.renderInputField()}
                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>{languageData.DebtDetails}</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Account-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.AccountNumberReferenceNumber}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Account-input"
                                                aria-describedby="Account-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.accountNumber}
                                                onChange={(event) => this.accountNumberInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.account_number}
                                            />
                                            {this.state.errors.account_number && <FormHelperText className={classes.errorText} error>{this.state.errors.account_number}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Original-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.OriginalCreditorName}

                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Original-input"
                                                aria-describedby="Original-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.creditorName}
                                                onChange={(event) => this.creditorNameInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.CreditorName}
                                            />
                                            {this.state.errors.CreditorName && <FormHelperText className={classes.errorText} error>{this.state.errors.CreditorName}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Debt-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.CurrentDebtAmountOwed}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Debt-input"
                                                aria-describedby="Debt-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.DebtAmount}
                                                onChange={(event) => this.DebtAmountInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.current_debt_amount}
                                            />
                                            {this.state.errors.current_debt_amount && <FormHelperText className={classes.errorText} error>{this.state.errors.current_debt_amount}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Email-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.DateOfTheLastPayment}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="lastPayment"
                                                aria-describedby="Email-helper-text"
                                                labelWidth={0}
                                                type="date"
                                                className={classes.inputTextFiled}
                                                value={this.state.dateOfLastPayment}
                                                onChange={(event) => this.dateOfLastPaymentInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.last_payment_date}
                                            />
                                            {this.state.errors.last_payment_date && <FormHelperText className={classes.errorText} error>{this.state.errors.last_payment_date}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid style={{ display: 'flex', gap: '20px', justifyContent: "start", alignItems: "center" }} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Account-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.AnyPastPaymentsMade}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="pastPayment"
                                                aria-describedby="Account-helper-text"
                                                labelWidth={0}
                                                type="date"
                                                inputProps={{ placeholder: '' }}
                                                className={classes.inputTextFiled}
                                                value={this.state.pastPayment}
                                                onChange={(event) => this.pastPaymentInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.past_payment_date}
                                            />
                                            {this.state.errors.past_payment_date && <FormHelperText className={classes.errorText} error>{this.state.errors.past_payment_date}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>{languageData.FinancialInformation}</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Employment-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.EmploymentStatus}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Employment-input"
                                                aria-describedby="Employment-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentStatus}
                                                onChange={(event) => this.employmentStatustInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.employment_status}
                                            />
                                            {this.state.errors.employment_status && <FormHelperText className={classes.errorText} error>{this.state.errors.employment_status}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Current-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.CurrentEmployerName}
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Current-input"
                                                aria-describedby="Current-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentName}
                                                onChange={(event) => this.employmentNameInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.EmploymentName}
                                            />
                                            {this.state.errors.EmploymentName && <FormHelperText className={classes.errorText} error>{this.state.errors.EmploymentName}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Employe-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.CurrentEmployerContact}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Employe-input"
                                                aria-describedby="Employe-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentContact}
                                                onChange={(event) => this.employmentContactInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.current_employer_phone_number}
                                            />
                                            {this.state.errors.current_employer_phone_number && <FormHelperText className={classes.errorText} error>{this.state.errors.current_employer_phone_number}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Income-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.MonthlyIncome}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Income-input"
                                                aria-describedby="Income-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentMonthly}
                                                onChange={(event) => this.employmentMonthlyInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.monthly_income}
                                            />
                                            {this.state.errors.monthly_income && <FormHelperText className={classes.errorText} error>{this.state.errors.monthly_income}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid style={{ display: 'flex', gap: '20px', justifyContent: "start", alignItems: "center" }} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Expenses-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.MonthlyExpenses}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Expenses-input"
                                                aria-describedby="Expenses-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.monthlyExpenses}
                                                onChange={(event) => this.monthlyExpensesInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.monthly_expenses}
                                            />
                                            {this.state.errors.monthly_expenses && <FormHelperText className={classes.errorText} error>{this.state.errors.monthly_expenses}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Outstanding-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.OtherOutstandingDebts}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Outstanding-input"
                                                aria-describedby="Outstanding-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.otherOutstandingDebts}
                                                onChange={(event) => this.otherOutstandingDebtsInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.other_outstandianing_debts}
                                            />
                                            {this.state.errors.other_outstandianing_debts && <FormHelperText className={classes.errorText} error>{this.state.errors.other_outstandianing_debts}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>{languageData.BankingInformation}</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Bank-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.BankAccountDetails}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Bank-input"
                                                aria-describedby="Bank-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.bankAccountDetails}
                                                onChange={(event) => this.BankAccountDetailsInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.bank_account_details}
                                            />
                                            {this.state.errors.bank_account_details && <FormHelperText className={classes.errorText} error>{this.state.errors.bank_account_details}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Preferred-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.PreferredPaymentMethod}
                                            </FormHelperText>
                                            <Select
                                                labelId="Preferred-simple-select-label"
                                                id="Preferred-simple-select"
                                                className={classes.inputTextFiled}
                                                value={this.state.selectPayment}
                                                onChange={this.handlePaymentChange}
                                                placeholder="Net Banking"

                                            >
                                                <MenuItem value={10}>Paytm</MenuItem>
                                                <MenuItem value={20}>PhonePe</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>{languageData.BankruptcyInformation}</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="debtor-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.WhetherDebtorFiledBankruptcy}
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="debtor-input"
                                                aria-describedby="debtor-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.bankruptcy}
                                                onChange={(event) => this.BankruptcyInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.is_bankruptcy_declared}
                                            />
                                            {this.state.errors.is_bankruptcy_declared && <FormHelperText className={classes.errorText} error>{this.state.errors.is_bankruptcy_declared}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Details-helper-text"
                                                className={classes.labelText}
                                            >
                                                {languageData.DetailsOfAnyBankruptcyProceedings}
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Details-input"
                                                aria-describedby="Details-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.bankruptcyDetail}
                                                onChange={(event) => this.BankruptcyDetailInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.BankruptcyDetail}
                                            />
                                            {this.state.errors.BankruptcyDetail && <FormHelperText className={classes.errorText} error>{this.state.errors.BankruptcyDetail}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            {this.renderFileUploadPart()}
                        </Grid>
                    </Container >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <ButtonCustom
                            size="sm"
                            themes="dark"
                            onClickHandle={this.handleSubmit}
                            data-testid="signup-btn"
                        >
                            {languageData.SignUp}
                        </ButtonCustom>
                    </Box>
                </Paper >

            </>
        )
        // Customizable Area End
    }
}


export default withStyles(styles)(ServiceInternalForm);