import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslation = require('../../languagesupport/src/LanguageData/RequestVerificaion.json')
export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    form : {
        id : string,
        value : string,
        name : string,
        error : string,
        regex : RegExp,
        placeholder : string
    }[]
    showStep1 : boolean,
    acceptedTerm : boolean,
    hideSucess : boolean,
    currentLanguage:'en'|'ar'
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class VerificationRequestController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    verifyCompanyCallId: any;
    requestVerificationCallId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            form : languageTranslation[configJSON.EN].Form,
            showStep1 : true,
            acceptedTerm : true,
            hideSucess: true,
            currentLanguage:configJSON.EN
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
    
            if (apiRequestCallId && responseJson) {
              if (apiRequestCallId === this.verifyCompanyCallId) {
                this.handleStep1Response(responseJson)
              } else if(apiRequestCallId === this.requestVerificationCallId) {
                this.handleStep2Response(responseJson)
              }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({ currentLanguage: language ,form:languageTranslation[language].Form});
        // Customizable Area End
    }
    convertErrorsToIdMessageList(errorList: {[key: string]: string}[]): {id:string, message:string}[] {
        let convertedList: {id:string, message:string}[] = [];
        errorList.forEach((errorDict: {[key: string]: string}) => {
            for (let key in errorDict) {
                if (errorDict.hasOwnProperty(key)) {
                    convertedList.push({ id: key, message: `${key.replace("_"," ")} ${errorDict[key]}` });
                }
            }
        });
        return convertedList;
    }
    handleStep1Response = (res:any) => {
        if (!res.error) {
            this.setState({
                showStep1 : false
            })
        } else {
          this.handleApiError([{id: "company_registration_number", message : res.error}])
        }
    }
    handleStep2Response = (res:any) => {
        if (!res.errors) {
            this.setState({
                hideSucess : false
            })
        } else {
          this.handleApiError(this.convertErrorsToIdMessageList(res.errors))
        }
    }
    handleApiError = (errors:{id : string, message : string}[]) => {
        const newForm = this.state.form;
        errors.forEach(error => {
            newForm.forEach(form => {
                if(form.id === error.id){
                    form.error = `${error.message}`
                }
            })
        })
        this.setState({
            form : newForm
        })
    }
    handleNext = () => {
        let errorCount : number = 0
        const newForm = this.state.form.slice(0,2).map(form => {
            if(form.value === "" || !form.value){
                form.error = this.state.currentLanguage === configJSON.EN?configJSON.FieldRequired:configJSON.FieldRequiredInArabic;
                errorCount++;
            }
            return form
        })
        if(errorCount>0){
            this.setState({
                form : [...newForm, ...this.state.form.slice(2, this.state.form.length)]
            })
        }else {
            this.verifyCompany()
        }
    }
    handleSubmit = () => {
        const newForm2 = this.state.form.slice(2,this.state.form.length);
        let errorCount2 : number = 0
        newForm2.forEach(form2 => {
            if(!new RegExp(form2.regex).test(form2.value)){
                const preMsg = this.state.currentLanguage === configJSON.EN ? configJSON.Invalid: configJSON.InvalidInArabic
                form2.error = `${preMsg} ${form2.name}`;
                errorCount2++;
            }
            return form2
        })
        if(errorCount2>0){
            this.setState({
                form : [...this.state.form.slice(0,2), ...newForm2]
            })
        }else {
            this.sendRequest()
        }
    }
    handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        const newForm = this.state.form;
        newForm.forEach(form => {
            if(form.id === event.target.id){
                form.value = event.target.value
                form.error = ""
            }
            return form
        })
        this.setState({
            form : newForm
        })
    }
    verifyCompany(){
        
        const headers = {
            "Content-Type": "application/json"
        }

        const data = {
            "company_name" : this.state.form[0].value,
            "commercial_registration_number" : this.state.form[1].value
        }

        const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.verifyCompanyCallId = requestMessage.messageId;

        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verifyCompanyEndPoint
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    async sendRequest(){
        const headers = {
            "Content-Type": "application/json"
        }

        const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.requestVerificationCallId = requestMessage.messageId;

        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verificationRequestEndPoint
        );

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );

        

        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
        );

        let data = {
            "company_name" : this.state.form[0].value,
            "commercial_registration_number" : this.state.form[1].value,
            "first_name": this.state.form[2].value,
            "last_name": this.state.form[3].value,
            "email": this.state.form[4].value,
            "full_phone_number": this.state.form[5].value,
            "legal_business_name": this.state.form[6].value
        }

        const userId = await getStorageData("userId")

        if(userId){
            data = {...data, ...{"account_id" : userId}}
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
