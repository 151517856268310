import React from "react";
// Customizable Area Start
import PlanTransactionsController from "./PlanTransactionsController.web";
import { Box, Button, Card, CardContent, Chip, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
const languageTranslationData = require('../../languagesupport/src/LanguageData/PlanTransactions.json');
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { visaIcon, profileImg } from './assets';
// Customizable Area End

// Customizable Area Start
const webStyle = {
    main: {
        padding: 20,
        backgroundColor: "#fff",
        boxShadow: "0px 8px 32px 0px #0000000F",
    },
    plans: {
        borderRadius: 28,
        border: '1px solid #BFDBFE',
        boxShadow: 'none'
        // width: 384,
        // maxWidth: 384
    },
    payment: {
        borderRadius: 8,
        border: '1px solid #BFDBFE',
        boxShadow: 'none'
    },
    content: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        background: '#EFF6FF'
    },
    paymentContent: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#EFF6FF"
    },
    description: {
        listStyleType: 'disc',
        paddingLeft: 15,
    },
    descriptionItem: {
        fontSize: 14 as const,
        fontWeight: 400
    },
    services: {
        paddingBottom: 2,
        paddingLeft: 0
    },
    servicesIcon: {
        minWidth: 32 as const
    },
    chip: {
        display: "flex",
        width: "65px",
        marginLeft: 'auto',
        color: '#1D4ED8',
        backgroundColor: "#DBEAFE"
    },
    successchip: {
        display: "flex",
        width: "90px",
        fontSize: 12,
        fontWeight: 700,
        color: '#059669',
        backgroundColor: "#D1FAE5",
        height: "20px",
        marginTop: "6px",
    },
    mainDetail: {
        display: "flex",
        justifiyContent: "space-between"
    },
    detail: {
        flexBasis: "20%",
        maxWidth: "20%"
    }
}

const StyledLisItemText = styled(ListItemText)({
    padding: 0,
    '& span': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "22px"
    }
  });
// Customizable Area End

export class OfferDetails extends PlanTransactionsController {
// Customizable Area Start
// Customizable Area End

render () {
// Customizable Area Start
const languageData = languageTranslationData[this.state.currentLanguage];
const subscription = languageData.SubscriptionDescription;
return (
    <Box>
        <Box sx={webStyle.main}>
            <Box>
                <Text variant="primary" size="mm" weight="lightBold">{languageData["#669059"]}</Text>
                <Box display="flex" mt={1} alignItems="center" mb={2} gridGap="10px">
                    <Box borderRadius="40px">
                        <Chip style={webStyle.successchip} label={languageData.Successful} />
                    </Box>
                    <Text size="xx" weight="bold" variant="neutrals">{languageData.Date} {languageData["22 Feb 2022"]}</Text>
                </Box>
            </Box>
            <Divider />
            <Box mt={3} mb={3}>
                <Text size="mm" variant="lightBlue" weight="lightBold">{languageData.UserInformation}</Text>
                <Box display="flex" gridGap="10px" alignItems="center" mt={3} mb={3}>
                    <img src={profileImg} />
                    <Box display="flex" flexDirection="column">
                        <Text size="mm" weight="lightBold" variant="lightBlue">{languageData.James}</Text>
                        <Text size="sm" weight="minBold" variant="grey">james.carter@example.com</Text>
                    </Box>
                </Box>
                <Grid container spacing={2} style={webStyle.mainDetail}>
                    <Grid item xs={2} sm={2} style={webStyle.detail}>
                        <Text size="xm" weight="lightBold" variant="lightBlue">{languageData.DummyNumber}</Text>
                        <Text size="sm" weight="minBold" variant="grey">{languageData.PhoneNumber}</Text>
                    </Grid>
                    <Grid item xs={2} sm={2} style={webStyle.detail}>
                        <Text size="xm" weight="lightBold" variant="lightBlue">{languageData.DummyCountry}</Text>
                        <Text size="sm" weight="minBold" variant="grey">{languageData.Country}</Text>
                    </Grid>
                    <Grid item  xs={2} sm={2} style={webStyle.detail}>
                        <Text size="xm" weight="lightBold" variant="lightBlue">{languageData.DummyState}</Text>
                        <Text size="sm" weight="minBold" variant="grey">{languageData.State}</Text>
                    </Grid>
                    <Grid item  xs={2} sm={2} style={webStyle.detail}>
                        <Text size="xm" weight="lightBold" variant="lightBlue">{languageData.DummyCity}</Text>
                        <Text size="sm" weight="minBold" variant="grey">{languageData.City}</Text>
                    </Grid>
                    <Grid item  xs={2} sm={2} style={webStyle.detail}>
                        <Text size="xm" weight="lightBold" variant="lightBlue">{languageData.DummyZip}</Text>
                        <Text size="sm" weight="minBold" variant="grey">{languageData.Zip}</Text>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={6} sm={6}>
                        <Text size="mm" variant="lightBlue" weight="lightBold">{languageData.PlanPricing}</Text>
                        <Box mt={2}>
                            <Card style={webStyle.plans}>
                                <CardContent style={webStyle.content}>
                                     <Chip style={webStyle.chip} label={languageData.Active} />
                                    <Box display="flex" alignItems="center">
                                        <Text size="1xl" variant="lightBlue" weight="extraBold">{languageData.Price}  </Text>/<Text size="xm" variant="lightBlue" weight="bold">{languageData.PerYear}</Text>
                                    </Box>
                                    <Box mt={2}>
                                        <Text size="1xl" variant="lightBlue" weight="lightBold" lineHeight="44px">{languageData.YearlySubscription}</Text>
                                    </Box>
                                    <List>
                                        { subscription.map((service: string) => 
                                            <ListItem style={webStyle.services} key={service}>
                                                <ListItemIcon style={webStyle.servicesIcon}>
                                                    <CheckCircleRoundedIcon htmlColor="#34D399" />
                                                </ListItemIcon>
                                                <StyledLisItemText>
                                                    {service}
                                                </StyledLisItemText>
                                            </ListItem>
                                        )}
                                        </List> 
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                        <Text size="mm" variant="lightBlue" weight="lightBold">{languageData.PaymentMethod}</Text>
                        <Box mt={2}>
                            <Card style={webStyle.payment}>
                                <CardContent style={webStyle.paymentContent}>
                                    <Box>
                                        <Text>{languageData["**** 8239"]}</Text>
                                        <Box display="flex" marginTop="10px">
                                            <Text>{languageData.Visa}</Text>
                                            <Button style={{ padding: 0, textTransform: "none"}} variant="text"><Text>{languageData.Edit}</Text></Button>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <img src={visaIcon} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box display="flex" gridGap="10px" mt={3}>
            <Button variant="contained" 
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 700, padding: "10px 24px", color: '#FFFFFF', backgroundColor: "#1E3A8A" ,textTransform: "none" }}>
                {languageData.ExportTransactions}</Button>
            <Button variant="contained" 
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 700, padding: "10px 24px", color: "#0F172A", backgroundColor: "#EFF6FF", textTransform: "none" }}>
                {languageData.Refund}</Button>
        </Box>
    </Box>
)
// Customizable Area End

}
// Customizable Area Start
// Customizable Area End

}