import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {getStorageData} from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    previewUrl: string | null;
    isDragging: boolean;
    selectedFile: File | null;
    firstName: string;
    address: string;
    phone: string;
    email: string;
    accountNumber: string,
    creditorName: string,
    DebtAmount: string,
    dateOfLastPayment: string,
    pastPayment: string,
    employmentStatus: string,
    employmentName: string,
    employmentContact: string,
    employmentMonthly:string,
    monthlyExpenses: string,
    otherOutstandingDebts: string,
    bankAccountDetails: string,
    bankruptcy: string,
    bankruptcyDetail: string,
    businessRegistration: string,
    selectPayment: string,
    License: File | null,
    taxRegistration:File | null,
    otherDocument: File | null,
    errors: {
        full_name: string,
        phone_number: string,
        Address: string,
        email: string,
        account_number: string,
        CreditorName: string,
        DebtAmount: string,
        last_payment_date: string,
        employment_status: string,
        EmploymentName: string,
        current_employer_phone_number: string,
        monthly_income:string,
        monthly_expenses: string,
        other_outstandianing_debts: string,
        BankAccountDetails: string,
        is_bankruptcy_declared: string,
        BankruptcyDetail: string,
        past_payment_date: string,
        bank_account_details: string,
        current_debt_amount: string
      } 
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class RequestFormController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    createAccountApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isDragging: false,
            previewUrl: "",
            selectedFile: null,
            firstName: "",
            address: "",
            email: "",
            phone: "",
            accountNumber: "",
            creditorName: "",
            DebtAmount: "",
            pastPayment: "",
            dateOfLastPayment: "",
            employmentStatus: "",
            employmentName: "",
            employmentContact: "",
            employmentMonthly:"",
            monthlyExpenses: "",
            otherOutstandingDebts: "",
            bankAccountDetails: "",
            bankruptcy: "",
            bankruptcyDetail: "",
            businessRegistration: "",
            selectPayment: "",
             License: null,
             taxRegistration: null,
             otherDocument: null,
            errors: {
                full_name: "",
                phone_number: "",
                Address: "",
                email: "",
                account_number: "",
                CreditorName: "",
                DebtAmount: "",
                last_payment_date: "",
                employment_status: "",
                EmploymentName: "",
                current_employer_phone_number: "",
                monthly_income: "",
                monthly_expenses: "",
                other_outstandianing_debts: "",
                BankAccountDetails: "",
                is_bankruptcy_declared: "",
                BankruptcyDetail: "",
                past_payment_date: "",
                bank_account_details:"",
                current_debt_amount: ""
                },
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiRequestCallId && responseJson) {
             this.sucessCell(apiRequestCallId,responseJson)
          }
        }

        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   
  async sendData() {
    let formdata = new FormData();
    let userId =  await getStorageData("userId");  
    formdata.append("[debtor_information][full_name]", this.state.firstName);
    formdata.append("[debtor_information][phone_number]", this.state.phone);
    formdata.append("[debtor_information][address]", this.state.address);
    formdata.append("[debtor_information][email]", this.state.email);
    formdata.append("[debtor_information][account_number]", this.state.accountNumber);
    formdata.append("[debtor_information][current_debt_amount]", this.state.DebtAmount);
    formdata.append("[debtor_information][employment_status]", this.state.employmentStatus);
    formdata.append("[debtor_information][past_payment_date]", this.state.pastPayment);
    formdata.append("[debtor_information][current_employer_phone_number]", this.state.employmentContact);
    formdata.append("[debtor_information][last_payment_date]", this.state.dateOfLastPayment);
    formdata.append("[debtor_information][original_creditor_name]", this.state.creditorName);
    formdata.append("[debtor_information][monthly_income]", this.state.employmentMonthly);
    formdata.append("[debtor_information][monthly_expenses]", this.state.employmentMonthly);
    formdata.append("[debtor_information][other_outstandianing_debts]", this.state.otherOutstandingDebts);
    formdata.append("[debtor_information][is_bankruptcy_declared]", this.state.bankruptcy);
    formdata.append("[debtor_information][bank_account_details]", this.state.bankAccountDetails);
    formdata.append("[debtor_information][current_employer_name]", this.state.employmentName);
    formdata.append("[debtor_information][bankruptcy_details]", this.state.bankruptcyDetail);  
    formdata.append("[debtor_information][admin_user_id]",userId);
    if (this.state.selectedFile !== null) {
      formdata.append("[debtor_information][business_registration]", this.state.selectedFile);
    }
    if (this.state.License !== null) {
      formdata.append("[debtor_information][license_certification]", this.state.License);

    }
    if (this.state.taxRegistration !== null) {
      formdata.append("[debtor_information][tax_registration]", this.state.taxRegistration);

    }
    if (this.state.otherDocument !== null) {
      formdata.append("[debtor_information][other_documents][]", this.state.otherDocument);
    }

    let token =  await getStorageData("accessToken");

    let myHeaders = new Headers();
    myHeaders.append("token", token);
        
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.createAccountApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/debtor_informations/create_from_superadmin"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        myHeaders
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage)
      
    };

  
    handleServiceContactNavigate = () => {
      const to = new Message(getName(MessageEnum.NavigationMessage));
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "dashboardRequests"
      );
      to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(to);
    }
    sucessCell = async (apiRequestCallId : string,responseJson :{data :{[key:string]:string},errors:[{[key:string]:string}],error:string}) =>{
      if (apiRequestCallId === this.createAccountApiCallId) {
        if (responseJson.data) {
          this.handleServiceContactNavigate();
        }
        else {
         this.handleMappingError(responseJson)
        }
      }
    }
     
    handleMappingError = (responseJson: { errors: [{ [key: string]: string }] }) => {
      for (const error of responseJson.errors) {
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            const value = error[key];
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                [key]: value
              }
            }))
          }
        }
      }
    }
      handleSubmit = () => {
        this.sendData()
      }
   

      resetError(field: string) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            [field]: '',
          },
        }));
      }

    firstNameInputProps = {
        onChangeText: (text: string) => {
          this.setState({ firstName: text });
          this.resetError("full_name");
        },
      };

      lastAddressProps = {
        onChangeText: (text: string) => {
          this.setState({ address: text });
          this.resetError("Address");
        },
      };

      emailProps = {
        onChangeText: (text: string) => {
          this.setState({ email: text });
          this.resetError("email");

        },
      };

      phoneProps = {
        onChangeText: (text: string) => {
            const inputValue = text?.replace(/[a-zA-Z@!#$%^&*()_+={}[\]:;<>,.?~\\/-]/g, '');
            this.setState({ phone: inputValue });
          this.resetError("phone_number");

        },
      };
    
      accountNumberInputProps = {
        onChangeText: (text: string) => {
          this.setState({ accountNumber: text });
          this.resetError("account_number");

        },
      };

      creditorNameInputProps = {
        onChangeText: (text: string) => {
          this.setState({ creditorName: text });
          this.resetError("CreditorName");
        },
      };

      DebtAmountInputProps = {
        onChangeText: (text: string) => {
          this.setState({ DebtAmount: text });
          this.resetError("current_debt_amount");

        },
      };

      dateOfLastPaymentInputProps = {
        onChangeText: (text: string) => {
          this.setState({ dateOfLastPayment: text });
          this.resetError("last_payment_date");
        },
      };

      pastPaymentInputProps = {
        onChangeText: (text: string) => {
          this.setState({ pastPayment: text });
          this.resetError("past_payment_date");
        },
      };
     employmentStatustInputProps = {
        onChangeText: (text: string) => {
          this.setState({ employmentStatus: text });
          this.resetError("employment_status")
        },
      };

      employmentNameInputProps = {
        onChangeText: (text: string) => {
          this.setState({ employmentName: text });
          this.resetError("EmploymentName")
        },
      };

      employmentContactInputProps = {
        onChangeText: (text: string) => {
          this.setState({ employmentContact: text });
          this.resetError("current_employer_phone_number")
        },
      };

      employmentMonthlyInputProps = {
        onChangeText: (text: string) => {
          this.setState({ employmentMonthly: text });
          this.resetError("monthly_income")
        },
      };

      monthlyExpensesInputProps = {
        onChangeText: (text: string) => {
          this.setState({ monthlyExpenses: text });
          this.resetError("monthly_expenses")
        },
      };

      otherOutstandingDebtsInputProps = {
        onChangeText: (text: string) => {
          this.setState({ otherOutstandingDebts: text });
          this.resetError("other_outstandianing_debts")
        },
      };

      BankAccountDetailsInputProps = {
        onChangeText: (text: string) => {
          this.setState({ bankAccountDetails: text });
          this.resetError("bank_account_details")
        },
      }

       BankruptcyInputProps = {
        onChangeText: (text: string) => {
          this.setState({ bankruptcy: text });
          this.resetError("is_bankruptcy_declared")
        },
      }

       BankruptcyDetailInputProps = {
        onChangeText: (text: string) => {
          this.setState({ bankruptcyDetail: text });
          this.resetError("BankruptcyDetail")

        },
      }

      handleOtherDocumentChange = (event:ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files?.[0];
        this.setState({
           otherDocument : file as File
        })
    };

      handleTaxChange = (event:ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        this.setState({
            taxRegistration: file as File
        })
    };

      handleLicenseFileChange = (event:ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        this.setState({
          License: file as File
        })
    };

    handleFileChange = (event:ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      this.setState({
          selectedFile: file as File
      })
  };
  handlePaymentChange = (event:React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    this.setState({ selectPayment: event.target.value  as string });
  };
    // Customizable Area End
}
