import { Box, List, ListItem, ListItemIcon, ListItemText, styled } from '@material-ui/core';
import React, { Component } from 'react';
import Text from './Text';
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/ForgotPassword.json')
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const StyledLisItemText = styled(ListItemText)({
    padding: 0,
    '& span': {
      padding: 0,
      fontSize: 12,
      fontWeight: 400,
    }
});

interface MessageProps {
    currentLanguage: string;
    passwordFieldChecklist: Array<string>;
}


const PasswordValidationChecker: React.FC<MessageProps> =  ({ currentLanguage, passwordFieldChecklist }) => {
    const languageData = languageTranslationData[currentLanguage];
    
    return (
      <Box p={2} sx={{ position: "relative"}}>
        <Text weight="extraBold">{languageData.PasswordContainText}</Text>
        <List>
          {languageData.PasswordValidation.map((validation: string, validIndex: number) => 
          <ListItem style={classes.services} key={validation}>
            <ListItemIcon style={classes.servicesIcon}>
              <CheckCircleRoundedIcon htmlColor={passwordFieldChecklist[validIndex]} />
            </ListItemIcon>
            <StyledLisItemText>
              {validation}
            </StyledLisItemText>
          </ListItem>)}
        </List>
    </Box>
    )
}

const classes = {
  services: {
      padding: 0,
    },
    servicesIcon: {
      minWidth: 32 as const
    },
}

export default PasswordValidationChecker;