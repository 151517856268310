export const headerRightSideImg = require("../assets/Frame (3).png")
export const leftSideImage = require("../assets/Frame_1.png")
export const about = require("../assets/about-img.png");
export const circle1 = require("../assets/circle1.svg");
export const circle2 = require("../assets/circle2.svg");
export const image1 = require("../assets/image_1.png");
export const image2 = require("../assets/image_2.png");
export const image3 = require("../assets/image_3.png");
export const image4 = require("../assets/image_7.png");
export const image5 = require("../assets/image_8.png");
export const image6 = require("../assets/image_vec.png");
export const image7 = require("../assets/image.png");
export const image8 = require("../assets/article_img.png")
export const image9 = require('../assets/article_image.png')
export const image10 = require('../assets/image10.png')
export const BlogImg = require('../assets/BlogImg.png')
export const leftImg = require('../assets/left.png')
export const rightImg = require('../assets/right.png')
export const alexImg = require('../assets/alex.png')
export const emilyImg = require('../assets/emily.png')
export const futureImg = require('../assets/future.png')
export const michaelImg = require('../assets/michael.png')
export const navigatingImg = require('../assets/navigating.png')
export const nickImg = require('../assets/nick.png')
export const vectorImg = require('../assets/Vector.png')
export const groupImg = require('../assets/group.png')
export const alarmImg = require('../assets/alarm.png')















