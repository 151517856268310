import React, { Children, Component, ReactNode } from "react";
import { Button } from "@builder/component-library";

type FontWeight = "normal" | "medium" | "semiBold";

type ButtonDisplayType =
  | "primary"
  | "primaryWithBorder"
  | "error"
  | "successBorderless"
  | "successWithBorder"
  | "secondaryBorderless"
  | "secondaryWithBorder"
  | "secondary"
  | "light"
  | "dark"
  | "lightWhite"
  | "gray";

type TextTransform = "capitalize" | "uppercase" | "lowercase" | "none";
type WidthStyles =  "lg" | "sm" | "auto"|"xl";

type CustomButtonProps = {
  
  onClickHandle?: () => void;
  styles?: React.CSSProperties;
  textStyles?: React.CSSProperties;
  fontSize?: "xl" | "x" | "base";
  iconPosition?: "left" | "right" | undefined;
  dataTestId?: string;
  size?: WidthStyles;
  themes: "dark" | "primary" | "secondary";
  weight?: FontWeight;
  icon?: string;
  transform?: TextTransform;
  display?: ButtonDisplayType;
};

export default class CustomButton extends Component<CustomButtonProps> {
  render(): React.ReactNode {
    const propStyles = this.props.styles || {};
    const propTxtStyle = this.props.textStyles || {};

    const displayStyles = {
      ...buttonDisplayTypes[this.props.display || "primary"],
    };
    const styles: React.CSSProperties = {
      textTransform: this.props.transform
      ? (textTransformations[this.props.transform]  as TextTransform)
      : "none",
      width: widthStyles[this.props.size || "auto"],
      fontSize: this.props.fontSize && fontSize[this.props.fontSize],
      padding: this.props.fontSize && padding[this.props.fontSize],
      fontWeight: fontWeights[this.props.weight || "semiBold"],
      color: displayStyles.color
    };
    

    const { themes, iconPosition, icon, onClickHandle, dataTestId, children } =
      this.props;

    return (
      <Button
        data-id={dataTestId}
        onPress={onClickHandle}
        textStyle={{ ...styles, ...propTxtStyle }}
        style={{ ...commonStyles, ...displayStyles, ...styles, ...propStyles }}
        icon={icon}
        iconPosition={iconPosition}
        theme={themes}
        text={children?.toString()}
      >
      </Button>
    );
  }
}

const fontWeights: Record<FontWeight, number> = {
  normal: 400,
  medium: 500,
  semiBold: 700,
};
const widthStyles:  Record<WidthStyles, string> = {
  lg: "386px",
  sm: "246px",
  auto: "100%",
  xl: "127%"
};

const commonStyles: React.CSSProperties = {
  padding: "10px 16px",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontSize: "14px",
  gap: "8px",
  backgroundImage: "none",
  fontWeight: fontWeights.semiBold,
  textAlign: "center",
  height: "44px",
};


const fontSize: Record<"xl" | "x" | "base", string> = {
  xl: "16px",
  x: "14px",
  base: "12px",
};

const padding: Record<"xl" | "x" | "base", string> = {
  xl: "19px 36px 18px",
  x: "9px 18px",
  base: "8px 17px",
};

const buttonDisplayTypes: Record<ButtonDisplayType, React.CSSProperties> = {
  primary: {
    color: "#fff",
    background: "#1E3A8A"
  },
  primaryWithBorder: {
    border: "2px solid #2563EB",
    color: "#2563EB"
  },
  error: {
    border: "1px solid #FEE3E3",
    background: "#FEE3E3",
    color: "#DC2626"
  },
  successBorderless: {
    background: "#77B300",
    border: "1px solid #77B300",
    color: "#fff"
  },
  successWithBorder: {
    border: "1px solid #77B300",
    color: "#77B300"
  },
  secondaryBorderless: {
    border: "1px solid #EEE",
    background: "#EEE",
    color: "#000000"
  },
  secondaryWithBorder: {
    border: "1px solid #A6A6A6",
    background: "#EEE",
    color: "#333"
  },
  secondary: {
    border: "1px solid #EEE",
    background: "#EEE",
    color: "#2563EB"
  },
  light: {
    background: "#F1F5F9",
    color: "#0F172A"
  },
  lightWhite: {
    background: "rgba(255, 255, 255, 0.31)",
    color: "#fff"
  },
  dark: {
    background: "#0F172A",
    color: "#fff",
  },
  gray: {
    background: "#EFF6FF",
    color: "#0F172A"
  }
};

const textTransformations: Record<TextTransform, string> = {
  capitalize: "capitalize",
  uppercase: "uppercase",
  lowercase: "lowercase",
  none: "none",
};

