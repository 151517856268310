import React from "react";
// Customizable Area Start
import RequestsWebController, { Props } from "./RequestsController.web";
import { withStyles, StyleRules, styled } from "@material-ui/core/styles";
import { Box, Modal, Typography, Button, IconButton, MenuItem, TextField, TableContainer, Paper, FormControl, Menu, InputAdornment, Select, Popover } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { acceptImg, closeImg, deleteRedImg, filterImg, pendingImg, rejectImg, verifiedImg } from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextInput from "../../../../packages/components/src/design-system/TextInput";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Request.json')
import SearchIcon from "@material-ui/icons/Search";
import { configJSON } from "./EmailAccountLoginController";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class RequestsWeb extends RequestsWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentLanguage, anchorEl, dot } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        function createData(name: string, idvalue: string, requestId: string, date: string) {
            return { name, idvalue, requestId, date, };
        }
        const rows = [
            createData('John Doe', 'ID: 1234', "LO6381245", "December 5, 2023T10:00 AM"),
            createData('Emily Brown', 'ID: 5678', "LO6381246", "January 15, 2024T1:45 PM"),
            createData('Michael Johnson', 'ID: 9012', "LO6381247", "February 20, 2024T11:30 AM"),
            createData('Sophie Miller', 'ID: 3456', "LO6381248", "March 25, 2024T2:15 PM"),
            createData('Daniel Wilson', 'ID: 7890', "LO6381249", "April 30, 2024T9:30 AM")
        ];
        return (
            <UserProfileDrawer
                data-test-id="drawerComponentTestID"
                handleClick={this.handleDrawerClick}
                pageName="UserRequests"
            >
                <Box style={classes.RequestSuperBox}>
                    <Typography style={classes.typographyHeader}>{configJSON.verificationBtn}</Typography>
                    <Box style={classes.vMainBox}>
                        <InnerBox>
                            <img style={classes.vImg} src={acceptImg} />
                            <InnerTextBox>
                                <Typography style={classes.vApprovedText}>{configJSON.verificationApprove}</Typography>
                                <Typography style={classes.vApprovedNumber}>{configJSON.verificationApprovenum}</Typography>
                            </InnerTextBox>

                        </InnerBox>

                        <InnerBox>
                            <img style={classes.vImg} src={rejectImg} />
                            <InnerTextBox>
                                <Typography style={classes.vApprovedText}>{configJSON.verificationReject}</Typography>
                                <Typography style={classes.vApprovedNumber}>{configJSON.verificationRejectnum}</Typography>
                            </InnerTextBox>

                        </InnerBox>

                        <InnerBox>
                            <img style={classes.vImg} src={pendingImg} />
                            <InnerTextBox>
                                <Typography style={classes.vApprovedText}>{configJSON.verificationPending}</Typography>
                                <Typography style={classes.vApprovedNumber}>{configJSON.verificationPendingnum}</Typography>
                            </InnerTextBox>

                        </InnerBox>
                    </Box>
                    <Box style={{ background: "#FFFFFF", width: "100%", borderRadius: "12px", boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", padding: "0 16px 16px 16px", margin: "20px 0 80px 0", }}>

                        <TableUpperBox>

                            <CustomTextFieldForm
                                variant="outlined"
                                placeholder="Search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={classes.searchIcon} />
                                        </InputAdornment>
                                    ),

                                }}
                            />

                            <Box style={classes.buttonMainBox}>
                                <TableCell style={{ border: 'none', position: "relative" }}>
                                    <FilterButton variant="outlined" onClick={this.handleMenuOpen}>
                                        <FilterListRoundedIcon style={{ width: "20px",marginRight:"8px" }} />
                                        <FilterButtonText>{configJSON.filterBtn}</FilterButtonText>
                                    </FilterButton>
                                    <Popover
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleMenuClose}
                                        style={{ position: "absolute", top: "60px", left: "-60px", borderRadius: "12px" }}>
                                        <Box style={{ height: "400px", width: "374px", display: "flex", flexDirection: "column", gap: "24px", padding: "0px 0px 20px 0px", borderRadius: "12px", border: "1px solid #CBD5E1" }}>
                                            <Box style={{ height: "20px", width: "334px", padding: "20px", borderBottom: "1px solid #E2E8F0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Box style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                    <img style={classes.filterBtn} src={filterImg} alt="filter" />
                                                    <FilterButtonText>{configJSON.filterBtn}</FilterButtonText>
                                                </Box>

                                                <img onClick={this.handleMenuClose} style={{ height: "24px", width: "24px" }} src={closeImg} alt="close" />
                                            </Box>
                                            <Box style={{ height: "100px", width: "334px", padding: "0 20px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                                <Typography style={{ fontSize: "14px", fontWeight: 700, color: "#1E3A8A", textAlign: "end" }}>Reset</Typography>
                                                <Box style={classes.inputBox}>
                                                    <Box>
                                                        <Typography style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 700, lineHeight: '22px', textAlign: 'left' }}>
                                                            From:
                                                        </Typography>
                                                        <CustomTextFieldDate
                                                            variant="outlined"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="2023-09-14"
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 700, lineHeight: '22px', textAlign: 'left' }}>
                                                            To:
                                                        </Typography>
                                                        <CustomTextFieldDate
                                                            variant="outlined"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            placeholder="2023-09-14"
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ height: "100px", width: "334px", padding: "0 20px", display: "flex", flexDirection: "column", gap: "8px" }}>
                                                <Typography style={{ fontSize: "14px", fontWeight: 700, color: "#1E3A8A", textAlign: "end" }}>Reset</Typography>
                                                <Box style={classes.inputBox}>
                                                    <Box>
                                                        <Typography style={{ fontSize: '14px', fontWeight: 700, color: '#334155' }}>Status</Typography>
                                                        <FormControl fullWidth>
                                                            <FilterSelect
                                                                fullWidth
                                                                data-test-id="listTypeTestID"
                                                                IconComponent={KeyboardArrowDownRoundedIcon}
                                                                variant="outlined"
                                                                name="UserManagement"
                                                                defaultValue={"option 1"}>
                                                                <MenuItem value={"option 1"}>Approved</MenuItem>
                                                                <MenuItem value={"option 2"}>Rejected</MenuItem>
                                                                <MenuItem value={"option 3"}>Pending</MenuItem>

                                                            </FilterSelect>
                                                        </FormControl>

                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box style={{ height: "60px", width: "334px", borderTop: "1px solid #E2E8F0", padding: "20px", display: "flex", justifyContent: "space-between" }}>

                                                <Button style={{ width: "77px", height: "44px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: '8px', backgroundColor: '#EFF6FF', fontSize: '16px', fontWeight:700, color: '#0F172A', textTransform: 'none' }} variant="contained" >
                                                    Reset
                                                </Button>
                                                <Button style={{ padding: "10px 16px", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '14px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none' }} variant="contained">
                                                    Apply now
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Popover>
                                </TableCell>
                                <RequestButton variant="contained" onClick={this.handleFirstModalOpen}>
                                    <Typography>{configJSON.customBtn}</Typography>
                                </RequestButton>
                            </Box>
                        </TableUpperBox>

                        <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                            <Table style={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <CustomHeadingRow>
                                        <TableCell>{configJSON.requestId}</TableCell>
                                        <TableCell>{configJSON.name}</TableCell>
                                        <TableCell>{configJSON.rNumber}</TableCell>
                                        <TableCell>{configJSON.date}</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{configJSON.status}</TableCell>
                                    </CustomHeadingRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data.map((item, index) => (
                                        <CustomBodyRow key={index}>
                                            <TableCell>{item[0]}</TableCell>
                                            <TableCell>{item[1]}</TableCell>
                                            <TableCell>{item[3]}</TableCell>
                                            <TableCell>{item[4]}</TableCell>
                                            <TableCell>
                                                <span
                                                    style={{
                                                        ...classes.statusStyle,
                                                        ...this.handleStatusColor(item[2]),
                                                    }}
                                                >
                                                    {item[2]}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={this.handleClick}>
                                                    <MoreVertIcon style={classes.moreOpStyle} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={dot}
                                                    keepMounted
                                                    open={Boolean(dot)}
                                                    onClose={this.handleClose}
                                                >
                                                    <MenuItem onClick={() => this.props.navigation.navigate('UserRequest')} style={{ color: "#0F172A", fontSize: "14px", fontWeight: 400 }}>View Details</MenuItem>
                                                    <MenuItem onClick={this.handleClose} style={{ color: "#DC2626", fontSize: "14px", fontWeight: 400, display: "flex", alignItems: "center", gap: "8px" }}><img src={deleteRedImg} /> Cancel Request</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </CustomBodyRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            style={classes.paginationResultBox}
                        >
                            <Box
                                style={classes.paginationBox}
                            >
                                <CustomPagination
                                    data-test-id="verificationPaginationTestID"
                                    page={this.state.verificationPage}
                                    count={8}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    onChange={this.handleVerificationPagination}
                                />
                            </Box>
                            <CustomVerificationResultBox>
                                <Typography style={classes.resultsText}>
                                    {languageData.results}
                                </Typography>
                            </CustomVerificationResultBox>
                        </Box>

                    </Box>

                    <StyledModal
                        open={this.state.isFirstModalOpen}
                        onClose={this.handleFirstModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}>
                        <ModalContent>
                            <Box style={{ borderBottom: "1px solid #E2E8F0", height: "196px", width: '560px' }}>
                                <CloseButton onClick={this.handleFirstModalClose}>
                                    <img style={{ height: "24px", width: "24px" }} src={closeImg} alt="close image" />
                                </CloseButton>
                                <Typography style={{ fontSize: '20px', fontWeight: 700, lineHeight: "28px", color: '#0F172A', position: "relative", top: "32px", left: "32px" }}>
                                    Upgrade to Access Verification Requests
                                </Typography>
                                <Typography style={{ width: "462px", fontSize: '14px', fontWeight: 400, color: '#475569', lineHeight: "22px", position: "relative", top: "50px", left: "32px" }}>
                                    To make verification requests and access premium features, please choose a subscription plan. Get unlimited business checks, real-time updates, and priority support with our Yearly Subscription, or opt for a Single Use Verification for one-time needs.
                                </Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '16px', width: '560px', height: "104px", padding: '24px' }}>
                                <Button onClick={this.handleSecondModalOpen} style={{ width: "248px", height: "56px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: '8px', backgroundColor: '#EFF6FF', fontSize: '16px', fontWeight: 700, color: '#0F172A', textTransform: 'none' }} variant="contained" >
                                    Continue with basic plan
                                </Button>
                                <Button onClick={() => this.props.navigation.navigate('Customisableusersubscriptions')} style={{ width: "248px", height: "56px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none' }} variant="contained">
                                    Go to plan section
                                </Button>
                            </Box>
                        </ModalContent>
                    </StyledModal>

                    <StyledModal2
                        open={this.state.isSecondModalOpen}
                        onClose={this.handleSecondModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}>
                        <ModalContent2>
                            <Box style={{ height: "80px", borderBottom: "1px solid #E2E8F0" }}>
                                <CloseButton onClick={this.handleSecondModalClose}>
                                    <img style={{ height: "24px", width: "24px" }} onClick={this.handleSecondModalClose} src={closeImg} alt="close image" />
                                </CloseButton>
                                <Typography style={{ position: "relative", top: "24px", left: "40px", fontSize: '24px', fontWeight: 700, color: '#0F172A', letterSpacing: "0.5%", lineHeight: "32px" }}>
                                    Request New Verification
                                </Typography>
                            </Box>
                            <Box style={{ borderBottom: "1px solid #E2E8F0", display: 'flex', flexDirection: 'column', gap: '16px', padding: '0px 40px 0px 40px', height: "390px" }}>
                                <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#64748B', lineHeight: "22px" }}>
                                    *The verification request is completely anonymous
                                </Typography>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', height: "70px", width: "480px" }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 700, color: '#334155', lineHeight: "22px" }}>Name of the entity</Typography>
                                    <CustomTextField
                                        variant="outlined"
                                        placeholder="Name of the entity" />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 700, color: '#334155' }}>Registration number</Typography>
                                    <CustomTextField
                                        variant="outlined"
                                        placeholder="Registration number" />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 700, color: '#334155' }}>Type of entity</Typography>
                                    <FormControl fullWidth>

                                        <FilterSelectFields
                                            fullWidth
                                            data-test-id="listTypeTestID"
                                            IconComponent={KeyboardArrowDownRoundedIcon}
                                            variant="outlined"
                                            name="UserManagement"
                                            defaultValue={"option 1"}>
                                            <MenuItem value={"option 1"}>Type 1</MenuItem>
                                            <MenuItem value={"option 2"}>Type 2</MenuItem>

                                        </FilterSelectFields>
                                    </FormControl>
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginBottom: "24px" }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 700, color: '#334155' }}>Additional details</Typography>
                                    <CustomTextField
                                        variant="outlined"
                                        placeholder="Additional details" />
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', gap: '16px', width: '560px', height: "104px", padding: '24px' }}>
                                <Button onClick={this.handleSecondModalClose} style={{ width: "248px", height: "56px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: '8px', backgroundColor: '#EFF6FF', fontSize: '16px', fontWeight: 700, color: '#0F172A', textTransform: 'none' }} variant="contained" >
                                    Cancel
                                </Button>
                                <Button onClick={this.handleThirdModalOpen} style={{ width: "248px", height: "56px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none' }} variant="contained">
                                    Request verification
                                </Button>
                            </Box>
                        </ModalContent2>
                    </StyledModal2>

                    <StyledModal3
                        open={this.state.isThirdModalOpen}
                        onClose={this.handleThirdModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}
                    >
                        <ModalContent3>
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
                                <img style={{ height: '120px', width: '120px' }} src={verifiedImg} />
                                <Typography style={{ fontSize: '30px', fontWeight: 700, color: '#0F172A', textAlign: 'center', width: "606px", lineHeight: "44px", letterSpacing: "-0.5%" }}>
                                    Verification Request Submitted Successfully
                                </Typography>
                                <Typography style={{ fontSize: '18px', fontWeight: 400, color: '#475569', textAlign: 'center', width: "496px", lineHeight: "26px", }}>
                                    Your request is under review by the business. Once they confirm, you will get the documents in the request section of your profile.
                                </Typography>
                                <Button onClick={this.handleThirdModalClose} style={{ width: "248px", height: "56px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none' }} variant="contained">
                                    Go to Requests
                                </Button>
                            </Box>
                        </ModalContent3>
                    </StyledModal3>




                    <Modal open={this.state.agreeModal} id='agreemodal' onClose={this.handleModalConfirm}>
                        <ModalWrapper>
                            <Text align="center" lineHeight="30px" weight="extraBold" size="xl" variant="darkBlack">
                                {languageData.AcceptVerification}
                            </Text>
                            <Box style={{ margin: "16px 0 31px 0px" }}>
                                <Text align="center" lineHeight="20px" weight="minBold" size="sm" variant="darkBlack">
                                    {languageData.EnterTheOTP}
                                </Text>
                                <Box style={{ width: '99.9%', }}>
                                    <TextInput
                                        data-test-id={"NumbertxtInput"}
                                        variant="outlined"
                                        color="#747A80"
                                        label="OTP"
                                        type="text"
                                        value={""}
                                    />
                                </Box>
                            </Box>
                            <ModalButtonContainer>
                                <ButtonCancelWrapper id="modalCancel" onClick={this.handleModalConfirm}>
                                    {languageData.Cancel}
                                </ButtonCancelWrapper>
                                <ButtonWrapper id="modalConfirm" onClick={() => this.handleModalConfirm()}>
                                    {languageData.Confirm}
                                </ButtonWrapper>
                            </ModalButtonContainer>
                        </ModalWrapper>
                    </Modal>
                    <Modal open={this.state.cancelModal} id="cancelModal" onClose={this.handleModalBlockConfirm}>
                        <ModalWrapper>
                            <Text align="center" lineHeight="30px" weight="extraBold" size="xl" variant="darkBlack" >
                                {languageData.RejectVerification}
                            </Text>
                            <Box mr={0} ml={0} mt={3.75} mb={3.75}>
                                <Text align="center" lineHeight="20px" weight="minBold" size="xx" variant="darkBlack">
                                    {languageData.ConfirmIf}
                                </Text>
                            </Box>
                            <ModalButtonContainer>
                                <ButtonCancelWrapper id="modalBlockCancel" onClick={this.handleModalBlockConfirm}>
                                    {languageData.Cancel}
                                </ButtonCancelWrapper>
                                <ButtonWrapper id="modalBlockConfirm" onClick={() => this.handleModalBlockConfirm()}>
                                    {languageData.Confirm}
                                </ButtonWrapper>
                            </ModalButtonContainer>
                        </ModalWrapper>
                    </Modal>
                </Box>
            </UserProfileDrawer>


        )
        // Customizable Area End
    }
}

export default withStyles(styles)(RequestsWeb);
const ModalWrapper = styled(Box)({
    boxSizing: "border-box",
    padding: "36px 128px",
    backgroundColor: "white",
    border: "1px solid #DFE0EB",
    borderRadius: "8px",
    width: "90%",
    maxWidth: "600px",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    "@media (max-width:767px)": {
        padding: "20px 30px",
    }
})
const FilterSelect = styled(Select)({
    width: "334px",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    height: "44px",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    '& .MuiSelect-select': {
        color: '#94A3B8',
    },
    '&.Mui-focused .MuiSelect-select': {
        color: 'inherit',
    },
});
const FilterSelectFields = styled(Select)({
    fontSize: "14px",
    width: '480px',
    height: '44px',
    color: "#0F172A",
    fontWeight: 400,
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        background: "transparent",
        borderColor: "#CBD5E1",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        background: "transparent",
        border: "1px solid #CBD5E1",
    },
    "&.Mui-disabled": {
        borderColor: "#CBD5E1",
        WebkitTextFillColor: "#0F172A",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    '& .MuiSelect-select': {
        color: '#94A3B8',
    },
    '&.Mui-focused .MuiSelect-select': {
        color: 'inherit',
    },
});
const ModalButtonContainer = styled(Box)({
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
})
const ButtonCancelWrapper = styled(Box)({
    padding: "12px 60px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
    fontSize: '14px',
    fontWeight: 600,
    color: "#3751FF",
    cursor: "pointer",
    border: "1px solid #3751FF",
    transition: "all 0.3s ease",
    width: '50%',
    "&:hover": {
        color: "#3751FF",
        backgroundColor: "white"
    }
})

const ButtonWrapper = styled(Box)({
    padding: "11px 16px",
    backgroundColor: "#3751FF",
    borderRadius: "8px",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
    fontSize: '14px',
    fontWeight: 600,
    color: "white",
    cursor: "pointer",
    transition: "all 0.3s ease",
    border: "1px solid #3751FF",
    width: '50%',
    textAlign: 'center',
    "&:hover": {
        backgroundColor: "#3751FF"
    }
})

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))
const StyledModal2 = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledModal3 = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const ModalContent = styled(Box)({
    position: 'absolute',
    borderRadius: '20px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    background: "#FFFFFF",
    height: '300px',
    width: '560px',
})
const ModalContent2 = styled(Box)({
    position: 'absolute',
    left: "440px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: '20px',
    background: "#FFFFFF",
    height: '622px',
    width: '560px',
    display: "flex",
    flexDirection: "column",
    gap: "24px"
})

const ModalContent3 = styled(Box)({
    position: 'absolute',
    left: "440px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: '20px',
    background: "#FFFFFF",
    height: '521px',
    width: '686px',
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    paddingTop: "40px"
})
const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
}));
const CustomTextField = withStyles({
    root: {
        width: '480px',
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        color: "#0F172A",
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const TableUpperBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
    gap: "20px",
    flexWrap: "wrap",
});
const RequestButton = styled(Button)({
    backgroundColor: "#1E3A8A",
    width: "auto",
    height: "44px",
    borderRadius: "8px",
    cursor: "pointer",
    "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        width: "auto",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 700,
        color: "white"
    },
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});
const FilterButton = styled(Button)({
    padding: "10px 16px",
    borderRadius: "8px",
    display: "flex",
    gap: "8px",
    alignItems: "center"

});

const CustomTextFieldStatus = styled(TextField)({
    flexGrow: 1,
    width: "334px",
    height: "44px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});
const CustomTextFieldDate = withStyles({
    root: {
        padding: '10px 8px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
        '& .MuiInputBase-input': {
            padding: '10px 8px',
            border: '1px solid #CBD5E1',
            borderRadius: '8px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
    },
})(TextField);

const CustomTextFieldForm = withStyles({
    root: {
        width: '320px',
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});
const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});
const CustomPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        fontSize: "1rem",
        minWidth: "32px",
        height: "32px",
        color: "#0F172A",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "@media(max-width: 421px)": {
            margin: "0px"
        },
        "&:hover": {
            backgroundColor: "#f0f0f0"
        }
    },
    "& .Mui-selected": {
        color: "blue !important",
        fontWeight: 700,
        backgroundColor: "transparent !important",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#f0f0f0 !important"
        }
    },
    "& .MuiPaginationItem-ellipsis": {
        fontSize: "1.2rem",
        textDecoration: "none"
    }
});
const CustomVerificationResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});
const InnerBox = styled(Box)({
    border: '1px solid #E2E8F0',
    height: '114px',
    padding: '20px',
    width: '143px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

});
const InnerTextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

});
const FilterButtonText = styled(Box)({
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "capitalize",

});
const classes = {
    RequestSuperBox: {
        padding: "0px 120px 0px 0px"
    },
    typographyHeader: {
        fontWeight: 700, fontSize: "30px", lineHeight: "44px", letterSpacing: "0.5%", color: "#0F172A",

    },
    verificationBox: {
        height: '80vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'

    },
    verificationInnerBox: {
        display: 'flex', gap: '20px', flexDirection: 'column', alignItems: 'center', height: '328px', width: '287px'

    },
    groupImg: {
        height: "166px", width: '150px'

    },
    verificationText: {
        fontSize: '18px', fontWeight: 500, textAlign: 'center', color: '#000000', lineHeight: "26px"

    },
    requestButton: {
        padding: "10px 16px", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '14px', fontWeight: 700, color: '#FFFFFF', textTransform: 'none'
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: "18px",
        whiteSpace: "nowrap"
    },
    searchButtonWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexGrow: 1,
        width: "320px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    searchIconStyle: {
        color: "#94A3B8"
    },
    textFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        height: "44px",
        width: "320px"
    },
    moreOpStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7"
    },
    paginationResultBox: {
        display: "flex",
        alignItems: "center",
        paddingTop: "16px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    paginationBox: {
        display: "flex",
        justifyContent: "center",
        flexGrow: 1
    },
    resultsText: {
        color: "#64748B",
        fontSize: "12px"
    },
    filterBtn: {
        height: "10px",
        width: "15px"
    },
    buttonMainBox: {
        display: "flex",
        gap: "16px",
        alignItems: "center",
    },

    drawerPaper: {
        padding: '16px',
        width: '320px',
    },
    filterBoxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    formControl: {
        marginBottom: '16px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    inputBox: {
        display: 'flex',
        gap: '16px',
        width: '334px',
        height: '70px',
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        textAlign: 'left',
    },
    inputField: {
        width: '159px',
        height: '44px',
        gap: '8px',
        border: "1px solid #CBD5E1",
        borderRadius: "8px"
    },
    inputRoot: {
        width: '320px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',

        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    searchIcon: {
        width: '17.05px',
        height: '17.06px',
        marginTop: '3.47px',
        marginLeft: '3.48px',
        color: "#94A3B8"

    },
    placeholder: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#94A3B8',
    },
    vMainBox: {
        width: '590px',
        height: '154px',
        display: 'flex',
        gap: '20px',
        marginTop: '48px',
    },

    vImg: {
        height: '24px',
        width: '24px',
    },

    vApprovedText: {
        color: '#94A3B8',
        lineHeight: '26px',
        fontSize: '18px',
        fontWeight: 500,
    },
    vApprovedNumber: {
        color: '#0F172A',
        lineHeight: '32px',
        fontSize: '24px',
        fontWeight: 600,
        letterSpacing: '-0.5%',
    },

}