export const Frame = require("../assets/Frame.png");
export const Clarity = require("../assets/clarity.png");
export const Fluent = require("../assets/fluent.png");

export const Rafiki = require("../assets/rafiki.png");

export const Rectangle = require("../assets/Rectangle.png");

export const logo = require("../assets/logo.svg");

// Customizable Area Start
export const buyNow = require("../assets/buy_now.svg");
export const comLanders = require("../assets/com_landers.svg");
export const faMobile = require("../assets/fa_mobile.svg");
export const mdiBank = require("../assets/mdi_bank.svg");
export const mdiFinance = require("../assets/mdi_finance.svg");
export const mdiPrinter = require("../assets/mdi_printer.svg");
export const mdiTools = require("../assets/mdi_tools.svg");

export const buyNow1 = require("../assets/buy_now1.png");
export const comLanders1 = require("../assets/com_landers1.png");
export const faMobile1 = require("../assets/fa_mobile1.png");
export const mdiBank1 = require("../assets/mdi_bank1.png");
export const mdiFinance1 = require("../assets/mdi_finance1.png");
export const mdiPrinter1 = require("../assets/mdi_printer1.png");
export const mdiTools1 = require("../assets/mdi_tools1.png");

export const frameMid = require("../assets/FrameMid.svg");
export const frameRightImg = require("../assets/FrameRightImg.svg");


export const image1 = require("../assets/Image_illustration.png");
export const image2 = require("../assets/image_illustration2.png");
export const image3 = require("../assets/image_illustration(1).png");
export const image4 = require("../assets/Image_illustration(1)2.png");
export const image5 = require("../assets/Image_img.png");

export const ringImage = require("../assets/ring.png");

export const debtImage = require("../assets/debt.png");
export const aboutusImage = require("../assets/aboutus.png");
export const visionImage = require("../assets/vision.png");
export const efficiencyImage = require("../assets/efficiency.png");
export const transparencyImage = require("../assets/transparency.png");
export const supportImage = require("../assets/support.png");

export const person1 = require("../assets/person1.png");
export const person2 = require("../assets/person2.png");
export const person3 = require("../assets/person3.png");

export const bgImageRight = require("../assets/bgImageRight.png");

// Customizable Area End
