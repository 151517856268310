import React from "react";
// Customizable Area Start
import UserLoginWebController, {
    Props
} from "./UserLoginPageController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Text from "../../../components/src/design-system/Text";
import {
    Box, FormControl as Form, IconButton,
    InputAdornment,
    Button
} from "@material-ui/core";
import { LoginFlow } from "../../../components/src/LoginFlow.web";
import TextInput from "../../../components/src/design-system/TextInput";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Login.json')
// Customizable Area End

const loginStyle: StyleRules = {
    // Customizable Area Start
    containerAuto: {
        margin: '0 auto',
        "@media (max-width: 600px)": {
            padding: "40px 20px"
        }
    },
    containerLogin: {
        height: '100%',
        boxSizing: 'border-box',
        padding: '44px 100px',
        "@media (max-width: 960px)": {
            padding: "40px 20px"
        },
        "@media (max-width: 600px)": {
            padding: "40px 20px",
        },
    },
    paper: {
        backgroundColor: "#FFFFFF",
    },
    linkText: {
        cursor: 'pointer',
        marginLeft: 5,
        display: 'flex',
        justifyContent: 'end',
        color: '#1E3A8A',
        "&:hover": {
            textDecoration: 'underline'
        },
    },
    gridWrapper:{
        borderRadius: '10px', overflow: 'auto' ,
        alignItems: "center"
    },
    errorBox: {
      padding: '12px 16px 12px 16px',
      borderRadius: '4px 0px 0px 0px',
      background: '#FEE2E2',
      borderLeft: '4px solid #DC2626',
      color: '#DC2626',
      marginBottom: 10
    },
    loginBtn: {
        background: "#1E3A8A",
        color: '#fff',
        borderRadius: "8px",
        padding: 12,
        textTransform: "none",
        fontSize: 16,
        fontWeight: 700,
        "&:hover": {
            background: "#1E3A8A",
            color: '#fff',    
        }
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class SellerLoginWeb extends UserLoginWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage, errors } = this.state;
        const languageData = languageTranslationData[`${currentLanguage}`]
        const isArabic = currentLanguage === 'ar';

        return (
          <LoginFlow>
            <Box className={classes.containerLogin}>
                <Box mt={0} mb={3}>
                    <Box display="flex">
                        <Text variant="primary" size="xl" weight="extraBold" family="inter">
                            {languageData.Seller.Title}
                        </Text>
                    </Box>
                </Box>
                {!this.isStringNullOrBlank(errors) && <Box className={classes.errorBox}>
                  <Text>{errors}</Text>
                </Box>}
                <Box mb={3}>
                    <Form style={{ width: '100%' }}>
                        <TextInput
                          id="Username-input"
                          label={languageData.Seller.Email}
                          onChange={this.txtInputEmailWebPrpos.onChangeText}
                          placeholder={languageData.Seller.EmailPlaceholder}
                          aria-describedby="Username-helper-text"
                          value={this.state.userName}
                          error={this.state.localErrors.Email}
                          isArabic={isArabic}
                      />
                    </Form>
                </Box>
                <Box>
                <TextInput
                  id="password-input"
                  isArabic={isArabic}
                  type={this.state.showPassword ? "text" : "password"}
                  label={languageData.Seller.Password}
                  onChange={this.txtInputPasswordWebPrpos.onChangeText}
                  placeholder={languageData.Seller.PasswordPlaceholder}
                  aria-describedby="password-helper-text"
                  value={this.state.password}
                  error={this.state.localErrors.Password}
                  endAdornment={(<InputAdornment position="end" style={{ position: 'absolute', top: "27px", right: isArabic ? 'auto': 0, left: isArabic ? 0 : 'auto' }}>
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          id="show-hide-password"
                      >
                          {this.state.showPassword ? (
                              <VisibilityOutlinedIcon style={{color: "#94A3B8"}}/>
                          ) : (
                              <VisibilityOffOutlinedIcon style={{color: "#94A3B8"}}/>
                          )}
                      </IconButton>
                    </InputAdornment>)
                }/>
              
                </Box>
                <Box mb={3} mt={2}>
                    <span className={classes.linkText} id="forgot-password" onClick={() => this.handleNavigation('NewPassword')}>
                        <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="xx">
                            {languageData.Seller.ForgotPassword}
                        </Text>
                    </span>
                </Box>
                <Button
                    data-testid="login-btn"
                    onClick={this.providerLoginButton.onClick}
                    variant="contained"
                    fullWidth
                    className={classes.loginBtn}
                >
                    {languageData.Seller.LoginBtn}
                </Button>

                <Box mt={2} mb={0} display="flex">
                  <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
                    {languageData.Seller.DontHaveAccount}
                    <span className={classes.linkText} id="signup-test" onClick={() => this.handleNavigation('SellerRegistration')}><Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Seller.Signup}</Text></span>
                  </Text>
                </Box>
            </Box>
          </LoginFlow>
        )
        // Customizable Area End
    }
}

export default withStyles(loginStyle)(SellerLoginWeb);
