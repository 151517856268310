import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')
export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  email: string;
  errors:{
    Email: string,
    Token: string;
  },
  validateResult: boolean;
  apiError: boolean,
  
  showPassword: boolean;
  showConfirmPassword: boolean;

  otp: string;
  password: string;
  confirmPassword: string;

  otpError: string;
  psEr: string;
  forgotPasswordToken: string | null;
  confirmPasswordError: string;
  passwordField: boolean;
  confirmPasswordField: boolean;
  route: string;
  adminOtp: Array<number | undefined>;
  userType: string;
  currentLanguage:'en'|'ar'
  emailSent: boolean;
  passwordFieldChecklist: Array<string>;
  emailAddress: string;
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  emailReg: RegExp;
  createAccountApiCallId: string = "";
  resetPasswordApiCallId: any;
  passwordReg: RegExp;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
     
      email: "",
      errors: {
        Email: "",
        Token: "",
      },
      validateResult: false,
      apiError: false,

      passwordField: false,
      confirmPasswordField: false,
      showPassword: false,
      showConfirmPassword: false,

      otp: '',
      password: "",
      confirmPassword: '',

      otpError: '',
      psEr: '',
      confirmPasswordError: '',
      route: '',
      adminOtp: [],
      userType: '',
      currentLanguage:configJSON.EN,
      emailSent: false,
      passwordFieldChecklist: ["#94A3B8","#94A3B8","#94A3B8","#94A3B8"],
      forgotPasswordToken: null,
      emailAddress: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.passwordReg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;

    this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
         this.sucessCell(apiRequestCallId, responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start 
  async componentDidMount() {
    super.componentDidMount();
    const url = new URL(window.location.href); 
    const token = url.searchParams.get("token");
    const language = await utills.getLanguageOrSetCurrentLanguage();
    const emailAddress = await this.getLocalData('email');
    this.setState({ currentLanguage: language, forgotPasswordToken: token, emailAddress: emailAddress });
    const loginRoute = await this.getLocalData('userType')
    if (loginRoute === 'consumer') {
      this.setState({route: 'UserLogin'})
    } else if (loginRoute === 'provider') {
      this.setState({route: 'SellerLogin'})
    }

  }

  saveLocalData = async (key: string, value: string) => {
    await setStorageData(key, value);
  };

  getLocalData =  async (key: string) => {
    const data = getStorageData(key)
    return data
  }

  handleResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      if(responseJson.message && responseJson.account){
        const userType = responseJson.account.data.attributes.user_type
       
        const email = responseJson.account.data.attributes.email
        this.saveLocalData('email', email)
        this.saveLocalData('userType', userType)
        this.setState({ emailSent: true });
       // this.handleRouting('ForgotPassword')

      } else if (responseJson.admin) {
        const email = responseJson.admin.data.attributes.email
        this.saveLocalData('userType', 'admin')
        this.saveLocalData('admin_email', email)
        this.handleRouting('SuperAdminOtp')
      }

    } else {
      const defaultError = this.getValidEmailErrorMsg();
      this.setError("Email", responseJson.error || defaultError);
    }
  }

  sucessCell = (apiRequestCallId : string,responseJson : any) =>{
    if (apiRequestCallId === this.createAccountApiCallId) {
      this.handleResponse(responseJson)
    }
    else if (apiRequestCallId === this.resetPasswordApiCallId) {
      if (responseJson.errors) {
        if (responseJson.errors[0].token) {
          this.setState({errors: { ...this.state.errors, Token: responseJson.errors[0].token }});
        } 
      } else {
          this.handleRouting('PasswordResetSuccess')
      }
    }
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isEmailValid(email: string) {
    return this.emailReg.test(email);
  }
  
  async sendData(userType: string = '') {
    const headers = {"Content-Type": "application/json"};

    let bodyData = {}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      this.createAccountApiCallId = requestMessage.messageId;

    if (userType === 'admin') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendOtp
      );

      bodyData = {
        admin_user: {
          email: this.state.email
        }
      }

    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotAPiEndPoint
      );
      bodyData = {
        email: this.state.email,
      }

    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  

  setError = (field:string, msg: string = "") => {
    const {currentLanguage} = this.state;
    const translatedField = languageTranslationData[currentLanguage][field];
    const endMsg = currentLanguage === configJSON.EN ? configJSON.IsRequired : configJSON.IsRequiredInArabic;
    this.setState((prevState)=>({
      errors:{
        ...prevState.errors,
        [field]: msg || `${translatedField} ${endMsg}`,
      }
    }))

  }

  resetError (field: string) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: '',
      },
    }));
  }

  getValidEmailErrorMsg = ()=>{
    const {currentLanguage} = this.state;
    return languageTranslationData[currentLanguage].EnterValidEmail;
  }

  createUserAccount = (userType: string = '') => {
    let hasErrors = false;
  
    if (this.isStringNullOrBlank(this.state.email)) {
      this.setError("Email");
       hasErrors = true;
    }
    if (!this.isStringNullOrBlank(this.state.email)) {
      if (!this.isEmailValid(this.state.email)) {
        this.setError("Email", this.getValidEmailErrorMsg());
        hasErrors = true;
      }
    }

    if(!hasErrors){
      this.sendData(userType)
    }  
  }

  signupButton = {
    onPress: () => {
      this.createUserAccount()
    }
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      this.resetError("Email");
    },
  };
  forgotPasswordButton = {
    onClick: () => {
      this.createUserAccount('admin')
    }
  }
  handleOtp = {
    onChangeText: (text: string) => {
      this.setState({ otp: text });
      this.setState({otpError: ''})
    }
  }

  handleConfirmPassword = {
    onChangeText: (text: string) => {
      this.setState({ confirmPassword: text });
      this.setState({confirmPasswordError: ''});
    }
  }
  handlePassword = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
      this.setState({psEr: ''});
      this.setChecklistColor(text);

    }
  }

  handleChangeOtp = {
    onChangeText: (index: any, event: any) => {
      const value = parseInt(event.target.value);
      const numbers = [...this.state.adminOtp];
      if (!isNaN(value)) {
        numbers[index] = value;
      } else {
        numbers[index] = undefined
      }
      this.setState({ adminOtp: numbers });
    }
  }
  validatePasswordField(password: string) {
    return this.passwordReg.test(password);
  }

  setChecklistColor(password: string) {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;
    
    this.setState({ passwordFieldChecklist: [
      hasUpperCase ? "#34D399" : "#94A3B8", 
      hasLowerCase ? "#34D399" : "#94A3B8", 
      hasNumber ? "#34D399" : "#94A3B8", 
      hasMinLength ? "#34D399" : "#94A3B8" ]})
  }

  setPasswordFieldIcon = () => {
    this.setState({ passwordField: !this.state.passwordField });
    this.setState((preState) => ({
      showPassword: !preState.showPassword
   }))
  };

  setConfirmPasswordFieldCom = () => {
    this.setState({ confirmPasswordField: !this.state.confirmPasswordField });
    this.setState((preState) => ({
      showConfirmPassword: !preState.showConfirmPassword
   }))
  };

  getConfirmPasswordErrorMessage = (type:string)=>{
    const {currentLanguage} = this.state;
    switch(type){
      case 'required': return languageTranslationData[currentLanguage].ConfirmPasswordRequired;
      case 'invalid': return languageTranslationData[currentLanguage].InvalidConfirmPassword;
      case 'match':return languageTranslationData[currentLanguage].PasswordMustMatch;
    } 
  }
  validate() {
    let error = false;
    if (!this.state.password) {
      this.setState({psEr: configJSON.requiredField})
      error = true;

    }
    else if (!this.validatePasswordField(this.state.password)) {
      this.setState({psEr: configJSON.psEr})
      error = true;

    }
    if (!this.state.confirmPassword) {
      this.setState({confirmPasswordError: this.getConfirmPasswordErrorMessage('required')})
      error = true;

    }
    else if (!this.validatePasswordField(this.state.confirmPassword)) {
      this.setState({confirmPasswordError: this.getConfirmPasswordErrorMessage('invalid')})      
      error = true;

    }

    if(this.state.password !== this.state.confirmPassword) {
      this.setState({confirmPasswordError: this.getConfirmPasswordErrorMessage('match')}) 
      error = true;

    }
    return error
  }

  submitData = async () => {
    let formdata = new FormData();
    formdata.append("new_password", this.state.password);
    formdata.append("confirm_password", this.state.confirmPassword);
    formdata.append("token", this.state.forgotPasswordToken || "")

    const headers = {}

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleRouting = (route: any) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }
  handleSubmit () {
    if (!this.validate()) {
      this.submitData()
    }
  }
  handleClickShowPassword = () => {
    this.setState((preState) => ({
        showPassword: !preState.showPassword
    }))
  }
  buttonProps = {
    onPress: () => this.handleSubmit(),
  };

  // Customizable Area End
}
