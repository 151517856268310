import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    FormControl,
    FormHelperText,
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tooltip
} from "@material-ui/core";
import {
    withStyles, StyleRules, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import VerificationRequestController, { Props, configJSON } from "./VerificationRequestController.web";
import Text from "../../../../packages/components/src/design-system/Text";
import MUICustomButton from "../../../components/src/design-system/Button/MuiButton"
import OutlinedInput from "../../../components/src/design-system/InputField/OutlinedInputField";
import { Info, Verified } from './assets'
const languageTranslation = require('../../languagesupport/src/LanguageData/RequestVerificaion.json')
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start

    labelText: {
        fontWeight: 700,
        fontSize: "12px",
        margin: "0px",
        color: "#222222",
        marginBottom: "5px",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between"
    },
    requiredText: {
        color: "#DC2626"
    },
    checkContainer: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "inter",
        color: "#FFF"
    }
    // Customizable Area End
}

export class VerificationRequest extends VerificationRequestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslation[currentLanguage];
        const renderForm = (form: { name: string, id: string, value: string, placeholder: string, error: string }) => {
            return (
                <FormControl variant="outlined" fullWidth>
                    <FormHelperText
                        id="name-helper-text"
                        className={classes.labelText}
                    >
                        <Text family="inter" variant="white" size="xs" weight="extraBold">{form.name}<span className={classes.requiredText}>*</span></Text>
                        {form.id === "phone" &&
                            <CustomTooltip placement="right" title={languageData.TooltipMsg} arrow>
                                <img src={Info} alt="Info" />
                            </CustomTooltip>
                        }
                    </FormHelperText>
                    <OutlinedInput
                        type="text"
                        id={form.id}
                        value={form.value}
                        onChange={this.handleChange}
                        aria-describedby="name-helper-text"
                        labelWidth={0}
                        placeholder={form.placeholder}
                    />
                    {form.error && <Text id={`${form.id}-error`} size="xs" variant="error" family="inter">{form.error}</Text>}
                </FormControl>
            )
        }
        return (
            <ThemeProvider theme={theme}>
                {this.state.hideSucess ? <Container>
                    <Text align="center" size="4xl" weight="lightBold" family="poppins" variant="white" >{languageData.Title}</Text>
                    <Text align="center" size="xm" weight="minBold" family="inter" variant="white">{this.state.showStep1 ? languageData.SubTitle : languageData.SubTitle2}</Text>
                    {
                        this.state.showStep1 ?
                            <>
                                <FormContainer>
                                    <GridWrapper2 container>
                                        {
                                            this.state.form.slice(0, 2).map(form => {
                                                return (
                                                    <Grid key={form.id} item xs={12}>
                                                        {renderForm(form)}
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </GridWrapper2>
                                </FormContainer>
                                <MUICustomButton id="next-test" onClick={this.handleNext} >{languageData.Next}</MUICustomButton>
                            </> :
                            <>
                                <Form2Container>
                                    <GridWrapper spacing={2} container>
                                        {
                                            this.state.form.slice(2, this.state.form.length).map(form => {
                                                return (
                                                    <GridItem key={form.id} item xs={12} sm={6}>
                                                        {renderForm(form)}
                                                    </GridItem>
                                                )
                                            })
                                        }
                                    </GridWrapper>
                                </Form2Container>
                                <FormGroup style={{ marginBottom: "36px" }}>
                                    <CustomFormControlLabel onClick={() => this.setState({ acceptedTerm: !this.state.acceptedTerm })} id="checkbox-test" control={<CustomCheckbox id="check-test" color="primary" checked={this.state.acceptedTerm} />} label={languageData.Accept} />
                                </FormGroup>
                                <MUICustomButton id="submit-test" disabled={!this.state.acceptedTerm} onClick={this.handleSubmit}>{languageData.RequestVerification}</MUICustomButton>
                            </>
                    }
                </Container>
                    :
                    <Container2>
                        <WhiteBox>
                            <Wrapper>
                                <img src={Verified} alt="Verified" width="120px" />
                                <Text size="1xl" weight="extraBold" variant='black' align="center">
                                    {languageData.VerificationSuccess}
                                </Text>
                                <Text size="xm" weight="minBold" variant='black' align="center">
                                    {languageData.SuccessMsg}
                                </Text>
                                <MUICustomButton>{languageData.GoTo}</MUICustomButton>
                            </Wrapper>
                        </WhiteBox>
                    </Container2>}
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const theme = createTheme({
    palette: {
        primary: {
            main: "#F1F5F9"
        },
    }
});

export default withStyles(styles)(VerificationRequest);

const FormContainer = styled(Box)({
    maxWidth: "420px",
    marginTop: "85px",
    width: "100%",
    marginBottom: "91px",
    display: "flex",
    flexDirection: "column",
    gap: "32px"
})

const Form2Container = styled(Box)({
    marginTop: "85px",
    maxWidth: "780px",
    width: "100%",
    marginBottom: "39px",
    display: "flex",
    flexDirection: "column",
    gap: "32px"
})

const Container = styled(Box)({
    width: "100%",
    backgroundColor: "#0F172A",
    padding: "138px 20px 93px 20px",
    boxSizing: "border-box",
    textAlignment: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
})

const GridWrapper = styled(Grid)({
    width: "100%",
    margin: "0px",
    padding: "0px"
})

const GridWrapper2 = styled(Grid)({
    width: "100%",
    margin: "0px",
    padding: "0px",
    gap: "32px"
})

const GridItem = styled(Grid)({
    marginBottom: "16px"
})

const CustomCheckbox = styled(Checkbox)({
    color: "#F1F5F9 !important"
})

const CustomFormControlLabel = styled(FormControlLabel)({
    fontSize: "16px",
    fontWeight: 400,
    color: "#F1F5F9",
    lineHeight: "24px",
    fontFamily: "inter"
})

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    color: "#858686 !important",
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "inter !important",
    backgrounColor: "#DBE0FF !important",
    padding: "10px 7px !important",
    lineHeight: "18px !important",
    maxWidth: "160px !important"
}));

const Container2 = styled(Box)({
    width: "100%",
    backgroundColor: "#0F172A",
    padding: "89px 20px",
    boxSizing: "border-box",
    textAlignment: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
})

const WhiteBox = styled(Box)({
    width: "100%",
    boxSizing: "border-box",
    maxWidth: "850px",
    backgroundColor: "white",
    borderRadius: "18px",
    padding: "42px 20px 68px 20px"
})

const Wrapper = styled(Box)({
    width: "100%",
    maxWidth: "496px",
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "auto"
})
// Customizable Area End
