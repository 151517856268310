// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import Label from "../../../components/src/design-system/Label";
import TextInput from "../../../components/src/design-system/TextInput";
import ButtonCustom from "../../../components/src/design-system/Button/Button";
import Image from "../../../components/src/design-system/Image/Image";
import AppBackground from "../../../components/src/design-system/BackgroundColor/AppBackground";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { signUpImage } from "../../email-account-registration/src/assets";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import { Props, configJSON } from "./EmailAccountRegistrationController";
import Text from "../../../components/src/design-system/Text";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json')
// Customizable Area End

// Customizable Area Start

const classes = {
  root: {
    "@media (max-width: 1200px)": {
      width: "100%",
      padding: "0px 0px 0px",
      margin: "0px",
    },
    margin: "100px auto 100px",
    padding: "0px 100px 16px",
    width: "1000px",
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
  },
  boxContainer: {
    margin: "48px auto 48px",
    borderRadius: "25px",
  },
  form: {
    "@media (max-width: 767px)": {
      flexDirection: 'column',      
      padding:"0 30px",
      "& .MuiFormControl-fullWidth": {
        width: '100% !important',
      }
    },
    "@media (min-width: 768px) and (max-width:1200px)": {
      display: "flex",
      justifyContent: "space-evenly",
      gap: 5,
      marginBottom: "24px",
    },
    display: "flex",
    justifyContent: "space-evenly",
    gap: 4,
    marginBottom: "15px",
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      boxShadow: "none",
      height: "44px",
      border: "none",
      '& > input[type=text]': {
        paddingTop: "8px"
      },
      '& > input[type=password]': {
        paddingTop: "8px"
      }
    },
    
  },
  mobileForm: {
    "@media (max-width: 768px)": {
      marginBottom: "48px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        boxShadow: "none",
        height: "44px",
        '& > input[type=text]': {
          paddingTop: "8px"
        },
        '& > input[type=password]': {
          paddingTop: "8px"
        }
      },
    },
    display: "none",
  },
  mobileFormField: {
    "@media (max-width: 768px)": {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    },
    display: "none",
  },
  password: {
    marginBottom: "40px",
  },
  imgMargin: {
    marginTop: "24px",
    marginBottom: "32px",
    textAlign: "center"
  },
  containerText: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
};

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[`${currentLanguage}`];
    return (
      <ThemeProvider theme={this.theme}>
        <Box sx={classes.root}>
          <AppBackground backgroundColor="#CBD5E1" borderRadius="10px">
            <Box mt={5.5}>
              <Text size="1xl" weight="extraBold" family="inter" align="center">{languageData.Buyer.Title}</Text>
              <Text size="sm" weight="minBold" align="center" family="inter">{languageData.Buyer.SubTitle}</Text>
              <Box sx={classes.imgMargin}>
                <Image src={signUpImage} alt="Sign Up" />
              </Box>
              <Box sx={classes.form}>
                <TextInput
                  id="firstName"
                  label={languageData.Buyer.FirstName}
                  manualWidth={380}
                  onChange={this.firstNameInputProps.onChangeText}
                  value={this.state.firstName}
                  error={this.state.errors.FirstName}
                />
                <TextInput
                  id="phone"
                  label={languageData.Buyer.PhoneNumber}
                  manualWidth={380}
                  onChange={this.phoneInputProps.onChangeText}
                  value={this.state.phone}
                  error={this.state.errors.PhoneNumber}
                />
              </Box>
              <Box sx={classes.form}>
                <TextInput
                  id="lastName"
                  label={languageData.Buyer.LastName}
                  manualWidth={380}
                  onChange={this.lastNameInputProps.onChangeText}
                  value={this.state.lastName}
                  error={this.state.errors.LastName}
                />
                <TextInput
                  id="email"
                  label={languageData.Buyer.Email}
                  manualWidth={380}
                  onChange={this.txtInputEmailWebPrpos.onChangeText}
                  value={this.state.email}
                  error={this.state.errors.Email}
                />
              </Box>
              <Box sx={classes.form}>
                <TextInput
                  id="password"
                  label={languageData.Buyer.CreatePassword}
                  manualWidth={380}
                  onChange={this.passwordProps.onChangeText}
                  value={this.state.password}
                  type="password"
                  error={this.state.errors.Password}
                />
                <TextInput
                  id="username"
                  label={languageData.Buyer.UserName}
                  manualWidth={380}
                  onChange={this.usernameInputProps.onChangeText}
                  value={this.state.userName}
                  error={this.state.errors.UserName}
                />
              </Box>
              <Box sx={classes.form}>
                <Box sx={classes.password}>
                  <TextInput
                    id="confirmPassword"
                    label={languageData.Buyer.ConfirmPassword}
                    manualWidth={380}
                    onChange={this.confirmPasswordProps.onChangeText}
                    value={this.state.reTypePassword}
                    type="password"
                    error={this.state.errors.ConfirmPassword}
                  />
                </Box>
                <Box sx={{ width: "380px" }} />
              </Box>
              <Box sx={classes.mobileForm}>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="firstName-mob"
                    label={languageData.Buyer.FirstName}
                    manualWidth={360}
                    onChange={this.firstNameInputProps.onChangeText}
                    value={this.state.firstName}
                    error={this.state.errors.FirstName}
                  />
                </Box>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="lastName-mob"
                    label={languageData.Buyer.LastName}
                    manualWidth={360}
                    onChange={this.lastNameInputProps.onChangeText}
                    value={this.state.lastName}
                    error={this.state.errors.LastName}
                  />
                </Box>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="email-mob"
                    label={languageData.Buyer.Email}
                    manualWidth={360}
                    onChange={this.txtInputEmailWebPrpos.onChangeText}
                    value={this.state.email}
                    error={this.state.errors.Email}
                  />
                </Box>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="phone-mob"
                    label={languageData.Buyer.PhoneNumber}
                    manualWidth={360}
                    onChange={this.phoneInputProps.onChangeText}
                    value={this.state.phone}
                    error={this.state.errors.PhoneNumber}
                  />
                </Box>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="username-mob"
                    label={languageData.Buyer.UserName}
                    manualWidth={360}
                    onChange={this.usernameInputProps.onChangeText}
                    value={this.state.userName}
                    error={this.state.errors.UserName}
                  />
                </Box>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="password-mob"
                    label={languageData.Buyer.CreatePassword}
                    manualWidth={360}
                    onChange={this.passwordProps.onChangeText}
                    value={this.state.password}
                    type="password"
                    error={this.state.errors.Password}
                  />
                </Box>
                <Box sx={classes.mobileFormField}>
                  <TextInput
                    id="confirmPassword-mob"
                    label={languageData.Buyer.ConfirmPassword}
                    manualWidth={360}
                    onChange={this.confirmPasswordProps.onChangeText}
                    value={this.state.reTypePassword}
                    type="password"
                    error={this.state.errors.ConfirmPassword}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ButtonCustom
                  size="sm"
                  themes="dark"
                  onClickHandle={this.signupButton.onPress}
                  data-testid="signup-btn"
                >
                  {languageData.Buyer.SignupBtn}
                </ButtonCustom>
              </Box>
              <Box mt={2} mb={5}>
                <Text size="sm" weight="minBold" align="center" family="inter" >{languageData.Buyer.AlreadyHaveAnAccount}</Text>

                <span id="signIn" onClick={this.signInButton.onPress} style={{ cursor: 'pointer' }}>
                  <Text size="sm" weight="extraBold" align="center" family="inter">{languageData.Buyer.SignIn}</Text>
                </span>
              </Box>
            </Box>
          </AppBackground>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
