import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');
export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  reTypePassword: string;
  phone: string;
  passwordHelperText: string;
  activated: boolean;
  userType: string;
  errors: {
    FirstName: string,
    PhoneNumber: string,
    LastName: string,
    Email: string,
    Password: string,
    UserName: string,
    ConfirmPassword: string

  },
  validateResult: boolean;
  apiError: string,
  currentLanguage:"en"|"ar"
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  phoneReg: RegExp;
  nameReg: RegExp;
  lastNameReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  labelFirstName: string;
  labelUserName: string;
  lastName: string;
  labelPhone: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  btnTextSignUp: string;
  userType: string;
  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      reTypePassword: "",
      phone: "",
      passwordHelperText: "",
      activated: true,
      userType: "consumer",
      errors: {
        FirstName: "",
        PhoneNumber: "",
        LastName: "",
        Email: "",
        Password: "",
        UserName: "",
        ConfirmPassword: ""
      },
      validateResult: false,
      apiError: "",
      currentLanguage:configJSON.EN
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.passwordReg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    this.phoneReg = /^\d{10}$/;
    this.nameReg = /^[^\d][a-zA-Z\d!@#$%^&*()_+{}\[\]:;<>,.?\/\\~-]{2,}$/;
    this.lastNameReg = /^(?![\d])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|~-]+$/;
    this.labelFirstName = configJSON.labelFirstName;
    this.labelUserName = configJSON.labelUserName;
    this.lastName = configJSON.lastName;
    this.labelPhone = configJSON.labelPhone;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.userType = configJSON.userType;
    // Customizable Area End
  }

  async componentDidMount() {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language });
  }

  handleErrors(responseJson: any, errorResponse: any) {
    //Check Error Response
    this.parseApiErrorResponse(responseJson);
    this.parseApiCatchErrorResponse(errorResponse);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.validateResponse(responseJson);
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors && !responseJson.error) {
            await setStorageData("userId", responseJson.data.id);
            const msg: Message = new Message(
              getName(MessageEnum.NavigationMessage)
            );
            msg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );
            this.send(msg);
          } else {
            this.errorResponse(responseJson);
          }
        }
      }
    }

    // Customizable Area End
  }

  validateResponse(responseJson: any) {
    this.arrayholder = responseJson.data;
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }

      if (regexData.name_validation_regexp) {
        this.nameReg = new RegExp(regexData.name_validation_regexp);
      }
      if (regexData.lastName_validation_regexp) {
        this.lastNameReg = new RegExp(regexData.lastName_validation_regexp);
      }

    }
  }
  // Customizable Area Start
  errorResponse(responseJson: { errors: [{ [key: string]: string }] }) {
    for (const key in responseJson?.errors[0]) {
      if (responseJson?.errors[0].hasOwnProperty(key)) {
        const appFieldName = this.mapApiFieldToAppField(key);
        this.setError(appFieldName, responseJson.errors[0][appFieldName]);
      }
    }
  }

  mapApiFieldToAppField(apiKey: string): string {
    const fieldMappings: Record<string, string> = {
      user_name: "UserName",
      email: "Email",
    };
    return fieldMappings[apiKey] || apiKey;
  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isEmailValid(email: string) {
    return this.emailReg.test(email);
  }

  isNameValid(email: string) {
    return this.nameReg.test(email);
  }

  isLastNameValid(email: string) {
    return this.lastNameReg.test(email);
  }

  isPasswordMatch() {
    return this.state.password === this.state.reTypePassword;
  }

  isPasswordValid() {
    return this.passwordReg.test(this.state.password);
  }

  sendData() {
    const headers = { "Content-Type": "application/json" };

    const bodyData = {
      "data": {
        "attributes": {
          "activated": true,
          "email": this.state.email,
          "first_name": this.state.firstName,
          "full_phone_number": this.state.phone,
          "last_name": this.state.lastName,
          "password": this.state.password,
          "user_name": this.state.userName,
          "user_type": "consumer",
          "images": null
        },
        "type": "email_account"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getConvertedErrorField = (key:string) => {
    const {currentLanguage} = this.state;
    const languageData = languageTranslationData[currentLanguage]
    switch(key){
      case configJSON.Email:return languageData.Buyer.Email;
      case configJSON.FirstName:return languageData.Buyer.FirstName;
      case configJSON.LastName: return languageData.Buyer.LastName;
      case configJSON.PhoneNumber:return languageData.Buyer.PhoneNumber;
      case configJSON.UserName:return languageData.Buyer.UserName;
      case configJSON.Password:return languageData.Buyer.Password;
      case configJSON.ConfirmPassword:return languageData.Buyer.ConfirmPassword;
      default: return "";
    }
  }

  setError = (field: string, msg: string = "") => {
    const convertedField = this.getConvertedErrorField(field);
    const errorMsg = this.state.currentLanguage === configJSON.EN?`${convertedField} is required`:`مطلوب ${convertedField}`  
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: msg || errorMsg,
      }
    }))
  }

  resetError(field: string) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: '',
      },
    }));
  }

  checkInputField = () => {
    let hasErrors = false;
    if (this.isStringNullOrBlank(this.state.firstName)) {
      this.setError(configJSON.FirstName);
      hasErrors = true;
    }

    if (this.isStringNullOrBlank(this.state.lastName)) {
      this.setError(configJSON.LastName);
      hasErrors = true;

    }
    if (this.isStringNullOrBlank(this.state.userName)) {
      this.setError(configJSON.UserName);
      hasErrors = true;
    }
    if (this.isStringNullOrBlank(this.state.email)) {
      this.setError(configJSON.Email);
      hasErrors = true;
    }
    if (this.isStringNullOrBlank(this.state.phone)) {
      this.setError(configJSON.PhoneNumber);
      hasErrors = true;
    }
    if (this.isStringNullOrBlank(this.state.password)) {
      this.setError(configJSON.Password);
      hasErrors = true;
    }
    if (this.isStringNullOrBlank(this.state.reTypePassword)) {
      this.setError(configJSON.ConfirmPassword)
      hasErrors = true;
    }
    return hasErrors;
  }

  getConvertedErrorMsg = (field:string)=>{
    const {currentLanguage} = this.state;
    const isEnglish = currentLanguage === configJSON.EN;
    const convertedField = this.getConvertedErrorField(field);
    return isEnglish?`Enter a valid ${convertedField}`:`أدخل الصحيح ${convertedField}`
  }

  checkInputError() {
    let hasErrors = false;

    if (!this.isStringNullOrBlank(this.state.email)) {
      if (!this.isEmailValid(this.state.email)) {
        const errorMsg = this.getConvertedErrorMsg(configJSON.Email);
        this.setError(configJSON.Email, errorMsg);
        hasErrors = true;
      }
    }
    if (!this.isStringNullOrBlank(this.state.firstName)) {
      if (!this.isNameValid(this.state.firstName)) {
        const errorMsg = this.getConvertedErrorMsg(configJSON.FirstName);
        this.setError(configJSON.FirstName, errorMsg);
        hasErrors = true;
      }
    }

    if (!this.isStringNullOrBlank(this.state.userName)) {
      if (!this.isNameValid(this.state.userName)) {
        const errorMsg = this.getConvertedErrorMsg(configJSON.UserName);
        this.setError(configJSON.UserName, errorMsg);
        hasErrors = true;
      }
    }

    if (!this.isStringNullOrBlank(this.state.lastName)) {
      if (!this.isLastNameValid(this.state.lastName)) {
        const errorMsg = this.getConvertedErrorMsg(configJSON.LastName);
        this.setError(configJSON.LastName, errorMsg);
        hasErrors = true;
      }
    }

    return hasErrors;

  }

  checkFieldError() {
    let hasErrors = false;

    if (!this.isStringNullOrBlank(this.state.phone)) {
      if (!this.validatePhoneNumber(this.state.phone)) {
        const errorMsg = this.getConvertedErrorMsg(configJSON.PhoneNumber);
        this.setError(configJSON.PhoneNumber, errorMsg);
        hasErrors = true;
      }
    }

    if (!this.isStringNullOrBlank(this.state.password)) {
      if (!this.isPasswordValid()) {
        const errorMsg = this.getConvertedErrorMsg(configJSON.Password);
        this.setError(configJSON.Password, errorMsg);
        hasErrors = true;
      }
    }

    if (!this.isStringNullOrBlank(this.state.reTypePassword)) {
      if (!this.isPasswordMatch()) {
        this.setError(configJSON.ConfirmPassword, this.state.currentLanguage === configJSON.EN?"Passwords should be same.":'يجب أن تكون كلمات المرور هي نفسها.');
        hasErrors = true;
      }

    }
    return hasErrors;
  }
  createUserAccount = () => {
    let hasErrors1 = this.checkInputField();
    let hasErrors2 = this.checkInputError();
    let hasErrors3 = this.checkFieldError();
    if (!hasErrors1 && !hasErrors2 && !hasErrors3) {
      this.sendData()
    }
  }

  validatePhoneNumber(phoneNumber: string) {
    return this.phoneReg.test(phoneNumber);
  }

  signupButton = {
    onPress: () => this.createUserAccount(),
  };

  signInButton = {
    onPress: () => {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserLogin");

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    },
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      this.resetError(configJSON.Email);
    },
  };

  phoneInputProps = {
    onChangeText: (text: string) => {
      const inputValue = text?.replace(/[a-zA-Z@!#$%^&*()_+={}[\]:;<>,.?~\\/-]/g, '');
      this.setState({ phone: inputValue });
      this.resetError(configJSON.PhoneNumber);
    },
  };

  lastNameInputProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
      this.resetError(configJSON.LastName)
    },
  };

  usernameInputProps = {
    onChangeText: (text: string) => {
      this.setState({ userName: text });
      this.resetError(configJSON.UserName)
    },
  };

  firstNameInputProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
      this.resetError(configJSON.FirstName)
    },
  };

  confirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
      this.resetError(configJSON.ConfirmPassword);
    },
  };

  passwordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
      this.resetError(configJSON.Password)
    },
  };

  // Customizable Area End
}
