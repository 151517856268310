export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dashboardIcon = require("../assets/dashboard.svg")
export const userManagementIcon = require("../assets/usermanagement.svg")
export const requestsIcon = require("../assets/requests.svg")
export const staffIcon = require("../assets/staff.svg")
export const addServiceIcon = require("../assets/addService.svg")
export const verificationRequestIcon = require("../assets/verificationRequest.svg")
export const settingsIcon = require("../assets/settings.svg")
export const auditLogIcon = require("../assets/auditLog.svg")
export const archiveIcon = require("../assets/archive.svg")
export const notificationIcon = require("../assets/notification.svg")
export const profile = require("../assets/profile.png")
export const allRead = require("../assets/allRead.svg")
export const burgerIcon = require("../assets/burger.svg")
export const SearchIcon = require("../assets/search.svg")
export const SortIcon = require("../assets/sort.svg")
export const FilterIcon = require("../assets/filter.svg")
export const VerticalMoreIcon = require("../assets/verticalMore.svg")
export const admin = require("../assets/Admin.png")
export const viewProfile = require("../assets/viewProfile.svg")
export const deleteProfile = require("../assets/deleteProfile.svg")
export const blockProfile = require("../assets/blockProfile.svg")
export const FileIcon = require("../assets/file.svg")
export const vectorIcon = require("../assets/VectorImage.png")
export const acceptImg = require("../assets/accept.png")
export const rejectImg = require("../assets/reject.png")
export const pendingImg = require("../assets/pending.png")
export const activeImg = require("../assets/active.svg")
export const filterImg = require("../assets/filter.png")
export const groupReImg = require("../assets/group_16860.png");
export const infoReImg = require("../assets/info.png");
export const image_notes = require("../assets/image_notes.png")
export const image = require("../assets/image_.png")
export const viewCircul = require("../assets/viewcircle.png")
export const profileImg = require("../assets/profileimg.png")
export const dollarImg = require("../assets/dollar.png")
export const serverImg = require("../assets/group_.png")
export const userProfileImg = require("../assets/Path.png")
export const uploadImg = require("../assets/upload.png")
export const morebtnuploadImg = require("../assets/uploadbtn.png")
export const vectorImg2 = require("../assets/vector.png")
export const blankListingImage= require("../assets/blankListingImage.svg");
export const exportImg = require("../assets/export.svg");
export const visaIcon = require("../assets/visaicon.svg");
export const UserDeleteProfile = require("../assets/UserDeleteProfile.png")
export const acitveIcon = require("../assets/activeIcon.svg");
export const rejectIcon = require("../assets/reject.svg");
export const pendingIcon = require("../assets/pendingSvg.svg");
export const avgIcon = require('../assets/avgIcon.svg');
export const fileUploadIcon = require('../assets/fileUploadIcon.svg');
export const componyNameLogo = require('../assets/componyNameLogo.svg');
export const dummyQrCode = require('../assets/dummyQrCode.svg');
export const tredingUp = require('../assets/tredingUp.svg');
export const tredingDown = require('../assets/tredingDown.svg');
export const DocUpload = require('../assets/DocUpload.png');
export const languageButton = require('../assets/languageButton.png');
export const adminProfile = require('../assets/adminProfile.png');
export const adminNotification = require('../assets/adminNotification.png');
export const UserFirst = require('../assets/User-1.png');
export const UserSecond = require('../assets/User-2.png');
export const UserThird = require('../assets/User-3.png');
export const UserFourth = require('../assets/User-4.png');
export const UserFifth = require('../assets/User-5.png');
export const UserSix = require('../assets/User-6.png');
export const UserSeven = require('../assets/User-7.png');
export const UserEight = require('../assets/User-8.png');
export const BreadcrumArrow = require('../assets/BreadcrumArrow.png');
