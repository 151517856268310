//@ts-nocheck
import React, { Fragment } from "react";
// Customizable Area Start
import { Box, Typography, Avatar, styled, } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { image8, image9, image10, BlogImg, rightImg, leftImg, alexImg, michaelImg, emilyImg, nickImg, navigatingImg, futureImg, groupImg, alarmImg, vectorImg } from "./assets";
import ContentManagementControllerWeb, {
  AboutPageData,
  Props as ContentProps
} from "./ContentManagementController.web";
import Text from "../../../components/src/design-system/Text";
import './ContentManagement.css'
import parse from 'html-react-parser'
import Loader from "../../../../packages/components/src/Loader.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start

// Customizable Area End

// Customizable Area Start

export class ContentManagement extends ContentManagementControllerWeb {
  constructor(props: ContentProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    // Customizable Area Start
    const { aboutUsData, currentLanguage, loading } = this.state;
    const aboutUsPageData = aboutUsData;
    const isEnglish = currentLanguage === configJSON.EN;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainBox>
        <Box style={styles.breadcrumbContainer}>
          <Typography style={styles.breadcrumbText}>
            {configJSON.breadcrumHome}
          </Typography>
          <Avatar style={styles.breadcrumbImg} src={vectorImg} />
          <Typography style={styles.breadcrumbText} onClick={() => this.props.navigation.navigate('Catalogue')}>
            {configJSON.breadcrumBlog}
          </Typography>
          <Avatar style={styles.breadcrumbImg} src={vectorImg} />
          <Typography style={styles.breadcrumbTextH}>
            {configJSON.breadcrumArticle}
          </Typography>
        </Box>
        <Box style={styles.blogHeroBox}>
          <Box style={styles.blogHeroSubBox}>
            <Box style={styles.blogHeroTextBox}>
              <Typography style={styles.blogTextHiglight}>
                {configJSON.blogHeroHeighlite}
              </Typography>
              <Typography style={styles.blogTextDate}>{configJSON.blogHeroDate}</Typography>
            </Box>
            <Box>
              <Typography style={styles.blogHeroHead}>{configJSON.blogHeroHead}</Typography>
            </Box>
            <Box style={styles.blogAvtarBox}>
              <Avatar style={styles.blogAvtar} src={nickImg} />
              <Box>
                <Typography style={styles.blogAvtarName}>
                  {configJSON.blogAvtarName}
                </Typography>
                <Typography style={styles.blogAvtarDetail}>
                  {configJSON.blogAvtarDetail}
                </Typography>
              </Box>
            </Box>


          </Box>
          <Box style={{ height: '240px', width: '587px', borderRadius: "20px" }}>
            <img style={{ height: '240px', width: '587px', objectFit: "cover" }} src={image8} />
          </Box>
        </Box>

        <Box style={styles.blogMainBox}>
          <Box style={styles.blogMainBoxWrapper}>
            <Box style={styles.blogSideBar}>
              <Typography style={styles.blogSideBarHead}>{configJSON.blogSideBarHead}</Typography>
              <Box style={styles.blogSideBarHeadLine}></Box>
            </Box>
            <Box style={styles.blogSideBarData}>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData1}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData2}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData3}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData4}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData5}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData6}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData7}</Typography>
              <Typography style={styles.sideList}>{configJSON.blogSideBarData8}</Typography>

            </Box>
            <Box style={styles.blogTimeBox}>
              <img style={{ width: "246px" }} src={groupImg} />
              <Box style={styles.blogAlarmBox}>
                <img style={styles.blogAlarmIcon} src={alarmImg} />
                <Typography style={styles.blogAlarmText}>{configJSON.blogAlarmText}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={styles.blogTextSectionBox}>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading1}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription1}</Typography>
            </Box>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading2}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription2}</Typography>
            </Box>
            <Box>
              <img style={styles.blogTextImg} src={image9} />
            </Box>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading3}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription3}</Typography>
            </Box>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading4}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription4}</Typography>
            </Box>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading5}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription5}</Typography>
            </Box>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading6}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription6}</Typography>
            </Box>
            <Box>
              <img style={styles.blogTextImg} src={BlogImg} />
            </Box>
            <Box>
              <Typography style={styles.blogTextHead}>{configJSON.blogTextHeading7}</Typography>
              <Typography style={styles.blogTextDes}>{configJSON.blogTextDescription7}</Typography>
            </Box>
          </Box>
        </Box>

        <Box style={styles.contentHero}>
          <Box style={styles.blogTestimonial}>
            <Typography style={styles.blogTestimonialHeading}>{configJSON.blogTestimonialHeading}</Typography>
            <Box style={styles.blogTestimonialBox}>
              <Box style={styles.blogTestimonialImgBox}>
                <img style={styles.blogTestimonialImg} src={leftImg} />
              </Box>
              <Box style={styles.blogTestimonialImgBox2}>
                <img style={styles.blogTestimonialImg} src={rightImg} />
              </Box>
            </Box>
          </Box>

          <Box style={styles.blogTestMainBox}>
            <Box style={styles.blogTestSubBox}>
              <Box
                style={styles.blogTestImg}
              />
              <Box style={styles.blogTestImgText}>
                {configJSON.blogTesImgText}
              </Box>
              <Box style={styles.blogTestTextBox}>
                <Typography style={styles.blogTestTextDate}>
                  {configJSON.blogTesTextDate}
                </Typography>
                <Typography style={styles.blogTestHeading}>
                  {configJSON.blogTesHeading}
                </Typography>
                <Box style={styles.blogAvatarDeatilBox}>
                  <Avatar style={styles.blogAvatarImg} src={alexImg} />
                  <Box>
                    <Typography style={styles.blogAvatarName}>
                      {configJSON.blogAvatarName}
                    </Typography>
                    <Typography style={styles.blogAvatarPosition}>
                      {configJSON.blogAvatarPosition}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>


            <Box style={styles.blogTestSubBox}>
              <Box
                style={{ height: "280px", width: '387px', backgroundImage: `url(${navigatingImg})`, backgroundPosition: 'center', backgroundSize: 'contain' }}
              />
              <Box style={styles.blogTestImgText}>
              {configJSON.blogTesImgText}
              </Box>
              <Box style={styles.blogTestTextBox}>
                <Typography style={styles.blogTestTextDate}>
                  {configJSON.blogTesTextDate1}
                </Typography>
                <Typography style={styles.blogTestHeading}>
                  {configJSON.blogTesHeading1}
                </Typography>
                <Box style={styles.blogAvatarDeatilBox}>
                  <Avatar style={styles.blogAvatarImg} src={michaelImg} />
                  <Box>
                    <Typography style={styles.blogAvatarName}>
                      {configJSON.blogAvatarName1}
                    </Typography>
                    <Typography style={styles.blogAvatarPosition}>
                      {configJSON.blogAvatarPosition1}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box style={styles.blogTestSubBox}>
              <Box
                style={{ height: "280px", width: '387px', backgroundImage: `url(${futureImg})`, backgroundPosition: 'center', backgroundSize: 'contain' }}
              />
              <Box style={styles.blogTestImgText}>
              {configJSON.blogTesImgText}
              </Box>
              <Box style={styles.blogTestTextBox}>
                <Typography style={styles.blogTestTextDate}>
                  {configJSON.blogTesTextDate2}
                </Typography>
                <Typography style={styles.blogTestHeading}>
                  {configJSON.blogTesHeading2}
                </Typography>
                <Box style={styles.blogAvatarDeatilBox}>
                  <Avatar style={styles.blogAvatarImg} src={emilyImg} />
                  <Box>
                    <Typography style={styles.blogAvatarName}>
                      {configJSON.blogAvatarName2}
                    </Typography>
                    <Typography style={styles.blogAvatarPosition}>
                      {configJSON.blogAvatarPosition2}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainBox>
      // Customizable Area End

    );
  }
}

// Customizable Area End

// Customizable Area Start
const MainBox = styled(Box)({
  padding: '80px 120px',
});
const styles: StyleRules = {

  breadcrumbContainer: {
    width: '197px',
    height: '30px',
    position: 'absolute' as 'absolute',
    top: '112px',
    left: '120px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  breadcrumbText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left' as 'left',
    color: '#475569',
  },
  breadcrumbTextH: {
    fontSize: '14px',
    color: '#0F172A',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    lineHeight: '22px',
  },
  contentHero: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    marginTop: "96px"
  },
  sideList: {
    color: "#101828",
    fontSize: "14px",
    fontWeight: 400,
  },
  breadcrumbImg: {

    width: "5.49px",
    height: "9.31px"
  },
  blogHeroBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '240px',
    width: "1200px",
    alignItems: "center"
  },
  blogHeroSubBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '549px',
    height: "216px"
  },
  blogHeroTextBox: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center'
  },
  blogTextHiglight: {
    background: '#DBEAFE',
    padding: '6px 12px',
    color: '#1D4ED8',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '26px'

  },
  blogTextDate: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'
  },
  blogHeroHead: {
    fontSize: '36px',
    fontWeight: 600,
    color: '#0F172A'
  },
  blogAvtarBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'

  },
  blogAvtar: {
    height: '48px',
    width: '48px',
    borderRadius: '50px'
  },
  blogAvtarName: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#334155'

  },
  blogAvtarDetail: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'

  },
  blogMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: "96px"
  },
  blogMainBoxWrapper: {
    height: '464px',
    width: '232px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingLeft: "14px",


  },
  blogSideBar: {
    width: "232px",
    height: '24px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"

  },
  blogSideBarHead: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#101828"
  },
  blogSideBarHeadLine: {
    height: "2px",
    width: "18px",
    background: "#0F172A"

  },
  blogSideBarData: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: "#101828",
    fontSize: "14px",
    fontWeight: 400,

  },
  blogTimeBox: {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  blogAlarmBox: {
    width: "246px",
    height: "22px",
    display: "flex",
    gap: "8px",
    justifyContent: "center"
  },
  blogAlarmIcon: {
    height: "16.48",
    width: "15.89px"
  },
  blogAlarmText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A"
  },
  blogTextSectionBox: {
    width: '895px',
    display: 'flex',
    flexDirection: 'column',
    gap: '44px'

  },
  blogTextHead: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#101828'
  },
  blogTextDes: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#344054'
  },
  blogTextImg: {
    width: '895px',
    height: '400px'
  },
  blogTestimonial: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  blogTestimonialHeading: {
    fontSize: '30px',
    fontWeight: 600,
    color: '#0F172A'
  },
  blogTestimonialBox: {
    display: "flex",
    gap: "24px"
  },
  blogTestimonialImgBox: {
    borderRadius: "8px",
    height: "44px",
    width: "44px",
    background: "#F1F5F9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  blogTestimonialImg: {
    height: "18.76px",
    width: "10.74"
  },
  blogTestimonialImgBox2: {
    borderRadius: "8px",
    height: "44px",
    width: "44px",
    background: "#1E3A8A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  blogTestMainBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  blogTestSubBox: {
    position: 'relative',
    height: '438px',
    width: '387px',
    borderRadius: '26px',
    border: 'none'
  },
  blogTestImg: {
    height: "280px",
    width: '387px',
    backgroundImage: `url(${image10})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  },
  blogTestImgText: {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: '#DBEAFE',
    padding: '6px 12px 6px 12px',
    color: '#1D4ED8',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '26px'
  },
  blogTestTextBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    marginTop: '12px'
  },
  blogTestTextDate: {
    display: 'flex',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'
  },
  blogTestHeading: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828'

  },
  blogAvatarDeatilBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'

  },
  blogAvatarImg: {
    height: '48px',
    width: '48px',
    border: '1px solid black',
    borderRadius: '50px'
  },
  blogAvatarName: {
    fontSize: '14px',
     fontWeight: 600,
      color: '#334155'

  },
  blogAvatarPosition: {
    fontSize: '14px',
     fontWeight: 400,
      color: '#64748B'

  }




}
export default withStyles(styles)(ContentManagement);
// Customizable Area End
