import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
export const configJSON = require("./config");
const languageTranslationData = require('../../languagesupport/src/LanguageData/Listing.json');
const utills = require('../../utilities/src/utills');

interface BasicInfoErrors {
    portfolioSizeErr: string;
    listingTitleErr: string;
    averageBalanceErr: string;
    accountCountErr: string;
};

interface PropsObjectItem {
    time: string;
    portfolioSize: string;
    listingTitle: string;
    averageBalance: string;
    accountCount: string;
    totalNumber: string;
    totalAmount: string;
    totalJudgement: string;
    totalMonth: string;
    pastPay: string;
    monthAvg: string;
    lastCollDay: string;
    lastCollMonth: string;
    lastCollYear: string;
    lastCredDay: string;
    lastCredMonth: string;
    lastCredYear: string;
    typeOfDebt:string;
    ageOfDebt:string;
    recoveryStatus:string;
    supportDocArray: FileArray;
    otherDocArray: FileArray;
    phoneNumber: string;
    name: string;
    email: string;
    country: string;
    state: string;
    city: string;
    zip: string
}

interface DetailInfoErrorsItem {
    totalNumErr: string;
    totalAmtErr: string;
    totalJudgmentsErr: string;
    totalMonthErr: string;
    pastPayErr: string;
    monthAvgErr: string;
    lastCollectErr: string;
    lastCredErr: string;
};

interface DebtCharErrItem {
    typeOfDebtErr: string;
    ageOfDebtErr: string;
    recoveryStatusErr: string;
};

interface UserData {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      first_name: string  | null;
      last_name: string  | null;
      full_phone_number: string  | null;
      country_code: string | null;
      email: string  | null;
      phone_number: string | null;
      gender: string | null;
      type: string  | null;
      user_type: string  | null;
      user_name: string  | null;
      address: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      zipcode: number | null;
      business_name: string | null;
      tax_number: string | null;
      created_at: string  | null;
      updated_at: string  | null;
      device_id: string | null;
      unique_auth_id: string  | null;
      kyc_name: string | null;
      kyc_number: string | null;
      kyc_verified: boolean | null;
      kyc_document:string | null,
      profile_image: string | null;
      images: {
        tax_registration: IDocumentInfo,
        license_certification:IDocumentInfo,
        business_registration:IDocumentInfo,
        other_documents:IDocumentInfo
      };
    };
};
interface IDocumentInfo {
    file_name: string | null
    url: string | null;
    size: string | null;
};

type FileArray = (File|null)[]
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    currentLanguage: "en" | "ar",
    listingSummary: {
        title: string,
        value: string,
        error: string,
        type: string,
        id: string,
        placeholder: string
    }[],
    supportingDocument: {
        id: string,
        error: string,
        file: File | null
    }[],
    otherDocument: {
        id: string,
        error: string,
        file: File | null
    }[],
    anchorEl: null | HTMLElement,
    breadscrumArray: string[];
    pageHeading: string;
    showPreview: boolean;
    checkSave: boolean;
    portfolioSize: string;
    listingTitle: string;
    averageBalance: string;
    accountCount: string;
    basicInfoErrors: BasicInfoErrors;
    detailInfoErrors:DetailInfoErrorsItem;
    totalNumber: string;
    totalAmount: string;
    totalJudgement: string;
    totalMonth: string;
    pastPay: string;
    monthAvg: string;
    lastCollDay: string;
    lastCollMonth: string;
    lastCollYear: string;
    lastCredDay: string;
    lastCredMonth: string;
    lastCredYear: string;
    typeOfDebt:string;
    ageOfDebt:string;
    recoveryStatus:string;
    debtCharErr: DebtCharErrItem;
    supportDocArray: FileArray;
    otherDocArray: FileArray;
    previewValues: PropsObjectItem;
    phoneNumber: string;
    name: string;
    email: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    isError: boolean;
    documentErr: string;
    otherDocError: string;
    userType: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateListingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchProfileCallId: string= "";
    createListCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            listingSummary: createListSummary('en'),
            supportingDocument: supportingDoc('en'),
            otherDocument: otherDoc('en'),
            anchorEl: null,
            currentLanguage: 'en',
            breadscrumArray: [],
            pageHeading: "",
            showPreview: false,
            checkSave: false,
            portfolioSize: "",
            listingTitle: "",
            averageBalance: "",
            accountCount: "",
            basicInfoErrors: {
                portfolioSizeErr: "",
                listingTitleErr: "",
                averageBalanceErr: "",
                accountCountErr: ""
            },
            detailInfoErrors:{
                totalNumErr: "",
                totalAmtErr: "",
                totalJudgmentsErr: "",
                totalMonthErr: "",
                pastPayErr: "",
                monthAvgErr: "",
                lastCollectErr: "",
                lastCredErr: ""
            },
            totalNumber: "",
            totalAmount: "",
            totalJudgement: "",
            totalMonth: "",
            pastPay: "",
            monthAvg: "",
            lastCollDay: "",
            lastCollMonth: "",
            lastCollYear: "",
            lastCredDay: "",
            lastCredMonth: "",
            lastCredYear: "",
            typeOfDebt: "",
            ageOfDebt: "",
            recoveryStatus: "",
            debtCharErr: {
                typeOfDebtErr: "",
                ageOfDebtErr: "",
                recoveryStatusErr: ""
            },
            supportDocArray: Array(2).fill(null),
            otherDocArray: Array(2).fill(null),
            previewValues: configJSON.previewValues,
            phoneNumber: "",
            name: "",
            email: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            isError: false,
            documentErr: "",
            otherDocError: "",
            userType: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.createListCallId) {
                    this.handleNavigation("UserMyListing")
                }
                if (apiRequestCallId === this.fetchProfileCallId) {
                    this.handleApiSuccessResponse(responseJson.data.attributes);
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        let accessToken = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        const language = await utills.getLanguageOrSetCurrentLanguage();
        let breadscrum = languageTranslationData[language].breadscrumOne;
        if (!accessToken || user === "provider") {
            this.handleNavigation("Home");
        } else {
            if (user !== "consumer") {
                user = "admin_account";
                breadscrum = languageTranslationData[language].adminBreadscrumOne;
            }
        }
        const heading = languageTranslationData[language].CreateListings;
        breadscrum = Object.values(breadscrum);
        this.setState({
            currentLanguage: language,
            listingSummary: createListSummary(language),
            supportingDocument: supportingDoc(language),
            otherDocument: otherDoc(language),
            pageHeading: heading,
            breadscrumArray: breadscrum,
            userType: user
        });
        this.fetchProfile();
        // Customizable Area End
    }

    // Customizable Area Start

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement |HTMLTextAreaElement>) => {
        let { name, value } = event.target;
        if(name!=='listingTitle'){
            value = value.replace(configJSON.replaceAlphabetRegex, "");
        }
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>, ()=>this.handelErrorCheck());
    };

    handleSelectChange = (event: React.ChangeEvent<{ value:unknown}>, name: string) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>, ()=>this.handelErrorCheck());
        } 
    };

    handleAddPortfolio = (event: React.ChangeEvent<HTMLInputElement>, index: number | null, array:string) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        let {supportDocArray, otherDocArray } = this.state;
        let fileArray = array==="supportDocArray"? supportDocArray: otherDocArray;
        let newIndex = index;
        if(index === null) {
            for (let i = 0; i < fileArray.length; i++) {
                if (fileArray[i] === null) {
                  newIndex = i;
                  break;
                }
            }
        }
        if (file) {
            this.changeFile(newIndex, array, file)
        }
    };

    changeFile = (newIndex:number|null, array: string, file: File) => {
        this.setState(prevState => {
            const updatedArray = array==="supportDocArray"? [...prevState.supportDocArray]: [...prevState.otherDocArray];
            if (newIndex !== null && newIndex >= 0 && newIndex < updatedArray.length) {
                updatedArray[newIndex] = file;
            } else {
                updatedArray.push(file);
            }
            return { [array]: updatedArray, anchorEl : null } as unknown as Pick<S, keyof S>;
        }, ()=>this.handelErrorCheck());
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleMoreDocClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const anchor = this.state.anchorEl;
        if (anchor && anchor.id) {
            const lastChar = anchor.id.slice(-1);
            const indexNumber = Number(lastChar);
            const docType = anchor.id.substring(0, (anchor.id).length - 1)
            this.handleAddPortfolio(event, indexNumber, docType);
        }
    };

    handleFileRemove = () => {
        const anchor = this.state.anchorEl
        if (anchor && anchor.id) {
            const lastChar = anchor.id.slice(-1);
            const indexNumber = Number(lastChar);
            const docType = anchor.id.substring(0, (anchor.id).length - 1)
            this.handlePortfolioRemove(indexNumber, docType);    
        }
    };

    handlePortfolioRemove = (index:number, fileArray:string) => {
        const {supportDocArray, otherDocArray } = this.state;
        let array = fileArray==="supportDocArray"? supportDocArray: otherDocArray;
        const arrayName = fileArray==="supportDocArray"? "supportDocArray": "otherDocArray"
        if (index >= 0 && index < array.length) {
            if(array.length>2) {
                array.splice(index, 1);
            } else {
                if(index===0 && array[1]!==null){
                    let value = array[1];
                    array[0] = value;
                    array[1] = null;
                } else {
                    array[index] = null;
                }
            }
            this.setState({ 
                [arrayName]: array, 
                anchorEl: null 
            } as unknown as Pick<S, keyof S>,
            ()=>this.handelErrorCheck()
        );
        }
    };

    handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value, name } = event.target;
        value = value.replace(configJSON.replaceAlphabetRegex, "");
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        if (value !== "" && (parseInt(value, 10) < 0 || parseInt(value, 10) > 12)) {
          value = "";
        }
        this.setState({
          [name]: value
        }  as unknown as Pick<S, keyof S>,
        ()=>this.handelErrorCheck());
    };

    handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value, name } = event.target;
        let { lastCollMonth, lastCollYear, lastCredMonth, lastCredYear } = this.state;
        let numberOfDays = 31;
        let dateName = name.substring(0,8), dateMonth = lastCredMonth, dateYear = lastCredYear;
        value = value.replace(configJSON.replaceAlphabetRegex, "");
        if(dateName==="lastColl"){
            dateMonth = lastCollMonth;
            dateYear = lastCollYear
        }
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        if (dateMonth && dateYear) {
          numberOfDays = (new Date(Number(dateYear), Number(dateMonth), 0).getDate());
        } else if (dateMonth && !dateYear) {
          numberOfDays = (new Date(2001, Number(dateMonth), 0).getDate());
        }
        if (
          value !== "" &&
          (parseInt(value, 10) < 0 || parseInt(value, 10) > numberOfDays)
        ) {
          value = "";
        }
        this.setState({
          [name]: value
        } as unknown as Pick<S, keyof S>,
        ()=>this.handelErrorCheck());
    };
    
    handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let {value, name} = event.target
        let inputYear = value.trim();
        const currentYear = new Date().getFullYear();
        const isValidYear = parseInt(inputYear, 10) >= 1 && parseInt(inputYear, 10) <= currentYear;
        if (!isValidYear) {
          inputYear=""
        }
        this.setState({
          [name]: inputYear
        } as unknown as Pick<S, keyof S>, 
        ()=>this.handelErrorCheck());
    };
    
    handleBlur = (valueName: string, value: string) => {
        const date = valueName.substring(8);
        switch (date) {
          case "Month": {
            if (value.length === 1 || value === "00") {
              if (value === "0" || value === "00") {
                value = "";
              } else {
                value = `0${value}`;
              }
            }
            break;
          }
          case "Day": {
            this.handleDayBlur(valueName, value);
            break;
          }
          default: {
            if (Number(value) < 1900) {
                value="1900"
            }
            break;
          }
        }
        if(date!=="Day"){
            this.setState({
                [valueName]: value,
            } as unknown as Pick<S, keyof S>,
            ()=>this.handelErrorCheck());
        }
    };

    handleDayBlur = (valueName: string, value: string) => {
        if (value.length === 1 || value === "00") {
            if (value === "0" || value === "00") {
                value = "";
            } else {
                value = `0${value}`;
            }
        }
        this.setState({
            [valueName]: value,
        } as unknown as Pick<S, keyof S>,
        ()=>this.handelErrorCheck());
    };

    handlePreviewShow = () => {
        const { 
            portfolioSize,
            listingTitle,
            averageBalance,
            accountCount,
            totalNumber,
            totalAmount,
            totalJudgement,
            totalMonth,
            pastPay,
            monthAvg,
            lastCollDay,
            lastCollMonth,
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear,
            typeOfDebt,
            ageOfDebt,
            recoveryStatus,
            supportDocArray,
            otherDocArray,
            phoneNumber,
            name,
            email,
            country,
            state,
            city,
            zip,
            currentLanguage
        }= this.state;
        const heading = languageTranslationData[currentLanguage].PreviewListing;
        let breadscrum = languageTranslationData[currentLanguage].breadscrumTwo;
        if (this.state.userType === "admin_account") {
            breadscrum = languageTranslationData[currentLanguage].adminBreadscrumTwo;
        }  
        breadscrum = Object.values(breadscrum);
        this.setState({
            previewValues:{
                time: "54",
                portfolioSize,
                listingTitle,
                averageBalance,
                accountCount,
                totalNumber,
                totalAmount,
                totalJudgement,
                totalMonth,
                pastPay,
                monthAvg,
                lastCollDay,
                lastCollMonth,
                lastCollYear,
                lastCredDay,
                lastCredMonth,
                lastCredYear,
                typeOfDebt,
                ageOfDebt,
                recoveryStatus,
                supportDocArray,
                otherDocArray,
                phoneNumber,
                name,
                email,
                country,
                state,
                city,
                zip
            },
            showPreview:true,
            pageHeading: heading,
            breadscrumArray: breadscrum
        });
    };

    fetchProfile = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchProfileEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleApiSuccessResponse = (resp: UserData['attributes']) => {
        this.setState({
            name : `${resp.first_name} ${resp.last_name}`,
            email: `${resp.email}`,
            phoneNumber: `${resp.full_phone_number}`,
            country: `${resp.country}`,
            state: `${resp.state}`,
            city: `${resp.city}`,
            zip: `${resp.zipcode}`,
        });
    };

    createList = async () => {
        const {
            totalNumber, 
            totalAmount, 
            totalJudgement,
            lastCollDay,
            lastCollMonth,
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear,
            totalMonth,
            pastPay,
            monthAvg,
            supportDocArray,
            otherDocArray,
        } = this.state

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createListCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createListApiEndPoint
        );



        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        const formData = new FormData()
        formData.append("[data][total_number_of_debts]", totalNumber)
        formData.append("[data][total_amount_of_debts]", totalAmount)
        formData.append("[data][total_judgements]", totalJudgement)
        formData.append("[data][last_collectors_contact]", `${lastCollDay}${lastCollMonth}${lastCollYear}`)
        formData.append("[data][last_creditors_contact]", `${lastCredDay}${lastCredMonth}${lastCredYear}`)
        formData.append("[data][collections_used_in_past]", "12345")
        formData.append("[data][total_months_as_costomer]", totalMonth)
        formData.append("[data][pas_payment_made]", pastPay)
        formData.append("[data][month_average_paytime]", monthAvg)
        if (supportDocArray[0]) {
            formData.append("[data][supporting_documents]", supportDocArray[0])
        }
        if (otherDocArray[0]) {
            formData.append("[data][other_documents]", otherDocArray[0])
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSaveListing = () => {
        this.setState({
            checkSave: true
        });
        let noError = this.handelErrorCheck();
        if(noError){
            this.handlePreviewShow();
        }
    };

    handelErrorCheck = () => {
        let {supportDocArray, otherDocArray} = this.state;
        let basicInfoError = this.checkBasicInfoError();
        let detailInfoError = this.checkDetailInfoError();
        let debtCharaErr = this.checkDebtCharError();
        let supportDocErr = this.handleDocError(supportDocArray, "documentErr");
        let otherDocErr = this.handleDocError(otherDocArray, "otherDocError");
        let noError = [
            basicInfoError,
            detailInfoError, 
            debtCharaErr, 
            supportDocErr, 
            otherDocErr
        ].every(value=>!value);
        return noError
    };

    checkBasicInfoError = () => {
        const { portfolioSize, listingTitle, averageBalance, accountCount, currentLanguage} =this.state;
        let portfolioSizeErr = "", listingTitleErr = "", averageBalanceErr = "", accountCountErr = "";
        const errorLang = languageTranslationData[currentLanguage].basicInfofields
        if(!portfolioSize){
            portfolioSizeErr = errorLang.PortfolioSizeErr;
        }
        if(!listingTitle){
            listingTitleErr = errorLang.ListingTitleErr;
        }
        if(!averageBalance){
            averageBalanceErr = errorLang.AverageBalanceErr;
        }
        if(!accountCount){
            accountCountErr = errorLang.AccountCountErr;
        }
        let errorObject = {
            portfolioSizeErr, 
            listingTitleErr, 
            averageBalanceErr, 
            accountCountErr
        }
        this.setState({
            basicInfoErrors: errorObject
        });
        let checkErrors = Object.values(errorObject).every((value) =>
            value === undefined || value.length === 0
        );
        return !checkErrors;
    };

    checkDetailInfoError = () => {
        const {
            currentLanguage, 
            totalNumber, 
            totalAmount,
            totalJudgement,
            totalMonth,
            pastPay,
            monthAvg,
            lastCollDay,
            lastCollMonth,
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear
        } =this.state;
        let totalNumErr= "", totalAmtErr= "", totalJudgmentsErr= "", totalMonthErr= "", pastPayErr= "", monthAvgErr= "";
        let lastCollectErr= "", lastCredErr= ""
        const errorLang = languageTranslationData[currentLanguage].detailInfoFields
        if(!totalNumber){
            totalNumErr = errorLang.totalNumDebtErr;
        }
        if(!totalAmount){
            totalAmtErr = errorLang.totalAmoDebtErr;
        }
        if(!totalJudgement){
            totalJudgmentsErr = errorLang.totalJudgErr;
        }
        if(!totalMonth){
            totalMonthErr = errorLang.totalMonCustErr;
        }
        if(!pastPay){
            pastPayErr = errorLang.pastPayErr;
        }
        if(!monthAvg){
            monthAvgErr = errorLang.avgMonErr;
        }
        if(!lastCollDay|| !lastCollMonth|| !lastCollYear){
            lastCollectErr = errorLang.lastCollErr;
        }
        if(!lastCredDay || !lastCredMonth ||!lastCredYear){
            lastCredErr = errorLang.lastCredErr;
        }

        let errorObject = {
            totalNumErr,
            totalAmtErr,
            totalJudgmentsErr,
            totalMonthErr,
            pastPayErr, 
            monthAvgErr,
            lastCollectErr,
            lastCredErr
        }
        this.setState({ detailInfoErrors:errorObject});
        let checkErrors = Object.values(errorObject).every((value) =>
            value === undefined || value.length === 0
        );
        return !checkErrors;
    };

    checkDebtCharError = () => {
        const {
            currentLanguage, 
            typeOfDebt,
            ageOfDebt,
            recoveryStatus
        } =this.state;
        let typeOfDebtErr= "", ageOfDebtErr= "", recoveryStatusErr= ""
        const errorLang = languageTranslationData[currentLanguage].DebtCharacFields
        if(!typeOfDebt){
            typeOfDebtErr = errorLang.typeErr;
        }
        if(!ageOfDebt){
            ageOfDebtErr = errorLang.ageErr;
        }
        if(!recoveryStatus){
            recoveryStatusErr = errorLang.currentErr;
        }
        let errorObject = {
            typeOfDebtErr, 
            ageOfDebtErr, 
            recoveryStatusErr
        }
        this.setState({
            debtCharErr: errorObject
        });
        let checkErrors = Object.values(errorObject).every((value) =>
            value === undefined || value.length === 0
        );
        return !checkErrors;
    };

    handleDocError = (array: (File|null)[], errorFor: string) => {
        let {currentLanguage} = this.state;
        const errors = languageTranslationData[currentLanguage].documentErr;
        let isError = array.some(item=>item===null);
        let errorMessage = ""
        if(isError){
            errorMessage = errors
        }
        this.setState({[errorFor]: errorMessage} as unknown as Pick<S, keyof S>);
        return isError
    };

    handleNavigation(route:string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }


    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.handleLogout();
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrum(route)
            } else {
                this.handleNavigation(route);
            }
        }
    };

    handleLogout = async () => {
        await removeStorageData("profilePic");
        await removeStorageData("userType");
        await removeStorageData("accessToken");
        this.handleNavigation("Home");
    };

    handleBreadScrum = (value:string) => {
        if(value==="breadScrum0"){
            if (this.state.userType==="consumer") {
                this.handleNavigation("UserMyListing")
            } else {
                this.handleNavigation("dashboardListing")
            }
        }
        if(value==="breadScrum1"){
            const {currentLanguage, userType} = this.state
            const heading = languageTranslationData[currentLanguage].CreateListings;
            let breadscrum = languageTranslationData[currentLanguage].breadscrumOne;;
            if(userType === "admin_account") {
                breadscrum = languageTranslationData[currentLanguage].adminBreadscrumOne;
            }
            breadscrum = Object.values(breadscrum);
            this.setState({
                showPreview: false,
                pageHeading: heading,
                breadscrumArray: breadscrum,
                checkSave: false
            });  
        }
    };

    handleMenuItem = (menuItem:string) => {
        const {currentLanguage} = this.state;
        const languageData = languageTranslationData[currentLanguage];
        let array;
        switch(menuItem){
            case "pastPay":{
                array=[{value: "Yes", label: languageData.Yes}, {value: "No", label: languageData.No}]
                break;
            }
            case "monthAvg":{
                array=[
                    {value: "1", label: "1"}, 
                    {value: "2", label: "2"}, 
                    {value: "3", label: "3"},
                    {value: "4", label: "4"},
                    {value: "5", label: "5"}
                ]
                break;
            }
            case "typeOfDebt":{
                const value:string[] = Object.values(languageTranslationData['en'].DebtCharacFields.typeMenu)
                const label:string[] = Object.values(languageData.DebtCharacFields.typeMenu)
                array = label.map((item, index) => {return {value:`${value[index]}`, label:item}})
                break;
            }
            default:{
                const value:string[] = Object.values(languageTranslationData['en'].DebtCharacFields.currentMenu)
                const label:string[] = Object.values(languageData.DebtCharacFields.currentMenu)
                array = label.map((item, index) => {return {value:`${value[index]}`, label:item}})
                break;
            }
        }
        return array;
    };

    // Customizable Area End
}

const createListSummary = (language: string) => [
    {
        ...configJSON.commonFieldSchema,
        title: languageTranslationData[language].listingSummary.TotalNumber,
        placeholder: languageTranslationData[language].listingSummary.TotalNumber,
        id: "total_number_of_debts",
    },
    {
        ...configJSON.commonFieldSchema,
        title: languageTranslationData[language].listingSummary.TotalAmount,
        placeholder: languageTranslationData[language].listingSummary.EnterDebtsAmount,
        id: "total_amount_of_debts",
    },
    {
        title: languageTranslationData[language].listingSummary.TotalJudgements,
        placeholder: languageTranslationData[language].listingSummary.EnterAmount,
        id: "total_judgements",
        ...configJSON.commonFieldSchema
    },
    {
        title: languageTranslationData[language].listingSummary.LastCollectorContact,
        placeholder: languageTranslationData[language].listingSummary.LastCollectorContact,
        id: "last_collector_contact",
        ...configJSON.commonFieldSchema
    },
    {
        title: languageTranslationData[language].listingSummary.LastCreditorContact,
        placeholder: languageTranslationData[language].listingSummary.LastCreditorContact,
        id: "last_creditor_contact",
        ...configJSON.commonFieldSchema
    },
    {
        title: languageTranslationData[language].listingSummary.CollectionsUsedInPast,
        placeholder: languageTranslationData[language].listingSummary.CollectionsUsedInPast,
        id: "collections_used_in_past",
        ...configJSON.commonFieldSchema
    },
    {
        title: languageTranslationData[language].listingSummary.TotalMonthAsCustomer,
        placeholder: languageTranslationData[language].listingSummary.TotalMonthAsCustomer,
        id: "total_month_as_customer",
        ...configJSON.commonFieldSchema
    },
    {
        title: languageTranslationData[language].listingSummary.PastPaymentMade,
        placeholder: languageTranslationData[language].listingSummary.PastPaymentMade,
        id: "past_payment_made",
        ...configJSON.commonFieldSchemaSelect
    },
    {
        title: languageTranslationData[language].listingSummary.MonthAveragePayTime,
        placeholder: languageTranslationData[language].listingSummary.MonthAveragePayTime,
        id: "month_average_pay_time",
        ...configJSON.commonFieldSchema
    }
]

const supportingDoc = (_language: string) => [
    {
        "id": 'supporting_1',
        "file": null,
        "error": ""
    },
    {
        "id": 'supporting_2',
        "file": null,
        "error": ""
    }
];

const otherDoc = (_language: string) => [
    {
        "id": 'other_1',
        "file": null,
        "error": ""
    },
    {
        "id": 'other_2',
        "file": null,
        "error": ""
    },
    {
        "id": 'other_3',
        "file": null,
        "error": ""
    },
    {
        "id": 'other_4',
        "file": null,
        "error": ""
    }
];
