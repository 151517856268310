import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/MyOffers.json');
// Customizable Area End

// Customizable Area Start

interface UserOfferAttributes {
    id: number;
    offer_type: string;
    offer_amount: number;
    offer_pitch: string;
    status: string | null;
    provider_id: number;
    consumer_id: number;
    Company: string;
    Debtor: string;
}

interface UserOfferData {
    id: string;
    type: string;
    attributes: UserOfferAttributes;
}
const offerList = [
    ["#669059", "#669059", "Sunrise Credit Solutions", "2,500", "16 Dec 2022", "Pending"],
    ["#669023", "#663059", "Atlas Credit Services", "3,500", "21 Mar 2022", "Accepted"],
    ["#234143", "#1273411", "Prime Lending Agency", "6,333", "03 Dec 2014", "Rejected"],
    ["#669345", "#472833", "Blue Horizon Credit", "9,475", "22 Jan 2019", "Pending"],
    ["#669748", "#889059", "National Credit Services", "6,345", "22 Feb 2022", "Accepted"],
    ["#349059", "#439059", "Strategic Finance Partners", "2,463", "23 July 2022", "Rejected"]
];


const offerListing = {
    en:[
        ["669059", "669059", "Sunrise Credit Solutions", "2,500", "16 Dec 2022", "Pending", "Pending"],
        ["669023", "663059", "Atlas Credit Services", "3,500", "21 Mar 2022", "Accepted", "Accepted"],
        ["234143", "1273411", "Prime Lending Agency", "6,333", "03 Dec 2014", "Rejected", "Pending"],
        ["669345", "472833", "Blue Horizon Credit", "9,475", "22 Jan 2019", "Pending", "Rejected"],
        ["669748", "889059", "National Credit Services", "6,345", "22 Feb 2022", "Accepted", "Rejected"],
        ["349059", "439059", "Strategic Finance Partners", "2,463", "23 July 2022", "Rejected", "Accepted"]
    ],
    ar:[
        ["٦٦٩٠٥٩", "٦٦٩٠٥٩", "صن رايز لحلول الائتمان", "2,500", "٢٢ فبراير ٢٠٢٢", "معلقة", "معلقة"],
        ["٦٦٩٢٨٧", "٦٦٩٢٨٧", "أطلس لخدمات الائتمان", "3,500", "٢٢ فبراير ٢٠٢٢", "مقبولة", "مقبولة"],
        ["٦٦٩٠٥٩", "٦٦٩٠٥٩", "برايم وكالة الإقراض ", "6,333", "٢٢ فبراير ٢٠٢٢", "مرفوضة", "معلقة"],
        ["٦٦٩٢٨٧", "٦٦٩٢٨٧", "بلو هورايزون للائتمان", "9,475", "٢٢ فبراير ٢٠٢٢", "معلقة", "مرفوضة"],
        ["٦٦٩٠٥٩", "٦٦٩٠٥٩", "ناشونال لخدمات الائتمان", "6,345", "٢٢ فبراير ٢٠٢٢", "مقبولة", "مرفوضة"],
        ["٦٦٩٢٨٧", "٦٦٩٢٨٧", "ستراتيجك شركاء التمويل", "2,463", "٢٢ فبراير ٢٠٢٢", "مرفوضة", "مقبولة"]
    ]
};

const businessList = [
    ["#669059", "Sunrise Credit Solutions", "Goods", "2,500", "16 Dec 2022", "Pending"],
    ["#669023", "Atlas Credit Services", "Services", "3,500", "21 Mar 2022", "Accepted"],
    ["#234143", "Prime Lending Agency", "Services", "6,333", "03 Dec 2014", "Rejected"],
    ["#669345", "Blue Horizon Credit", "Assets", "9,475", "22 Jan 2019", "Pending"],
    ["#669748", "National Credit Services", "Services", "6,345", "22 Feb 2022", "Accepted"],
    ["#349059", "Strategic Finance Partners", "Assets", "5,655", "23 July 2022", "Rejected"]
];

const businewwDealList = [
    ["#669059", "1", "3,000,000", "16 Dec 2022", "Paid"],
    ["#669023", "2", "1,166,667", "21 Mar 2022", "Due"],
    ["#234143", "3", "1,166,667", "03 Dec 2014", "Due"],
    ["#669345", "4", "1,166,667", "22 Jan 2019", "Due"],
    ["#669748", "5", "1,166,667", "22 Feb 2022", "Due"],
    ["#349059", "6", "1,166,667", "23 July 2022", "Due"]
];

interface UserResponseData {
    activated: boolean;
    first_name: string  | null;
    last_name: string  | null;
    full_phone_number: string  | null;
    country_code: string | null;
    email: string  | null;
    phone_number: string | null;
    gender: string | null;
    type: string  | null;
    user_type: string  | null;
    user_name: string  | null;
    address: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zipcode: number | null;
    business_name: string | null;
    tax_number: string | null;
    created_at: string  | null;
    updated_at: string  | null;
    device_id: string | null;
    unique_auth_id: string  | null;
    kyc_name: string | null;
    kyc_number: string | null;
    kyc_verified: boolean | null;
    kyc_document:string | null,
    profile_image: string | null;
    images: {
        tax_registration: Document,
        license_certification:Document,
        business_registration:Document,
        other_documents:Document
    };
};

interface Document {
    file_name: string | null
    url: string | null;
    size: string | null;
};
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    myOffers: UserOfferData[];
    currentLanguage:'ar'|'en';
    breadscrumValues: string[];
    headingText: string;
    selectedOffer: string;
    isOfferAvailable: boolean;
    myOfferList: typeof offerList;
    offerListing: typeof offerListing;
    businessList: typeof businessList;
    businewwDealList: typeof businewwDealList;
    anchorEl: HTMLElement | null;
    currentPage: number;
    userName: string; 
    userEmail: string; 
    userCountry: string;
    userState: string; 
    userCity: string; 
    phone: string; 
    zipcode: string; 
    suppDoc: (File|null)[];
    otherDocs: (File|null)[];
    showDetail: boolean;
    isOfferAccept: boolean;
    showModal: boolean;
    status: string;
    showFilter: boolean;
    startDate: Date| null;
    endDate: string| null;
    userType: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AllListingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    offerListApiCallId: string = "";
    fetchProfileApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            myOffers: [],
            currentLanguage:'en',
            breadscrumValues:[],
            headingText: "",
            selectedOffer: "myOffer",
            isOfferAvailable: true,
            myOfferList: offerList,
            offerListing: offerListing,
            businessList: businessList,
            businewwDealList: businewwDealList,
            anchorEl: null,
            currentPage: 1,
            userName: "", 
            userEmail: "", 
            userCountry: "",
            userState: "", 
            userCity: "", 
            phone: "", 
            zipcode: "", 
            suppDoc: Array(2).fill(null),
            otherDocs: Array(2).fill(null),
            showDetail: false,
            isOfferAccept: false,
            showModal: false,
            showFilter: false,
            status: "",
            startDate: null,
            endDate: null,
            userType: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.offerListApiCallId) {
                    this.setState({ myOffers: responseJson.data })
                }
                if (apiRequestCallId === this.fetchProfileApiCallId) {
                    this.getProfileApiSuccess(responseJson.data.attributes);
                }
            } else {
                if (apiRequestCallId === this.fetchProfileApiCallId) {
                    this.getProfileApiFailure();
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getMyOfferListData();
        const dummyFile = new File([''], 'example.txt', { type: 'text/plain' });
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Offers;
        let getToken = await getStorageData("accessToken");
        let getUser = await getStorageData("userType");
        if ( !getToken ) {
            this.handleNavigatingRoute("Home");
        } else {
            if( !getUser ) {
                getUser = "admin_account"
            } else {
                getUser = "consumer"
            }
        }
        this.setState({ 
            currentLanguage: language,
            headingText: heading,
            suppDoc: [dummyFile, dummyFile],
            otherDocs: [dummyFile, dummyFile],
            userType: getUser
        });
        this.getUserProfile();
        // Customizable Area End
    }

    // Customizable Area Start

    getMyOfferListData = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.myOffersEndPoint
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getUserProfile = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchProfileEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getProfileApiSuccess = (resp: UserResponseData) => {
        this.setState({
            userName : `${resp.first_name} ${resp.last_name}`,
            userEmail: `${resp.email}`,
            phone: `${resp.full_phone_number}`,
            userCountry: `${resp.country}`,
            userState: `${resp.state}`,
            userCity: `${resp.city}`,
            zipcode: `${resp.zipcode}`
        });
    };

    getProfileApiFailure = () => {
        this.setState({
            userName : "James Carter",
            userEmail: "james.carter@example.com",
            phone: "33666903456",
            userCountry: "United State",
            userState: "Florida",
            userCity: "Miami",
            zipcode: "33101"
        });
    };

    handleNavigatingRoute = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawerNavigation = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle()
        } else {
            if(routing.includes("breadScrum")) {
                this.handlebreadScrumClick(routing);
            } else {
                this.handleNavigatingRoute(routing);
            }
        }
    };

    handlebreadScrumClick = (clickedOn: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        if(clickedOn==="breadScrum0"){
            this.setState({
                showDetail: false, 
                breadscrumValues:[],
                isOfferAccept: false,
                headingText: languageData.Offers
            });
        }
    };

    logoutHandle = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigatingRoute("Home");
    };

    handleOfferSelect = (select: string) => {
        this.setState({ selectedOffer: select, showFilter: false});
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorEl:event.currentTarget, showFilter: false});
    };

    handleMenuClose = () => {
        this.setState({anchorEl:null});
    };

    handleModalOpen = () => {
        this.setState({showModal: true});
    };

    handleModalClose = () => {
        this.setState({showModal: false});
    };

    handleStatusColorChange = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "Pending" || value==="Due") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "Rejected") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor
        };
    };

    handlePageChange = (page: number) => {
        this.setState({ currentPage: page });
    };

    handleOfferDetailOpen = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        let array: string[], heading;
        if( this.state.selectedOffer!=="businessDeals" || this.state.userType === "admin_account") {
            array=[languageData.MyOffers, languageData.OfferDetails];
            heading= languageData.OfferDetails;
        } else {
            array=[languageData.MyOffers, languageData.DealDetails];
            heading= languageData.DealDetails;
        }
        this.setState({
            showDetail: true,
            breadscrumValues: array,
            anchorEl: null,
            headingText: heading
        });
    };

    handleOfferAccept = () => {
        this.setState({
            isOfferAccept: true,
            showModal: false
        });
    };

    handleFilterOpen = () => {
        this.setState({showFilter: !this.state.showFilter});
    };

    handleCloseFilter = () => {
        this.setState({showFilter: false});
    };

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>);
    };

    handleSelect = (event: React.ChangeEvent<{ value:unknown}>) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                status: value
            });
        } 
    };

    resetDate = () => {
        this.setState({startDate: null, endDate: null});
    };

    resetSelect = () => {
        this.setState({status: ""});
    };

    resetAll = () => {
        this.setState({
            startDate: null, 
            endDate: null,
            status: ""
        });
    };

    toArabicNumeral = (num: string): string => {
        const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        return String(num).replace(/\d/g, (d) => arabicNumerals[parseInt(d)]);
    };

    // Customizable Area End
}
