import { getStorageData, setStorageData } from "framework/src/Utilities";
import Utills from './Utills.web';

export const getLanguageOrSetCurrentLanguage = async () => {
    const currentLanguage = await getStorageData('currentLanguage');
    if (!currentLanguage) {
        setStorageData('currentLanguage', 'en');
    }
    Utills(currentLanguage);
    return currentLanguage || 'en';
}