import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
const utills = require('../../utilities/src/utills');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  email: string;

  
  showPassword: boolean;
  showConfirmPassword: boolean;

  password: string;
  confirmPassword: string;

  psEr: string;
  confirmPasswordError: string;
  passwordField: boolean;
  confirmPasswordField: boolean;
  userType: string;
  currentLanguage:'en'|'ar'
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SuperAdminResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  superAdminResetApiCallId: any;
  passwordReg: RegExp;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
     
      email: "",
      passwordField: false,
      confirmPasswordField: false,
      showPassword: false,
      showConfirmPassword: false,

      password: "",
      confirmPassword: '',

      psEr: '',
      confirmPasswordError: '',
      userType: '',
      currentLanguage: 'en'
      // Customizable Area End
    };

    // Customizable Area Start
    this.passwordReg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.superAdminResetApiCallId) {

            if (responseJson.error) {
                this.setState({confirmPasswordError: ''})
            } else {
                this.handleRouting('SuperAdminLogin')
            }
          }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start 
  async componentDidMount() {
    super.componentDidMount();
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language });
  }

  getLocalData =  async (key: string) => {
    const data = getStorageData(key)
    return data
  }

  handlePassword = {
    onChangeText: (event: any) => {
      this.setState({ password: event.target.value });
      this.setState({psEr: ''})
    }
  }

  handleConfirmPassword = {
    onChangeText: (event: any) => {
      this.setState({ confirmPassword: event.target.value });
      this.setState({confirmPasswordError: ''})
    }
  }

  setPasswordField = () => {
    this.setState({ passwordField: !this.state.passwordField });
    this.setState((preState) => ({
      showPassword: !preState.showPassword
   }))
  };

  setConfirmPasswordField = () => {
    this.setState({ confirmPasswordField: !this.state.confirmPasswordField });
    this.setState((preState) => ({
      showConfirmPassword: !preState.showConfirmPassword
   }))
  };

  validatePassword(password: string) {
    return this.passwordReg.test(password);
  }

  validate() {
    let error = false;

    if (!this.state.password) {
      this.setState({psEr: configJSON.requiredField})
      error = true;

    }
    else if (!this.validatePassword(this.state.password)) {
      this.setState({psEr: configJSON.psEr})
      error = true;

    }
    
    if (!this.state.confirmPassword) {
      this.setState({confirmPasswordError: 'Confirm password is required'})
      error = true;

    }
    else if (!this.validatePassword(this.state.confirmPassword)) {
      this.setState({confirmPasswordError: 'Password must be capitalized or valid.'})      
      error = true;

    }

    if(this.state.password !== this.state.confirmPassword) {
      this.setState({confirmPasswordError: 'Passwords must match'}) 
      error = true;

    }
    return error
  }

  submitData = async () => {
    const email = await this.getLocalData('admin_email')

    const headers = {"Content-Type": "application/json"};
    const bodyData = {
        admin_user: {
            email: email,
            new_password: this.state.password,
            confirm_password: this.state.confirmPassword
        }
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.superAdminResetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.superAdminResetEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit () {
    if (!this.validate()) {
      this.submitData()
    }
  }

  adminButtonProps = {
    onPress: () => this.handleSubmit(),
  };

  handleRouting = (route: any) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  // Customizable Area End
}
