import { Box, Button, Container, FormControl, FormHelperText, Grid, OutlinedInput, Paper, Typography, styled } from "@material-ui/core";

const HeaderMainBox = styled(Grid)({
    background: "#0F172A",
    width: "100%",
    height: "auto",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: '90px 40px',
    boxSizing : "border-box",
    "@media (max-width: 960px)" : {
        padding : "40px 20px"
    },
    "@media (max-width: 600px)" : {
        padding : "40px 20px",
    },
})

const SecondHeaderMainBox = styled(Grid)({
    backgroundColor: "#F1F5F9",
    width: "100%",
    height: "auto",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: '90px 40px',
    boxSizing : "border-box",
    "@media (max-width: 960px)" : {
        padding : "40px 20px"
    },
    "@media (max-width: 600px)" : {
        padding : "40px 20px",
    },
})

const DebtBox = styled(Grid)({
    height: "auto",
    backgroundColor: "#F1F5F9",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    boxSizing : "border-box",
    padding: '0px 40px 40px 40px',
    "@media (max-width: 600px)" : {
        padding : "40px 20px",
    },
    "@media (max-width: 960px)" : {
        padding : "40px 20px"
    },
})

const HeaderMainText = styled(Typography)({
    fontSize: "48px",
    fontWeight: 600,
    color: "white",
    marginBottom: '15px',
    fontStyle: "normal",
    lineHeight: "64.205px",
    '@media(max-width:991px)':{
      fontSize: "34px",
      lineHeight:'48px',
    },
    '@media(max-width:576px)':{
      fontSize: "24px",
      lineHeight:'38px'
    }
})

const DescriptionText = styled(Typography)({
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#fff",
})

const ProcessCardTitle = styled(Typography)({
    fontWeight: 700,
    fontFamily: "inter",
    fontSize: "18px",
    lineHeight: '26px',
    color: "#fff",
})

const ProcessCardDescription = styled(Typography)({
    fontWeight: 400,
    fontFamily: "inter",
    lineHeight: "22px",
    marginTop: "10px",
    fontSize: "14px",
    color: "#fff"
})

const StyledImage = styled("img")({
    width: "100%",
    maxWidth: "488px"
})

const PortfolioBtn = styled(Button)({
    background: "#FFFFFF",
    padding: "10px 16px",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Inter",
    textTransform : "capitalize",
    color: "#0F172A",
    borderRadius: "8px",
    width : "fit-content",
    boxShadow: "0px 8px 32px 0px #0000000F",
    '&:hover': {
        background: "#FFFFFF",
    }
})

const MainPaper = styled(Paper)({
    display: "flex",
    flexDirection: "column",
    gap: "64px",
    backgroundColor: '#F1F5F9'
})

const HeaderDescriptionBox = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
})

const ImageGrid = styled(Grid)({
    width : "100%",
    textAlign : "center"
})

const HeaderMainTextBox = styled(Box)({
    width: '590px',
    '@media (max-width: 960px)': {
        width: '100%',
    }
})

const CreditorsLandingButtonWrapper = styled(Box)({
    width: '590px',
    '@media (max-width: 960px)': {
        width: '100%',
    }
})

const DescriptionTextBox = styled(Box)({
    marginTop: '15px',
    marginBottom: '30px',
    width: '590px',
    '@media (max-width: 960px)': {
        width: '100%',
    }
})

const DetailTextBox = styled(Box)({
    width: '590px',
    '@media (max-width: 960px)': {
        width: '100%',
    }
})

const DeptSaleDescriptionTextBox = styled(Box)({
    width: '590px',
    '@media (max-width: 960px)': {
        width: '100%',
        marginBottom : "15px",
    }
})

const FormControllerBox = styled(FormControl)({
    marginTop: "12px",
    width: '100%'
})

const InputHelper = styled(FormHelperText)({
    fontSize: "12px",
    color: "#334155",
    margin: "0px",
    fontWeight: 700,
    display: "flex"
})

const ReqireText = styled(Typography)({
    color: "red"
})

const InputTextFiled = styled(OutlinedInput)({
    width: "100%",
    borderRadius: "8px",
    fontFamily: "Inter",
    backgroundColor : "#FFF"
})

const StartedMainBox = styled(Box)({
    background: "#CBD5E1",
    padding: "64px 32px 44px 32px",
    borderRadius: "10px"
})

const ContainerBox = styled(Box)({
    display: 'flex',
    justifyContent: "space-between",
    gap: "20px",
    maxWidth:"1200px",
    alignItems : "center",
    width:"100%",
    '@media (max-width: 960px)': {
        flexDirection: "column",
        justifyContent: "center",
        alignItems : "center"
    }
})

const ContainerBox2 = styled(Box)({
    alignItems : "end",
    width:"100%",
    justifyContent: "space-between",
    maxWidth:"1200px",
    display: 'flex',
    gap: "20px",
    '@media (max-width: 960px)': {
        justifyContent: "center",
        alignItems : "center",
        flexDirection: "column",
    }
})

const ThirdSectionSubCreditorsWrapper = styled(Container)({
    padding:0
})

const StepsMainBox = styled(Box)({
    backgroundColor: "#F1F5F9",
    padding: "32px 24px 80px 24px",
    borderRadius: "20px",
    marginTop: "36px",
    display : "flex",
    flexDirection : "column",
    gap : "16px"
})

const ProcessCardBox = styled(Box)({
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    marginTop : "60px",
    gap: "16px",
    boxSizing : "border-box",
    '@media(max-width: 960px)':{
        flexWrap:'wrap'
    }
})

const ProcessCardInnerBox = styled(Box)({
    backgroundColor: "#1E293B",
    padding: "30px 16px",
    borderRadius: "20px",
    marginTop: "36px",
    width : "227px",
    boxSizing : "border-box",
    minHeight:'236px',
    '@media(max-width: 960px)':{
        margin:'0 !important'
    }
})

const SubmitButton = styled(Button)({
    background: "#1E3A8A",
    color: '#fff',
    borderRadius: "8px",
    padding: 12,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 700,
    "&:hover": {
        background: "#1E3A8A",
        color: '#fff',    
    }
})

export {
    HeaderMainText,
    HeaderMainBox,
    DescriptionText,
    ProcessCardTitle,
    ProcessCardDescription,
    PortfolioBtn,
    HeaderDescriptionBox,
    MainPaper,
    HeaderMainTextBox,
    StyledImage,
    DescriptionTextBox,
    FormControllerBox,
    InputHelper,
    ReqireText,
    InputTextFiled,
    StartedMainBox,
    ContainerBox,
    ContainerBox2,
    StepsMainBox,
    ProcessCardBox,
    ProcessCardInnerBox,
    SecondHeaderMainBox,
    ImageGrid,
    DeptSaleDescriptionTextBox,
    DetailTextBox,
    DebtBox,
    CreditorsLandingButtonWrapper,
    ThirdSectionSubCreditorsWrapper,
    SubmitButton
};