import React, { FunctionComponent, ChangeEvent, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Text from "../../../../packages/components/src/design-system/Text";
import { MultiValueLabel } from "react-select/src/components/MultiValue";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        display: "block",
        borderRadius: "8px",
        "& input": {
            border: "none",
            outline: "none",
            boxShadow: "none",
            paddingBottom: '16.5px',
        },
        '& fieldset': {
            top: '-5.5px !important',
        },
    },
    label: {
        marginBottom: "5px",
        fontSize: '14px',
        fontWeight: 700,
        color: "#334155"
    },
    mandatorySign: {
        color: "#DC2626",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
    },
    error: {
        border: "1px solid var(--Status-Red-400, #F87171)"
    }
}));

interface TextInputProps {
    id?: string;
    value?: string;
    onChange?: (value: any) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    variant?: "standard" | "filled" | "outlined";
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    manualWidth?: any;
    type?: string;
    mandatorySign?: string;
    error?: string;
    InputProps?: {
        className?: any;
    };
    color?: string;
    customStyle?: any;
    isArabic?: boolean;
    multiline?: boolean;
    minRows?: number;
}

const TextInput: FunctionComponent<TextInputProps> = (props) => {
    const {
        id,
        value,
        onChange,
        label,
        placeholder,
        disabled = false,
        variant,
        startAdornment,
        endAdornment,
        manualWidth,
        type,
        mandatorySign,
        error,
        color,
        customStyle,
        isArabic = false,
        multiline = false,
        minRows = 0
    } = props;

    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (onChange) {
            if (event.target.files && event.target.files[0]) {
                onChange(event.target.files[0])
            }
            else {
                onChange(newValue);
            }
        }
    };
    return (
        <div>
            {label && (
                <Typography variant="subtitle1" color="textPrimary" style={{ textAlign: isArabic ? "right" : "left", color: color }} className={classes.label}>
                    {label}
                    {mandatorySign && <span className={classes.mandatorySign}>*</span>}
                </Typography>
            )}
            <TextField
                id={id}
                disabled={disabled}
                style={manualWidth ? { width: manualWidth } : undefined}
                placeholder={placeholder}
                value={value}
                type={type}
                multiline={multiline}
                onChange={handleChange}
                fullWidth
                minRows={minRows}
                variant={variant || "outlined"}
                error={Boolean(error)}
                className={customStyle}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end" style={{ color: color }}>
                            {endAdornment}
                        </InputAdornment>
                    ),
                    classes: {
                        root: classes.root,
                        error: classes.error,
                    },
                    ...props.InputProps,
                }}
            />
            {
                error &&
                <Text variant="error" family="inter" weight="minBold" size="xs">{error}</Text>
            }
        </div>
    );
};

export default TextInput;
