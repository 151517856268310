import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { ChangeEvent } from "react";
const utills = require('../../utilities/src/utills');

const reqVeriData = [
    ["#669677", "Blue Horizon Investments", "APPROVED", "GHI128789", "09 Jul 2018"],
    ["#669459", "Apex Financial Solutions", "APPROVED", "ABC823456", "20 Jan 2018"],
    ["#869287", "Legacy Financial Group", "PENDING", "MNO458723", "17 Sep 2013"],
    ["#664979", "Zenith Credit Management", "REJECTED", "JKL787654", "29 Aug 2023"],
    ["#679287", "Crestwood Capital Partners", "PENDING", "XYZ709012", "12 May 2023"],
    ["#664559", "Silverline Ventures LLC", "APPROVED", "BEF456789", "24 Jun 2012"]    
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    open: boolean;
    search: string;
    invoiceSearch: string;
    data: typeof reqVeriData;
    invoiceData: {
        mainID: string;
        invoiceID: string;
        payment: string;
        dueDate: string;
        status: string;
    }[];
    currentLanguage: 'ar' | 'en';
    invoicePage: number;
    verificationPage: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            open: false,
            search: "",
            invoiceSearch:"",
            data:reqVeriData,
            invoiceData: configJSON.invoiceData,
            currentLanguage: 'en',
            invoicePage: 1,
            verificationPage: 1
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.handleDrawerClick = this.handleDrawerClick.bind(this);
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        let token = await getStorageData("accessToken");
        let userType = await getStorageData("userType");
        if (!token && userType !== "consumer") {
            this.handleNavigation("Home");
        }
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: selectedLanguage
        });
    };

    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: event.target.value });
    };

    handleInvoiceSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ invoiceSearch: event.target.value });
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "PENDING" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "REJECTED" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };

    handleInvoicePagination = (event: ChangeEvent<unknown>, page: number) => {
        this.setState({ invoicePage: page });
    };

    handleVerificationPagination = (evetn: ChangeEvent<unknown>, page: number) => {
        this.setState({ verificationPage: page });
    };

    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    };
    // Customizable Area End
}
