
import React from "react";
import {
  Box,
  // Customizable Area Start
  Grid,
  TextField,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { styled } from "@material-ui/core/styles";
import { CaptchaImg, ResetCaptchaImg, ServiceInternal } from "./assets";
import CustomImage from "../../../components/src/design-system/Image/Image";
import Text from "../../../components/src/design-system/Text";
import Button from "../../../components/src/design-system/Button/Button";

// Customizable Area End
import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";

export default class ServiceInternalNotLoggedIn extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
       <ServiceInternalContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={5} lg={5} className="serviceInternalImagegrid">
              <Box className="serviceInternalImageContainer">
                <CustomImage
                  src={ServiceInternal}
                  className="serviceInternalImage"
                  alt="serviceInternalImage"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box>
                <Box  mb="12px">
                 <Text size="2xxl" weight="lightBold" variant="primary" family="poppins" align="center">{configJSON.serviceInternalBtnLabel}</Text>
                 </Box>
                 <Box className="serviceInternalFormContainer">
                  <Box className="serviceInternalFormInnerContainer">  
                  <Box mb="32px">  
                  <Text size="xl" weight="lightBold" variant="white" family="poppins">{configJSON.serviceInternalFormHeading}</Text>
                 </Box>
                 <Box mb="5px">  
                 <Text size="xs" weight="extraBold" variant="white">{configJSON.serviceInternalFormLable1}</Text>
                 </Box>
                    <TextField
                      className="serviceInternalFormInput"
                      variant="outlined"
                    />
                    <Box  mb="5px" mt="24px">  
                    <Text size="xs" weight="extraBold" variant="white">{configJSON.serviceInternalFormLable2}</Text>
                    </Box>
                    <TextField
                      className="serviceInternalFormInput"
                      variant="outlined"
                    />
                    <Box className="serviceInternalCaptchaBox">
                      <Box className="serviceInternalCaptchImgContainer">
                      <CustomImage src={ResetCaptchaImg} className="serviceInternalResetImg" alt="serviceimg" />
                      <CustomImage src={CaptchaImg} alt="serviceimg" className="serviceInternalCaptchaNumber" />
                      </Box>
                      <input
                        className="serviceInternalCaptchaTextField"
                        placeholder="Enter Captcha"
                      />
                    </Box>
                    <Box mt="50px">
                      <Button
                        themes="dark"
                      >
                        {configJSON.serviceInternalBtnLabel}
                      </Button>
                    </Box>
                  </Box>
                </Box>  
                <div className="serviceInterFooter">
                  <Text size="mm" weight="bold" variant="primary" family="poppins">{configJSON.serviceInternalfooterText1}</Text><br />
                </div>
                <Text size="mm" variant="primary" weight="extraBold" family="poppins" align="center">{configJSON.serviceInternalfooterText2}</Text>        
              </Box>
            </Grid>
          </Grid>
        </ServiceInternalContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ServiceInternalContainer = styled("div")({
  padding: "100px",
  "@media(max-width: 959px)": {
    padding: "70px",
    "& .serviceInternalImagegrid":{
      height:"min-content"
    }
  },
  "@media(max-width: 768px)": {
    padding: "50px"
  },
  "@media(max-width: 576px)": {
    padding: "30px"
  },
  "& .serviceInternalFormContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    borderRadius: "20px",
    background: "#1E293B",
    margin: "auto",
    "@media(max-width: 1240px)": {
      width: "100%",
      margin: "0px"
    },
    "@media(max-width: 991px)": {
      width: "100%",
      margin: "0px"
    },
  },
  "& .serviceInternalFormInnerContainer": {
    width: "386px",
    margin: "50px 0px",
    "@media(max-width: 1240px)": {
      margin: "40px 0px"
    },
    "@media(max-width: 959px)": {
      width: "386px"
    },
    "@media(max-width: 576px)": {
      width: "90%",
      margin: "30px 0px"
    },
    "@media(max-width: 476px)": {
      margin: "2s0px 0px"
    },
  },  
  "& .serviceInternalImageContainer": {
    marginTop: "80px",
    height: "100%",
    "@media(max-width:959px) ": {
      marginTop: "0px",
      "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
        height:"min-content"
    },
    },
    "@media(max-width:899px) ": {
      marginTop: "0px",
      height: "min-content"
    },
  },
  "& .serviceInternalImage": {
    width: "100%",
    height: "75%",
    "@media(max-width:800px) ": {
      height: "75%"
    },
  },
  "& .serviceInternalFormInput": {
    backgroundColor: "#fff",
    borderRadius: "8px",
    width: "100%",
    margin: "auto"
  },
  "& .serviceInternalCaptchaBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    marginTop: "24px",
    "@media(max-width:476px) ": {
      display: "flex",
      flexDirection: "column"
    },
  },
  "& .serviceInternalCaptchImgContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media(max-width:476px) ": {
      width: "100%"
    },
    
  },
  "& .serviceInternalResetImg": {
    width: "45px",
    height: "45px"
  },
  "& .serviceInternalCaptchaNumber": {
    width: "auto",
    height: "40px"
 },
  "& .serviceInternalCaptchaTextField": {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: "#FFF ",
    width: "100%",
    height: "44px",
    border: "none",
    outline: "none"
  },
  "& .serviceInterFooter": {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "32px"
  },
});

// Customizable Area End
