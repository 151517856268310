// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert } from "react-native";
import DocumentPicker from 'react-native-document-picker';
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Notification.json');

export interface FolderFiles {
  url: string,
  blob_id: number,
  filename: string,
  folder_name?: string
}
interface FolderItems {
  folder_name: string,
  folder_medias: Array<FolderFiles>,
  gallery: { id: number }
}

interface FolderMedia {
  data: {
    id: number,
    attributes: FolderItems
  }
}

interface MenuModalData {
  blobId: number,
  fileAndFolderName: string
  renameModal?: string
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
}

interface SuccessResponse {
  message: string;
}
interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}
interface TokenAlert {
  token: string
}

type FileMenuType = MenuModalData | boolean | string;

// For Mobile

export interface Filefolder {
  blob_id: number;
  filename: string;
  url: string;
}

interface GetDocumentFolder {
  message: string;
  data: Array<Filefolder>;
}

interface Getmediafile {
  data: Dataobject;
}

export interface Dataobject {
  type: string;
  attributes: Attributeobjectformedia
}

export interface Attributeobjectformedia {
  folder_medias: Filefolder[];
  folder_name: string;
}

interface Errorobject {
  errors: string;
}


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loader: boolean;
  fileMenu: FileMenuType;
  renameModal: string;
  deleteModal: boolean;
  uploadedFiles: [];
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  folderMedia: FolderFiles[];
  folderId: number;
  galleryId: number;
  blobId: number;
  folderTitle: string;
  breadscrumValues: string[],
  userType: string;
  currentLanguage: 'ar' | 'en',
  headingText: string,


  // For Mobile

  modalDotsFolder: boolean;
  documentListFolder: Array<Filefolder>;
  ShowData: string;
  folderNameDoc: string;
  fileRenameTextDoc: string;
  folderRename: boolean;
  modalFileRename: boolean;
  cancelDoc: boolean;
  renameDoc: boolean;
  middleModal: boolean;
  modalDelete: boolean;
  fileDeleteTextDoc: string;
  deleteDoc: boolean;
  blobIdDoc: number;
  floderWord: string;
  isloadingDoc: boolean;
  documentIdDoc?: number;
  isResponseOne: object;
  yesNoModalDoc: boolean;
  yesNoModalFileDoc: boolean;
  responseFailureData: boolean;

}

interface SS {
  id: string;
}

export default class DocumentationFolderController extends BlockComponent<
  Props,
  S,
  SS
> {
  renameFileApiCallId: string = "";
  renameFolderApiCallId: string = "";
  folderMediaApiCallId: string = "";
  deleteFileApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesApiCallId: string = "";

  // For Mobile

  getAddFiletoFolderApiCell: string = "";
  getFolderMedia: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      loader: false,
      fileMenu: false,
      deleteModal: false,
      renameModal: "",
      folderMenuOptions: false,
      uploadedFiles: [],
      renameValue: "",
      menuOption: "",
      folderMedia: [],
      folderId: 0,
      galleryId: 0,
      blobId: 0,
      folderTitle: "",
      breadscrumValues: [],
      userType: "",
      headingText: "",
      currentLanguage: 'en',

      // For Mobile

      modalDotsFolder: false,
      documentListFolder: [],
      ShowData: '',
      folderNameDoc: "",
      fileRenameTextDoc: '',
      folderRename: false,
      modalFileRename: false,
      modalDelete: false,
      cancelDoc: false,
      renameDoc: false,
      middleModal: false,
      fileDeleteTextDoc: '',
      deleteDoc: false,
      blobIdDoc: 0,
      floderWord: "",
      isloadingDoc: false,
      documentIdDoc: 0,
      isResponseOne: {},
      yesNoModalDoc: false,
      yesNoModalFileDoc: false,
      responseFailureData: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      // For Mobile

      if (responseJson.status === 500) {
        this.showAlert("Error", "Internal Server Error");
        return;
      }

      if (responseJson && !responseJson.errors) {
        this.getAllSuccessFunction(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.getAllErrorFunction(apiRequestCallId, responseJson);
      }

      // For Web

      if (responseJson && !responseJson.errors) {
        this.successCallBack(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.failureCallBack(apiRequestCallId, responseJson);
      }
    }
  }

  // web events
  async componentDidMount(): Promise<void> {
    //  For Mobile
    this.setState({
      documentIdDoc: this.props.navigation.state?.params?.documentId, ShowData: this.props.navigation.state?.params?.item?.id,
    }
      , async () => {
        await this.getFolderMediaList()
      }
    )
    //  For Web
    const folderId = parseInt(this.props.navigation.getParam("folderId"))
    const galleryId = parseInt(this.props.navigation.getParam("galleryId"))
    this.getFolderData(folderId, galleryId);

    const language = await utills.getLanguageOrSetCurrentLanguage();
    const heading = languageTranslationData[language].Signup;
    this.setState({
      currentLanguage: language,
      headingText: heading,
    });
  }

  handleMenuModal = (value: MenuModalData | boolean) => { this.setState({ fileMenu: value }) }

  handleFolderOptionsModal = (value: boolean) => { this.setState({ folderMenuOptions: value }) }

  handleRenameModal = (value: string, element: FileMenuType) => {
    if (typeof element === "string") {
      this.setState({
        renameModal: value,
        folderMenuOptions: false,
        renameValue: element,
      })
    }
    else if (typeof element !== "boolean") {
      this.setState({
        renameModal: value,
        folderMenuOptions: false,
        renameValue: element.fileAndFolderName
      })
    }
    else {
      this.setState({
        renameModal: value,
        folderMenuOptions: false,
      })
    }
  }

  handleDeleteModal = (value: boolean) => { this.setState({ deleteModal: value, folderMenuOptions: false }) }

  handleRename = (value: React.ChangeEvent<HTMLInputElement>) => { this.setState({ renameValue: value.target?.value }) }

  apiCall = async (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getFolderData = async (folderId: number, gallery_id: number) => {
    this.setState({ loader: true })
    this.folderMediaApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentation/folder_documents/${folderId}?data[attributes][gallery_id]=${gallery_id}`,
    })
  }

  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = Array.from(event.target.files!)
    let formData = new FormData();
    for (const file of files) {
      formData.append("data[attributes][gallery_id][]", `${this.state.galleryId}`)
      formData.append("data[attributes][folder_medias][]", file)
    }
    this.setState({ loader: true })
    this.uploadFilesApiCallId = await this.apiCall({
      method: 'PATCH',
      endPoint: `bx_block_documentation/folder_documents/${this.state.folderId}`,
      body: formData,
      type: "formData"
    })
  }

  renameFile = async (value: FileMenuType) => {

    if (this.state.renameModal === "file" && typeof value !== "string" && typeof value !== "boolean") {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: this.state.galleryId,
            blob_id: value.blobId,
            filename: this.state.renameValue
          }
        }
      }
      this.setState({ loader: true })
      this.renameFileApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'PATCH',
        endPoint: `/bx_block_documentation/folder_documents/${this.state.folderId}/update_file_name`,
        body: bodyData,
      })
    } else {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: this.state.galleryId,
            folder_name: this.state.renameValue
          }
        }
      }
      this.renameFolderApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'PATCH',
        endPoint: `bx_block_documentation/folder_documents/${this.state.folderId}/update_folder_name`,
        body: bodyData,
      })
    }
    this.setState({
      renameModal: "",
      fileMenu: false,
      folderMenuOptions: false
    })
  }

  deleteFiles = async (value: FileMenuType) => {
    this.setState({ loader: true, folderMenuOptions: false })
    if (value && typeof value !== "string" && typeof value !== "boolean") {
      let bodyData = {
        data: {
          attributes: {
            blob_id: value.blobId,
            gallery_id: this.state.galleryId,
          }
        }
      }
      this.deleteFileApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'DELETE',
        endPoint: `bx_block_documentation/folder_documents/${this.state.folderId}/destroy_media_file`,
        body: bodyData,
      })
    }
    else {
      let bodyData = {
        data: {
          attributes: {
            gallery_id: this.state.galleryId,
          }
        }
      }
      this.deleteFolderApiCallId = await this.apiCall({
        contentType: "application/json",
        method: 'DELETE',
        endPoint: `bx_block_documentation/folder_documents/${this.state.folderId}`,
        body: bodyData,
      })
    }
    this.setState({ deleteModal: false, fileMenu: false });
  }

  successCallBack = (apiRequestCallId: string, responseJson: string & FolderMedia & SuccessResponse) => {
    if (apiRequestCallId === this.folderMediaApiCallId) {
      this.getFolderMediaSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesApiCallId) {
      this.uploadFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderSuccesCallback(responseJson);
    }
  }
  failureCallBack = (apiRequestCallId: string, responseJson: string & ErrorResponse & TokenExpire) => {
    if (apiRequestCallId === this.folderMediaApiCallId) {
      this.getFolderMediaFailureCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesApiCallId) {
      this.uploadFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderFailureCallback(responseJson);
    }
  }

  getFolderMediaSuccesCallback = (response: FolderMedia) => {
    this.setState({ loader: false })
    this.setState({
      folderMedia: response.data.attributes.folder_medias,
      folderTitle: response.data.attributes.folder_name,
      galleryId: response.data.attributes.gallery.id,
      folderId: response.data.id,
    })
  }

  getFolderMediaFailureCallback = (response: TokenExpire) => {
    this.setState({ loader: false })
    if (!!this.state.folderId && !!this.state.galleryId) {
      this.getFolderData(this.state.folderId, this.state.galleryId);
    }
  }

  uploadFilesSuccesCallback = (response: string) => {
    this.setState({ loader: false, folderMenuOptions: false })
    this.showAlert('Alert', 'Files uploaded successfully')
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  uploadFilesFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false, folderMenuOptions: false })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFileSuccesCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false, renameModal: "" })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFolderSuccesCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  renameFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false, renameModal: "" })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  deleteFilesSuccesCallback = (response: string) => {
    this.setState({ loader: false })
    this.showAlert('Alert', 'File deleted successfully')
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  deleteFilesFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  deleteFolderSuccesCallback = (response: string) => {
    this.setState({ loader: false })
    this.props.navigation.goBack();
  }

  deleteFolderFailureCallback = (response: string) => {
    this.setState({ loader: false })
    this.getFolderData(this.state.folderId, this.state.galleryId);
  }

  // For Mobile

  getAllSuccessFunction = (apiRequestCallId: string, responseJson: GetDocumentFolder & Getmediafile) => {
    if (apiRequestCallId === this.getAddFiletoFolderApiCell) {
      this.getAddFiletoFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getFolderMedia) {
      this.FolderMediaSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
  }

  getAllErrorFunction = (apiRequestCallId: string, responseJson: Errorobject & string) => {
    if (apiRequestCallId === this.getAddFiletoFolderApiCell) {
      this.getAddFiletoFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getFolderMedia) {
      this.FolderMediaFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBackID(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
  }

  getAddFiletoFolderSuccesCallBack = async (responseJson: GetDocumentFolder) => {
    this.setState({ isloadingDoc: false, modalDotsFolder: false })
    this.getFolderMediaList()
  };

  getAddFiletoFolderFailureCallBack = async (responseJson: Errorobject) => {
    this.setState({ isloadingDoc: false, modalDotsFolder: false })
    if (responseJson?.errors) {
      Alert.alert('Alert', responseJson.errors)
    }
  };

  FolderMediaSuccesCallBack = async (responseJson: Getmediafile) => {
    if (responseJson?.data) {
      this.setState({ documentListFolder: responseJson?.data?.attributes?.folder_medias, floderWord: responseJson?.data?.attributes?.folder_name, folderNameDoc: responseJson?.data?.attributes?.folder_name })
    }
  };

  FolderMediaFailureCallBack = async (responseJson: string) => {
    Alert.alert("@@@ ==== createDocumentFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  deleteFolderSuccesCallBackID = async (responseJson: GetDocumentFolder) => {
    this.setState({ isloadingDoc: false })
    Alert.alert(responseJson.message)
    await this.props.navigation.goBack()
  };

  deleteFolderFailureCallBackID = async (responseJson: string) => {
    Alert.alert("@@@ ==== deleteFolderFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  renameFolderSuccesCallBackID = async (responseJson: GetDocumentFolder) => {
    this.setState({ folderRename: false, folderNameDoc: '', blobIdDoc: 0, })
    this.showAlert('Alert', responseJson.message)
    this.getFolderMediaList()
  };

  renameFolderFailureCallBackID = async (responseJson: string) => {
    Alert.alert("@@@ ==== deleteFolderFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  deleteFileSuccesCallBack = async (responseJson: GetDocumentFolder) => {
    this.setState({ modalDelete: false, blobIdDoc: 0, fileDeleteTextDoc: '', isloadingDoc: false, yesNoModalFileDoc: false })
    this.showAlert('Alert', responseJson.message)
    this.getFolderMediaList()
  };

  deleteFileFailureCallBack = async (responseJson: string) => {
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
    this.setState({ isloadingDoc: false })
  };

  renameNewFileSuccesCallBack = async (responseJson: GetDocumentFolder) => {
    this.setState({ modalFileRename: false, blobIdDoc: 0, fileRenameTextDoc: '' })
    this.showAlert('Alert', responseJson.message)
    this.getFolderMediaList()
  };

  renameNewFileFailureCallBack = async (responseJson: Errorobject) => {
    this.setState({ modalFileRename: false, })
    if (responseJson.errors) {
      Alert.alert('Alert', responseJson.errors)
    }
  };

  AddFiletoFolderApiCell = async (isResponseUri: string, namefile: string) => {
    this.setState({ isloadingDoc: true })
    let formData = new FormData();
    formData.append("data[attributes][folder_medias][]",
      JSON.parse(JSON.stringify({
        uri: isResponseUri,
        type: "sample/pdf",
        name: namefile
      }))
    )
    formData.append("data[attributes][gallery_id][]", `${this.state.documentIdDoc}`)

    this.getAddFiletoFolderApiCell = await this.apiCall({
      contentType: "multipart/form-data",
      method: 'PATCH',
      endPoint: `bx_block_documentation/folder_documents/${this.state.ShowData}`,
      body: formData,
      type: 'formData'
    });
  }

  getFolderMediaList = async () => {
    this.getFolderMedia = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentation/folder_documents/${this.state.ShowData}?data[attributes][gallery_id]=${this.state.documentIdDoc}`,
      type: "",
      body: '',
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloadingDoc: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentIdDoc,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `bx_block_documentation/folder_documents/${this.state.ShowData}`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    this.setState({ folderRename: false })
    if (this.state.folderNameDoc.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentIdDoc,
          folder_name: this.state.folderNameDoc
        }
      }
    }

    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint: `bx_block_documentation/folder_documents/${this.state.ShowData}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFileApiCell = async () => {
    this.setState({ isloadingDoc: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdDoc,
          gallery_id: this.state.documentIdDoc
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `bx_block_documentation/folder_documents/${this.state.ShowData}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    if (this.state.fileRenameTextDoc.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentIdDoc,
          blob_id: this.state.blobIdDoc,
          filename: this.state.fileRenameTextDoc,
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint: `/bx_block_documentation/folder_documents/${this.state.ShowData}/update_file_name`,
      body: apidata,
      type: ""
    });
  }

  deleteFolderModal = () => { this.setState({ modalDotsFolder: false, yesNoModalDoc: true }) }

  yesNoModalFalseDoc = () => this.setState({ yesNoModalDoc: false, yesNoModalFileDoc: false })

  responseDeleteYesNoFile = () => this.DeleteFileApiCell()

  goBackCell = () => this.props.navigation.goBack()

  documentFolderClose = () => this.setState({ modalDotsFolder: true }, () => this.setState({ cancelDoc: false, renameDoc: false }))

  renameFolderModal = () => this.setState({ modalDotsFolder: false, folderRename: true })

  middleModalTrue = (Itemid: number, fileName: string) => this.setState({ blobIdDoc: Itemid, fileDeleteTextDoc: fileName, fileRenameTextDoc: fileName }, () => { this.setState({ middleModal: true, cancelDoc: false, renameDoc: false, deleteDoc: false }) })

  documentFolderModalFalse = () => this.setState({ modalDotsFolder: false })

  changeTextFileModal = (text: string) => { this.setState({ fileRenameTextDoc: text }) }

  changeDeleteFileModalDoc = (text: string) => { this.setState({ fileDeleteTextDoc: text }) }

  changeTextFolderModalRename = (text: string) => { this.setState({ folderNameDoc: text }) }

  cancelForAll = () => this.setState({ folderRename: false, modalFileRename: false, cancelDoc: true, renameDoc: false, modalDelete: false })

  fileRenameState = () => { this.setState({ cancelDoc: false, renameDoc: true, modalFileRename: false }, () => this.RenameFileApiCell()) }

  deleteTouchableState = () => { this.setState({ cancelDoc: false, deleteDoc: true, modalDelete: false, yesNoModalFileDoc: true }) }

  folderRenameTouch = () => {
    this.setState({ cancelDoc: false, renameDoc: true, folderRename: false }, async () => {
      if (!this.state.folderRename) {
        await this.RenameFolderApiCell()
      }
    })
  }

  middleModalFalse = () => this.setState({ middleModal: false })

  renameFileinFolder = () => this.setState({ modalFileRename: true, middleModal: false })

  deleteFileinFolder = () => this.setState({ modalDelete: true, middleModal: false })

  uplaodDocumentFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
      this.setState({ isResponseOne: isResponse }, () => {
        if (this.state.isResponseOne) {
          this.AddFiletoFolderApiCell(isResponse[0].uri, isResponse[0].name)
        }
      })
    }
    catch (error) {
    }
  };
  drawerNavigation = (routing: string) => {
    if (routing === "logOut") {
      this.logoutButton()
    } else {
      this.deawerNavigatingRouteFile(routing);
    }
  };
  logoutButton = async () => {
    this.deawerNavigatingRouteFile("Home");
  };

  deawerNavigatingRouteFile = (selectedRoute: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  };
}

// Customizable Area End
