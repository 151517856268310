import React from 'react';

const Utills = (currentLanguage: any) => {
    const element = document.getElementById('root');
    if (currentLanguage === 'en' && element) {
        element.dir = 'ltr';
    } else if(currentLanguage === 'ar' && element) {
        element.dir = 'rtl';
    }

    return null;
}
export default Utills;
