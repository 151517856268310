import React from "react";
// Customizable Area Start
import NotificationWebController, {Props} from "./NotificationController.web";
import {withStyles, StyleRules, styled} from "@material-ui/core/styles";
import { Avatar, Box, Divider, FormControlLabel, Grid, IconButton, Menu, MenuItem, Switch, SwitchProps, Tab, Tabs } from "@material-ui/core";
import { MarkReadIcon, notificationAvatar } from "./assets";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import Text from "../../../components/src/design-system/Text";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import ReplyIcon from '@material-ui/icons/Reply';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const languageTranslationData = require('../../languagesupport/src/LanguageData/Notification.json')

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    tabLabel : {
        fontSize : "16px",
        fontWeight : 700,
        fontFamily : "inter",
        textTransform : "none"
    },
    alertContainer : {
        display : "flex",
        flexDirection : "column",
        gap : "32px",
        margin : "38px 12px",
    },
    alert : {
        display : "flex",
        flexDirection: "column",
        gap : "16px",
        width: '78%'
    },
    alertLabel : {
        flex : 1,
        maxWidth : "253px",
        fontSize : "16px",
        fontWeight : 400,
        color : "#1F2C37",
        fontFamily : "inter"
    },
    notificationItems: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        padding: "16px 0px"
    },
    descriptionText : {
        fontSize : "14px",
        fontWeight: 400,
        color : "#0F172A",
        fontFamily : "inter",
        lineHeight : "22px",
        marginBottom : "16px",
        margin : "0px"
    },
    buttonContainer : {
        display : "flex",
        alignItems : "center",
        gap : "10px",
        flexWrap : "wrap"
    }
    // Customizable Area End
}

// Customizable Area Start
const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#407BFF',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorInherit' : {
        color : "#94A3B8",
        opacity : 1
    },
    '& .MuiTab-textColorInherit.Mui-selected' : {
        color : "#1D4ED8",
        backgroundColor: "#EFF6FF",
        borderRadius: "100px",
        border: "1px solid #BFDBFE",
        opacity : 1
    },
    '& .MuiTabs-indicator' : {
        display : "none"
    }
});
// Customizable Area End

export class NotificationWeb extends NotificationWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { notifications, anchorEl } = this.state;
      
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <UserProfileDrawer
                data-test-id="sideDrawerTestID"
                handleClick={this.handleDrawer}
                pageName={["consumer", "provider", "debt_owner", "debt_buyer"].includes(this.state.userType) ? "UserNotification" : "dashboardNotification"}
                breadScrumArray={this.state.breadscrumarray}
                userType = {["consumer", "provider", "debt_owner", "debt_buyer"].includes(this.state.userType) ? "consumer" : "admin_account"}
            >
                <Box style={{ marginBottom: "16px" }}>
                    <Text size="1xl" weight="extraBold" variant="lightBlue">
                        {languageData.Heading}
                    </Text>
                </Box>
                <Box style={{ borderRadius: '18px', backgroundColor: 'white', padding: '20px', margin : "0px", minHeight:"540px"}}>
                    <CustomTabs value={this.state.notificationTab} onChange={this.handleTabChange} id="tabs" aria-label="notification tabs">
                        <Tab className={classes.tabLabel} label={languageData.Notifications} id="notification" />
                        <Tab className={classes.tabLabel} label={languageData.NotificationSettings} id="settings" />
                    </CustomTabs>
                    { this.state.notificationTab === 0 &&
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" gridGap="2px" alignItems="center" justifyContent="end" mb={1}>
                                <CheckIcon color="primary" />
                                <Text weight="extraBold" size="xx" variant="primaryBlue">{languageData.MarkAllAsRead}</Text>
                            </Box>
                            <Grid item xs={12} sm={12}>
                                <Divider style={{ color: '#E2E8F0'}} />
                            </Grid>
                            {notifications.map((notification: {title: string, description: string, image: string, time: string}, notIdx: number) =>
                                <Grid
                                    key={notIdx}
                                    container
                                >
                                    <Grid className={classes.notificationItems} item xs={12} sm={12}>
                                        <Grid item xs={12} sm={1}>
                                            <Avatar src={notificationAvatar} style={{ width: '56px', height: '56px', boxSizing: "border-box"}} />
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                            <Box display="flex" alignItems="center">
                                                <Box>
                                                    <Text variant={notIdx === 0 ? "primaryBlue" : "lightBlue"} size="xx" lineHeight="20px" weight="extraBold">{notification.title}</Text>
                                                    <Text variant="lightBlue" size="xx" lineHeight="20px" weight="minBold">{notification.description}</Text>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                <IconButton
                                                    data-testid="more-icon"
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={this.handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={this.handleClose}
                                                    PaperProps={{
                                                    style: {
                                                        border: "1px solid #E2E8F0",
                                                        borderRadius: 8
                                                    },
                                                    }}
                                                >
                                                    {[{label: languageData.Reply, icon: ReplyIcon}, 
                                                    {label: languageData.MarkAsRead, icon: MarkReadIcon}, 
                                                    {label: languageData.Delete, icon: DeleteOutlineIcon}].map((option: {label: string, icon: any}, optIdx) => (
                                                    <MenuItem data-testid={option.label} key={option.label} selected={option.label === 'Reply'} onClick={this.handleClose}>
                                                        {optIdx === 1 ? <img src={option.icon} style={{ marginRight: 10}} /> : 
                                                        <option.icon style={{ marginRight: 10, width: 18}} htmlColor={optIdx === 2 ? "#DC2626" : "#0F172A"} />}
                                                        <Text size="xx" variant={optIdx === 2 ? "error"  :"lightBlue"} weight="minBold">{option.label}</Text>
                                                    </MenuItem>
                                                    ))}
                                                </Menu>
                                                <Text variant="naturalCoolGrey" size="xx" weight="minBold" >{notification.time}</Text>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>)}
                        </Box>
                    }
                    { this.state.notificationTab === 1 &&
                        <div
                            className={classes.alertContainer}
                        >
                            {
                                this.state.settings.map(setting => {
                                    return (
                                        <Box key={setting.heading} className={classes.alert}>
                                            <Box mb={2}>
                                                <Text family="inter" size="mm" variant="lightBlue" weight="extraBold">{setting.heading}</Text>
                                            </Box>
                                            {setting.options.map((option) => 
                                                <Box pb={2} justifyContent="space-between" borderBottom="1px solid #E2E8F0" display="flex">
                                                    <Box>
                                                        <Text family="inter" size="xx" variant="lightBlue" weight="bold">{option.label}</Text>
                                                    </Box>
                                                    <FormControlLabel
                                                        id="toggle-options"
                                                        control={<IOSSwitch id={option.id} onClick={this.handleAlertChange} checked={option.active} />}
                                                        label=""
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                })
                            }
                        </div>
                    }
                </Box>
            </UserProfileDrawer>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(NotificationWeb);
