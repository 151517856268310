import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

interface ParagraphProps {
  fontSize?: string;
  fontFamily?: string;
  color?: string;
  fontWeight?: string | number;
  width?: string | number;
  lineHeight?: string | number;
  margin?: string | number;
  mb?: string | number;
  padding?: string | number;
  align?: TextAlign;
  bgColor?: string;
  children: React.ReactNode;
  borderRadius?: string | number;
}

type TextAlign = 'left' | 'center' | 'right' | 'justify' | 'unset' | 'end';

class Paragraph extends Component<ParagraphProps> {
  render() {
    const {
      fontSize,
      fontFamily,
      color,
      fontWeight,
      width,
      lineHeight,
      margin,
      mb,
      padding,
      children,
      align,
      bgColor,
      borderRadius,
    } = this.props;

    const paragraphStyle = {
      fontSize: fontSize || 'inherit',
      fontFamily: fontFamily || 'inherit',
      color: color || 'inherit',
      fontWeight: fontWeight || 'normal',
      width: width || 'auto',
      lineHeight: lineHeight || 'normal',
      margin: margin || 0,
      marginBottom: mb || 0,
      padding: padding || 0,
      textAlign: align || 'unset',
      backgroundColor: bgColor || '',
      borderRadius: borderRadius || '',
    };

    return <Typography style={paragraphStyle}>{children}</Typography>;
  }
}

export default Paragraph;
