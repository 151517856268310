import React from "react";
// Customizable Area Start
import MyOffersController, {
    Props, configJSON
} from "./MyOffersController.web";

import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import { 
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Menu,
    Avatar,
    Divider,
    Grid,
    Modal,
    FormControl,
    Select,
    InputLabel,
    MenuItem
} from "@material-ui/core";
import { blankListingImage, visaLogo, masterCardLogo } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from "@material-ui/icons/Search";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import NotesIcon from "@material-ui/icons/Notes";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Pagination from "@material-ui/lab/Pagination";
const languageTranslationData = require('../../languagesupport/src/LanguageData/MyOffers.json');
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import PreviewListing from "../../../components/src/design-system/PreviewListing/PreviewListing.web";
import TablePagination from "../../../components/src/design-system/Pagination.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    publishButton: {
        borderRadius: "25px",
        border: "1px solid #BFDBFE",
        backgroundColor: "#EFF6FF",
        padding: "8px 16px",
        cursor: "pointer",
        color: "#1D4ED8",
        fontSize: "16px",
        fontWeight: 700
    },
    draftButton: {
        padding: "8px",
        cursor: "pointer",
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: 600
    }
    // Customizable Area End
}

// Customizable Area Start
const offerDetailPreview = {
    time: "54",
    portfolioSize: "2,500",
    listingTitle: "Medical Debt Package",
    averageBalance: "500",
    accountCount: "1",
    totalNumber: "1",
    totalAmount: "2,500",
    totalJudgement: "0",
    totalMonth: "3",
    pastPay: "Yes",
    monthAvg: "1",
    lastCollDay: "01",
    lastCollMonth: "02",
    lastCollYear: "2024",
    lastCredDay: "01",
    lastCredMonth: "02",
    lastCredYear: "2024",
    typeOfDebt:"Credit Card",
    ageOfDebt:"1",
    recoveryStatus:"ACTIVE COLLECTION"
};

const debtBuyerInfo = {
    image: "",
    name: "John John",
    email: "john.doe@example.com",
    phone: "5678901234",
    country: "United State",
    state: "Florida",
    city: "Miami",
    zipcode: "33101"
};
// Customizable Area End

export class MyOffers extends MyOffersController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderEmptyScreen = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <Box style={webStyle.emptyWrapper}>
                <Box style={webStyle.emptyBodyWrapper}>
                    <img src={blankListingImage} alt="blank_listing_image" width="160px"/>
                    <Box>
                        <Typography style={webStyle.emptyParagraph}>
                            {languageData.ParaFir}
                        </Typography>
                        <Typography style={webStyle.emptyParagraph}>
                            {languageData.ParaSec}
                        </Typography>
                    </Box>
                    <OkButton
                        style={{width:"183px", height: "44px", fontSize: "16px"}}
                        onClick={()=>this.handleNavigatingRoute("Service")}
                    >
                        {languageData.GotoMarketplace}
                    </OkButton>
                </Box>
            </Box>
        );
    };

    renderSwitchButtons = () => {
        const { classes } = this.props;
        const { userType } = this.state;
        const {MyOffers, offerListing, BusinessDeals, MarketplaceOffers} = languageTranslationData[this.state.currentLanguage];
        return (
            <Box style={{display: "flex", gap: "20px"}}>
                {[
                    {id: "myOffer", label: userType==="consumer"? MyOffers: MarketplaceOffers},
                    {id: "offerListing", label: offerListing },
                    {id: "businessDeals", label: BusinessDeals}
                ].map((value, index) =>
                    {return (
                        <>
                        {
                        (userType==="admin_account" && index === 1)? 
                            null:
                            <Box
                                key={index}
                                className={
                                    this.state.selectedOffer === value.id? 
                                    classes.publishButton: 
                                    classes.draftButton
                                }
                                data-test-id = {`selectOfferButton${index}`}
                                onClick={()=>this.handleOfferSelect(value.id)}
                            >
                                {value.label}
                            </Box>
                    }
                        </>
                    )}
                    
                )}
            </Box>
        );
    };

    renderTableHeading = () => {
        const { currentLanguage, selectedOffer} = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const {tableHeadingText} = languageData;
        return (
            <CustomHeaderRow>
                {selectedOffer!=="businessDeals"?
                    <>
                        <TableCell>{tableHeadingText.OfferID}</TableCell>
                        <TableCell>{tableHeadingText.ListingID}</TableCell>
                    </>:
                    <TableCell>{tableHeadingText.DealID}</TableCell>
                }
                {selectedOffer==="offerListing"?
                    <TableCell>{tableHeadingText.BuyerName}</TableCell>
                    :<TableCell>{tableHeadingText.OwnerName}</TableCell>
                }
                {selectedOffer==="businessDeals" &&
                    <TableCell>{tableHeadingText.Category}</TableCell>
                }
                <TableCell>{tableHeadingText.OfferedPrice}</TableCell>
                <TableCell>{tableHeadingText.DateofOffer}</TableCell>

                {selectedOffer==="offerListing"?
                    <>
                        <TableCell>{tableHeadingText.Status}</TableCell>
                        <TableCell colSpan={2}>{tableHeadingText.Adminstatus}</TableCell>
                    </>
                    :<TableCell colSpan={2}>{tableHeadingText.Status}</TableCell>
                }
            </CustomHeaderRow>
        );
    };

    renderAdminTableHeading = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage].tableHeadingText;
        const textAlign = currentLanguage === "ar" ? 'right' : 'left';
        const cellStyle: React.CSSProperties = {
            textAlign
        };
        return (
            <CustomHeaderRow>
                <TableCell style={cellStyle}>{languageData.OfferID}</TableCell>
                <TableCell style={cellStyle}>{languageData.ListingID}</TableCell>
                <TableCell style={cellStyle}>{languageData.BuyerName}</TableCell>
                <TableCell style={cellStyle}>{languageData.OfferedPrice}</TableCell>
                <TableCell style={cellStyle}>{languageData.DateofOffer}</TableCell>
                <TableCell style={cellStyle}>{languageData.UserStatus}</TableCell>
                <TableCell colSpan={2} style={cellStyle}>{languageData.Adminstatus}</TableCell>    
            </CustomHeaderRow>
        );
    }

    renderTable = () => {
        const { currentLanguage, selectedOffer, userType} = this.state;
        const languageDataConfig = configJSON.userDashboardLanguage[currentLanguage];
        const languageData = languageTranslationData[currentLanguage]
        const textAlign = currentLanguage === "ar" ? 'right' : 'left';
        const cellStyle: React.CSSProperties = {
            textAlign
        };
        return (
            <TableBoxWrap style={webStyle.tableContainer}>
                <TableSearchBox>
                    <Box style={webStyle.searchFieldBox}>
                        <SearchIcon style={webStyle.searchIcons} />
                        <SearchInputField
                            fullWidth
                            variant="outlined"
                            placeholder={languageDataConfig.search}
                        />
                    </Box>
                    <Box style={{display: "flex", gap: "20px", position: "relative"}}>
                        <FilterButtonStyle
                            style={{height: "44px"}}
                            data-test-id="filterButtonTestID"
                            onClick={()=>this.handleFilterOpen()}
                        >
                            <Box 
                                style={{display: "flex", gap: "10px"}}
                            >
                                <FilterListRoundedIcon/>
                                {languageData.Filters}
                            </Box>
                        </FilterButtonStyle>
                        {this.state.showFilter && this.renderFilter()}
                    </Box>
                </TableSearchBox>
                <TableContainer style={webStyle.tableContainerStyle} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            {userType === "consumer"? 
                                this.renderTableHeading():
                                this.renderAdminTableHeading()
                            }
                        </TableHead>
                        {(selectedOffer==="myOffer" && userType === "consumer") && 
                            <TableBody>
                                {this.state.myOfferList.map((value, index) => (
                                    <BodyRowStyle key={index}>
                                        <TableCell>{value[0]}</TableCell>
                                        <TableCell>{value[1]}</TableCell>
                                        <TableCell>{value[2]}</TableCell>
                                        <TableCell>${value[3]}</TableCell>
                                        <TableCell>{value[4]}</TableCell>
                                        <TableCell>
                                            <span
                                                style={{...webStyle.statusStyle, ...this.handleStatusColorChange(value[5])}}
                                            >
                                                {value[5]}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                data-test-id={`moreIcon${index}TestID`}
                                                onClick={this.handleMenuOpen}
                                            >
                                                <MoreVertIcon style={{color: "#64748B", cursor: "pointer"}}/>
                                            </IconButton>
                                        </TableCell>
                                    </BodyRowStyle>
                                ))}
                            </TableBody>
                        }
                        
                        {(selectedOffer==="offerListing" || userType === "admin_account") &&
                            <TableBody>
                                {this.state.offerListing[currentLanguage].map((item, index) => (
                                    <BodyRowStyle key={index}>
                                        <TableCell style={cellStyle}>#{item[0]}</TableCell>
                                        <TableCell style={cellStyle}>#{item[1]}</TableCell>
                                        <TableCell style={cellStyle}>{item[2]}</TableCell>
                                        <TableCell style={cellStyle}>${currentLanguage==="en"? item[3]: this.toArabicNumeral(item[3])}</TableCell>
                                        <TableCell style={cellStyle}>{item[4]}</TableCell>
                                        <TableCell style={cellStyle}>
                                            <span
                                                style={{
                                                    ...webStyle.statusStyle, 
                                                    ...this.handleStatusColorChange(this.state.offerListing.en[index][5])}}
                                            >
                                                {item[5]}
                                            </span>
                                        </TableCell>
                                        <TableCell style={cellStyle}>
                                            <span
                                                style={{
                                                    ...webStyle.statusStyle, 
                                                    ...this.handleStatusColorChange(this.state.offerListing.en[index][6])}}
                                            >
                                                {item[6]}
                                            </span>
                                        </TableCell >
                                        <TableCell style={cellStyle}>
                                            <IconButton
                                                data-test-id={`offerListMoreIcon${index}TestID`}
                                                onClick={this.handleMenuOpen}
                                            >
                                                <MoreVertIcon style={{color: "#64748B", cursor: "pointer"}}/>
                                            </IconButton>
                                        </TableCell>
                                    </BodyRowStyle>
                                ))}
                            </TableBody>
                        }

                        {(selectedOffer==="businessDeals" && userType === "consumer") &&
                            <TableBody>
                                {this.state.businessList.map((arrayItem, index) => (
                                    <BodyRowStyle key={index}>
                                        <TableCell>{arrayItem[0]}</TableCell>
                                        <TableCell>{arrayItem[1]}</TableCell>
                                        <TableCell>{arrayItem[2]}</TableCell>
                                        <TableCell>${arrayItem[3]}</TableCell>
                                        <TableCell>{arrayItem[4]}</TableCell>
                                        <TableCell>
                                            <span
                                                style={{...webStyle.statusStyle, ...this.handleStatusColorChange(arrayItem[5])}}
                                            >
                                                {arrayItem[5]}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={this.handleMenuOpen}
                                                data-test-id={`detailMoreIcon${index}TestID`}
                                            >
                                                <MoreVertIcon style={{color: "#64748B", cursor: "pointer"}}/>
                                            </IconButton>
                                        </TableCell>
                                    </BodyRowStyle>
                                ))}
                            </TableBody>
                        }   
                    </Table>
                </TableContainer>
                <Box
                    style={webStyle.mainResultBox}
                >
                    <Box
                        style={webStyle.paginationBox}
                    >
                        <TablePagination
                            data-test-id="paginationTestID"
                            page={this.state.currentPage}
                            count={8}
                            handlePageChange={this.handlePageChange}
                        />
                    </Box>
                    <CustomResultBox>
                        <Typography style={{color: "#64748B", fontSize: "12px"}}>
                            1 - 12 {languageData.of} 48 {languageData.results}
                        </Typography>
                    </CustomResultBox>
                </Box>
            </TableBoxWrap>
        );
    };

    rendeBusinessDealTable = () => {
        const languageData=languageTranslationData[this.state.currentLanguage].businessDealTable;
        return (
            <Box data-test-id="businessDealShowDataTestID">
                <Typography style={{fontSize: "24px", fontWeight: 700, color: "#0F172A"}}>
                    {languageData.heading}
                </Typography>
                <Box style={{...webStyle.tableContainer, marginTop: "30px"}}>
                    <TableContainer 
                        style={{
                            ...webStyle.tableContainerStyle, 
                            border: "1px solid #E2E8F0", 
                            marginBottom: "25px"}} 
                            component={Paper}
                        >
                        <Table style={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <CustomHeaderRow>
                                    <TableCell>{languageData.TotalAmntPaid}</TableCell>
                                    <TableCell colSpan={2}>{languageData.PaymentTerms}</TableCell>
                                </CustomHeaderRow>
                            </TableHead>
                                <TableBody>
                                    <BodyRowStyle>
                                        <TableCell>$10,000,000</TableCell>
                                        <TableCell>30% Down payment, then monthly installments over 6 months</TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <MoreVertIcon style={{color: "#64748B", cursor: "pointer"}}/>
                                            </IconButton>
                                        </TableCell>
                                    </BodyRowStyle>
                                </TableBody>  
                        </Table>
                    </TableContainer>
                    <TableContainer style={{...webStyle.tableContainerStyle, border: "1px solid #E2E8F0"}} component={Paper}>
                        <Table style={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <CustomHeaderRow>
                                    <TableCell>{languageData.PaymentID}</TableCell>
                                    <TableCell>{languageData.Installment}</TableCell>
                                    <TableCell>{languageData.Amount}</TableCell>
                                    <TableCell>{languageData.DueDate}</TableCell>
                                    <TableCell>{languageData.Status}</TableCell>
                                    <TableCell>{languageData.Action}</TableCell>
                                </CustomHeaderRow>
                            </TableHead>
                                <TableBody>
                                    {this.state.businewwDealList.map((item, index) => (
                                        <BodyRowStyle key={index}>
                                            <TableCell>{item[0]}</TableCell>
                                            <TableCell>{item[1]}</TableCell>
                                            <TableCell>${item[2]}</TableCell>
                                            <TableCell>{item[3]}</TableCell>
                                            <TableCell>
                                                <span
                                                    style={{...webStyle.statusStyle, ...this.handleStatusColorChange(item[4])}}
                                                >
                                                    {item[4]}
                                                </span>
                                            </TableCell>
                                            <TableCell style={{width: "180px"}}>
                                                {item[4]==="Paid"?
                                                    <CancelButton style={{width: "180px", height: "44px"}}>
                                                       {languageData.DownloadInvoice}
                                                    </CancelButton>:
                                                    <>
                                                        {
                                                            this.state.selectedOffer==="offerListing"?
                                                                <CancelButton style={{width: "180px", height: "44px", color: "#64748B"}}>
                                                                    {languageData.DownloadInvoice}
                                                                </CancelButton>:
                                                                <AcceptButton style={{width: "180px", height: "44px"}}>
                                                                    {languageData.Makepayment}
                                                                </AcceptButton>
                                                        }  
                                                    </>                                                  
                                                }
                                            </TableCell>
                                        </BodyRowStyle>
                                    ))}
                                </TableBody>  
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        );
    };

    renderOfferDetail = () => {
        const { 
            userName, 
            userEmail, 
            userCountry,
            userState, 
            userCity, 
            phone, 
            zipcode, 
            suppDoc,
            otherDocs,
            selectedOffer,
            isOfferAccept,
            currentLanguage,
            userType
        } = this.state;
        const valueObject = {
            ...offerDetailPreview,
            name: userName,
            email: userEmail,
            country: userCountry,
            state: userState,
            city:userCity,
            phoneNumber: phone,
            zip: zipcode,
            supportDocArray: suppDoc,
            otherDocArray: otherDocs
        };
        const languageData = languageTranslationData[currentLanguage]
        return (
            <Box style={webStyle.detailWrapper}>
                <Box style={webStyle.descBox}>
                    <DescUpBox>
                        <Box>
                            <Typography style={webStyle.descUpValue}>Service</Typography>
                            <Typography>{languageData.OfferType}</Typography>
                        </Box>
                        <Box>
                            <Typography style={webStyle.descUpValue}>$10,000</Typography>
                            <Typography>{languageData.Purchaseamount}</Typography>
                        </Box>
                    </DescUpBox>
                    <Typography>
                        {configJSON.dummyOfferDescription}
                    </Typography>
                </Box>
                <PreviewListing 
                    previewValues={valueObject} 
                    toggleHide={isOfferAccept}
                    debtBuyerInfo = {userType==="admin_account"? debtBuyerInfo: null}
                />
                
                { isOfferAccept?
                    <>
                        {this.rendeBusinessDealTable()}
                        {this.renderTimelineBox()}
                    </>:
                    <Box style={{display: "flex", gap: "20px"}}>
                        {(selectedOffer === "offerListing" || userType === "admin_account")  && 
                            <AcceptButton 
                                style={{width: "200px"}}
                                data-test-id = "offerDetailAcceptButton"
                                onClick={() => userType === "consumer"? this.handleModalOpen(): null}
                            >
                                {userType === "admin_account"? languageData.Accept: languageData.AcceptOffer}
                            </AcceptButton>}
                        <CancelButton 
                            style={{width: "200px"}}
                            data-test-id = "offerDetailCancelButton"
                            onClick={()=>this.handlebreadScrumClick("breadScrum0")}
                        >
                            {userType === "admin_account"? 
                                languageData.Reject: 
                                languageData.CancelOffer
                            }
                        </CancelButton>
                    </Box>
                }
            </Box>
        );
    };

    renderTimelineBox = () => {
        const {selectedOffer, currentLanguage}= this.state;
        const languageData = languageTranslationData[currentLanguage].timelineTable;
        return (
            <Box style={webStyle.timelineBox}>
                <Typography style={webStyle.timelineHeading}>{languageData.heading}</Typography>
                <Box style={{paddingLeft: "12px"}}>
                    {[
                        {date: "26 Feb 2022", text: languageData.labelOne},
                        {date: "26 Feb 2022", text: languageData.labelTwo},
                        {date: "26 Feb 2022", text: languageData.labelThree},
                        {date: "26 Feb 2022", text: languageData.labelFour},
                        {date: "26 Feb 2022", text: languageData.labelFive},
                        {date: "26 Feb 2022", text: languageData.labelSix}
                    ].map((step, index) => (
                        <>
                            {((index!==5 && selectedOffer === "offerListing") || selectedOffer === "myOffer") &&
                            <Box style={currentLanguage === "en"? {
                                    ...webStyle.stepperBox, 
                                    borderLeft: (index===5 || (index===4 && selectedOffer === "offerListing"))? 
                                    "none" : "1px solid #E2E8F0"
                                }:
                                {
                                    ...webStyle.stepperBox, 
                                    borderRight: (index===5 || (index===4 && selectedOffer === "offerListing"))? 
                                    "none" : "1px solid #E2E8F0"
                                }
                            }
                            >
                                <span 
                                    style={currentLanguage === "en"?
                                        {...webStyle.stepperSpan, left: "-16px"}:
                                        {...webStyle.stepperSpan, right: "-16px"} 
                                    }
                                >
                                    {index+1}
                                </span>
                                <Typography
                                    style={{ color: "#475569", fontSize: "12px", marginBottom: "7px" }}
                                >
                                    {step.date}
                                </Typography>
                                <Typography
                                    style={{ color: "#1E293B", fontSize: "18px", fontWeight: 600 }}
                                >
                                    {step.text}
                                </Typography>
                                {
                                    (index === 1 || (index===4 && selectedOffer === "myOffer") ) &&
                                    this.renderFileBox()
                                }
                            </Box>
                            }
                        </>
                    ))}
                </Box>
            </Box>
        );
    };

    renderFileBox = () => {
        return (
            <Box style={webStyle.fileWrapper}>
                <Box style={webStyle.noteIconBoxStyle}>
                    <NotesIcon style={{color: "#2563EB"}}/>
                </Box>
                <Box style={{flexGrow:1, width:"50%"}}>
                    <Typography style={webStyle.fileName}>File name.doc</Typography>
                    <Typography style={webStyle.fileSize}>{(450000/1024).toFixed(2)} KB</Typography>
                </Box>
                <IconButton 
                    aria-label="more1"
                    aria-haspopup="true"
                >
                    <MoreVertIcon style={{color: "#475569", cursor: "pointer"}} />
                </IconButton>
            </Box>
        );
    };

    renderPaymentMethod = () => {
        return (
            <Box style={{ display: "flex", gap: "40px", marginTop: "30px" }}>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{ fontSize: "20px", color: "#0F172A", fontWeight: 600 }}
                >
                  Payment method
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    gap: "8px",
                    color: "#1E3A8A",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Typography style={{ fontSize: "16px", fontWeight: 700 }}>
                    Add new
                  </Typography>
                  <AddRoundedIcon style={{ width: "20px", height: "20px" }} />
                </Box>
              </Box>
              <Box
                style={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                {[
                  { cardNum: "12345678", type: "Visa", imgSrc: visaLogo },
                  { cardNum: "12345678", type: "Mastercard", imgSrc: masterCardLogo }
                ].map((value, index) => (
                  <Box
                    key={index}
                    style={{
                      ...webStyle.paymentItemWrapper,
                      border:
                        index === 0
                          ? "1px solid #3B82F6"
                          : "1px solid #E2E8F0",
                      background:
                        index === 0 ? "#EFF6FF" : "white",
                    }}
                  >
                    <Box
                      style={{ display: "flex", alignItems: "center", gap: "16px" }}
                    >
                      <span
                        style={{
                          display: "block",
                          width: "20px",
                          height: "20px",
                          borderRadius: "25px",
                          border:
                            index === 0
                              ? "6px solid #1D4ED8"
                              : "1px solid #64748B",
                          boxSizing: "border-box",
                        }}
                      ></span>
                      <Box>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#0F172A",
                          }}
                        >
                            {`**** ${value.cardNum.substring(8)}`}
                        </Typography>
                        <Box style={{ display: "flex", gap: "5px" }}>
                          <Typography
                            style={{ fontSize: "14px", color: "#64748B" }}
                          >
                            {value.type}
                          </Typography>
                          <Typography
                            style={{ fontSize: "14px", color: "#E2E8F0" }}
                          >
                            |
                          </Typography>
                          <Typography
                            style={{ fontSize: "14px", color: "#64748B" }}
                          >
                            Edit
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <img src={value.imgSrc} alt={`${value.type + index}_image`} />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: "24px",
                boxShadow: "0 0 8px 4px #00000008",
                padding: "32px"
              }}
            >
              <Typography
                style={{
                  color: "#0F172A",
                  fontSize: "24px",
                  fontWeight: 600,
                  marginBottom: "20px"
                }}
              >
                Payment Details
              </Typography>
              <PayDetailBox>
                <Box>
                  <Typography>Location name</Typography>
                  <Typography style={webStyle.payDetValuText}>
                    National Bank
                  </Typography>
                </Box>
                <Box>
                  <Typography>Payment date</Typography>
                  <Typography style={webStyle.payDetValuText}>
                    22 Jan 2024
                  </Typography>
                </Box>
                <Box>
                  <Typography>Current balance</Typography>
                  <Typography
                    style={{ ...webStyle.payDetValuText, color: "#1D4ED8" }}
                  >
                    $24,000,000
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ color: "#0F172A", fontSize: "18px", fontWeight: 500 }}
                  >
                    Payment amount
                  </Typography>
                  <Typography
                    style={{
                      ...webStyle.payDetValuText,
                      color: "#1D4ED8",
                      fontSize: "18px",
                      fontWeight: 600
                    }}
                  >
                    $24,000,000
                  </Typography>
                </Box>
              </PayDetailBox>
              <AcceptButton
                style={{ width: "100%" }}
              >
                Make payment
              </AcceptButton>
              <Typography
                style={{ color: "#475569", fontSize: "14px", marginTop: "10px" }}
              >
                By clicking Make payment you agree to our{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#0F172A",
                  }}
                >
                  Terms and conditions
                </span>{" "}
              </Typography>
            </Box>
          </Box>
        );
    };

    renderProfileAddInfo = () => {
        const {phone, userCountry, userState, userCity, zipcode, currentLanguage} = this.state;
        const languageData = languageTranslationData[currentLanguage].dealDetailShow;
        return (
            <ProAddBox style={webStyle.profAddWrapper}>
                <Box >
                    <Typography style={webStyle.proAddUpValText}>
                        {`(${phone.substring(0,3)}) ${phone.substring(3,6)}-${phone.substring(6)}`}
                    </Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{languageData.PhoneNumber}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.proAddUpValText}>{userCountry}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{languageData.Country}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.proAddUpValText}>{userState}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{languageData.State}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.proAddUpValText}>{userCity}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{languageData.City}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.proAddUpValText}>{zipcode}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{languageData.Zip}</Typography>
                </Box>
            </ProAddBox>
        );
    };

    renderDealDetails = () => {
        const languageData = languageTranslationData[this.state.currentLanguage].dealDetailShow;
        const mainLanguageData = languageTranslationData[this.state.currentLanguage];
        return (
            <Box style={{marginTop: "22px"}}>
                <Box>
                    <Typography style={webStyle.profileNameStyle}>{languageData.ClientInformation}</Typography>
                    <Box style={webStyle.timelineBox}>
                        <Box style={webStyle.avatarWrapper}>
                            <Avatar
                                src={""}
                                alt="profile_image"
                                style={{width: "80px", height: "80px"}}
                            />
                            <Box style={webStyle.profileTextBox}>
                                <Typography style={{...webStyle.profileNameStyle, marginBottom: "0px"}}>
                                    {this.state.userName}
                                </Typography>
                                <Typography style={webStyle.proEmailStyle}>
                                    {this.state.userEmail}
                                </Typography>
                            </Box>
                        </Box>
                        {this.renderProfileAddInfo()}
                    </Box>
                </Box>
                <CustomizedDivider/>
                <Box>
                    <Typography style={webStyle.profileNameStyle}>{languageData.DealInformation}</Typography>
                    <Box style={webStyle.timelineBox}>
                        <ProAddBox style={webStyle.profAddWrapper}>
                            {[
                                {value: "Goods", item: languageData.Category},
                                {value: "Cars", item: languageData.SubCategory},
                                {value: "50", item: languageData.Quantity},
                                {value: "30% Down payment", item: languageData.PaymentTerms},
                                {value: "$2,500,000", item: languageData.TotalPrice}
                                ].map((value, index)=>
                                    <Box key={index}>
                                        <Typography style={webStyle.proAddUpValText}>{value.value}</Typography>
                                        <Typography style={{color:"#334155", fontSize: "16px"}}>{value.item}</Typography>
                                    </Box>
                                )
                            }
                        </ProAddBox>
                        <Box style={{color: "#1E293B"}}>
                            <Typography style={{ fontSize: "14px", fontWeight: 700, margin: "30px 0 15px"}}>{languageData.Description}</Typography>
                            <Typography>
                                This deal involves the sale of 50 brand-new electric cars, Model X. Each car comes with a 5-year warranty, free servicing for the first two years, and a complimentary charging station installation.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <CustomizedDivider/>
                <Box>
                    <Typography style={webStyle.profileNameStyle}>{languageData.Documentation}</Typography>
                    <Grid container spacing={4} xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={12} md={6}>
                            {this.renderFileBox()}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {this.renderFileBox()}
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{display: "flex", gap: "20px", marginTop: "50px"}}>
                    <AcceptButton 
                        style={{width: "200px"}}
                    >
                        {mainLanguageData.AcceptOffer}
                    </AcceptButton>
                    <CancelButton style={{width: "200px"}}>{mainLanguageData.RejectOffer}</CancelButton>
                </Box>
            </Box>
        );
    };

    renderAcceptModal = () => {
        const languageData = languageTranslationData[this.state.currentLanguage]
        return (
            <Modal 
                open={this.state.showModal}
                onClose={this.handleModalClose}
            >
                <Box style={webStyle.modalMainBox}>
                    <Box style={webStyle.modalBodyBox}>
                        <Box style={{padding: "32px", borderBottom: "1px solid #E2E8F0"}}>
                            <Box style={webStyle.modCloseWrapper}>
                                <Typography style={{color: "#0F172A", fontSize: "20px", fontWeight: 700}}>
                                    {languageData.AcceptConfirmText}
                                </Typography>
                                <IconButton
                                    data-test-id="modalCloseIconButton"
                                    onClick={this.handleModalClose}
                                >
                                <CloseRoundedIcon
                                    style={{ width: "24px", color: "#0F172A" }}
                                />
                                </IconButton>
                            </Box>
                            <Typography style={{maxWidth: "510px", color: "#475569", fontSize: "14px"}}>
                                {languageData.AcceptMessageFir} <span style={{color: "#0F172A", textDecoration: "underline"}}>{languageData.termsandconditions}</span> {languageData.AcceptMessageEnd}
                            </Typography>
                        </Box>
                        <Box style={{display: "flex", gap: "20px", padding: "32px"}}>
                            <CancelButton 
                                style={{width: "240px"}}
                                onClick={this.handleModalClose}
                            >
                                {languageData.Cancel}
                            </CancelButton>
                            <AcceptButton 
                                data-test-id="modalAcceptButtonTestID"
                                style={{width: "240px"}}
                                onClick={this.handleOfferAccept}
                            >
                                {languageData.AcceptOffer}
                            </AcceptButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    renderFilter = () => {
        const { currentLanguage } = this.state
        const languageData = languageTranslationData[currentLanguage];
        return (
            <Box 
                style={currentLanguage==="en"?
                    {...webStyle.filtermainWrapper, right: "0px"}:
                    {...webStyle.filtermainWrapper, left: "0px"}
                }>
                <Box style={webStyle.filtCloseBox}>
                    <Box style={webStyle.filtHeadingBox}>
                        <FilterListRoundedIcon style={{width: "20px"}}/>
                        {languageData.Filters}
                    </Box>
                    <IconButton
                        data-test-id="filterCloseButtonTestID"
                        onClick={()=>this.handleCloseFilter()}
                    >
                        <CloseRoundedIcon style={{width: "20px", color: "#0F172A"}}/>
                    </IconButton>
                </Box>
                <Box style={webStyle.filtMiddleBox}>
                    <Box>
                        <FiltResetWrapper>
                            <Button
                                data-test-id="resetDateTestID"
                                onClick={this.resetDate}
                            >
                                {languageData.Reset}
                            </Button>
                        </FiltResetWrapper>
                        <Box style={{display: "flex", gap:"20px"}}>
                            <Box>
                                <Typography style={webStyle.filtLabel}>{languageData.From}</Typography>
                                <FiltDateField
                                    data-test-id="dateInputTestID"
                                    type="date" 
                                    variant="outlined"
                                    value={this.state.startDate}
                                    name="startDate"
                                    onChange={this.handleDateChange}
                                />
                            </Box>
                            <Box>
                                <Typography style={webStyle.filtLabel}>{languageData.To}</Typography>
                                <FiltDateField 
                                    type="date" 
                                    variant="outlined"
                                    name="endDate"
                                    value={this.state.endDate}
                                    onChange={this.handleDateChange}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <FiltResetWrapper>
                            <Button
                                data-test-id="resetSelectTestID"
                                onClick={()=>this.resetSelect()}
                            >
                                {languageData.Reset}
                            </Button>
                        </FiltResetWrapper>
                        <Typography style={{...webStyle.filtLabel, marginBottom: "4px"}}>{languageData.Status}</Typography>
                        <FormControl fullWidth>
                            <FiltInputLabel shrink={ false } style={{ display:this.state.status?"none": "block" , lineHeight: "0.5" }}>
                                {languageData.Accepted}
                            </FiltInputLabel>
                            <FiltSelect
                                data-test-id="selectInputTestID"
                                fullWidth
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                variant="outlined"
                                value = {this.state.status}
                                name = "status"
                                onChange = {this.handleSelect}
                            >
                                <MenuItem value={"Accepted"}>{languageData.Accepted}</MenuItem>
                                <MenuItem value={"Rejected"}>{languageData.Rejected}</MenuItem>
                            </FiltSelect>
                        </FormControl>
                    </Box>
                </Box>
                <Box style={webStyle.filtBottomBox}>
                    <CancelButton
                        style={{height: "44px"}}
                        data-test-id="resetAllTestID"
                        onClick={this.resetAll}
                    >
                        {languageData.Reset}
                    </CancelButton>
                    <AcceptButton 
                        style={{fontSize: "14px", height: "44px"}}
                        onClick={this.handleCloseFilter}
                    >
                        {languageData.Applynow}
                    </AcceptButton>
                </Box>
            </Box>
        );
    };

    renderScreenSwith = () => {
        const { isOfferAvailable, showDetail, selectedOffer, userType} = this.state;
        return (
            <>
                {!showDetail?
                    <>
                        {isOfferAvailable?
                            this.renderTable():
                            this.renderEmptyScreen()
                        }
                    </>:
                    <>
                        {(selectedOffer!=="businessDeals" || userType==="admin_account")?
                            this.renderOfferDetail():
                            this.renderDealDetails()
                        }
                    </>
                }
            </>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentLanguage, selectedOffer, showDetail } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const textAlign = currentLanguage === "ar" ? 'right' : 'left';
        const cellStyle: React.CSSProperties = { textAlign };
        return (
            <UserProfileDrawer 
                handleClick={this.handleDrawerNavigation}
                pageName={this.state.userType==="consumer"? "UserMyOffers" : "dashboardAdminOffers"}
                breadScrumArray={this.state.breadscrumValues}
                data-test-id = "drawerTestID"
                userType = {this.state.userType==="consumer"? "consumer" : "admin_account"}
                navigation={this.props.navigation}
            >
                <Box>
                    <Box style = {{marginBottom: "16px"}} >
                        <Typography style={webStyle.headingText}>
                            {this.state.headingText}
                        </Typography>
                    </Box>
                    {!showDetail && this.renderSwitchButtons()}
                    {this.renderScreenSwith()}
                </Box>
                <Menu 
                    id={"simple-popper"}
                    data-test-id="menuTestID"
                    open={Boolean(this.state.anchorEl)} 
                    anchorEl={this.state.anchorEl}
                    elevation={0}
                    getContentAnchorEl={null}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: '0px 0px 8px 4px #00000008',
                            minWidth: "160px",
                            borderRadius: "8px",
                            border:"1px solid #E2E8F0"
                        }
                    }}
                >
                    <MenuBox>
                        {this.state.userType === "consumer"?
                            <>
                                {selectedOffer === "myOffer" &&
                                    <>
                                        <Typography
                                            onClick={this.handleOfferDetailOpen}
                                        >
                                            {languageData.ViewOfferDetails}
                                        </Typography> 
                                        <Typography style={{color: "#DC2626"}}>{languageData.CancelOffer}</Typography>
                                    </>
                                }
                                {selectedOffer === "offerListing" &&
                                    <>
                                        <Typography
                                            data-test-id="viewOfferListDetailButtonTestID"
                                            onClick={this.handleOfferDetailOpen}
                                        >
                                            {languageData.ViewOfferDetails}
                                        </Typography>
                                        <Typography>
                                            {languageData.AcceptOffer}
                                        </Typography>
                                        <Typography style={{color: "#DC2626"}}>{languageData.RejectOffer}</Typography>
                                    </>
                                }
                                {selectedOffer === "businessDeals" &&
                                    <>
                                        <Typography
                                            data-test-id="busDealDetailShowTestID"
                                            onClick={this.handleOfferDetailOpen}
                                        >
                                            {languageData.ViewDealDetails}
                                        </Typography> 
                                        <Typography style={{color: "#DC2626"}}>{languageData.CancelDeal}</Typography>
                                    </>
                                }
                            </>:
                            <>
                                <Typography
                                    data-test-id="viewOfferListDetailButtonTestID"
                                    onClick={this.handleOfferDetailOpen}
                                    style={cellStyle}
                                >
                                    {languageData.ViewDetails}
                                </Typography>
                                <Typography style={{color: "#059669", ...cellStyle}}>
                                    {languageData.Accept}
                                </Typography>
                                <Typography style={{color: "#DC2626", ...cellStyle}}>
                                    {languageData.Reject}
                                </Typography>
                            </>
                        } 
                    </MenuBox>
                </Menu>
                {this.renderAcceptModal()}
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}


// Customizable Area Start

const TableBoxWrap = styled(Box)({
    "@media(max-width: 1250px)": {
        width: "850px"
    },
    "@media(max-width: 1200px)": {
        width: "800px"
    },
    "@media(max-width: 1150px)": {
        width: "750px"
    },
    "@media(max-width: 1100px)": {
        width: "700px"
    },
    "@media(max-width: 1050px)": {
        width: "650px"
    },
    "@media(max-width: 1000px)": {
        width: "600px"
    },
    "@media(max-width: 990px)": {
        width: "auto"
    }
});

const FiltDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          top: "0px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "10px",
        fontWeight: "400",
        color: "#0F172A",
        fontSize: "14px",
        top: "0px",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        opacity: 1,
        fontSize: "16px",
        color: "#94A3B8",
      }
});

const FiltSelect = styled(Select)({
    color: "#0F172A",
    height: "44px",
    fontSize: "14px",
    fontWeight: 400,
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
      background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});


const FiltResetWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
        textTransform: "capitalize",
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700
    }
});

const FiltInputLabel = styled(InputLabel)({
    fontSize: "16px",
    top: "-5px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
        display: "none",
        color: "#94A3B8",
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const CustomizedDivider = styled(Divider)({
    margin:"50px 0 45px",
    background: "#E2E8F0",
    display: "block !important"
});

const ProAddBox = styled(Box)({
    justifyContent: "space-between",
    "@media(max-width: 1200px)":{
        justifyContent: "flex-start"
    }
});

const PayDetailBox = styled(Box)({
    marginBottom: "25px",
    "& .MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "15px",
      color: "#475569",
      fontSize: "16px"
    }
});

const CancelButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "56px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#EFF6FF"
    }
});

const AcceptButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#1E3A8A"
    }
});

const DescUpBox = styled(Box)({
    display: "flex",
    gap:"20px",
    justifyContent: "space-between",
    maxWidth: "265px",
    marginBottom: "30px",
    flexWrap: "wrap"
});

const MenuBox = styled(Box)({
    padding: "0 8px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    "& .MuiTypography-root": {
        padding: "4px 15px",
        cursor: "pointer",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 400,
        borderRadius: "8px",
        "&:hover":{
            background: "#EFF6FF"
        }
    }
});

const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width:"100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});

const CustomHeaderRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});

const BodyRowStyle = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#475569",
        fontSize: "14px"
    }
});

const FilterButtonStyle = styled(Button)({
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content"
});

const TableSearchBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    gap: "20px",
    flexWrap: "wrap"
});

const SearchInputField= styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const OkButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#1E3A8A"
    }
});

const webStyle = {
    filtLabel: {
        fontWeight: 700,
        color: "#334155",
        fontSize: "14px"
    },
    filtHeadingBox: {
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 600,
        gap: "8px"
    },
    filtBottomBox: {
        padding: "20px",
        display: "flex",
        gap: "20px",
        justifyContent: "space-between"
    },
    filtCloseBox: {
        display: "flex",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 20px"
    },
    filtMiddleBox: {
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1",
        display: "flex",
        gap: "10px",
        flexDirection: "column"
    } as React.CSSProperties,
    filtermainWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        position: "absolute",
        top: "56px",
        background: "white",
        zIndex: 10,
    } as React.CSSProperties,
    modCloseWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:  "center",
        marginBottom: "12px"
    },
      modalMainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
      modalBodyBox: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008"
    },
    proAddUpValText: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "18px",
        marginBottom: "10px"
    },
    profAddWrapper:{
        display:"flex",
        gap:"30px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    proEmailStyle: {
        color: "#334155",
        fontSize: "16px",
        fontSeight: 400
    },
    profileNameStyle: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600,
        marginBottom: "25px"
    },
    profileTextBox: {
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    } as React.CSSProperties,
    avatarWrapper: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        marginBottom: "25px"
    },
    payDetValuText: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 500
      },
    paymentItemWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 20px",
        borderRadius: "8px",
        border: "1px solid #3B82F6",
        minWidth: "350px",
        cursor: "pointer"
    },
    fileSize: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0"
    },
    fileName: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    noteIconBoxStyle: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px",
        cursor: "pointer"
    },
    fileWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px",
        maxWidth: "480px"
    },
    stepperBox: {
        position: "relative",
        padding: "0 33px 23px 33px"
    } as React.CSSProperties,
    stepperSpan: {
        position: "absolute",
        background: "#EFF6FF",
        color: "#1D4ED8",
        fontSize: "18px",
        fontWeight: 600,
        width: "32px",
        height: "32px",
        top: "-1px",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,
    timelineHeading: {
        fontSize: "20px",
        color: "#1E293B",
        fontWeight: 600,
        marginBottom: "30px"
    },
    timelineBox: {
        padding: "40px",
        borderRadius: "24px",
        boxShadow: "0px 0px 8px 4px #00000008"
    },
    detailWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        marginTop: "30px"
    } as React.CSSProperties,
    descBox: {
        padding: "40px",
        borderRadius: "24px",
        boxShadow: "0px 0px 8px 4px #00000008",
        color: "#334155",
        fontSize: "16px",
        background: "white"
    },
    descUpValue: {
        color: "#0F172A",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "12px"
    },
    mainResultBox:{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center", 
        paddingTop: "16px"
    } as React.CSSProperties,
    paginationBox: {
        display: "flex", 
        flexGrow: 1,
        justifyContent: "center"
    },
    tableContainer: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px 4px #00000008",
        marginTop: "40px",
        background: "white"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#D1FAE5",
        color: "#059669",
        textTransform:"uppercase"
    } as React.CSSProperties,
    tableContainerStyle: {
        boxShadow: "none",
        width: "100%", 
        overflow: "auto",
        borderRadius: "16px"
    },
    searchFieldBox: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px",
        maxWidth: "400px"
    },
    searchIcons: {
        color: "#94A3B8"
    },
    headingText: {
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F172A"
    },
    emptyWrapper:{
        height:"460px",
        display:"flex",
        justifyContent:"center",
        alignItems:'center'
    },
    emptyBodyWrapper: {
        display:'flex',
        gap:"20px",
        alignItems:"center",
        flexDirection:"column"
    } as React.CSSProperties,
    emptyParagraph:{
        fontWeight: 500,
        fontSize: "18px",
        textAlign: 'center'
    } as React.CSSProperties
};
// Customizable Area End
export default withStyles(styles)(MyOffers);
