import React from "react";
// Customizable Area Start
import AllListingController, {
    Props, configJSON
} from "./AllListingController.web";

import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Button,
    TableContainer,
    TextField,
    Paper,
    Menu,
    IconButton,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Avatar
} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import EditIcon from "@material-ui/icons/Edit";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { blankListingImage, acitveIcon, rejectIcon, pendingIcon } from "./assets";
import Pagination from "@material-ui/lab/Pagination";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Listing.json');
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import PreviewListing from "../../../components/src/design-system/PreviewListing/PreviewListing.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    publishButton: {
        borderRadius: "25px",
        border: "1px solid #BFDBFE",
        backgroundColor: "#EFF6FF",
        padding: "8px 16px",
        cursor: "pointer",
        color: "#1D4ED8",
        fontSize: "16px",
        fontWeight: 700
    },
    draftButton: {
        padding: "8px",
        cursor: "pointer",
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: 600
    }
    // Customizable Area End
}

// Customizable Area Start

const detailPreview = {
    time: "54",
    portfolioSize: "2,500",
    listingTitle: "Medical Debt Package",
    averageBalance: "500",
    accountCount: "1",
    totalNumber: "1",
    totalAmount: "2,500",
    totalJudgement: "0",
    totalMonth: "3",
    pastPay: "Yes",
    monthAvg: "1",
    lastCollDay: "01",
    lastCollMonth: "02",
    lastCollYear: "2024",
    lastCredDay: "01",
    lastCredMonth: "02",
    lastCredYear: "2024",
    typeOfDebt: "Credit Card",
    ageOfDebt: "1",
    recoveryStatus: "ACTIVE COLLECTION"
};

const listingData = [
    ["#669677", "Strategic Finance Partners", "Medical Debt Package", "2,500", "Credit Card", "1", "1", "Active"],
    ["#669459", "James Carter", "Auto Loan Portfolio", "2,500", "Credit Card", "2", "2", "Active"],
    ["#869287", "Blue Horizon Credit", "Personal Loan Debt", "4,000", "Credit Card", "3", "0", "Active"],
    ["#664979", "Sunrise Credit Solutions", "Zenith Credit Management", "6,000", "Credit Card", "4", "4", "Active"],
    ["#679287", "James Carter", "Retail Credit Accounts", "4,000", "Credit Card", "2", "3", "Active"],
    ["#664559", "James Carter", "Healthcare Debt Portfolio", "2,500", "Credit Card", "1", "10", "Active"]    
];
// Customizable Area End

export class AllListing extends AllListingController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area
    renderListingBlank = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <Box style={webStyle.blankMainBox}>
                <Box style={webStyle.blankBodyWrapper}>
                    <img src={blankListingImage} alt="blank_listing_image" width="160px" />
                    <Box>
                        <Typography style={webStyle.blankParagraph}>
                            {languageData.blankListParaFir}
                        </Typography>
                        <Typography style={webStyle.blankParagraph}>
                            {languageData.blankListParaSec}
                        </Typography>
                    </Box>
                    <CreateButton
                        data-test-id="createListingTestID"
                        style={{ width: "183px", height: "44px" }}
                        onClick={() => this.handleNavigationRouting("UserCreateListing")}
                    >
                        <Box style={webStyle.createButton}>
                            <AddCircleOutlineIcon />
                            {languageData.CreateListingsButton}
                        </Box>
                    </CreateButton>
                </Box>
            </Box>
        );
    };

    renderTableHeading = () => {
        const { currentLanguage, showPublished, userType } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        const { tableHeading } = languageData;
        return (
            <CustomHeadingRow>
                <TableCell>{tableHeading.ID}</TableCell>
                {
                    userType === "consumer"? 
                    <>
                        <TableCell>{tableHeading.Name}</TableCell>
                        <TableCell>{tableHeading.PortfolioSize}</TableCell>
                        <TableCell>{tableHeading.TypesOfDebt}</TableCell>
                        {showPublished ?
                            <React.Fragment>
                                <TableCell>{tableHeading.AgeOfDebt}</TableCell>
                                <TableCell>{tableHeading.OffersMade}</TableCell>
                                <TableCell
                                    colSpan={2}
                                    style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}
                                >
                                    {tableHeading.Status}
                                </TableCell>
                            </React.Fragment> :
                            <TableCell
                                colSpan={2}
                                style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}
                            >
                                {tableHeading.AgeOfDebt}
                            </TableCell>
                        }
                    </>:
                    <>
                        {showPublished && <TableCell>{tableHeading.UserName}</TableCell>}
                        <TableCell>{tableHeading.ListingName}</TableCell>
                        <TableCell>{tableHeading.PortfolioSize}</TableCell>
                        <TableCell>{tableHeading.TypesOfDebt}</TableCell>
                        <TableCell>{tableHeading.AgeOfDebt}</TableCell>
                        <TableCell>{tableHeading.Offers}</TableCell>
                        <TableCell
                            colSpan={2}
                            style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}
                        >
                            {tableHeading.Status}
                        </TableCell>
                    </>
                }
                
            </CustomHeadingRow>
        );
    };

    renderTableBodyRow = () => {
        const {showPublished} = this.state;
        return (
            <React.Fragment>
                {
                    this.state.userType==="consumer"?
                    <>
                        {this.state.data.map((item, index) => (
                            <CustomBodyRow key={index}>
                                <TableCell>{item[0]}</TableCell>
                                <TableCell>{item[1]}</TableCell>
                                <TableCell>${item[2]}</TableCell>
                                <TableCell>{item[3]}</TableCell>
                                <TableCell>{item[4]} years</TableCell>
                                {showPublished ?
                                    <React.Fragment>
                                        <TableCell style={{ textDecoration: "underline" }}>{item[5]}</TableCell>
                                        <TableCell>
                                            <span
                                                style={webStyle.statusStyling}
                                            >
                                                {item[6]}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                data-test-id="publishedMoreButtonTestID"
                                                onClick={this.handlePublishMenu}
                                            >
                                                <MoreVertIcon style={webStyle.moreIconStyle} />
                                            </IconButton>
                                        </TableCell>
                                    </React.Fragment> :
                                    <TableCell>
                                        <IconButton
                                            onClick={this.handlePublishMenu}
                                        >
                                            <MoreVertIcon style={webStyle.moreIconStyle} />
                                        </IconButton>
                                    </TableCell>
                                }
                            </CustomBodyRow>
                        ))}
                    </>:
                    <>
                        {listingData.map((value, index) => (
                            <CustomBodyRow key={index}>
                                <TableCell>{value[0]}</TableCell>
                                {showPublished && <TableCell>{value[1]}</TableCell>}
                                <TableCell>{value[2]}</TableCell>
                                <TableCell>${value[3]}</TableCell>
                                <TableCell>{value[4]}</TableCell>
                                <TableCell>{value[5]} years</TableCell>
                                <TableCell style={{ textDecoration: "underline" }}>{value[6]}</TableCell>
                                <TableCell>
                                    <span
                                        style={{...webStyle.statusStyling, ...this.handleStatusCell()}}
                                    >
                                        {showPublished? "Pending" : "Active"}
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        data-test-id="publishedMoreButtonTestID"
                                        onClick={this.handlePublishMenu}
                                    >
                                        <MoreVertIcon style={webStyle.moreIconStyle} />
                                    </IconButton>
                                </TableCell>
                            </CustomBodyRow>
                        ))}
                    </>
                }
            </React.Fragment>
        );
    };

    renderTable = () => {
        const { currentLanguage } = this.state;
        const languageDataConfig = configJSON.userDashboardLanguage[currentLanguage];
        const languageData = languageTranslationData[currentLanguage]
        return (
            <>
                <TableUpBox>
                    <Box style={webStyle.searchFieldWrapper}>
                        <SearchIcon style={webStyle.searchIcon} />
                        <SearchTextField
                            data-test-id="searchInputTestID"
                            fullWidth
                            variant="outlined"
                            placeholder={languageDataConfig.search}
                        />
                    </Box>
                    <Box style={{ display: "flex", gap: "20px", position: "relative" }}>
                        <FilterButton
                            style={{ height: "44px" }}
                            data-test-id="filterButtonTestID"
                            onClick={this.handleToggleFilter}
                        >
                            <Box style={webStyle.createButton}>
                                <FilterListRoundedIcon />
                                {languageData.Filters}
                            </Box>
                        </FilterButton>
                        <CreateButton
                            style={{ height: "44px" }}
                            data-test-id="addNewButtonTestID"
                            onClick={this.handleNewListingButton}
                        >
                            <Box style={webStyle.createButton}>
                                <AddCircleOutlineIcon />
                                {languageData.AddNewListing}
                            </Box>
                        </CreateButton>
                        {this.state.showFilter && this.renderFilter()}
                    </Box>
                </TableUpBox>
                <TableContainer style={webStyle.tableConStyle} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            {this.renderTableHeading()}
                        </TableHead>
                        <TableBody>
                            {this.renderTableBodyRow()}
                            {this.state.userType==="admin_account" &&
                                <CustomBodyRow>
                                    <TableCell colSpan={this.state.showPublished? 7: 6}>
                                        <Box style={{display: "flex", justifyContent: "center"}}>
                                            <PaginationStyle
                                                data-test-id="paginationTestID"
                                                count={8}
                                                siblingCount={1}
                                                boundaryCount={1}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell colSpan={2} style={{textAlign: "right"}}>
                                        <CustomResultBox>
                                            <Typography style={{color: "#64748B", fontSize: "12px"}}>
                                                1 - 12 of 48 results
                                            </Typography>
                                        </CustomResultBox>
                                    </TableCell>
                                </CustomBodyRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    renderEditBox = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <Box style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0 18px" }}>
                <Box
                    style={webStyle.editMainWrapper}
                >
                    <Typography style={webStyle.edit}>{languageData.Edit}</Typography>
                    <EditIcon style={webStyle.iconEdit} />
                </Box>
            </Box>
        );
    };

    renderListingDetail = () => {
        const { name, email, country, state, city, phoneNumber, zip, supportDoc, otherDoc } = this.state;
        const dataObject = {
            ...detailPreview,
            name,
            email,
            country,
            state,
            city,
            phoneNumber,
            zip,
            supportDocArray: supportDoc,
            otherDocArray: otherDoc
        }
        return (
            <Box data-test-id="previewDetailBoxTestID">
                {this.renderEditBox()}
                {<PreviewListing previewValues={dataObject} />}
            </Box>
        );
    };

    renderListing = () => {
        const { currentLanguage, showPublished, userType } = this.state
        const languageData = languageTranslationData[currentLanguage];
        const { classes } = this.props
        return (
            <Box style={webStyle.mainListingBox} data-test-id="listingMainBoxTestID">
                <Box style={{ display: "flex", gap: "20px" }}>
                    {userType==="consumer"?
                    <>
                        <Box
                            className={showPublished ? classes.publishButton : classes.draftButton}
                            onClick={() => this.handlePublishedShow(true)}
                            data-test-id="publishedButtonTestID"
                        >
                            {languageData.Published}
                        </Box>
                        <Box
                            className={showPublished ? classes.draftButton : classes.publishButton}
                            onClick={() => this.handlePublishedShow(false)}
                            data-test-id="draftButtonTestID"
                        >
                            {languageData.Drafts}
                        </Box>
                    </>:
                    <>
                        <Box
                            className={showPublished ? classes.publishButton : classes.draftButton}
                            onClick={() => this.handlePublishedShow(true)}
                            data-test-id="publishedButtonTestID"
                        >
                            {languageData.Userslistings}
                        </Box>
                        <Box
                            className={showPublished ? classes.draftButton : classes.publishButton}
                            onClick={() => this.handlePublishedShow(false)}
                            data-test-id="draftButtonTestID"
                        >
                            {languageData.Mylistings}
                        </Box>
                    </>
                    }
                </Box>
                {showPublished &&
                    <Box style={webStyle.countWrapper}>
                        {[
                            {
                                icon: <img src={acitveIcon} alt="activeIcon" width="24px" />,
                                title: languageData.Active,
                                value: "14"
                            },
                            {
                                icon: <DoneOutlineRoundedIcon style={{ color: '#1D4ED8' }} />,
                                title: languageData.Completed,
                                value: "2"
                            },
                            {
                                icon: <img src={rejectIcon} alt="activeIcon" width="24px" />,
                                title: languageData.Rejected,
                                value: "5"
                            },
                            {
                                icon: <img src={pendingIcon} alt="activeIcon" width="24px" />,
                                title: languageData.Pending,
                                value: "12"
                            }
                        ].map((item, index) =>
                            <Box key={index} style={webStyle.countBox}>
                                {item.icon}
                                <Typography style={webStyle.countTitle}>{item.title}</Typography>
                                <Typography style={webStyle.countValue}>{item.value}</Typography>
                            </Box>
                        )}
                    </Box>
                }
                <TableBoxWrapper data-test-id="tableTestID" style={webStyle.tableBox}>
                    {this.renderTable()}
                </TableBoxWrapper>
            </Box>
        );
    };

    renderMenu = () => {
        const { currentLanguage, showPublished, userType } = this.state
        const languageData = languageTranslationData[currentLanguage];
        return (
            <Menu
                open={Boolean(this.state.publishAnchorEl)}
                id="basic-menu"
                data-test-id="publishMenuTestID"
                anchorEl={this.state.publishAnchorEl}
                onClose={this.handleMenuClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        overflow: 'visible',
                        boxShadow: '0px 0px 8px 4px #00000008',
                        width: "160px",
                        borderRadius: "8px",
                        border: "1px solid #E2E8F0"
                    }
                }}
            >
                {userType === "consumer" ?
                    <PublisMenuBox>
                        {showPublished ? 
                            <>
                                <Typography
                                    onClick={this.handleDetailPreviewShow}
                                    data-test-id="viewDetailButtonTestID"
                                >
                                    {languageData.ViewDetails}
                                </Typography>
                                <Typography>{languageData.Deactivate}</Typography>
                            </>:
                            <>
                                <Typography>{languageData.Publish}</Typography>
                            </>
                        }
                        <Box>
                            <DeleteOutlineRoundedIcon style={{ width: "18px", color: "#DC2626" }} />
                            <Typography style={{ color: "#DC2626", fontSize: "14px" }}>{languageData.Remove}</Typography>
                        </Box>
                    </PublisMenuBox> :
                    <PublisMenuBox>
                        <Typography
                            onClick={this.handleDetailPreviewShow}
                        >
                            {languageData.ViewDetails}
                        </Typography>
                        <Box>
                            <DeleteOutlineRoundedIcon style={{ width: "18px", color: "#DC2626" }} />
                            <Typography style={{ color: "#DC2626", fontSize: "14px" }}>
                                {languageData.Delete}
                            </Typography>
                        </Box>
                    </PublisMenuBox>
                }
            </Menu>
        );
    };

    renderFilter = () => {
        const { currentLanguage } = this.state
        const languageData = languageTranslationData[currentLanguage];
        return (
            <Box style={webStyle.filterWrapper}>
                <Box style={webStyle.filterCloseBox}>
                    <Box style={webStyle.filterHeadingBox}>
                        <FilterListRoundedIcon style={{ width: "20px" }} />
                        {languageData.Filters}
                    </Box>
                    <IconButton data-test-id="filterCloseTestID" onClick={this.handleCloseFilter}>
                        <CloseRoundedIcon style={{ width: "20px", color: "#0F172A" }} />
                    </IconButton>
                </Box>
                <Box style={webStyle.filterMiddleBox}>
                    <Box>
                        <FilterResetWrapper>
                            <Button>{languageData.Reset}</Button>
                        </FilterResetWrapper>
                        <Box style={{ display: "flex", gap: "20px" }}>
                            <Box>
                                <Typography style={webStyle.filterLabel}>{languageData.From}</Typography>
                                <FilterDateField
                                    type="date"
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <Typography style={webStyle.filterLabel}>{languageData.To}</Typography>
                                <FilterDateField
                                    type="date"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <FilterResetWrapper>
                            <Button
                                data-test-id="listingResetTestID"
                                onClick={() => this.handleSelectReset("listingType")}
                            >
                                {languageData.Reset}
                            </Button>
                        </FilterResetWrapper>
                        <Typography style={{ ...webStyle.filterLabel, marginBottom: "4px" }}>{languageData.ListingType}</Typography>
                        <FormControl fullWidth>
                            <FilterInputLabel shrink={false} style={{ display: this.state.listingType ? "none" : "block", lineHeight: "0.5" }}>
                                {languageData.ListingType}
                            </FilterInputLabel>
                            <FilterSelect
                                fullWidth
                                data-test-id="listTypeTestID"
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                variant="outlined"
                                value={this.state.listingType}
                                name="listingType"
                                onChange={(event) => this.handleSelect(event, "listingType")}
                            >
                                <MenuItem value={"option 1"}>option 1</MenuItem>
                                <MenuItem value={"option 2"}>option 2</MenuItem>
                            </FilterSelect>
                        </FormControl>
                    </Box>
                    <Box>
                        <FilterResetWrapper>
                            <Button
                                data-test-id="statusResetTestID"
                                onClick={() => this.handleSelectReset("status")}
                            >
                                {languageData.Reset}
                            </Button>
                        </FilterResetWrapper>
                        <Typography style={{ ...webStyle.filterLabel, marginBottom: "4px" }}>{languageData.tableHeading.Status}</Typography>
                        <FormControl fullWidth>
                            <FilterInputLabel shrink={ false } style={{ display: this.state.status ? "none" : "block", lineHeight: "0.5" }}>
                                {languageData.tableHeading.Status}
                            </FilterInputLabel>
                            <FilterSelect
                                fullWidth
                                data-test-id="statusTestID"
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                variant="outlined"
                                value={this.state.status}
                                name="status"
                                onChange={(event) => this.handleSelect(event, "status")}
                            >
                                <MenuItem value={"option 1"}>option 1</MenuItem>
                                <MenuItem value={"option 2"}>option 2</MenuItem>
                            </FilterSelect>
                        </FormControl>
                    </Box>
                </Box>
                <Box style={webStyle.filterBottomBox}>
                    <ResetButton
                        data-test-id="filterResetButtonTestID"
                        onClick={this.handleFilterReset}
                    >
                        {languageData.Reset}
                    </ResetButton>
                    <CreateButton
                        data-test-id="applyNowTestID"
                        style={{ fontSize: "14px", height: "44px" }}
                        onClick={this.handleApplyNow}
                    >
                        {languageData.Applynow}
                    </CreateButton>
                </Box>
            </Box>
        );
    };

    renderModCreateLis = () => {
        const { modCreatSelect, currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage].modalText
        return (
            <Box style={{
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "12px",
                    padding: "0 32px",
                    marginBottom: "32px"
                }}
            >
                {[
                    {text: languageData.createOwn, select: "own"},
                    {text: languageData.createBehalf, select: "behalf"}
                ].map((value, index) => (
                    <Box
                        key={index}
                        style={{
                            ...webStyle.modalSelectItem,
                            border:
                                modCreatSelect === value.select
                                ? "1px solid #3B82F6"
                                : "1px solid #E2E8F0",
                            background:
                                modCreatSelect === value.select ? "#EFF6FF" : "white",
                        }}
                        data-test-id={`${value.select}ButtonTestID`}
                        onClick={()=>this.handleModalCreate(value.select)}
                    >
                        <Box
                            style={{ display: "flex", alignItems: "center", gap: "16px" }}
                        >
                            <span
                                style={{
                                display: "block",
                                width: "20px",
                                height: "20px",
                                borderRadius: "25px",
                                border:
                                    modCreatSelect === value.select
                                    ? "6px solid #1D4ED8"
                                    : "1px solid #64748B",
                                    boxSizing: "border-box",
                                }}
                            ></span>
                            <Typography 
                                style={{color: "#0F172A", fontWeight: 700}}
                            >
                                {value.text}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        );
    };

    renderModUserList = () => {
        const { selectModUser, currentLanguage } = this.state;
        const languageDataConfig = configJSON.userDashboardLanguage[currentLanguage];
        return (
            <Box>
                <Box style={{...webStyle.searchFieldWrapper, margin: "0 32px 12px", maxWidth: "100%"}}>
                    <SearchIcon style={webStyle.searchIcon} />
                    <SearchTextField
                        data-test-id="searchInputTestID"
                        fullWidth
                        variant="outlined"
                        placeholder={`${languageDataConfig.search}...`}
                    />
                </Box>
                <Box style={{maxHeight: "350px", overflow: "auto"}}>
                    {[
                        {id: "1", name: "Michael Thompson", email: "michael.thompson@debtagency.com"},
                        {id: "2", name: "Sarah Collins", email: "sarah.collins@debtagency.com"},
                        {id: "3", name: "Sarah Collins", email: "sarah.collins@debtagency.com"},
                        {id: "4", name: "James Carter", email: "james.carter@example.com"},
                        {id: "5", name: "Michael Thompson", email: "michael.thompson@debtagency.com"},
                        {id: "6", name: "Emily Davis", email: "emily.davis@debtagency.com"}
                    ].map((value, index) =>
                        <ModUserBox 
                            key={index}
                            style={{
                                background: selectModUser === value.id? "#EFF6FF": "white"                               
                            }}
                            data-test-id={`modList${index}ButtonTestID`}
                            onClick={()=>this.handleModalList(value.id)}
                        >
                            <Box>
                                <Avatar alt="profile_img" style={{width: "40px", height: "40px"}}/>
                                <Typography>
                                    {value.name}
                                </Typography>
                            </Box>
                            <Typography style={{marginTop: "8px"}}>
                                {value.email}
                            </Typography>
                        </ModUserBox>
                    )}
                </Box>
                
            </Box>
        );
    };

    renderAcceptModal = () => {
        const {showModUserList} = this.state;
        const languageData = languageTranslationData[this.state.currentLanguage]
        const languageModal = languageData.modalText
        return (
            <Modal 
                data-test-id="modalTestID"
                open={this.state.showModal}
                onClose={this.handleCloseModal}
            >
                <Box style={webStyle.modalBox}>
                    <Box style={webStyle.modalBody}>
                        <Box style={{padding: "32px 0 0", borderBottom: "1px solid #E2E8F0"}}>
                            <Box style={webStyle.modCloseBox}>
                                <Typography style={{color: "#0F172A", fontSize: "20px", fontWeight: 700}}>
                                    {showModUserList?
                                        languageModal.headingTwo:
                                        languageModal.headingOne
                                    }
                                </Typography>
                                <IconButton
                                    data-test-id="modalCloseIconButton"
                                    onClick={this.handleCloseModal}
                                    style={{position: "relative", bottom: "18px"}}
                                >
                                    <CloseRoundedIcon
                                        style={{ width: "24px", color: "#0F172A" }}
                                    />
                                </IconButton>
                            </Box>
                            { showModUserList?
                                this.renderModUserList():
                                this.renderModCreateLis()
                            }
                        </Box>
                        <Box style={{display: "flex", gap: "20px", padding: "32px"}}>
                            <ResetButton 
                                style={{width: "240px", height: "56px"}}
                                onClick={this.handleCloseModal}
                            >
                                {languageData.Cancel}
                            </ResetButton>
                            <CreateButton 
                                data-test-id="modalOkButtonTestID"
                                style={{width: "248px"}}
                                onClick={this.handleModalOkButton}
                            >
                                {showModUserList? languageModal.Selectuser: languageModal.Continue}
                            </CreateButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <UserProfileDrawer
                data-test-id="sideDrawerTestID"
                handleClick={this.handleDrawer}
                pageName={this.state.userType==="consumer"? "UserMyListing" : "dashboardListing"}
                breadScrumArray={this.state.breadscrumarray}
                userType = {this.state.userType==="consumer"? "consumer" : "admin_account"}
                navigation={this.props.navigation}
            >
                <Box>
                    <Box style={{ marginBottom: "16px" }}>
                        <Typography style={webStyle.pageHeadingText}>
                            {this.state.pageHeadingText}
                        </Typography>
                    </Box>
                    {this.state.showDetailPreview ?
                        this.renderListingDetail() :
                        <>
                            {
                                this.state.isListingAvailable ?
                                    this.renderListing() :
                                    this.renderListingBlank()
                            }
                        </>
                    }
                </Box>
                {this.renderAcceptModal()}
                {this.renderMenu()}
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

export default withStyles(styles)(AllListing);

// Customizable Area Start

const TableBoxWrapper = styled(Box)({
    "@media(max-width: 1250px)": {
        width: "850px"
    },
    "@media(max-width: 1200px)": {
        width: "800px"
    },
    "@media(max-width: 1150px)": {
        width: "750px"
    },
    "@media(max-width: 1100px)": {
        width: "700px"
    },
    "@media(max-width: 1050px)": {
        width: "650px"
    },
    "@media(max-width: 1000px)": {
        width: "600px"
    },
    "@media(max-width: 990px)": {
        width: "auto"
    }
});

const ModUserBox = styled(Box)({
    padding: "13px 32px",
    color: "#1E293B",
    fontSize: "12px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#EFF6FF !important"
    },
    "& .MuiBox-root": {
        display: "flex", 
        gap: "15px", 
        alignItems: "center"
    }
});

const PaginationStyle = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        fontSize: "1rem",
        minWidth: "32px",
        height: "32px",
        color: "#0F172A",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "@media(max-width: 421px)": {
            margin: "0px"
        },
        "&:hover": {
            backgroundColor: "#f0f0f0"
        }
    },
    "& .Mui-selected": {
        color: "blue !important",
        fontWeight: 700,
        backgroundColor: "transparent !important",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#f0f0f0 !important"
        }
    },
    "& .MuiPaginationItem-ellipsis": {
        fontSize: "1.2rem",
        textDecoration: "none"
    }
});

const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width:"100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});

const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "44px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const FilterResetWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
        textTransform: "capitalize",
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700
    }
});

const FilterSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    height: "44px",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});

const FilterInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    top: "-5px",
    "&.Mui-focused": {
        color: "#94A3B8",
        display: "none"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const PublisMenuBox = styled(Box)({
    padding: "0 4px",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    "& .MuiTypography-root": {
        padding: "4px 10px",
        cursor: "pointer",
        color: "#0F172A",
        fontSize: "14px",
        borderRadius: "8px",
        "&:hover": {
            background: "#EFF6FF"
        }
    },
    "& .MuiBox-root": {
        padding: "0 6px",
        cursor: "pointer",
        display: "flex",
        borderRadius: "8px",
        "&:hover": {
            background: "#EFF6FF"
        }
    }
});

const SearchTextField = styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const FilterDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            top: "0px"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        top: "0px",
        "&.Mui-disabled": {
            WebkitTextFillColor: "#0F172A"
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const FilterButton = styled(Button)({
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content"
});

const CreateButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#475569",
        fontWeight: 500,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});

const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#475569",
        fontSize: "14px"
    }
});

const TableUpBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    gap: "20px",
    flexWrap: "wrap"
});

const webStyle = {
    modalSelectItem: {
        minWidth: "350px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 20px",
        borderRadius: "8px",
        border: "1px solid #3B82F6",
        cursor: "pointer"
    },
    modalBody: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008",
        maxWidth: "580px"
    },
    modCloseBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:  "center",
        marginBottom: "25px",
        padding: "0 32px"
    },
    modalBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
    filterHeadingBox: {
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        gap: "8px"
    },
    filterLabel: {
        fontWeight: 700,
        fontSize: "14px",
        color: "#334155"
    },
    filterBottomBox: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        padding: "20px"
    },
    filterMiddleBox: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1"
    } as React.CSSProperties,
    filterCloseBox: {
        padding: "5px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center"
    },
    filterWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        position: "absolute",
        top: "56px",
        background: "white",
        zIndex: 10,
        right: "0px"
    } as React.CSSProperties,
    edit: {
        fontWeight: 700,
        color: "#1E3A8A",
        fontSize: "16px"
    },
    iconEdit: {
        width: "15px",
        color: "#1E3A8A"
    },
    editMainWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer"
    },
    mainListingBox: {
        display: "flex",
        flexDirection: "column",
        gap: "28px",
        marginTop: "25px"
    } as React.CSSProperties,
    tableConStyle: {
        boxShadow: "none",
        width: "100%",
        overflow: "auto",
        borderRadius: "16px",
        border: "1px solid #E2E8F0"
    },
    tableBox: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px 4px #00000008",
        marginTop: "8px",
        background: "white"
    },
    moreIconStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyling: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#D1FAE5",
        color: "#059669",
        textTransform: "uppercase"
    } as React.CSSProperties,
    searchFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px",
        maxWidth: "400px"
    },
    searchIcon: {
        color: "#94A3B8"
    },
    countWrapper: {
        display: "flex",
        gap: "15px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    countBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderRadius: "20px",
        border: "1px solid #E2E8F0",
        padding: "20px",
        width: "160px",
        background: "white"
    } as React.CSSProperties,
    countTitle: {
        fontSize: "18px",
        fontWeight: 500,
        color: "#94A3B8",
        marginTop: "18px"
    },
    countValue: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    createButton: {
        display: 'flex',
        gap: "10px"
    },
    pageHeadingText: {
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F172A"
    },
    blankMainBox: {
        height: "460px",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    },
    blankBodyWrapper: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        gap: "20px"
    } as React.CSSProperties,
    blankParagraph: {
        textAlign: 'center',
        fontSize: "18px",
        fontWeight: 500
    } as React.CSSProperties,
}
// Customizable Area End