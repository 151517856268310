import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button
} from "@material-ui/core";
import ContactusController, { Props,configJSON } from "./ContactusController";
import { applicationLogo, bgImageLeft, bgImageRight } from "./assets";
import CustomImage from "../../../../packages/components/src/design-system/Image/Image";
import Text from "../../../../packages/components/src/design-system/Text";
import TextInput from "../../../../packages/components/src/design-system/TextInput";
const languageTranslation = require('../../languagesupport/src/LanguageData/ContactUs.json')
// Customizable Area End
export default class Contactus extends ContactusController {

  constructor(props: Props) {
    super(props);
     // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
// Customizable Area End

  render() {
  // Customizable Area Start
  const {currentLanguage} = this.state
  const isArabic = currentLanguage === configJSON.AR;
  const languageData = languageTranslation[currentLanguage]
  return (<ContactPageContainer>
        <Box className="leftContainerAuto">
          <Box className="leftContainer">
            {/* <img src={bgImageLeft} className="leftBgImage" /> */}
            <Typography style={{ color: '#2563EB' }} variant="h6">{languageData.Contact}</Typography>
            <Typography variant="h5">{languageData.GetInTouch}</Typography>
            <Typography variant="body1">{languageData.ContactDescription}</Typography>
            <CustomImage src={applicationLogo} alt="contactimage" className="contactPageImg" />
          </Box>
          <Box className="rightContainer">
              <Box style={{marginBottom:"20px",marginTop:"20px"}}>
                <Text size="xl" weight="lightBold" align={isArabic?"right":'left'} family="poppins" variant="primary">{languageData.GetConnectedWithUs}</Text>
              </Box>
            <TextInput
              isArabic={isArabic}
              placeholder={languageData.Name}
              label={languageData.Name}
              type="text"
              mandatorySign="*"
              value={this.state.name}
              onChange={this.txtNameProps.onChangeText}
              error={this.state.errors.Name}
            />
            <TextInput
              isArabic={isArabic}
              data-testid="companyName"
              label={languageData.CompanyName}
              placeholder={languageData.CompanyName}
              type="text"
              mandatorySign="*"
              value={this.state.companyName}
              onChange={this.txtCompanyNameProps.onChangeText}
              error={this.state.errors.CompanyName}
            />
            <TextInput
              isArabic={isArabic}
              label={languageData.Email}
              placeholder={languageData.Email}
              type="email"
              mandatorySign="*"
              value={this.state.email}
              onChange={this.txtEmailProps.onChangeText}
              error={this.state.errors.Email}
            />
            <TextInput
              isArabic={isArabic}
              label={languageData.PhoneNumber}
              placeholder={languageData.PhoneNumber}
              type="text"
              mandatorySign="*"
              value={this.state.phoneNumber}
              onChange={this.txtPhoneNumberProps.onChangeText}   
              error={this.state.errors.PhoneNumber}      
            />
            <Box mt="20px">
              <TextInput
                isArabic={isArabic}
                placeholder={languageData.Message}
                data-testid="messageField"
                type="text"
                multiline
                minRows={4}
                value={this.state.comments}
                onChange={this.txtCommentsProps.onChangeText}   
                error={this.state.errors.comments}
              />
            </Box>
            <Box mt="20px">
              <Button
                className="submitBtn"
                variant="contained"
                fullWidth
                data-testid="submit-btn"
                onClick={this.btnSubmitProps.onPress}
              >
                {languageData.Submit}
              </Button>
            </Box>
            {/* <img src={bgImageRight} className="rightBgImage" /> */}
          </Box>
      </Box>
    </ContactPageContainer>
      //Merge Engine End DefaultContainer
    );
   // Customizable Area End 
  }
}

// Customizable Area Start

const ContactPageContainer = styled(Box)({
  
  height: "auto",
  padding: '90px 15px',
  boxSizing : "border-box",
  background: `url(${bgImageLeft}) no-repeat top left, url(${bgImageRight}) no-repeat top right`,
    "@media (max-width: 960px)" : {
        padding : "40px 20px",
        backgroundPosition: "bottom right",
    },
    "@media (max-width: 600px)" : {
        padding : "40px 20px",
        alignItems : "center",
        backgroundPosition: "bottom right",
    },
  "& .leftContainerAuto": {
    maxWidth: '1200px',
    margin: '0 auto',
    gap:"20px",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)" : {
      flexDirection : "column",
      justifyContent : "center"
    },
  },
  "& .leftBgImage": {
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1
  },
  "& .rightBgImage": {
    position: 'absolute',
    top: 0,
    right: 0,
    objectFit: "cover",
    zIndex: -1
  },
  "& .leftContainer": {
    position: 'relative',
    width: "100%",
    '& h5': {
      color: '#1E293B',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 600,
      marginBottom: '10px',
      '@media(max-width:767px)': {
        fontSize: '24px',
      }
    },
    '& h6': {
      color: '#1E293B',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      marginBottom: '24px',
    },
    '& p': {
      color: '#1E293B',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      marginBottom: '24px',
    }
  },
  "& .rightContainer": {
    position: 'relative',
    width: "60%",
    borderRadius: "28px",
    background: "#FFFFFF",
    padding: "32px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    border: "1px solid #F1F5F9",
    "@media (max-width: 960px)" : {
      width : "auto",
    },
    "& .makeStyles-label-9": {
      marginBottom: "10px"   
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px"
    },
    "& .MuiTypography-subtitle1": {
      marginTop: "15px"
    },
  },
  "& .messageBox": {
    marginTop: 20
  },
  "& .contactPageImg": {
    width: "80%",
    height: "75%",
    objectFit: "cover"
  },
  "& .submitBtn": {
    background: "#1E3A8A",
    color: '#fff',
    borderRadius: "8px",
    padding: 12,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 700,
    "&:hover": {
        background: "#1E3A8A",
        color: '#fff',    
    }
}
});

// Customizable Area End
