// Customizable Area Start

import React from "react";

import {
  Box,
  Typography,
  Grid,
  styled,
  Breadcrumbs,
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Notification.json')
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DocumentationFolderController, { Props, FolderFiles } from "./DocumentationFolderController";
import { Circle, FolderDefaultImage } from "./assets";
export default class DocumentationFolder extends DocumentationFolderController {
  constructor(props: Props) {
    super(props);
  }
  renderFolders = () => {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    return Array.from({ length: 9 }).map((_, index) => (
      <Grid item xs={4} key={index}>
        <FolderBox>
          <img style={webStyle.folderImg} src={FolderDefaultImage} />
          <Box style={webStyle.folderTextBox}>
            <Box sx={webStyle.folderTextWrapper}>
              <Typography style={webStyle.folderTitle}>{languageData.BusinessRegCertificate}</Typography>
              <Box style={webStyle.folderSizeBox}>
                <Typography style={webStyle.numberOfFiles}>{languageData.RegistrationDate}</Typography>
                <img src={Circle} />
                <Typography style={webStyle.numberOfFiles}>{languageData.FileSize}</Typography>
              </Box>
            </Box>
            <MoreVertIcon style={{ color: "#64748B" }} />
          </Box>

        </FolderBox>
      </Grid>
    ))
  }

  render() {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    return (
      <UserProfileDrawer
        handleClick={this.drawerNavigation}
        pageName={this.state.userType === "consumer" ? "" : "Documentation"}
        breadScrumArray={this.state.breadscrumValues}
        data-test-id="drawerTestID"
        userType={this.state.userType === "consumer" ? "consumer" : "admin_account"}>
        <MainBox>
          <Breadcrumbs separator="" style={webStyle.breadcrumBox}>
            <Typography style={webStyle.breadcrumText} onClick={() => this.props.navigation.navigate('Documentation')}>{languageData.Documents}</Typography>
            <ArrowForwardIosIcon style={webStyle.breadcrumIcon} />
            <Typography style={webStyle.breadcrumText}>{languageData.FolderName}</Typography>

          </Breadcrumbs>
          <Title>{languageData.FolderName}</Title>
          <Grid container spacing={2}>
            {this.renderFolders()}
          </Grid>

        </MainBox>
      </UserProfileDrawer>
    );
  }
}

const MainBox = styled(Box)({
  width: "100%",
  background: "#F8FAFC",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
const Title = styled(Typography)({
  color: "#0F172A",
  fontSize: "30px",
  fontWeight: 700,
  letterSpacing: "-0.5%"
});
const FolderBox = styled(Box)({
  width: "338.33px",
  height: "294px",
  borderRadius: "16px",
  border: "1px solid #E2E8F0",
  background: "#FFFFFF",
  overflow: "hidden",
  "@media (max-width: 1280px)": {
    width: "100%", 
  },

});
const webStyle = {
  breadcrumBox: {
    display: 'flex',
    alignItems: 'center',
    height: "30px",
    marginTop : "-24px"
  },
  breadcrumText: {
    color: "#1E293B",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
  },
  breadcrumIcon: {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 400,

  },
  folderTextBox: {
    width: "298.33px",
    height: "54px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  folderTextWrapper: {
    width: "257px",
    height: "54px",
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  },
  folderSizeBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  numberOfFiles: {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500
  },
  folderTitle: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 500
  },
  folderImg:{
    width : "100%",
    height :"200px"
  }
};

// Customizable Area End
