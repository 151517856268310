import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { bellIcon, editProfile, listIcon, sheetIcon } from "./assets";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentPage: string,
    breadCrumb: string[],
    menu: {
        icon: string,
        title: string,
        route: string,
        tabs: {
            title: string,
            route: string
        }[]
    }[];
    providerMenu: {
        icon: string,
        title: string,
        route: string,
        tabs: {
            title: string,
            route: string
        }[]
    }[];
    type: string,
    currentLanguage: 'en' | 'ar'
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserProfileWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentPage: '',
            type: "consumer",
            breadCrumb: [],
            currentLanguage: 'en',
            menu: this.Menu('en'),
            providerMenu: this.ProvideMenu('en')
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const currentURL = window.location.pathname;
        this.setState({
            menu: this.Menu(language),
            providerMenu: this.ProvideMenu(language),
            currentLanguage: language,
            currentPage: currentURL.split("/").map(urlString => urlString.charAt(0).toUpperCase() + urlString.slice(1)).join(""),
            breadCrumb: currentURL.split("/").filter(urlString => (urlString !== "" && urlString !== "User")),
            type: await getStorageData("userType")
        });
        // Customizable Area End
    }

    getArabicTitle = (title: string, currentLanguage: string) => {
        const titleWithoutSpaces = title.replace(/\s+/g, '');
        const languageData = languageTranslationData[currentLanguage];
        switch (titleWithoutSpaces) {
            case "Profile": return languageData.Profile;
            case "Notification": return languageData.Notification;
            case "Requests": return languageData.Requests;
            case "MyListing": return languageData.MyListing;
            case "CreateListing": return languageData.CreateListing;
            case "MyOffers": return languageData.MyOffers;
        }
    }
    // Customizable Area Start
    navigationTrack = [
        {
            title: "Profile",
            link: "UserProfile"
        },
        {
            title: "Notification",
            link: "UserNotification"
        },
        {
            title: "Requests",
            link: "UserRequests"
        },
        {
            title: "My Listing",
            link: "UserMyListing"
        },
        {
            title: "Create Listing",
            link: "UserCreateListing"
        },
        {
            title: "My Offers",
            link: "UserMyOffers"
        }
    ];

    Menu = (language: string) => [
        {
            icon: editProfile,
            tabs: [],
            title: languageTranslationData[language].Profile,
            route: "UserProfile"
        },
        {
            icon: bellIcon,
            tabs: [],
            title: languageTranslationData[language].Notification,
            route: "UserNotification"
        },
        {
            icon: sheetIcon,
            tabs: [],
            title: languageTranslationData[language].Requests,
            route: "UserRequests"
        }
    ]

    ProvideMenu = (language: string) => [
        {
            icon: listIcon,
            title: languageTranslationData[language].MyListing,
            route: "UserMyListing",
            tabs: [
                {
                    title: languageTranslationData[language].CreateListing,
                    route: "UserCreateListing"
                },
                {
                    title: languageTranslationData[language].MyOffers,
                    route: "UserMyOffers"
                }
            ]
        }
    ];

    doExpand = (urlString: string): boolean => {
        return (urlString === "UserMyListing" || urlString === "UserCreateListing" || urlString === "UserMyOffers")
    };

    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    handleLogout = async () => {
        await removeStorageData("accessToken")
        await removeStorageData("profilePic")
        await removeStorageData("userType")
        this.handleNavigation("Home")
    }

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };
    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    }
    // Customizable Area End
}
