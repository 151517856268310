// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import Text from '../../../components/src/design-system/Text';
import { createTheme } from "@material-ui/core/styles";
import ForgotPasswordController, { Props } from "./ResetForgotPasswordController.web";
import { LoginFlow } from "../../../components/src/LoginFlow.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')
// Customizable Area End

// Customizable Area Start
const classes = {
  root: {
    height: '100%',
    boxSizing: 'border-box',
    padding: '44px 100px',
    "@media (max-width: 960px)": {
        padding: "40px 20px"

    },
    "@media (max-width: 600px)": {
        padding: "40px 20px",
        borderRadius: "10px 10px 0px 0px"
    },
  },

  linkText: {
    cursor: 'pointer',
    marginLeft: 5,
    color: '#1E3A8A',
    "&:hover": {
      textDecoration: 'underline'
    },
    }
}

// Customizable Area End

export default class NewPassword extends ForgotPasswordController{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const {currentLanguage} = this.state;
    const languageData = languageTranslationData[currentLanguage];
    return (
      <LoginFlow>
        <Box sx={classes.root}>
          <Box mb={2}>
            <Text size="xm" weight="extraBold">{languageData.PasswordResetSuccessful}</Text>
          </Box>
          <Box mb={3}>
            <Text size="sm" weight="minBold" family="inter">{languageData.SuccessMsg}</Text>
          </Box>
          <Box mt={3} mb={0} style={{ display: 'flex', justifyContent: 'end' }}>
            <Box sx={classes.linkText} id="login-test" onClick={() => this.handleRouting('SellerLogin')}>
              <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Login}</Text>
            </Box>
          </Box>
        </Box>
      </LoginFlow>
    );
    // Customizable Area End
  }

// Customizable Area Start
theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  // Customizable Area End
}
