// Customizable Area Start

import React from "react";
import {
  Box,
  styled,
  Button,
  Typography,
  IconButton,
  Paper,
  TextField,
  TableContainer,
  Menu,
  MenuItem,
  Avatar,
  Modal,
  Divider
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Notification.json')
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from "@material-ui/lab";
import TablePagination from "../../../components/src/design-system/Pagination.web";
import DocumentationController, {
  Props
} from "./DocumentationController";
import { Circle, Delete, DocUpload, Dot, FileType, FolderImage, TableImage, UploadDocument } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
export default class Documentation extends DocumentationController {
  constructor(props: Props) {
    super(props);
  }

  renderFolder = () => {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage];
    return (
      <Folder onClick={() => this.props.navigation.navigate('DocumentationFolder')}>
        <img style={webStyle.filderImage} src={FolderImage} />
        <Typography style={webStyle.folderTitle}>{languageData.FolderName}</Typography>
        <Box style={webStyle.folderSizeBox}>
          <Typography style={webStyle.numberOfFiles}>{languageData.NumberOfFiles}</Typography>
          <img src={Circle} />
          <Typography style={webStyle.numberOfFiles}>{languageData.FileSize}</Typography>
        </Box>
      </Folder>
    )
  }
  renderCardsData = () => {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    return (

      <CustomCard>
        <img src={FileType} />
        <Box style={webStyle.documentInfo}>
          <Typography style={webStyle.documentTitle1}>
            {languageData.Filename}
          </Typography>
          <Typography style={webStyle.documentSize}>
            {languageData.Fileunit}
          </Typography>
        </Box>
        <IconButton>
          <img src={Dot} />
        </IconButton>
      </CustomCard>

    )
  }


  render() {
    const { currentLanguage, dot } = this.state;
    const languageData = languageTranslationData[currentLanguage];
    return (
      <>
        <UserProfileDrawer
          handleClick={this.documentDrawerNavigation}
          pageName={this.state.userType === "consumer" ? "" : "Documentation"}
          breadScrumArray={this.state.breadscrumValues}
          data-test-id="drawerTestID"
          userType={this.state.userType === "consumer" ? "consumer" : "admin_account"}>
          <MainBox>
            <Typography style={webStyle.documentHeading}>{languageData.DocumentTitle}</Typography>
            <Box style={webStyle.searchMainBox}>

              <Box style={webStyle.searchFieldWrapper}>
                <SearchIcon style={webStyle.searchIcon} />
                <SearchTextField
                  id="blankInput"
                  data-test-id="searchInputTestID"
                  fullWidth
                  variant="outlined"
                  placeholder={languageData.Search}
                />
              </Box>

              <Box style={webStyle.buttonMainBox}>
                <FilterButton
                  style={{ height: "44px" }}
                  data-test-id="filterButtonTestID"
                >
                  <Box style={webStyle.createButton}>
                    <FilterListRoundedIcon />
                    {languageData.Filters}
                  </Box>
                </FilterButton>

                <FileButton
                  data-test-id="add-folder-modal-button"
                  variant="contained"
                  startIcon={<AddIcon />}>
                  {languageData.AddNewFolder}
                </FileButton>

                <FileButton
                  data-test-id="upload-files"
                  onClick={this.handleModalOpen}
                  variant="contained"
                  startIcon={<img src={DocUpload} />}>
                  {languageData.UploadNewDoc}
                </FileButton>
              </Box>

            </Box>
            <Box sx={webStyle.filterMainBox}>
              <Typography style={webStyle.foldersTitle}>{languageData.Folders}</Typography>
              <Box style={webStyle.foldersBox}>
                {this.renderFolder()}
                {this.renderFolder()}
                {this.renderFolder()}

              </Box>
            </Box>

            <Box sx={webStyle.filterMainBox1}>
              <Typography style={webStyle.foldersTitle}>{languageData.Recent}</Typography>
              <Box style={webStyle.foldersBox2}>
                {this.renderCardsData()}
                {this.renderCardsData()}
                {this.renderCardsData()}
              </Box>
            </Box>

            <Box sx={webStyle.filterMainBox2}>
              <Typography style={webStyle.foldersTitle}>{languageData.AllFiles}</Typography>
              <Box style={webStyle.tableMainBox}>
                <TableContainer style={webStyle.tableContainer} component={Paper}>
                  <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <CustomHeadingRow>
                        <TableCell></TableCell>
                        <TableCell>{languageData.DocName}</TableCell>
                        <TableCell>{languageData.Type}</TableCell>
                        <TableCell>{languageData.UploadDate}</TableCell>
                        <TableCell></TableCell>
                      </CustomHeadingRow>
                    </TableHead>
                    {
                      this.state.loading ?
                        <TableBody>
                          <TableRow style={{ verticalAlign: "text-top" }}>
                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                            <TabelBodyCell><Skeleton /></TabelBodyCell>

                          </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                          {this.state.docData[currentLanguage].map((item, index) => (
                            <CustomBodyRow key={index}>
                              <TableCell>
                                <UserAvatar src={TableImage} alt="user" /></TableCell>
                              <TableCell>{item[0]}</TableCell>
                              <TableCell>{item[1]}</TableCell>
                              <TableCell>{item[2]}</TableCell>
                              <TableCell>
                                <IconButton onClick={this.handleClick} data-test-id="publishedMoreButtonTestID">
                                  <MoreVertIcon style={webStyle.moreOpStyle} />
                                </IconButton>
                                <Menu
                                  data-test-id="menu-modal"
                                  id="menu-view"
                                  anchorEl={dot}
                                  keepMounted
                                  open={Boolean(dot)}
                                  onClose={this.handleClose}
                                  MenuListProps={{
                                    style: { 
                                      padding: 4,  
                                    },
                                  }}
                                  PaperProps={{
                                    style: {
                                      height: "82px",  
                                      width: "159px",
                                      boxShadow: "0px 8px 32px 0px #0000000F",
                                    },
                                  }}>
                                  <MenuItem id="menu-view" style={webStyle.viewButton}>{languageData.View}</MenuItem>
                                  <MenuItem data-test-id="menu-delete-button" id="menu-archive" style={webStyle.deleteButton}><img style={webStyle.deleteButtonIcon} src={Delete} />{languageData.Delete}</MenuItem>
                                </Menu>
                              </TableCell>
                            </CustomBodyRow>
                          ))}
                        </TableBody>
                    }
                  </Table>
                </TableContainer>
                <Box
                  style={webStyle.mainResultBox}
                >
                  <Box
                    style={webStyle.paginationBox}
                  >
                    <TablePagination
                      data-test-id="paginationTestID"
                      page={this.state.currentPage}
                      count={8}
                      handlePageChange={this.handlePageChange}
                    />
                  </Box>
                  <CustomResultBox>
                    <Typography style={{ color: "#64748B", fontSize: "12px" }}>
                      {languageData.totalresuls}
                    </Typography>
                  </CustomResultBox>
                </Box>

              </Box>

            </Box>
          </MainBox>

          <StyledModal
            open={this.state.isModalOpen}
            onClose={this.handleModalClose}
            closeAfterTransition
            BackdropProps={{ timeout: 500 }}
          >
            <ModalContent>
              <Box sx={webStyle.modalBox}>
                <Box style={webStyle.modalWrapperBox}>
                  <Typography style={webStyle.modalTitle}>
                    {languageData.UploadingNewDoc}
                  </Typography>
                  <CloseIcon style={{ cursor: "pointer" }} onClick={this.handleModalClose} />
                </Box>
                <PaperBox elevation={1}>
                  <IconButton color="primary" component="span" style={{ fontSize: 40 }}>
                    <img src={UploadDocument} style={webStyle.uploadImagestyle} />
                  </IconButton>
                  <Typography style={webStyle.uploadImagelable}>
                    {languageData.uploadImageLable}
                  </Typography>
                  <FileTypeText gutterBottom>
                    {languageData.uploadImageFileFormat}
                  </FileTypeText>
                  <ChoseFileButton variant='text'>
                    {languageData.choseFileButtonText}
                    <input type="file" />
                  </ChoseFileButton>
                </PaperBox>
                <StyledDivider />
                <ModalButtonWrapper>
                  <ModalCancelButton onClick={this.handleModalClose} variant="contained">
                    {languageData.Cancel}
                  </ModalCancelButton>
                  <ModalConfirmButton variant="contained">
                    {languageData.Confirm}
                  </ModalConfirmButton>
                </ModalButtonWrapper>
              </Box>
            </ModalContent>
          </StyledModal>
        </UserProfileDrawer>
      </>
    );
  }
}


const MainBox = styled(Box)({
  width: "100%",
  height: "100%",
  background: "#F8FAFC",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  "@media (max-width: 1280px)": {
    width: "950px",
    gap: "16px",
  },
});
const FileButton = styled(Button)({
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#1E3A8A',
  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  textTransform: "capitalize",
  cursor: "pointer",
  whiteSpace: "nowrap",
  '&:hover': {
    backgroundColor: '#1E3A8A',
    color: '#FFFFFF',
  },
});
const FilterButton = styled(Button)({
  padding: "10px 16px",
  borderRadius: "8px",
  display: "flex",
  border: "1px solid #CBD5E1",
  gap: "8px",
  alignItems: "center",
  textTransform: "capitalize",
  cursor: "pointer",

});
const SearchTextField = styled(TextField)({
  flexGrow: 1,
  maxWidth: "320px",
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    },
    "&:focus-within": {
      "& fieldset": {
        border: "none !important",
        boxShadow: "none !important"
      }
    }
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1
  }
});
const Folder = styled(Box)({
  height: "128px",
  width: "222px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  borderRadius: "20px",
  border: "1px solid #E2E8F0",
  background: "#FFFFFF",
  padding: "20px",
  cursor: "pointer",
});
const CustomCard = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  gap: "16px",
  boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
  borderRadius: "8px",
  padding: "8px 4px 8px 8px",
  width: "368px",
  height: "60px",
  "@media (max-width: 1280px)": {
    width: "288px",
  },
}));
const CustomHeadingRow = styled(TableRow)({
  background: "#E2E8F0",
  "& .MuiTableCell-root": {
    color: "#64748B",
    fontWeight: 600,
    fontSize: "14px",
    padding: "20px 16px 8px",
    whiteSpace: "nowrap"
  }
});
const TabelBodyCell = styled(TableCell)({
  padding: "26px 32px",
  fontSize: "14px",
  fontWeight: 600,
  color: "#252733"
})
const CustomBodyRow = styled(TableRow)({
  height: "54px",
  "& .MuiTableCell-root": {
    fontSize: "12px"
  }
});
const UserAvatar = styled(Avatar)({
  height: "34px",
  width: "34px",
  border: "1ox solid #D9D9D9",
  borderRadius: "8px",

});
const CustomResultBox = styled(Box)({
  "@media(max-width: 540px)": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
});
const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
const ModalContent = styled(Box)({
  position: 'absolute',
  left: "440px",
  boxShadow: "0px 8px 32px 0px #0000000F",
  borderRadius: '20px',
  background: "#FFFFFF",
  width: '560px',
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
const PaperBox = styled(Paper)({
  width: "496px",
  height: "206px",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: "center",
  border: '1px dashed #CBD5E1',

});
const FileTypeText = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  color: '#64748B',
  textAlign: 'center'

});
const ChoseFileButton = styled(Button)({
  backgroundColor: '#EFF6FF',
  fontSize: '16px',
  fontWeight: 700,
  color: '#0F172A',
  textTransform: 'none',
  height: '44px',
  marginTop: '8px',
  borderRadius: '8px',
  '& input[type="file"]': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },

});
const ModalButtonWrapper = styled(Box)({
  width: "496px",
  display: "flex",
  gap: "16px",
  alignItems: 'center',
  marginBottom: "64px"

});
const ModalCancelButton = styled(Button)({
  width: "248px",
  height: "56px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: '8px',
  backgroundColor: '#EFF6FF',
  fontSize: '16px',
  fontWeight: 700,
  color: '#0F172A',
  textTransform: 'none'

});
const ModalConfirmButton = styled(Button)({
  backgroundColor: '#1E3A8A',
  width: "248px",
  height: "56px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: '8px',
  fontSize: '16px',
  color: '#FFFFFF',
  textTransform: 'none',
  fontWeight: 700,

});
const StyledDivider = styled(Divider)({
  background: '#CBD5E1',
});
const webStyle = {
  documentHeading: {
    color: "#0F172A",
    fontSize: "30px",
    fontWeight: 700,
  },
  searchMainBox: {
    height: "44px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonMainBox: {
    gap: "12px",
    display: "flex",
  },
  createButton: {
    display: 'flex',
    gap: "10px"
  },
  searchFieldWrapper: {
    display: "flex",
    flexGrow: 1,
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    alignItems: "center",
    height: "24px",
    maxWidth: "314px",
    padding: "10px 8px"
  },
  searchIcon: {
    marginLeft: '3.48px',
    color: "#94A3B8"

  },
  filterMainBox: {
    width: "818px",
    height: "210px",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  filterMainBox1: {
    width: "1047px",
    height: "114px",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  filterMainBox2: {
    width: "100%",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  foldersTitle: {
    color: "#0F172A",
    fontSize: "18px",
    fontWeight: 600,
  },
  foldersBox: {
    height: "168px",
    width: "818px",
    display: "flex",
    gap: "16px",
  },
  foldersBox2: {
    height: "168px",
    width: "100%",
    display: "flex",
    gap: "16px",
  },
  folderSizeBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  filderImage: {
    height: "50px",
    width: "56px"
  },
  folderTitle: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 500
  },
  numberOfFiles: {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500
  },
  documentInfo: {
    flex: "1 0 auto",
    marginLeft: "4px"
  },
  documentInfoSide: {
    marginLeft: "4px",
    flex: "1 0 auto",
  },
  documentTitle1: {
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "16px",
  },
  documentSize: {
    fontWeight: 400,
    color: "#64748B",
    fontSize: "16px",
  },
  moreOpStyle: {
    color: "#64748B",
    cursor: "pointer"
  },
  mainResultBox: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "16px",
    paddingRight: "20px",
  } as React.CSSProperties,

  paginationBox: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center"
  },
  tableMainBox: {
    background: "#FFFFFF",
    width: "100%",
    borderRadius: "8px",
    margin: "0px 0 80px 0",
    border: "1px solid #E2E8F0"
  },
  tableContainer: {
    boxShadow: "none",
    width: "100%",
    overflow: "auto"

  },
  viewButton: {
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400

  },
  deleteButton: {
    color: "#DC2626",
    fontSize: "14px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  deleteButtonIcon: {
    height: "13.5px",
    width: "10.5px"

  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: '700',
    color: '#0F172A',

  },
  modalBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: "center"
  },
  modalWrapperBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "496px",
    marginTop: "64px",
  },
  uploadFileSectionGridText: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155',
  },
  uploadImagestyle: {
    height: "20.77",
    width: "21.33px"
  },
  uploadImagelable: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A'
  },

};

// Customizable Area End
