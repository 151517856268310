import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { cursorTo } from 'readline';
type TextAlign = "left" | "center" | "right" | "justify" | "end" | "unset";
type FontFamily = "inter" | "poppins" | "montserrat";

interface TextProps {
  size?: "xxs"|"xs" |'sm' | 'xsm' |'xl' | '2xl' | '3xl' | '4xl' | "2xxl" |"mm"|"1xl"|"xx" | "xm";
  weight?: 'light' | 'bold' | "extraBold"|"minBold"|"lightBold";
  variant?: 'primary' | 'darkBlack' | 'secondary' | 'navOff' |"lightGrey"|"black" | "coolGrey" | "offWhite" | "coolWhite" |"neutrals"| "lightBlack" |"white" | "error" | "grey" | "lightBlue" | "naturalCoolGrey" | "darkBlue" | "blue" | "darkGrey" | "grayScale" | "darkerGrey" | "subGray" | "success" | "coolBlue"|"GraniteGray"|"shamrock" | "primaryBlue";
  family?: FontFamily ;
  align?: TextAlign;
  children: ReactNode;
  cursor?: string;
  id?: string;
  display?: string;
  lineHeight?: string;
}

const Text: React.FC<TextProps> = ({ size, weight, variant, align, children, family, cursor, id, lineHeight, display }) => {
  // Define sample font sizes for different sizes
  const fontSize = {
    "xxs": "10px",
    "xs": "12px",
    'sm': '16px',
    'xsm': '15px',
    "mm": "20px",
    'xl': '24px',
    "1xl": "30px",
    '2xl': '32px',
    "2xxl": "36px",
    '3xl': '40px',
    '4xl': '48px',
    "xx": "14px",
    "xm": "18px",
  };

  // Define sample font weights for light and bold
  const fontWeight = {
    light: 300,
    bold: 500,
    minBold: 400,
    lightBold: 600,
    extraBold: 700
  };

  // Define colors for primary and secondary variants
  const color = {
    primary: '#1E293B',
    secondary: 'gray',
    lightGrey: "94A3B8",
    black: "#1F2C37",
    coolGrey: 'rgba(51, 65, 85, 1)',
    coolWhite: 'rgba(226, 232, 240, 1)',
    neutrals: "#64748B",
    lightBlack: "#475569",
    white: "#F1F5F9",
    error: "#DC2626",
    grey: "#334155",
    lightBlue: "#0F172A",
    naturalCoolGrey: "#94A3B8",
    darkBlue: "#2F2F2F",
    blue: "#0E3DD2",
    darkGrey: "#333333",
    grayScale: "#252733",
    offWhite: "rgba(255,255,255,0.7)",
    navOff: "#CBD5E1",
    darkBlack: "#252733",
    darkerGrey: "#4B506D",
    subGray: "#989898",
    success: "#1AB516",
    coolBlue: "#2563EB",
    GraniteGray:"#565656",
    shamrock:"#34D399",
    primaryBlue: "#1E3A8A",
  };

  const textAlign = {
    center: "center",
    left: "left",
    right: "right",
    justify: "justify",
    end: "end",
    unset: "unset"
  };

  const fontFamily = {
    inter: "inter",
    poppins: "poppins",
    montserrat: "montserrat"
  }

  // Define the style for the Text component based on props
  const style = {
    cursor: cursor,
    fontSize: fontSize[size || "sm"], // Default to 16px if size is not provided
    fontWeight: fontWeight[weight as keyof typeof fontWeight] || 400, // Default to normal (400) if weight is not provided
    color: color[variant || "primary"], // Default to black if variant is not provided
    textAlign: textAlign[align || "unset"] as TextAlign,
    fontFamily: fontFamily[family || "inter"] as FontFamily,
    display: display,
    lineHeight: lineHeight ?? "normal"
  };

  return <Typography id={id} style={style} >{children}</Typography>;
};

Text.propTypes = {
  size: PropTypes.oneOf(["xxs", "xs", 'sm', 'xsm', 'xl', '2xl', '3xl', '4xl', "2xxl", "mm", "1xl", "xx", "xm"]),
  weight: PropTypes.oneOf(['light', 'bold', "extraBold", "minBold", "lightBold"]),
  variant: PropTypes.oneOf(['primary', 'secondary', "lightGrey", "black", "coolGrey", "coolWhite", "neutrals", "lightBlack", "white",
  'error', 'grey', 'lightBlue', 'naturalCoolGrey', 'darkBlue','blue', 'darkGrey', 'grayScale','offWhite', 'navOff', 'darkBlack',
  'darkerGrey', 'subGray', 'success', 'coolBlue', 'GraniteGray', 'shamrock','primaryBlue' ]),
  align: PropTypes.oneOf(["center", "left", "right", "justify"]),
  family: PropTypes.oneOf(["inter", "poppins", "montserrat"]),
  children: PropTypes.node.isRequired,
};

export default Text;
