import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    title: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEL:HTMLElement | null,
    notificationState : string,
    currentLanguage: 'ar' | 'en',
    headingText: string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminNavbarController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            anchorEL : null,
            notificationState : "All",
            currentLanguage: 'en',
            headingText: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        this.handleSetLanguage();
        // Customizable Area End
    }
    handleSetLanguage = async () => {
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Profile;
        this.setState({
            currentLanguage: language,
            headingText: heading,
        });
    }
    navigateToProfile = () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "dashboardAdminProfile");
    
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg);
    }
    navigateToNotification= () => {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "dashboardNotification");
    
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg);
    }

    handleChangeLanguage = async () => {
        const currentLanguage = await getStorageData('currentLanguage');
        if (currentLanguage) {
          const language = currentLanguage === 'en' ? 'ar' : 'en'
          setStorageData('currentLanguage', language);
          window.location.reload()
        }
      }
    // Customizable Area End
}
