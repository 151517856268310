import { Box, styled } from "@material-ui/core";
import React, { Component } from "react";
import Text from "../../../components/src/design-system/Text";

interface States {
  activeBtn: string;
}
interface Props {
  data: Array<{ tabData: string }>;
  activeBtn: string;
  handleTab: (tabName: string) => void;
}
class ButtonTabs extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <TabsContainer>
        {this.props.data.map(
          (
            item: { tabData: string; },
            index: number
          ) => {
            return (
              <Box
                key={index}
                className={
                  this.props.activeBtn === item.tabData ? "tabActive" : "tab"
                }
                onClick={() => {
                  this.props.handleTab(item.tabData);
                }}
              >
                {
                  this.props.activeBtn === item.tabData ?
                    <Text size="sm" family="inter" weight="extraBold" variant="grey" >{item.tabData}</Text> :
                    <Text size="sm" family="inter" weight="extraBold" variant="lightGrey" >{item.tabData}</Text>

                }
              </Box>
            );
          }
        )}
      </TabsContainer>
    );
  }
}

const TabsContainer = styled(Box)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px",
  // borderRadius: "50px",
  "& .tab": {
    display: "flex",
    padding: "16px 18px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    transition: "0.3s ease",
    cursor: "pointer",
  },
  "& .tabActive": {
    display: "flex",
    padding: " 16px 0px",
    // borderRadius: "50px",
    // background: "#F1F5F9",
    cursor: "pointer",
    // border: '1px solid #E2E8F0',
    borderBottom : '2px solid black'
  },
});
export default ButtonTabs;
