import React, { FunctionComponent } from "react";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";

interface LabelProps {
    id?: string;
    isUpperCase?: boolean;
    onPress?: (event: any) => void;
    size?: "xxs"|"xs" | "xx" |'sm' |'xl' | '2xl' | '3xl';
    weight?: 'light' | 'normal' | 'bold' | "mediumBold" | "extraBold";
    align?: "left" | "center" | "right" | "justify"
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "button" | "caption" | "overline";
    customFontSize?: string;
    color?: string; 
}

const Label: FunctionComponent<LabelProps> = (props) => {
    const {
        children,
        onPress,
        variant,
        size,
        weight,
        isUpperCase,
        customFontSize,
        color,
    } = props;

    // Define sample font sizes for different sizes
  const fontSize = {
    "xxs":"10px",
    "xs": "12px",
    "xx": '14px',
    'sm': '16px',
    'xl': '24px',
    '2xl': '32px',
    '3xl': '40px',
  };

  // Define align variations
  const textAlign = {
    center: "center",
    left: "left",
    right: "right",
    justify: "justify",
  };

  // Define sample font weights for light and bold
  const fontWeight = {
    light: 300,
    normal : 400,
    bold: 500,
    mediumBold: 600,
    extraBold:700
  };

    const content =
        typeof children === "string" && isUpperCase
            ? (children as string).toUpperCase()
            : children;

    const style = {
        fontSize: customFontSize ? customFontSize : fontSize[size || "sm"], // Default to 16px if size is not provided
        fontWeight: fontWeight[weight as keyof typeof fontWeight] || 400, // Default to normal (400) if weight is not provided
        color: color || "inherit"
    };

    return (
        <Typography style={style}
            variant={variant}
            onClick={(event) => (onPress ? onPress(event) : undefined)}
        >
            {content}
        </Typography>
    );
};

Label.defaultProps = {
    isUpperCase: false,
};

export default Label;
