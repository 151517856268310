import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData,removeStorageData } from "framework/src/Utilities";
// Customizable Area Start
export interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json');
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage: 'ar' | 'en',
    headingText : string,
    userType: string,
    showDetail: boolean,
    breadscrumValues: string[];
    userInput : {
        id : "first_name" |
        "last_name" |
        "full_phone_number" |
        "email" |
        "address" |
        "city" |
        "state" |
        "country" |
        "zipcode",
        label : string,
        
    }[],
    businessInput : {
        id : "business_name" | "tax_number",
        label : string
    }[],
    businessDocument : {
        id : "business_registration_document" |
        "license_certification" |
        "tax_registration" |
        "other_documents",
        label : string
    }[],
    additionalDocument : {
        id : "business_registration_document" |
        "license_certification"
        label : string
    }[],
    response : {
        "first_name": null | string,
        "last_name": null | string,
        "full_phone_number": null | string,
        "email": null | string,
        "address": null | string,
        "city": null | string,
        "state": null | string,
        "country": null | string,
        "zipcode": null | string,
        "business_name": null | string,
        "tax_number": null | string,
        "business_registration_document": null | {file : string, name : string, size : string},
        "license_certification": null | {file : string, name : string, size : string},
        "tax_registration": null | {file : string, name : string, size : string},
        "other_documents": null | {file : string, name : string, size : string},
        "profile_image": null | string
    },
    anchorEl : null | HTMLElement,
    tabValue: number,
    companyData: {
        HeadText: string;
        smallText: string;

    }[];
    businessProfileData: {
        TopText: string;
        BottomText: string;

    }[];

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchProfileCallId:any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            anchorEl : null,
            currentLanguage:'en',
            headingText : "",
            userType: "",
             showDetail: false,
            breadscrumValues: [],
            userInput : [
                {
                    "label": "First Name",
                    "id": "first_name"
                },
                {
                    "label": "Last Name",
                    "id": "last_name"
                },
                {
                    "label": "Email",
                    "id": "email"
                },
                {
                    "label": "Contact Number",
                    "id": "full_phone_number"
                },
                {
                    "label": "Address",
                    "id": "address"
                },
                {
                    "label": "City",
                    "id": "city"
                },
                {
                    "label": "State",
                    "id": "state"
                },
                {
                    "label": "Country",
                    "id": "country"
                },
                {
                    "label": "Zip",
                    "id": "zipcode"
                }
            ],
            businessInput : [
                {
                    "label": "Business Name",
                    "id": "business_name"
                },
                {
                    "label": "Business Tax No.",
                    "id": "tax_number"
                }
            ],
            additionalDocument : [{
                id : "business_registration_document",
                label : "Business Registration Document"
            },{
                label : "License Certification",
                id : "license_certification"
            }],
            businessDocument : [
                {
                    label : "Business Registration Document",
                    id : "business_registration_document"
                },
                {
                    label : "License Certification",
                    id : "license_certification"
                },
                {
                    label : "Tax Registration Document",
                    id : "tax_registration"
                },
                {
                    label : "Other Document",
                    id : "other_documents"
                },
            ],
            response : {
                "first_name": null,
                "last_name": null,
                "full_phone_number": null,
                "email": null,
                "address": null,
                "city": null,
                "state": null,
                "country": null,
                "zipcode": null,
                "business_name": null,
                "tax_number": null,
                "business_registration_document": null,
                "license_certification": null,
                "tax_registration": null,
                "other_documents": null,
                "profile_image": null
            },
            tabValue: 0,
            companyData: configJSON.companyData,
            businessProfileData: configJSON.businessProfileData,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
    
            if (apiRequestCallId && responseJson) {
              if (apiRequestCallId === this.fetchProfileCallId) {
                if (!responseJson.errors) {
                  this.handleApiResponse(responseJson.data.attributes)
                }
              }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    async componentDidMount() {
        // Customizable Area Start
        this.fetchProfile(await getStorageData("selectedUser"))
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Profile;
        this.setState({ 
            currentLanguage: language,
            headingText: heading,
        });
        // Customizable Area End
    }

    // Customizable Area Start
    
    handleApiResponse = async (res:any) => {

        const rd = await this.fetchFileDetails(res["business_registration_document"])
        const lc = await this.fetchFileDetails(res["license_certification"])
        const tr = await this.fetchFileDetails(res["tax_registration"])
        const od = await this.fetchFileDetails(res["other_documents"])
        const newResponse = {
            "first_name": res["first_name"],
            "last_name": res["last_name"],
            "full_phone_number": res["full_phone_number"],
            "email": res["email"],
            "address": res["address"],
            "city": res["city"],
            "state": res["state"],
            "country": res["country"],
            "zipcode": res["zipcode"],
            "business_name": res["business_name"],
            "tax_number": res["tax_number"],
            "business_registration_document": {file : res["business_registration_document"], name : rd.name, size : rd.size},
            "license_certification": {file : res["license_certification"], name : lc.name, size : lc.size},
            "tax_registration": {file : res["tax_registration"], name : tr.name, size : tr.size},
            "other_documents": {file : res["other_documents"], name : od.name, size : od.size},
            "profile_image": res["profile_image"]
        }
        this.setState({
            response : newResponse
        })
    }

    fetchProfile = async (id : string) => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfile}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token" : await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchFileDetails = async (url:string) => {
        let name = ""
        let size = ""
        const response = await fetch(url);
        const contentDisposition = response.headers.get('content-disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        if(contentDisposition){
        const matches = filenameRegex.exec(contentDisposition);
        name = matches ? matches[1].replace(/['"]/g, '') : "";
        size = response.headers.get('content-length') ?? "";
        }
        return {
            name,
            size
        }
    };

    handleView = () => {
        switch(this.state.anchorEl?.id) {
            case "business_registration_document" :
                window.open(this.state.response["business_registration_document"]?.file, "_blank", "noreferrer")
                break;
            case "license_certification" : 
                window.open(this.state.response["license_certification"]?.file, "_blank", "noreferrer")
                break;
            case "tax_registration" :
                window.open(this.state.response["tax_registration"]?.file, "_blank", "noreferrer")
                break;
            case "other_documents" :
                window.open(this.state.response["other_documents"]?.file, "_blank", "noreferrer")
                break;
        }
    }

    handleTabValueChange = (event:React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue });
      };
      handleDrawerNavigation = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle()
        } else {
            if(routing.includes("breadScrum")) {
            } else {
                this.handleNavigatingRoute(routing);
            }
        }
    };


    logoutHandle = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigatingRoute("Home");
    };
    handleNavigatingRoute = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    // Customizable Area End
}
