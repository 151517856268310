import React from "react";
import { Box, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

export interface BasicInformationInputChange {
  fullNameInputProps: (text: string) => void;
  bankNameInputProps: (text: string) => void;
  agencyNameInputProps: (text: string) => void;
  serviceProviderNameInputProps: (text: string) => void;
  emailInputProps: (text: string) => void;
  phoneInputProps: (text: string) => void;
  passwordInputProps: (text: string) => void;
  countryInputProps: (text: string) => void;
  stateInputProps: (text: string) => void;
  cityInputProps: (text: string) => void;
  zipInputProps: (text: string) => void;

}
interface Props {
  basicInformationInputChange: BasicInformationInputChange;
  currentLanguage: string;
  renderTextInput: (
    id: string,
    label: string,
    value: string,
    onChange: (value: any) => void,
    type: string,
    error: string,
    endAdornment?: boolean
  ) => JSX.Element;
  classes: any;
  showPersonalDetail: () => boolean;
  showBankDetail: () => boolean;
  showAgencyDetail:() => boolean;
  showServiceProviderDetail: () => boolean;
  showPassword: boolean,
  errors: {
    FullName: string;
    BankName: string;
    AgencyName: string;
    ServiceProviderName: string;
    Email: string;
    Password: string;
    Phone: string;
    City: string;
    State: string;
    Country: string;
    Zip: string;
  }
  basicInformation: {
    fullName: string;
    bankName: string;
    agencyName: string;
    serviceProviderName: string;
    email: string;
    password: string;
    phone: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  }
}

const CustomBox = styled(Box)({
  "@media (max-width: 800px)": {
    "& .MuiBox-root": {
    flexDirection: "column",
      width: "100%",
      gap: "0px"
    },
    "& .MuiBox-root > div": {
      width: '100% !important',
      "& .MuiFormControl-fullWidth": {
        width: '100% !important'
      }
    }
  }
});

const webStyle = {
  mainBox: {
    marginTop: 20,
    "@media (min-width: 1920px)": {
      paddingTop: 60,
      marginBottom: 25
    }
  }
}

class BasicInformationWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

  render() {
    const languageData = languageTranslationData[this.props.currentLanguage];
    const { classes, renderTextInput, basicInformation, showPassword,
      basicInformationInputChange, errors,
      showPersonalDetail, showBankDetail,
      showAgencyDetail, showServiceProviderDetail
      } = this.props;

    return(
      <CustomBox sx={webStyle.mainBox} flexDirection="column" display="flex" alignItems="center">
        <Text variant="primary" size="xl" weight="extraBold" family="inter">{languageData.BasicInformation}</Text>
        <Box mt={2} mb={3}>
          <Text align="center" size="sm" family="inter" variant="grey" weight="minBold">{languageData.BasicInformationDesc}</Text>
        </Box>

        <Box sx={classes.form}>
          {showPersonalDetail() && renderTextInput("fullName", languageData.FullName+"*", basicInformation.fullName, basicInformationInputChange.fullNameInputProps, "text", errors['FullName'] )}
          {showBankDetail() && renderTextInput("bankName", languageData.BankName+"*", basicInformation.bankName, basicInformationInputChange.bankNameInputProps, "text", errors['BankName'] )}
          {showAgencyDetail() && renderTextInput("agencyName", languageData.AgencyName+"*", basicInformation.agencyName, basicInformationInputChange.agencyNameInputProps, "text", errors['AgencyName'])}
          {showServiceProviderDetail() && renderTextInput("serviceProviderName", languageData.ServiceProviderName+"*", basicInformation.serviceProviderName, basicInformationInputChange.serviceProviderNameInputProps, "text", errors['ServiceProviderName'] )}
          {renderTextInput("email", languageData.Seller.Email+"*", basicInformation.email, basicInformationInputChange.emailInputProps, "email", errors['Email'])}
        </Box>

        <Box sx={classes.form}>
          {renderTextInput("phone", languageData.Seller.PhoneNumber+"*", basicInformation.phone, basicInformationInputChange.phoneInputProps, "text", errors['Phone'])}
          {renderTextInput("password", languageData.Password, basicInformation.password, basicInformationInputChange.passwordInputProps, showPassword ? "text" : "password", errors['Password'], true )}
        </Box>
        <Box sx={classes.form}>
          {renderTextInput("country", languageData.Country, basicInformation.country, basicInformationInputChange.countryInputProps, "text", errors['Country'])}
          {renderTextInput("state", languageData.State, basicInformation.state, basicInformationInputChange.stateInputProps, "text", errors['State'])}
        </Box>
        <Box sx={classes.form}>
          {renderTextInput("city", languageData.City, basicInformation.city, basicInformationInputChange.cityInputProps, "text", errors['City'])}
          {renderTextInput("zip", languageData.Zip, basicInformation.zip, basicInformationInputChange.zipInputProps, "text", errors['Zip'])}
        </Box>
      </CustomBox>
    )
  }
}

export default BasicInformationWeb;