// Customizable Area Start
import React from "react";
// Customizable Area Start
import { Box, IconButton, Button, InputAdornment, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Label from "../../../components/src/design-system/Label";
import TextInput from "../../../components/src/design-system/TextInput";
import { createTheme, styled } from "@material-ui/core/styles";
import ForgotPasswordControllerWeb, { Props } from "./ResetForgotPasswordController.web";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Text from "../../../components/src/design-system/Text";
const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { LoginFlow } from "../../../components/src/LoginFlow.web";
// Customizable Area End

// Customizable Area Start
const classes = {
  services: {
    padding: 0,
  },
  servicesIcon: {
    minWidth: 32 as const
  },
  containerLogin: {
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '10px 0px 0px 10px',
      padding: '44px 100px',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media (max-width: 960px)": {
          padding: "40px 20px"

      },
      "@media (max-width: 600px)": {
          padding: "40px 20px",
          borderRadius: "10px 10px 0px 0px"
      },
  },
  errorBox: {
    padding: '12px 16px 12px 16px',
    borderRadius: '4px 0px 0px 0px',
    background: '#FEE2E2',
    borderLeft: '4px solid #DC2626',
    color: '#DC2626',
    marginBottom: 10
  },
  linkText: {
    cursor: 'pointer',
    marginLeft: 5,
    color: '#1E#A8A',
    "&:hover": {
        textDecoration: 'underline'
    },
  },
  submitBtn: {
    background: "#1E3A8A",
    color: '#fff',
    borderRadius: "8px",
    padding: 12,
    textTransform: "none",
    fontSize: 16,
    fontWeight: 700,

    "&:hover": {
        background: "#1E3A8A",
        color: '#fff',    
    }
  } as CSSProperties,
  inputField: {
      "& .MuiInputBase-root": {
          '& fieldset': {
              top: '-5.5px !important',
          },
      }
    },
  }

  const StyledLisItemText = styled(ListItemText)({
    padding: 0,
    '& span': {
      padding: 0,
      fontSize: 14,
      fontWeight: 500,
    }
  });
  

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordControllerWeb{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const {currentLanguage, errors } = this.state
    const languageData = languageTranslationData[currentLanguage];
    const isArabic = currentLanguage === 'ar';

    return (
      <LoginFlow>
        <Box sx={classes.containerLogin}>
          <Box mb={3}>
              <Text size="xm" weight="extraBold">{languageData.NewPassword}</Text>
          </Box>
          <Box mb={3}>
              <Text size="sm" weight="minBold" family="inter">{languageData.EnterPassword+" "+this.state.emailAddress}</Text>
          </Box>
          {!this.isStringNullOrBlank(errors.Token) && <Box sx={classes.errorBox}>
            <Text>{errors.Token}</Text>
          </Box>}
          <Box sx={classes.inputField as CSSProperties} >
            <Box mb={3}>
              <Label children={languageData.NewPassword} weight="extraBold" size="xx" color="#334155"/>
              <TextInput
                id="newpassword-input"
                onChange={this.handlePassword.onChangeText}
                value={this.state.password}
                placeholder={languageData.NewPasswordPlaceholder}
                type={this.state.showPassword ? "text" : "password"}
                error={this.state.psEr}
                endAdornment={
                  <InputAdornment position="end" style={{ position: 'absolute', top: "27px", right: isArabic ? "auto": "0px", left: isArabic ? 0 : "auto" }}>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.setPasswordFieldIcon}
                        id="new-password"
                      >
                        {this.state.passwordField ? (
                          <VisibilityOutlinedIcon style={{color: "#94A3B8"}}/>
                        ) : (
                          <VisibilityOffOutlinedIcon style={{color: "#94A3B8"}}/>
                        )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box mb={3}>
            <Label children={languageData.ConfirmPassword} weight="extraBold" size="xx" color="#334155"/>
              <TextInput
                id="newconfirmpassword-input"
                onChange={this.handleConfirmPassword.onChangeText}
                placeholder={languageData.ConfirmPasswordPlaceholder}
                value={this.state.confirmPassword}
                type={this.state.showConfirmPassword ? "text" : "password"}
                error={this.state.confirmPasswordError}
                endAdornment={
                  <InputAdornment position="end" style={{ position: 'absolute', top: "27px", right: isArabic ? "auto": "0px", left: isArabic ? 0 : "auto"  }}>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.setConfirmPasswordFieldCom}
                        id="new-confirm-password"
                      >
                        {this.state.confirmPasswordField ? (
                          <VisibilityOutlinedIcon style={{color: "#94A3B8"}}/>
                        ) : (
                          <VisibilityOffOutlinedIcon style={{color: "#94A3B8"}}/>
                        )}
                      </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Text weight="extraBold">{languageData.PasswordContainText}</Text>
            <List>
              {languageData.PasswordValidation.map((validation: string, validIndex: number) => 
              <ListItem style={classes.services} key={validation}>
                <ListItemIcon style={classes.servicesIcon}>
                  <CheckCircleRoundedIcon htmlColor={this.state.passwordFieldChecklist[validIndex]} />
                </ListItemIcon>
                <StyledLisItemText>
                  {validation}
                </StyledLisItemText>
              </ListItem>)}
            </List>
            <Button
              data-testid="set-password"
              fullWidth
              variant="contained"
              style={classes.submitBtn}
              onClick={this.buttonProps.onPress}
            >
              {languageData.SetNewPassword}
            </Button>
          </Box>
          <Box mt={3} mb={0}>
            <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
              {languageData.BackTo}
              <span style={classes.linkText} id="login-test" onClick={() => this.handleRouting('SellerLoginWeb')}>
                <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Login}</Text>
              </span>
            </Text>
          </Box>
        </Box>
      </LoginFlow>
    );
    // Customizable Area End
  }

  // Customizable Area Start
theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  // Customizable Area End
}
