//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { ServiceCards, ServiceCardSecond, ServiceCardThird, ServiceCardFourth, ServiceCardFifth, ServiceCardSixth, ServiceCardSeventh, ServiceCardEighth, ServiceCardNinth } from './assets';
import { getStorageData } from "../../../framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslation = require('../../languagesupport/src/LanguageData/ProductDescription.json');

interface Document {
  file_name: string;
  url: string;
  size: string;
}

interface Attributes {
  id: number;
  total_number_of_debts: number;
  total_amount_of_debts: number;
  total_judgements: number;
  last_collectors_contact: number;
  last_creditors_contact: number;
  collections_used_in_past: number;
  total_months_as_costomer: number;
  pas_payment_made: boolean;
  month_average_paytime: number;
  other_documents: Document[][];
  account_id: number;
  supporting_documents: Document[][];
}

interface UserListing {
  id: string;
  type: string;
  attributes: Attributes;
}

interface UserListingData {
  data: UserListing[];
}


export interface ServiceListApiResponse {

  id: string,
  type: string,
  attributes: {
    company_name: string,
    company_name_arabic: string;
    about_us: string,
    about_us_arabic: string;
    unifying_number: string,
    is_verified_provider: false,
    verification_response: string,
    company_image: { [key: string]: string } | null,
    user_account: {
      id: number,
      email: string
    },
    tabs: [
      {
        achievements: null,
        achievements_arabic: null
      },
      {
        conditions: null,
        conditions: null
      },
      {
        steps: null,
        steps_arabic: null
      }
    ],
    documents: {
      business_registration: {
        file_name: string,
        url: string,
        size: string
      },
      tax_registration: {
        file_name: string,
        url: string,
        size: string
      },
      license_certification: {
        file_name: string,
        url: string,
        size: string
      }
    }
  }
}




export interface GetServicesSuccessResponse {
  data: Array<ServiceDataResponse>
}
export interface GetServicesErrorResponse {
  message: string
}

export interface ServiceDataResponse {
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string,
    description: string
    title_arabic: string;
    description_arabic: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  classes: Record<string, string>;

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  serviceData: Array<ServiceDataResponse>;
  serviceListData: Array<ServiceListApiResponse>
  serviceBuyerProfilesData: Array<ServiceListApiResponse>
  serviceListDetailData: Array<ServiceListApiResponse>
  errors: string;
  currentLanguage: 'en' | 'ar',
  productData: { name: string; price: number; description: string; images: { url: string }[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id: number;
  value: number;
  activeBtn: string;
  activeBtn1: string;
  cheking: string;
  tabData: string;
  userList: UserListing[];
  token: string;
  filters: {
    debtSize: string[];
    debtCategory: string[];
    debtAgeRange: string[];
    debtOwnerType: string[];
  };
  expanded: {
    debtSize: boolean;
    debtCategory: boolean;
    debtAgeRange: boolean;
    debtOwnerType: boolean;
  };

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  getServiceDetailsApiCallId: string = "";
  getServiceListApiCallId: string = "";
  getServiceListDetailApiCallId: string = "";
  fetchOfferApiCall: string = ""
  fetchBuyerProfileApiCall: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: { name: "", price: 1, description: "", images: [{ url: "" }] },
      product_id: 8,
      focusedImageIndex: 1,
      value: 0,
      serviceData: [],
      serviceListData: [],
      serviceBuyerProfilesData: [],
      serviceListDetailData: [],
      errors: "",
      activeBtn: "Listings",
      activeBtn1: "Buyer profiles",
      tabData: [],
      currentLanguage: configJSON.EN,
      token: "",
      userList: [],
      filters: {
        debtSize: ['Under $10,000'],
        debtCategory: ['Credit Card Debt'],
        debtAgeRange: ['Under 30 days'],
        debtOwnerType: ['Individual'],
      },
      expanded: {
        debtSize: true,
        debtCategory: true,
        debtAgeRange: true,
        debtOwnerType: true,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.serviceResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.serviceResponseFailureCall(apiRequestCallId, responseJson);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    if (this.props.navigation?.getParam("navigationBarTitleText")) {
      const companyId = this.props.navigation.getParam("navigationBarTitleText");
      this.getServiceListDetail(companyId);
    }
    this.setState({ currentLanguage: language, activeBtn: language === 'en' ? 'Listings' : 'شركات' });
    this.setState({ currentLanguage: language, activeBtn1: language === 'en' ? 'Buyer profiles' : 'عروض' });

    this.getServiceDetails();
    this.getServiceList();
    const token = await getStorageData("accessToken")
    this.setState({
      token: token
    })
    this.fetchOffers();
  }

  serviceResponseSucessCell = async (apiRequestCallId: string, responseJson: ServiceDataResponse & ServiceListApiResponse) => {
    if (apiRequestCallId === this.getServiceDetailsApiCallId) {
      this.getServiceDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getServiceListApiCallId) {
      this.setState({ serviceListData: responseJson.data })
    }

    if (apiRequestCallId === this.getServiceListDetailApiCallId) {
      this.setState({ serviceListDetailData: responseJson?.data });
      let Info = [];
      for (const item of responseJson.data.attributes.tabs) {
        for (const keyName in item) {
          const value = item[keyName];
          Info.push({
            label: `${keyName}`,
            content: `${value}`
          });
        }
      }
      const isEnglish = this.state.currentLanguage === configJSON.EN;
      const condition = isEnglish ? 0 : 1;
      const tabData = Info.filter((_dataInfo, index) => index % 2 === condition).map((tabData) => {
        return {
          label: isEnglish ? tabData.label : this.getArabicText(tabData.label),
          content: tabData.content
        }
      })
      this.setState({ tabData: tabData })
    }
    if (apiRequestCallId === this.fetchOfferApiCall) {
      this.setState({ userList: responseJson.data })
    }
    if (apiRequestCallId === this.fetchBuyerProfiles) {
      this.setState({ userList: responseJson.data })
    }
  };

  getArabicText = (keyName: string) => {
    switch (keyName) {
      case 'achievements_arabic': return languageTranslation[this.state.currentLanguage].Achievements;
      case 'conditions_arabic': return languageTranslation[this.state.currentLanguage].Conditions;
      case 'steps_arabic': return languageTranslation[this.state.currentLanguage].Steps;
    }
  }

  serviceResponseFailureCall = async (apiRequestCallId: string, responseJson: GetServicesErrorResponse) => {
    if (apiRequestCallId === this.getServiceDetailsApiCallId) {
      this.getServiceDetailsFailureCallBack(responseJson);
    }
  };

  mockApiCall = async (data: ApidataResponseMock) => {
    const { contentType, method, endPoint, body, type } = data;

    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && type === "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleChange = (newValue) => {
    this.setState({ value: newValue });
  };

  handleAccordionChange = (panel: keyof State['expanded']) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState((prevState) => ({
      expanded: {
        ...prevState.expanded,
        [panel]: isExpanded,
      },
    }));
  };

  handleFilterChange = (category, value) => {
    this.setState((prevState) => {
      const { filters } = prevState;
      const updatedCategory = filters[category].includes(value)
        ? filters[category].filter((item) => item !== value)
        : [...filters[category], value];
      return {
        filters: {
          ...filters,
          [category]: updatedCategory,
        }
      };
    });
  };

  handleTab = (tabName: string) => {
    this.setState({ activeBtn: tabName });
    this.setState({ activeBtn1: tabName });
    if (tabName === "Listings" || tabName === 'عروض') {
      this.fetchOffers()
    }
    if (tabName === "Buyer profiles" || tabName === 'عروض') {
      this.fetchBuyerProfiles()
    }
  }

  fetchOffers = () => {
    const headers = {
      "Content-Type": configJSON.loginApiContentType
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchOfferApiCall = requestMessage.messageId;

    const endpoint = !this.state.token ? configJSON.fetchOfferEndPoint : `${configJSON.fetchOfferEndPoint}?token=${this.state.token}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchBuyerProfiles = () => {
    const headers = {
      "Content-Type": configJSON.contentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchBuyerProfileApiCall = requestMessage.messageId;

    const endpoint = !this.state.token ? configJSON.fetchBuyerProfileEndPoint : `${configJSON.fetchBuyerProfileEndPoint}?token=${this.state.token}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  getServiceDetails = async () => {
    this.getServiceDetailsApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getServiceEndPoint
    });
  };

  getServiceList = async () => {
    this.getServiceListApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getServiceListEndPoint
    });
  };

  getServiceListDetail = async (serviceId: string) => {
    this.getServiceListDetailApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: `${configJSON.getServiceListEndPoint}/${serviceId}`
    });
  };

  getServiceDetailsSucessCallBack = (response: GetServicesSuccessResponse) => {
    this.setState({ serviceData: response?.data });
  };

  getServiceDetailsFailureCallBack = (response: GetServicesErrorResponse) => {
    this.setState({ cheking: true });
  };

  handleVerificationNavigate = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VerificationRequest"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigate = (card) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), card.id)
    this.send(message);
  }
  handleContactNavigate = async () => {
    const token = await getStorageData("accessToken");
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      token ? "ServiceInternalForm" : "UserLogin"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleServiceContactNavigate = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ServiceContact"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleSubmittionNavigate = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "UserProfile"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigateForUserList = (routes : string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), routes);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
