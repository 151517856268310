import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) => ({
  filterInputBox: {
    marginTop: "-10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100.5%",
    "& > :first-child": {
      width: "auto",
      display: "flex",
      justifyContent: "flex-start",
      backgroundColor: "white"
    },
    "& > :last-child": {
      width: "43%",
      display: "flex",
      // justifyContent: "flex-end"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& > :last-child": {
        width: "43%"
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      "& > :last-child": {
        width: "43%"
      },
    },
    [theme.breakpoints.down(580)]: {
      width: "100%",
      display: "flex",
      "& > :last-child": {
        width: "43%"
      },
    },
    [theme.breakpoints.down(540)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& > :last-child": {
        width: "100%"
      },
    },
  },
});

interface CustomBoxProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
}

class CustomBox extends Component<CustomBoxProps> {
  render() {
    const { classes, children, ...props } = this.props;

    return (
      <Box className={classes.filterInputBox} {...props}>
        {children}
      </Box>
    );
  }
}

export default withStyles(styles)(CustomBox);