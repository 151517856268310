import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');

interface Response {
  data?:LandingPageData[],
  errors?: {[keyName:string]:unknown}[]
}

interface LandingPageData {
  id: string;
  type: string;
  attributes: {
    id: number;
    sequence: number;
    title: string;
    title_in_arabic: string;
    sub_title: string;
    sub_title_in_arabic: string;
    description: string;
    description_in_arabic: string;
    image: string;
    created_at: string;
    updated_at: string;
  };
}


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // loading: boolean;
  data: LandingPageData[];
  currentLanguage: 'en'|'ar';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataApiCallId: string = "";
  arrayResponse: LandingPageData[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.arrayResponse = [];
    this.state = {
      // Customizable Area Start
      data: [],
      currentLanguage: configJSON.EN
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language })
    this.getResponse();
  }

  handleResponse(responseJson: Response) {
    if (responseJson.errors) {
      if (responseJson.errors[0].token === "Invalid token") {
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UserLogin");

        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    } else {
      this.arrayResponse = responseJson.data!;
      if (this.arrayResponse) {
        this.setState({ data: this.arrayResponse });
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDataApiCallId) {
          this.handleResponse(responseJson);
        }
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  getResponse() {
    const headers = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  }
  // Customizable Area End
}
