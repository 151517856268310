import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const utills = require('../../utilities/src/utills');
// Customizable Area Start
import {removeStorageData, getStorageData} from "framework/src/Utilities";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Listing.json');
export const configJSON = require("./config");

const reqVeriData = [
    ["#669677", "Medical Debt Package", "2,500", "Credit Card", "1", "1", "Active"],
    ["#669459", "Auto Loan Portfolio", "2,500", "Credit Card", "2", "2", "Active"],
    ["#869287", "Personal Loan Debt", "4,000", "Credit Card", "3", "0", "Active"],
    ["#664979", "Zenith Credit Management", "6,000", "Credit Card", "4", "4", "Active"],
    ["#679287", "Retail Credit Accounts", "4,000", "Credit Card", "2", "3", "Active"],
    ["#664559", "Healthcare Debt Portfolio", "2,500", "Credit Card", "1", "10", "Active"]    
];

interface UserDataResponse {
    activated: boolean;
    first_name: string  | null;
    last_name: string  | null;
    full_phone_number: string  | null;
    country_code: string | null;
    email: string  | null;
    phone_number: string | null;
    gender: string | null;
    type: string  | null;
    user_type: string  | null;
    user_name: string  | null;
    address: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zipcode: number | null;
    business_name: string | null;
    tax_number: string | null;
    created_at: string  | null;
    updated_at: string  | null;
    device_id: string | null;
    unique_auth_id: string  | null;
    kyc_name: string | null;
    kyc_number: string | null;
    kyc_verified: boolean | null;
    kyc_document:string | null,
    profile_image: string | null;
    images: {
        tax_registration: DocumentInfo,
        license_certification:DocumentInfo,
        business_registration:DocumentInfo,
        other_documents:DocumentInfo
    };
};

interface DocumentInfo {
    file_name: string | null
    url: string | null;
    size: string | null;
};
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage:"ar"|"en",
    pageHeadingText: string;
    isListingAvailable: boolean;
    showPublished: boolean;
    data: typeof reqVeriData;
    publishAnchorEl: HTMLElement | null;
    showFilter: boolean;
    showDetailPreview: boolean;
    supportDoc: (File|null)[];
    otherDoc: (File|null)[];
    breadscrumarray: string[];
    name: string;
    email: string;
    phoneNumber: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    listingType: string;
    status: string;
    userType: string;
    showModal: boolean;
    modCreatSelect: string;
    selectModUser: string;
    showModUserList: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AllListingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getProfileCallId: string= "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage:"en",
            pageHeadingText: "",
            isListingAvailable: true,
            showPublished: true,
            data:reqVeriData,
            publishAnchorEl: null,
            showFilter: false,
            showDetailPreview: false,
            supportDoc: Array(2).fill(null),
            otherDoc: Array(2).fill(null),
            name: "",
            email: "",
            phoneNumber: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            breadscrumarray: [],
            listingType: "",
            status: "",
            userType: "",
            showModal: false,
            modCreatSelect: "own",
            selectModUser: "",
            showModUserList: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.getProfileCallId) {
                    this.getProfileApiSuccessResponse(responseJson.data.attributes);
                }
            } else {
                if (apiRequestCallId === this.getProfileCallId) {
                    this.getProfileApiFailureResponse();
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const initialFile = new File([''], 'example.txt', { type: 'text/plain' });
        const language = await utills.getLanguageOrSetCurrentLanguage();
        let token = await getStorageData("accessToken");
        if (token) {
            this.handleUserType()
        } else {
            this.handleNavigationRouting("Home");
        }
        this.setState({ 
            currentLanguage: language,
            supportDoc: [initialFile, initialFile],
            otherDoc: [initialFile, initialFile],
        });
        this.getProfile();
        // Customizable Area End
    }

    // Customizable Area Start

    handleUserType = async () => {
        let user = await getStorageData("userType");
        const language = await utills.getLanguageOrSetCurrentLanguage();
        if ( !user ){
            this.setState({
                userType: "admin_account",
                pageHeadingText: languageTranslationData[language].Listings
            });
        } else {
            this.setState({
                userType: "consumer",
                pageHeadingText: languageTranslationData[language].MyListings
            });
        }
    };

    getProfile = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchProfileEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getProfileApiSuccessResponse = (resp: UserDataResponse) => {
        this.setState({
            name : `${resp.first_name} ${resp.last_name}`,
            email: `${resp.email}`,
            phoneNumber: `${resp.full_phone_number}`,
            country: `${resp.country}`,
            state: `${resp.state}`,
            city: `${resp.city}`,
            zip: `${resp.zipcode}`
        });
    };

    getProfileApiFailureResponse = () => {
        this.setState({
            name : "James Carter",
            email: "james.carter@example.com",
            phoneNumber: "33666903456",
            country: "United State",
            state: "Florida",
            city: "Miami",
            zip: "33101"
        });
    };

    handleSelect = (event: React.ChangeEvent<{ value:unknown}>, name: string) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>);
        } 
    };

    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
            listingType: "",
            status: ""
        });
    };

    handleApplyNow = () => {
        this.setState({
            showFilter: false
        });
    };

    handleNavigationRouting = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawer = (route:string)=>{
        if(route==="logOut"){
            this.handleLogOut()
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrumClick(route);
            } else {
                this.handleNavigationRouting(route);
            }
        }
    };

    handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigationRouting("Home");
    };

    handleBreadScrumClick = (clickOn: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        if(clickOn==="breadScrum0"){
            this.setState({
                showDetailPreview: false, 
                breadscrumarray:[],
                publishAnchorEl: null,
                pageHeadingText: languageData.MyListings
            });
        }
    };

    handlePublishedShow = (show: boolean) => {
        this.setState({
            showPublished: show,
            showFilter: false
        });
    };

    handlePublishMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({publishAnchorEl:event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({publishAnchorEl:null});
    };

    handleToggleFilter = () => {
        this.setState({showFilter: !this.state.showFilter});
    };

    handleCloseFilter = () => {
        this.setState({showFilter: false});
    };

    handleDetailPreviewShow = () => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        this.setState({
            pageHeadingText: languageData.ListingDetails,
            showDetailPreview: true,
            breadscrumarray: [languageData.MyListings, languageData.ListingDetails],
            publishAnchorEl: null
        });
    };

    handleStatusCell = () => {
        let value = this.state.showPublished ? "Pending": "Active";
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "Pending") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "Rejected") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor
        };
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            showModUserList: false
        });
    };

    handleModalCreate = (value: string) => {
        this.setState({modCreatSelect: value});
    };

    handleModalList = (value: string) => {
        this.setState({selectModUser: value});
    };

    handleNewListingButton = () => {
        if(this.state.userType==="consumer") {
            this.handleNavigationRouting("UserCreateListing");
        } else {
            this.setState({showModal: true});
        }
    };

    handleModalOkButton = () => {
        if(!this.state.showModUserList){
            if (this.state.modCreatSelect==="own") {
                this.handleNavigationRouting("dashboardCreateListing");
            } else {
                this.setState({showModUserList: true});
            }
        } else {
            if(this.state.selectModUser) {
                this.handleNavigationRouting("dashboardCreateListing");
            }
        }
    };

    // Customizable Area End
}
