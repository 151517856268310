import React from "react";
import { Box, MenuItem, TextField, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import moment from "moment";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

export interface DetailInformationInputChange {
  dateChange: (text: string) => void;
  monthChange: (text: string) => void;
  yearChange: (text: string) => void;
  nationalIdChange: (text: string) => void;
  idProofChange: (file: File) => void;
  addressProofChange: (file: File) => void;
  commercialRegistrationChange: (text: string) => void;
  bankLicenseNumberChange: (text: string) => void;
  commercialRegistrationDocumentChange: (file: File) => void;
  bankLicenseDocumentChange: (file: File) => void;
  agencyRegistrationNumberChange: (text: string) => void;
  agencyLicenseNumberChange: (text: string) => void;
  agencyRegistrationDocumentChange: (file: File) => void;
  agencyLicenseDocumentChange: (file: File) => void;
  registrationNumberChange: (text: string) => void;
  serviceLicenseNumberChange: (text: string) => void;
  registrationDocumentChange: (file: File) => void;
  serviceLicenseDocumentChange: (file: File) => void;
}

interface Props {
  accountType: string;
  currentLanguage: string;
  renderDragAndDropInput: any;
  renderTextInput: (
    id: string,
    label: string,
    value: string,
    onChange: (value: any) => void,
    type: string,
    error: string,
    endAdornment?: boolean
  ) => JSX.Element;
  classes: any;
  showPersonalDetail: () => boolean;
  showBankDetail: () => boolean;
  showAgencyDetail:() => boolean;
  showServiceProviderDetail: () => boolean;
  errors: {
    NationalId: string,
    CommercialRegistration: string,
    BankLicenseNumber: string,
    AgencyRegistrationNumber: string,
    AgencyLicenseNumber: string,
    RegistrationNumber: string,
  },
  detailInformationStates: {
    date: string;
    month: string;
    year: string;
    nationalId: string;
    idProof: File | null;
    addressProof: File | null;
    commercialRegistration: string;
    bankLicenseNumber: string;
    commercialRegistrationDocument: File | null;
    bankLicenseDocument: File | null;
    agencyRegistrationNumber: string;
    agencyLicenseNumber: string;
    agencyRegistrationDocument: File | null;
    agencyLicenseDocument: File | null;
    registrationNumber: string;
    serviceLicenseNumber: string;
    registrationDocument: File | null;
    serviceLicenseDocument: File | null;
  },
  detailInformationInputChange: DetailInformationInputChange;
}

const CustomBox = styled(Box)({
  "@media (max-width: 800px)": {
    flexDirection: "column",
    "& .MuiBox-root": {
      width: "100%"
    },
    "& div": {
      width: '100% !important'
    }
  }
});

const CustomTextField = styled(TextField)({
  "@media (max-width: 800px)": {
    width: "100% !important",
    "& .MuiFormControl-fullWidth": {
      width: '100% !important'
    }
  }
});

const webStyle = {
  mainBox: {
    "@media(min-width: 1920px), (mix-height: 1080px)": {
      paddingTop: 80,
      marginBottom: 80
    }
  }
}

class DetailInformationWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

  renderPersonalDetail () {
    const { renderTextInput, classes, renderDragAndDropInput, errors,
      detailInformationStates, detailInformationInputChange } = this.props;
    const languageData = languageTranslationData[this.props.currentLanguage];

    const months = [
      { value: '01', label: '01'}, {value: '02', label: '02'},
      { value: '03', label: '03'},{ value: '04', label: '04'},
      { value: '05', label: '05'},{ value: '06', label: '08'},
      { value: '07', label: '07'},{ value: '08', label: '08'},
      { value: '09', label: '09'},{ value: '10', label: '10'},
      { value: '11', label: '11'},{ value: '12', label: '12'},
    ];
    
    const years = () => {
      const currentYear = moment().year();
      const years = [];
      for (let i = 0; i < 80; i++) {
        years.push({label: (currentYear - i).toString(), value: (currentYear - i).toString()});
      }
      return years;        
    }

    const dates = () => {
      const dates = [];
      for (let i = 0; i < 31; i++) {
        const date = i+1 < 10 ? `0${i+1}` : (i+1).toString();
        dates.push({label: date, value: date});
      }
      return dates;        

    }

    return (
      <Box mt={3}>
        <CustomBox sx={classes.form} alignItems="baseline">
          <Box display="flex" flexDirection="column" gridGap="10px" style={{width: "100%"}}>
            <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DetailedInformationData.DOB}</Text>
            <Box display="flex" justifyContent="space-between" gridGap="10px" style={{width: "100%"}}>
              <CustomTextField
                data-testid="monthInputTestID"
                id="month"
                select
                value={detailInformationStates.month}
                placeholder="MM"
                size="small"
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => detailInformationInputChange.monthChange(event.target.value)}
                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: detailInformationStates.month !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.month}</Text>,
                }}        
                style={{ width: 120 }}
              >
                {months.map((month: {label: string, value: string }) => 
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                )}
              </CustomTextField>
              <CustomTextField
                data-testid="dayInputTestID"
                id="date"
                select
                placeholder="DD"
                size="small"
                variant="outlined"
                value={detailInformationStates.date}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => detailInformationInputChange.dateChange(event.target.value)}
                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: detailInformationStates.date !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.date}</Text>,
                }}        
                style={{ width: 120 }}
              >
                {dates().map((date: {label: string, value: string }) => 
                  <MenuItem key={date.value} value={date.value}>
                    {date.label}
                  </MenuItem>
                )}
              </CustomTextField>
              <CustomTextField
                data-test-id="yearInputTestID"
                id="year"
                select
                size="small"
                placeholder="YYYY"
                variant="outlined"
                value={detailInformationStates.year}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => detailInformationInputChange.yearChange(event.target.value)}
                style={{ width: 120 }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: detailInformationStates.year !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.year}</Text>,
                }}        
                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
              }}
              >
                {years().map((year: {label: string, value: string }) => 
                  <MenuItem key={year.value} value={year.value}>
                    {year.label}
                  </MenuItem>
                )}
              </CustomTextField>
            </Box>
          </Box>
          {renderTextInput("nationalId", languageData.DetailedInformationData.NationalId+"*", detailInformationStates.nationalId, detailInformationInputChange.nationalIdChange, "text", errors['NationalId'] )}
        </CustomBox>
        <CustomBox sx={classes.formMain}>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.idProofChange, label: languageData.DetailedInformationData.IdProof, errorName: "IdProof", selectedDocInfo: detailInformationStates.idProof,testId:'idproof' })}
          </Box>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.addressProofChange, label: languageData.DetailedInformationData.AddressProof, errorName: "AddressProof", selectedDocInfo: detailInformationStates.addressProof ,testId:'addressproof'})}
          </Box>
        </CustomBox>
      </Box>
    )
  }

  renderBankDetail () {
    const { renderTextInput, classes, renderDragAndDropInput, errors,
      detailInformationStates, detailInformationInputChange } = this.props;
    const languageData = languageTranslationData[this.props.currentLanguage];
    return (
      <Box mt={3}>
        <Box sx={classes.form}>
          {renderTextInput("commercialRegistration", languageData.DetailedInformationData.CommercialRegistration+"*", detailInformationStates.commercialRegistration, detailInformationInputChange.commercialRegistrationChange, "text", errors['CommercialRegistration'])}
          {renderTextInput("bankLicenseNumber", languageData.DetailedInformationData.BankLicenseNumber+"*", detailInformationStates.bankLicenseNumber, detailInformationInputChange.bankLicenseNumberChange, "text", "" )}
        </Box>
        <Box sx={classes.formMain}>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.commercialRegistrationDocumentChange, label: languageData.DetailedInformationData.CommercialRegistrationDocument, errorName: "CommercialRegistrationDocument", selectedDocInfo: detailInformationStates.commercialRegistrationDocument,testId:'commercialRegistrationDocument' })}
          </Box>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.bankLicenseDocumentChange, label: languageData.DetailedInformationData.BankLicenseDocument, errorName: "BankLicenseDocument", selectedDocInfo: detailInformationStates.bankLicenseDocument ,testId:'bankLicenseDocument'})}
          </Box>
        </Box>
      </Box>
    )
  }

  renderAgencyDetail () {
    const { renderTextInput, classes, renderDragAndDropInput, 
      detailInformationStates, detailInformationInputChange } = this.props;
    const languageData = languageTranslationData[this.props.currentLanguage];
    return (
      <Box mt={3} mb={2}>
        <Box sx={classes.form}>
          {renderTextInput("agencyRegistrationNumber", languageData.DetailedInformationData.AgencyRegistrationNumber+"*", detailInformationStates.agencyRegistrationNumber, detailInformationInputChange.agencyRegistrationNumberChange, "text", "")}
          {renderTextInput("agencyLicenseNumber", languageData.DetailedInformationData.AgencyLicenseNumber+"*", detailInformationStates.agencyLicenseNumber, detailInformationInputChange.agencyLicenseNumberChange, "text", "")}
        </Box>
        <Box sx={classes.formMain}>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.agencyRegistrationDocumentChange, label: languageData.DetailedInformationData.AgencyRegistrationDocument, errorName: "AgencyRegistrationDocument", selectedDocInfo: detailInformationStates.agencyRegistrationDocument,testId:'agencyRegistrationDocument' })}
          </Box>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.agencyLicenseDocumentChange, label: languageData.DetailedInformationData.AgencyLicenseDocument, errorName: "AgencyLicenseDocument", selectedDocInfo: detailInformationStates.agencyLicenseDocument ,testId:'agencyLicenseDocument'})}
          </Box>
        </Box>
      </Box>
    )
  }

  renderServideProviderDetail () {
    const { renderTextInput, classes, renderDragAndDropInput, 
      detailInformationStates, detailInformationInputChange } = this.props;
    const languageData = languageTranslationData[this.props.currentLanguage];
    return (
      <Box mt={3}>
        <Box sx={classes.form}>
          {renderTextInput("registrationNumber", languageData.DetailedInformationData.RegistrationNumber+"*", detailInformationStates.registrationNumber, detailInformationInputChange.registrationNumberChange, "text", "")}
          {renderTextInput("serviceLicenseNumber", languageData.DetailedInformationData.ServiceLicenseNumber+"*", detailInformationStates.serviceLicenseNumber, detailInformationInputChange.serviceLicenseNumberChange, "text", "" )}
        </Box>
        <Box sx={classes.formMain}>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.registrationDocumentChange, label: languageData.DetailedInformationData.RegistrationDocument, errorName: "RegistrationDocument", selectedDocInfo: detailInformationStates.registrationDocument,testId:'registrationDocument' })}
          </Box>
          <Box sx={classes.formMainHalf}>
            {renderDragAndDropInput({ onChange: detailInformationInputChange.serviceLicenseDocumentChange, label: languageData.DetailedInformationData.ServiceLicenseDocument, errorName: "ServiceLicenseDocument", selectedDocInfo: detailInformationStates.serviceLicenseDocument ,testId:'serviceLicenseDocument'})}
          </Box>
        </Box>
      </Box>
    )
  }

  render() {
    const languageData = languageTranslationData[this.props.currentLanguage];
    const { showPersonalDetail, showBankDetail,
      showAgencyDetail, showServiceProviderDetail
      } = this.props;

    return(
      <Box sx={webStyle.mainBox as CSSProperties} flexDirection="column" display="flex" alignItems="center">
        <Text variant="primary" size="xl" weight="extraBold" family="inter">{languageData.DetailedInformation}</Text>
        <Box mt={2} maxWidth={'330px'}>
          <Text align="center" size="sm" family="inter" variant="grey" weight="minBold">{languageData.DetailedInformationDesc}</Text>
        </Box>
        {showPersonalDetail() && this.renderPersonalDetail()}
        { showBankDetail() && this.renderBankDetail() }
        { showAgencyDetail() && this.renderAgencyDetail() }
        { showServiceProviderDetail() && this.renderServideProviderDetail() }
      </Box>
    )
  }
}

export default DetailInformationWeb;