import React, { ReactNode } from 'react';
import { Box } from "@material-ui/core";

interface AppBackgroundProps {
  backgroundColor?: string;
  borderRadius?: string;
  children?: ReactNode;
  variant?: "primary" | "white" | "black";
}

const AppBackground: React.FC<AppBackgroundProps> = ({ backgroundColor, variant, borderRadius, children }) => {
  let color: string;

  switch (variant) {
    case "primary":
      color = "#F1F5F9";
      break;
    case "black":
      color = "black";
      break;
    case "white":
    default:
      color = "white";
      break;
  }

  const style: React.CSSProperties = {
    backgroundColor: backgroundColor || color, // Set a default color if none is provided
    borderRadius: borderRadius || '0px'
  };

  return <Box style={style}>{children}</Box>;
};

export default AppBackground;
