Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.landingPageApiEndPoint = "bx_block_landingpage/landing_page"
exports.btnExampleTitle = "CLICK ME";
exports.industriesPageEndPoint = "bx_block_landingpage/industries_pages";
exports.EN = 'en';
exports.AR = 'ar';
exports.LearnMore = "Learn More";
exports.LearnMoreInArabic = "يتعلم أكثر";
// Customizable Area End