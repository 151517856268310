import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
import {getStorageData, removeStorageData} from "framework/src/Utilities";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Notification.json')
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    notificationTab: number,
    settings: {
        heading: string,
        options: {
            active: boolean,
            id: string,
            label: string
        }[]
    }[],
    notifications: {
        title: string,
        description: string,
        image: string,
        time: string
    }[],
    currentLanguage: 'ar' | 'en',
    userType: string,
    breadscrumarray: Array<string>,
    anchorEl: null | HTMLElement,
    open: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NotificationWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            notificationTab: 0,
            currentLanguage: 'en',
            settings: this.alertMenu('en'),
            userType: "",
            breadscrumarray: [],
            notifications: this.notifications,
            anchorEl: null,
            open: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        let userType = await getStorageData("userType");
        this.setState({ currentLanguage: language, 
            settings: this.alertMenu(language), userType })
        // Customizable Area End
    }

    // Customizable Area Start

    alertMenu = (language: string) => [
        {
            heading: languageTranslationData[language].settings.GeneralSettings,
            options: [
                {
                    id: "email",
                    active: true,
                    label: languageTranslationData[language].settings.EmailNotifications
                },
                {
                    id: "inplatform",
                    active: true,
                    label: languageTranslationData[language].settings.InPlatformNotifications
                },
            ]
        },
        {
            heading: languageTranslationData[language].settings.ListingNotifications,
            options: [
                {
                    id: "offer",
                    active: true,
                    label: languageTranslationData[language].settings.NewOffersListing
                },
                {
                    id: "offeraccepted",
                    active: true,
                    label: languageTranslationData[language].settings.OfferAccepted
                },
                {
                    id: "listpublished",
                    active: true,
                    label: languageTranslationData[language].settings.ListingPublished
                },
                {
                    id: "expirationreminder",
                    active: true,
                    label: languageTranslationData[language].settings.ListingExpirationReminder
                },
            ]
        },
        {
            heading: languageTranslationData[language].settings.PaymentNotifications,
            options: [
                {
                    id: "paymentreceived",
                    active: true,
                    label: languageTranslationData[language].settings.PaymentReceived
                },
                {
                    id: "paymentdue",
                    active: true,
                    label: languageTranslationData[language].settings.UpcomingPaymentDue
                },
                {
                    id: "missedpayment",
                    active: true,
                    label: languageTranslationData[language].settings.MissedPayment
                },
                {
                    id: "scheduleupdates",
                    active: true,
                    label: languageTranslationData[language].settings.PaymentScheduleUpdates
                },
            ]
        },
        {
            heading: languageTranslationData[language].settings.DealNotifications,
            options: [
                {
                    id: "newdeal",
                    active: true,
                    label: languageTranslationData[language].settings.NewDealInitiated
                },
                {
                    id: "dealagreement",
                    active: true,
                    label: languageTranslationData[language].settings.DealAgreementSigned
                },
                {
                    id: "dealstatus",
                    active: true,
                    label: languageTranslationData[language].settings.DealStatusUpdates
                },
                {
                    id: "dealclosure",
                    active: true,
                    label: languageTranslationData[language].settings.DealClosureNotification
                },
            ]
        }
    ]

    notifications = [
        {
            title: "Megan - customer support",
            description: "Megan: The order has been double checked. Indeed is arriving today",
            image: "string",
            time: "6h"
        },
        {
            title: "Jack - customer support",
            description: "Jack: I hope you had a good experience with our customer support. Let me know if you have more questions for us.",
            image: "string",
            time: "6 days"
        },
        {
            title: "Jack - Customer support",
            description: "Jack: I hope you had a good experience with our customer support. Let me know if you have more questions for us.",
            image: "string",
            time: "6 days"
        },
        {
            title: "Jack - customer support",
            description: "Jack: I hope you had a good experience with our customer support. Let me know if you have more questions for us.",
            image: "string",
            time: "6 days"
        },
        {
            title: "Jack - customer support",
            description: "Jack: I hope you had a good experience with our customer support. Let me know if you have more questions for us.",
            image: "string",
            time: "6 days"
        }
    ]

    handleTabChange = (event:  React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            notificationTab: newValue
        })
    };

    handleDrawer = (route:string)=>{
        if(route==="logOut"){
            this.handleLogOut()
        } else {
            this.handleNavigation(route);
        }
    };

    handleNavigation = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
      };
    

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    handleAlertChange = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newSetting = this.state.settings.map(setting => {
            setting.options.forEach(option => {
                if (option.id === (event.target as HTMLInputElement).id) {
                    option.active = !option.active;
                }
            });
            return setting
        })
        this.setState({
            settings: newSetting
        })
    }
    // Customizable Area End
}
