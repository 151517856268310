import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/BusinessProfile.json');
import { createRef } from 'react';
type FileArray = (File | null)[];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: "en" | "ar";
    breadscrumArray:string[];
    isDataAvailable: boolean;
    editModalOpen: boolean;
    pageHeading: string;
    saveCheck: boolean;
    errors:{
        clientNameErr: string;
        websiteErr: string;
        sectorErr: string;
        regNumberErr: string;
        countryErr: string;
        stateErr: string;
        cityErr: string;
        zipErr: string;
        serviceErr: string;
        bioErr: string;
        portfolioErr: string;
    },
    logo: string|null;
    sector:string;
    clientName: string;
    website: string;
    regNumber: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    bio: string;
    portfolioArray:FileArray;
    serviceArray: string[];
    serviceInput: string;
    anchorEl: null | HTMLElement;
    showPreview: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BusinessProfileController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    protected fileInputRef = createRef<HTMLInputElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectedLanguage: "en",
            breadscrumArray: [],
            isDataAvailable: false,
            editModalOpen: false,
            pageHeading: "",
            saveCheck: false,
            errors:{
                clientNameErr: "",
                websiteErr: "",
                sectorErr: "",
                regNumberErr:"",
                countryErr: "",
                stateErr: "",
                cityErr: "",
                zipErr: "",
                serviceErr: "",
                bioErr: "",
                portfolioErr: "",
            },
            sector:"",
            clientName: "",
            website: "",
            regNumber: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            bio: "",
            portfolioArray:Array(2).fill(null),
            serviceArray:[],
            serviceInput:"",
            anchorEl: null,
            logo:null,
            showPreview: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        let accessToken = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        const heading = languageTranslationData[selectedLanguage].BusinessProfile;
        if (!accessToken || !user ) {
            this.navigationRoute("Home");
        }
        this.setState({
            selectedLanguage: selectedLanguage,
            pageHeading: heading
        });
    };

    navigationRoute = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    handleDrawerBreadscrumClick = (route:string)=>{
        if(route==="logOut"){
            this.handleLogout();
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrum(route)
            } else {
                this.navigationRoute(route);
            }
        }
    };

    handleLogout = async () => {
        await removeStorageData("profilePic");
        await removeStorageData("userType");
        await removeStorageData("accessToken");
        this.navigationRoute("Home");
    };

    handleBreadScrum = (value:string) => {
        if(value==="breadScrum0"){
            this.handleInputCancel();
        }
    };

    handleEditModalOpen = (isOpen:boolean) => {
        this.setState({editModalOpen:isOpen, showPreview:false, breadscrumArray:[]});
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        if(name==='zip'){
            value = value.replace(configJSON.replaceAlphabetRegex, "");
            if (value.length > 6) {
                value = value.slice(0, 6);
            }
        }
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>, () => this.handleErrorChecking());
    };

    handleSectorChange = (event: React.ChangeEvent<{ value:unknown}>) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({sector:value}, () => this.handleErrorChecking());
        } 
    };

    handleServiceArray = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        let { serviceArray } = this.state;
        if (value.includes(",")) {
          serviceArray.push(value.slice(0, -1));
          this.setState({ serviceArray: serviceArray, serviceInput: ""}, () => this.handleErrorChecking());
        } else {
          this.setState({ serviceInput: value }, () => this.handleErrorChecking());
        }
    };

    handleServiceBlur = () => {
        let {serviceInput, serviceArray} = this.state;
        if(serviceInput){
            serviceArray.push(serviceInput);
            this.setState({ serviceArray: serviceArray, serviceInput: ""}, () => this.handleErrorChecking());
        }
    };

    deleteServiceItem = (index: number) => {
        let array = this.state.serviceArray;
        if (index >= 0 && index < array.length) {
          array.splice(index, 1);
          this.setState({ serviceArray: array }, () => this.handleErrorChecking());
        }
    };

    handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleCloseMenu = () => {
        this.setState({anchorEl:null});
    };

    handleDivClick = () => {
        if (this.fileInputRef.current) {
          this.fileInputRef.current.click();
        }
    };

    handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        this.setState({logo: URL.createObjectURL(file as Blob | MediaSource)});
    };

    handleAddPortfolio = (event: React.ChangeEvent<HTMLInputElement>, index: number | null) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        let fileArray = this.state.portfolioArray;
        let newIndex = index;
        if(index === null) {
            for (let i = 0; i < fileArray.length; i++) {
                if (fileArray[i] === null) {
                  newIndex = i;
                  break;
                }
              }
        }
        if (file) {
        this.setState(prevState => {
            const updatedArray = [...prevState.portfolioArray];
            if (newIndex !== null && newIndex >= 0 && newIndex < updatedArray.length) {
                updatedArray[newIndex] = file;
            } else {
                updatedArray.push(file);
            }
            return { portfolioArray: updatedArray, anchorEl : null };
        }, () => this.handleErrorChecking());
        }
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const anchor = this.state.anchorEl;
        const file: File | null = event.target.files ? event.target.files[0] : null;
        if (anchor && anchor.id) {
            if (anchor.id === "componyLogo") {
                this.setState({
                    logo: URL.createObjectURL(file as Blob | MediaSource),
                    anchorEl: null
                });
            } else {
                    const lastChar = anchor.id.slice(-1);
                    const indexNumber = Number(lastChar);
                    this.handleAddPortfolio(event, indexNumber);
            }
        }
    };

    handleFileRemove = () => {
        const anchor = this.state.anchorEl
        if (anchor && anchor.id) {
            if (anchor.id === "componyLogo") {
                this.setState({
                    logo: null,
                    anchorEl: null
                });
            } else {
                const lastChar = anchor.id.slice(-1);
                const indexNumber = Number(lastChar);
                this.handlePortfolioRemove(indexNumber);    
            }
        }
    };

    handlePortfolioRemove = (index:number) => {
        let array = this.state.portfolioArray;
        if (index >= 0 && index < array.length) {
            if(array.length>2) {
                array.splice(index, 1);
            } else {
                if(index===0 && array[1]!==null){
                    let value = array[1];
                    array[0] = value;
                    array[1] = null;
                } else {
                    array[index] = null;
                }
            }
            this.setState({ portfolioArray: array, anchorEl: null }, () => this.handleErrorChecking());
        }
        
    };

    handleErrorChecking = () => {
        let {clientName, website, sector, regNumber, country, state, city, zip, bio, serviceArray, selectedLanguage} = this.state;
        const errors = languageTranslationData[selectedLanguage].errors;
        let clientNameErr = "", websiteErr = "", sectorErr = "", regNumberErr = "", countryErr = "", stateErr = "", cityErr = "", zipErr = "", bioErr="";
        let serviceErr = "", portfolioErr="";
        if(!clientName){
            clientNameErr = errors.clientNameErr;
        }
        if(!website){
            websiteErr = errors.websiteErr;
        }
        if(!sector){
            sectorErr = errors.sectorErr;
        }
        if(!regNumber){
            regNumberErr = errors.regNumErr;
        }
        if(!country){
            countryErr = errors.countryErr;
        }
        if(!state){
            stateErr = errors.stateErr;
        }
        if(!city){
            cityErr = errors.cityErr;
        }
        if(!zip){
            zipErr = errors.zipErr;
        }
        if(!bio){
            bioErr = errors.bioErr;
        }
        if(serviceArray.length===0){
            serviceErr = errors.servceErr;
        }
        portfolioErr = this.handlePortfolioError();
        this.setState({
            errors:{
                clientNameErr: clientNameErr,
                websiteErr: websiteErr,
                sectorErr: sectorErr,
                regNumberErr: regNumberErr,
                countryErr: countryErr,
                stateErr: stateErr,
                cityErr: cityErr,
                zipErr: zipErr,
                serviceErr: serviceErr,
                bioErr: bioErr,
                portfolioErr: portfolioErr
            }
        });
        return {
                clientNameErr,
                websiteErr,
                sectorErr,
                regNumberErr,
                countryErr,
                stateErr,
                cityErr,
                zipErr,
                serviceErr,
                bioErr,
                portfolioErr
        }
    };

    handlePortfolioError = () => {
        let {portfolioArray, selectedLanguage} = this.state;
        const errors = languageTranslationData[selectedLanguage].errors;
        let isError = portfolioArray.some(item=>item===null);
        if(isError){
            return errors.portfolioErr
        } else {
            return ""
        }
    };

    handleInputCancel = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        this.setState({
            anchorEl: null,
            editModalOpen: false,
            saveCheck: false,
            showPreview: false,
            breadscrumArray: [],
            pageHeading: languageData.BusinessProfile
        });
    };

    handleInputSave = () => {
        this.setState({saveCheck: true});
        let getErrors = this.handleErrorChecking();
        let checkErrors = Object.values(getErrors).every((value, index) =>
           value === undefined || value.length === 0
        );
        if(checkErrors){
            const languageData = languageTranslationData[this.state.selectedLanguage];
            const breadScrum = languageData.breadscrum;
            this.setState({
                saveCheck:false, 
                showPreview:true, 
                editModalOpen: false,
                breadscrumArray: Object.values(breadScrum),
                pageHeading: languageData.previewBusinessProfile
            });
        }
    };

    handlePrevConfirmSave = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        this.setState({
            pageHeading: languageData.BusinessProfile,
            breadscrumArray: [],
            showPreview: false,
            isDataAvailable: true,
        });
    };

    handleBusinessProfileDelete = () => {
        this.setState({
            clientName: "",
            website: "",
            sector: "",
            regNumber: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            serviceArray: [],
            serviceInput: "",
            bio: "",
            portfolioArray: [null, null],
            logo: null,
            isDataAvailable: false
        });
    };
    // Customizable Area End
}
