import React from "react";
// Customizable Area Start
import {
    Box,
    styled,Grid
} from "@material-ui/core";
import ServiceContactController,{Props, configJSON} from "./ServiceContactController.web";
import Text from "../../../../packages/components/src/design-system/Text";
import Button from "../../../../packages/components/src/design-system/Button/Button";
import TextInput from "../../../../packages/components/src/design-system/TextInput";
const languageTranslation = require('../../languagesupport/src/LanguageData/ServiceContact.json')

// Customizable Area End

export default class   extends ServiceContactController {
    constructor(props: Props) {
        super(props);
         // Customizable Area Start
        // Customizable Area End
      }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {currentLanguage} = this.state;
        const languageData = languageTranslation[currentLanguage]
        return (
            <FormContainer>
                <Box mt={2} mb={4}>
                    <Text size="xl" weight="lightBold" family="poppins" variant="primary" align="center">{languageData.GetConnectedWithUs}</Text>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <AddContactFormContainer >
                            <TextInput id="name" label={languageData.Name} mandatorySign="*" />
                        </AddContactFormContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <AddContactFormContainer >
                            <TextInput id="companyName" label={languageData.CompanyName} mandatorySign="*" />
                        </AddContactFormContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <AddContactFormContainer >
                            <TextInput id="email" label={languageData.Email} mandatorySign="*" />
                        </AddContactFormContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <AddContactFormContainer >
                            <TextInput id="phoneNumber" label={languageData.PhoneNumber} mandatorySign="*" />
                        </AddContactFormContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <AddContactFormContainer >
                            <TextInput id="message" label={languageData.Message} mandatorySign="*" />
                        </AddContactFormContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box style={{ width: "50%", margin: "auto", marginTop: "50px" }}>
                            <Button themes="dark" >
                            {languageData.Submit}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </FormContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const AddContactFormContainer = styled(Box)({
    "& .MuiInputBase-root": {
      marginBottom: "10px",
      borderRadius: "8px",
      height:"44px",
      "& .MuiOutlinedInput-input": {
        padding: "12px 0px !important"
    }
    },
  })
const FormContainer = styled(Box)({
    width: "100%",
    maxWidth: "60%",
    margin: "0 auto",
    marginTop: "90px",
    marginBottom: "15px",
    backgroundColor: "#CBD5E1",
    padding: "30px 90px",
    borderRadius: "10px"

})
// Customizable Area End
