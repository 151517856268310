import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const reqVeriData = [
    ["#669059 ", "Sunrise Credit Solutions", "PENDING", "XYZ789012", "22 Feb 2022"],
    ["#669287", "Apex Financial Solutions", "Accepted", "ABC823456", "19 Feb 2022"],
    ["#669059", "Legacy Financial Group", "REJECTED", "MNO458723", "19 Feb 2022"],
    ["#669287", "Zenith Credit Management", "Accepted", "JKL787654", "15 Feb 2022"],
    ["#669059", "Crestwood Capital Partners", "Accepted", "XYZ709012", "12 Feb 2022"],
    ["#669287", "Strategic Finance Partners", "PENDING", "BEF456789", "9 Feb 2022"]
];
import { removeStorageData } from "framework/src/Utilities";

const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');

const offerListing = {
    en:[
        ["#669059",  "Sunrise Credit Solutions","Pending", "XYZ789012", "22 Feb 2022", ],
        ["#669287",  "Atlas Credit Services", "Accepted", "JKL987654", "19 Feb 2022"],
        ["#669059",  "Prime Lending Agency","Rejected", "MNO456123", "19 Feb 2022", ],
        ["#669287",  "Blue Horizon Credit",  "Accepted","XYZ789012", "15 Feb 2022",],
        ["#669059",  "National Credit Services", "Accepted", "DEF456789", "12 Feb 2022",],
        ["#669287",  "Strategic Finance Partners","Pending", "XYZ789012", "9 Feb 2022", ]
    ],
    ar:[
        ["#٦٦٩٠٥٩",  "صن رايز لحلول الائتمان","معلقة", "XYZ٧٨٩٠١٢", "٢٢ فبراير ٢٠٢٢", ],
        ["#٦٦٩٢٨٧",  "أطلس لخدمات الائتمان","مقبولة", "JKL٩٨٧٦٥٤", "١٩ فبراير ٢٠٢٢", ],
        ["#٦٦٩٠٥٩",  "برايم وكالة الإقراض ","مرفوضة", "MNO٤٥٦١٢٣", "١٩ فبراير ٢٠٢٢", ],
        ["#٦٦٩٢٨٧",  "بلو هورايزون للائتمان","مقبولة", "XYZ٧٨٩٠١٢", "١٥ فبراير ٢٠٢٢", ],
        ["#٦٦٩٠٥٩",  "ناشونال لخدمات الائتمان","مقبولة", "DEF٤٥٦٧٨٩", "١٢ فبراير ٢٠٢٢", ],
        ["#٦٦٩٢٨٧",  "ستراتيجك شركاء التمويل","معلقة", "XYZ٧٨٩٠١٢", "٩ فبراير ٢٠٢٢", ],

    ]
};
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: Array<"All" | "New" | "In review" | "Draft" | "Archived">,
    selectedTab: "All" | "New" | "In review" | "Draft" | "Archived",
    rowsPerPage: number,
    page: number,
    data: typeof reqVeriData;
    dot: HTMLElement | null,
    showFilter: boolean;
    listingType: string;
    status: string,
    currentLanguage: "ar" | "en",
    showDetail: boolean,
    breadscrumValues: string[],
    userType: string;
    offerListing: typeof offerListing;
    currentPage: number;



    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class VerificationRequestManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs: ["All", "New", "In review", "Draft", "Archived"],
            selectedTab: "All",
            rowsPerPage: 5,
            page: 0,
            data: reqVeriData,
            dot: null,
            showFilter: false,
            listingType: " ",
            status: "",
            currentLanguage: "en",
            showDetail: false,
            breadscrumValues: [],
            userType: "",
            offerListing: offerListing,
            currentPage: 1,




            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    /*  */
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
        });
        // Customizable Area End
    }

    
    handleDrawerNavigation2 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle2()
        } else {
                this.handleNavigatingRoute2(routing);
        }
    };
    logoutHandle2 = async() => {
        this.handleNavigatingRoute2("Home");
    };

    handleNavigatingRoute2 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "Pending" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "Rejected" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    handleClose = () => {
        this.setState({ dot: null });
    };
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ dot: event.currentTarget });
    };


    handleCloseFilter = () => {
        this.setState({ showFilter: false });
    };
    handleSelect = (event: React.ChangeEvent<{ value: unknown }>, name: string) => {
        let { value } = event.target;
        if (typeof value === "string") {
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>);
        }
    };
    handleToggleFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };
    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
            listingType: "",
            status: ""
        });
    };

    handleApplyNow = () => {
        this.setState({
            showFilter: false
        });
    };
    handlePageChange = (page: number) => {
        this.setState({ currentPage: page });
    };
    // Customizable Area End
}
