Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productList="Product List"
exports.cancel="cancel"
exports.approved="Approved"
exports.pending="Pending"
exports.addProduct='Add Your Product'
exports.title='enter the Title'
exports.description='enter the Description'
exports.price='enter the Price'
exports.quantity='enter the Quantity'
exports.productImages='add Product Images'
exports.btnExampleTitle = "Submit";
exports.users="User's";
exports.addNewButton='Add New Product'
exports.deleteText="Are you sure you want to delete product?"
exports.delete="delete"
exports.validationsMessage="Please enter all fields"
exports.numberRegex =/^\d+$/
exports.upload ="Upload"
exports.validationApiContentType = "application/json";
exports.formData='multipart/form-data'
exports.userListUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod='GET'
exports.postMethod='POST'
exports.addDataUrl ='https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status='Staus :'
exports.success='Product Added Successfully'
exports.aboutUsEndPoint = "/bx_block_landingpage/about_pages";
exports.creditorsPageEndPoint = "bx_block_landingpage/creditors";
exports.EN = 'en';
exports.AR = 'ar';
exports.dbdtcarshead= 'What Debt Buyers should look out for';
exports.dbdtcard1head= 'Type of Debts to Buy';
exports.dbdtcard1des=' Selecting the right type of debts, such as credit card debts, medical bills, or utility bills, is crucial.';
exports.dbdtcard2head= 'Assessing Debt Portfolios';
exports.dbdtcard2des=' Evaluating the potential profitability of debt portfolios by analyzing historical data on recovery rates.';
exports.dbdtcard3head= 'Legal Considerations';
exports.dbdtcard3des=' Ensuring all debt buying activities align with state laws and regulations enforced by bodies like CFPB.';

exports.faqsubmit= 'Support';
exports.faqhead='Frequently Asked Questions';
exports.creaditorsArray = [
  {
    title: "The Tech",
    description: `House of Jeddah has created proprietary patent-pending technology for optimizing the debt sales process for the benefit of both creditors and debt buyers. Our platform is unlike anything else in the industry, setting us apart as pioneers in the evolution of debt sales and receivables management. The EverChain platform meets the highest levels of data security compliance (SOC 2 Type 2) and we are a Certified Broker with the Receivables Management Association International. We minimize risks with end-to-end transaction support via our dynamic, multi-functional platform, thoughtfully designed to enable buyers and sellers to seamlessly and successfully come together to execute transactions from pre-bid analysis to post-sale relationship. The platform is fully inclusive of everything necessary for the safest, simplest process on the market including automatic file masking and scrubbing, advanced filtering tools, customized listing notifications, in-depth analysis functionalities, accessible seller data, triple-blind bidding, contract review and negotiation, electronic sale execution, and comprehensive post-sale management including due diligence and oversight maintenance of the collection agencies used by debt buyers.`
  },
  {
    title: "The Process",
    description: `We understand the complexities and risks associated with the debt sales process and have built our business around the resolution of these challenges. EverChain requires all buyers and their collection agencies to be certified through our detailed compliance process providing supporting documentation and enabling sellers to have oversight post-sale. Leveraging our technology platform we are able to market portfolios to qualified buyers without releasing unmasked data while still empowering portfolio due-diligence through partnerships with credit bureaus and account scoring providers. EverChain’s post-sale and chain of title tools enable the effortless management of media documentation, putbacks, buybacks and other post-sale needs. As facilitators between originating and secondary creditors, we care for consumers by ensuring high standards of professionalism, security, and compliance through above-par account management and transfer processes. We drive creditor compliance and consumer protection simultaneously, making us the premiere option for low-risk, high-reliability portfolio sales and acquisitions`
  },
  {
    title: "The Certified Network",
    description: `Our network of certified debt buyers and collection agencies is the key to our success, guarded at the gates by the most rigorous certification standards in the industry. The EverChain Debt Buyer and Agency Network consists of highly qualified organizations focused on the purchase, management, and recovery of defaulted consumer accounts. Certified buyers and vendors complete a detailed due diligence package and on-site audit based on and above the standards of the CFPBs Larger Market Participation rule to validate their compliance with EverChain requirements. This includes annual license and certifications review, onsite audits, recording 100% of the calls made on accounts and maintaining a record of all communications with the consumer should any post-sale complications arise. Potential members must be fully licensed and insured to be considered for initial entry to the robust certification process, and members must maintain standards as proven through audits to obtain recertification.`
  },
  {
    title: "The People",
    description: `EverChain is not just about technology; it is about the people behind it using our technology to guide the way. Our team at EverChain ensures that sellers and buyers get everything they need from the debt sales process while protecting consumer data. We assist with the due diligence process, providing insights into the marketplace and helping sellers to understand their options. After the sale, our people assist with the investigation and resolution of any consumer complaints, management of media and continued auditing of buyer, agency and law firm compliance with our certification standards. This ensures EverChain’s Network delivers the best post-sale consumer experience by some distance. Nothing compares to EverChain.`
  }
];
exports.debtProcessArray = [
  {
    title: "Consultation",
    description: `Setting expectations and preparing your file and documentation to maximize the value of your portfolio.`
  },
  {
    title: "Listing",
    description: `Loading your portfolio to our platform where you can easily scrub ineligible accounts and create your due diligence packages for future buyer review.`
  },
  {
    title: "Marketing",
    description: `Alerting the EverChain Network of the portfolio listing and assisting buyers with due diligence without accessing PII.`
  },
  {
    title: "Execution",
    description: `Reviewing buyer due diligence packages, selecting the right buyer, executing sale documents, receiving sale proceeds and delivering the final unmasked file.`
  },
  {
    title: "Post-Sale Support",
    description: `Providing the technology and people to manage media documentation, ongoing buyer and agency due diligence, post-sale obligations and complaint investigations.`
  }
];

exports.debtBuyerfaqQue1 = ' What types of debt portfolios are available for purchase on Platform?';
exports.debtBuyerfaqAns1 = 'On Platform, you can find a diverse range of debt portfolios available for purchase, including but not limited to consumer debts, commercial debts, medical debts, and more. Our platform offers opportunities for both individual and institutional buyers to invest in various debt types.';
exports.debtBuyerfaqQue2 = 'How do I search for and find debt portfolios that match my investment criteria?';
exports.debtBuyerfaqAns2 = 'Shipping usually takes 5-7 business days.';
exports.debtBuyerfaqQue3 = ' What information is provided about debt portfolios listed on Platform Name?';
exports.debtBuyerfaqAns3 = 'Yes, we ship to most countries worldwide.';
exports.debtBuyerfaqQue4 = 'How do I evaluate the quality and potential profitability of a debt portfolio?';
exports.debtBuyerfaqAns4 = 'You will receive a tracking link via email once your order ships.';
exports.debtBuyerfaqQue5 = 'What steps are involved in the debt acquisition and transaction process?';
exports.debtBuyerfaqAns5 = 'You will receive a tracking link via email once your order ships.';
exports.debtBuyerfaqQue6 = 'How does Platform Name ensure security and compliance during debt transactions?';
exports.debtBuyerfaqAns6 = 'You will receive a tracking link via email once your order ships.';
exports.debtBuyerfaqsubHead = 'Everything you need to know about using Jeddah House of Commerce.';
exports.firstName="First Name"
exports.lastName="Last Name"
exports.companyName="Company Name"
exports.email="Email"
exports.phoneNumber='Phone Number'
exports.submit='Submit'
exports.headerMainText="Sell Defaulted Loans with Us"
exports.descriptionText="Your reputation does not stop at default, it just becomes more sensitive."
exports.portfolioBtn="Sell Your Defaulted Portfolios"
exports.headerDescriptionTextBox1="Creditors are facing more challenges today with managing defaulted consumer credit accounts. Account default rates are rising, the cost of collecting is rising and the web of regulatory and legal environment changes requires full-time effort to understand, manage and monitor. Creditors are not in the business of collecting on defaulted accounts forever; they are in the business of making loans, selling products and providing services. Working with EverChain allows you to cease collecting at the point of diminishing return."
exports.headerDescriptionTextBox2='Debt sales is an account recovery strategy that enables creditors to unlock immediate cash flow from defaulted accounts by transferring ownership to an organization that specializes in recovering these types of accounts (a debt buyer) with the infrastructure necessary to collect effectively and compliantly. Cash received for post-defaulted accounts goes straight to the creditor’s bottom line and provides immediate liquidity.'
exports.headerDescriptionTextBox3='By selling accounts to a debt buyer, creditors are able to improve their financial performance while minimizing regulatory, reputation and financial risks inherent in debt collection both internally and through the management of third-party collection agencies and law firms.'
exports.getStarted='Get Started Today'
exports.descriptionTextBox1='Why Sell with Jeddah House of Commerce?'
exports.descriptionTextBox2='If any of the links in the EverChain are missing, the chain breaks.'
exports.descriptionTextBox3='The consumer experience does not end with default. How consumers are treated during the collections process will impact their future purchasing and borrowing behavior. EverChain helps creditors to maximize the value of their defaulted portfolios while maintaining tight control over the treatment and satisfaction of consumers post-sale.'
exports.containerTextBox1="Debt Sales Process"
exports.containerTextBox2="Debt sales is a process, not an event."
exports.containerTextBox3="House of Jeddah has simplified the process of selling debt and enables creditors to quickly unlock the maximum value of their receivables portfolios while guarding against inherent risks on an ongoing basis. Here’s how we assist throughout the process:"
exports.getDebutbuyerdata = 'bx_block_landingpage/debut_buyers'
exports.debtByuercard1head="Mastering Debt Buying: Your Comprehensive Guide to Strategic Investments";
exports.debtBuyercardSubHead="Mastering Debt Buying:"
exports.debtBuyercardSubHead1="Your"
exports.debtBuyercardSubHead2=" Comprehensive Guide"
exports.debtBuyercardSubHead3="to Strategic Investments"
exports.debtByuercard1des="This detailed guide provides key insights into the process, from acquiring bad debts effectively to navigating the complexities of the debt market.";
exports.debtByuercard2head="The role and functionality of a Debt Buyer";
exports.debtByuercard2des="Debt buyers play a critical role in the financial sector by purchasing delinquent debts at a fraction of the original amount owed. These entities, which can range from private firms to large corporations, invest in unpaid debts and seek to recover the amounts from debtors. This process not only helps original creditors mitigate losses but also offers an opportunity for debt buyers to profit from successful collections.";
exports.debtByuercard2layer="Introduction";
exports.debtByuercard3head="Agreement Initiation";
exports.debtByuercard3des="The Debt Buyer and Debt Owner agree on the terms and conditions of the debt purchase through the platform. Once both parties reach a consensus, they initiate the agreement process electronically. ";
exports.debtByuercard3subdes="Utilize the platform's automated agreement feature to generate a standardized agreement template based on the negotiated terms. Both parties can review the agreement, electronically sign it, and submit it through the platform for verification.";
exports.debtByuercard3layer="Step 1";
exports.debtByuercard4head="Escrow Setup";
exports.debtByuercard4des="Upon agreement, an escrow account is set up for the transaction. The platform securely holds the funds until the terms of the agreement are fulfilled.  ";
exports.debtByuercard4subdes="The platform facilitates the creation of an escrow account for the deal. Account details are provided to both parties, ensuring transparency and security throughout the transaction.";
exports.debtByuercard4layer="Step 2";
exports.debtByuercard5head="Debt Transfer";
exports.debtByuercard5des="After the agreement is finalized, the Debt Owner transfers the debt portfolio to the Debt Buyer. This may involve providing access to debtor information and documentation. ";
exports.debtByuercard5subdes="The platform allows the Debt Owner to securely transfer the debt portfolio to the Debt Buyer. Sample files and legal documentation can be uploaded and shared through the platform to facilitate due diligence.";
exports.debtByuercard5layer="Step 3";
exports.debtByuercard6head="Payment Schedule and Monitoring";
exports.debtByuercard6des="A payment schedule is agreed upon between the parties involved. The platform monitors the payment schedule and sends alerts for upcoming due dates.  ";
exports.debtByuercard6subdes="The platform facilitates the negotiation of payment terms and generates a payment schedule based on the agreement. Both parties receive notifications for upcoming payments and can track payment status in real-time.";
exports.debtByuercard6layer="Step 4";
exports.debtByuercard7head="Final Settlement and Documentation";
exports.debtByuercard7des="Once all payments are completed according to the agreed schedule, the platform facilitates the final settlement of the debt. Closing documents summarizing all transactions are generated and shared with both parties.  ";
exports.debtByuercard7subdes="The platform triggers the final settlement process upon completion of all payments. Closure notifications are sent to both parties, and comprehensive documentation of the transaction is provided for record-keeping purposes.";
exports.debtByuercard7layer="Step 5";
exports.breadcrumHome="Home";
exports.breadcrumBlog="Blog";
exports.breadcrumArticle="Article";
exports.blogHeroHeighlite="Debt Collection Strategies";
exports.blogHeroDate="17 Jan, 2024";
exports.blogHeroHead="5 Strategies for Effective Debt Collection in 2024";
exports.blogAvtarName="Nick Garre";
exports.blogAvtarDetail="Debt Collection Specialist";
exports.blogSideBarHead="Table of Content";
exports.blogSideBarData1="Introduction";
exports.blogSideBarData2="Embrace Technology and Automation";
exports.blogSideBarData3="Prioritize Customer Engagement and Communication";
exports.blogSideBarData4="Implement Data-Driven Decision Making";
exports.blogSideBarData5="Implement Data-Driven Decision Making";
exports.blogSideBarData6="Offer Flexible Payment Options";
exports.blogSideBarData7="Invest in Training and Development";
exports.blogSideBarData8="Summary";
exports.blogAlarmText="12 min for reading";
exports.blogTextHeading1="Introduction";
exports.blogTextDescription1="In the ever-evolving landscape of debt collection, staying ahead of the curve is essential for success. As we navigate the challenges of 2024, debt collection professionals must adapt their strategies to meet the changing needs of creditors and debtors alike. Here are five key strategies to enhance your debt collection efforts in 2024.";
exports.blogTextHeading2="Embrace Technology and Automation";
exports.blogTextDescription2="Embracing technology and automation can streamline your debt collection processes and improve efficiency. Utilize advanced software solutions for debtor communication, payment tracking, and reporting. Automated reminders and follow-ups can help you stay organized and ensure timely payments.";
exports.blogTextHeading3="Prioritize Customer Engagement and Communication";
exports.blogTextDescription3="Entering the real estate market as a first-time buyer can feel like stepping into uncharted territory. That's why it's essential to arm yourself with knowledge and information to navigate the process confidently. From understanding mortgage options to researching neighborhoods and negotiating offers, being informed empowers you to make smart decisions and avoid common pitfalls along the way. With the right resources and guidance, you can approach the home buying process with clarity and peace of mind, knowing that you're making choices that align with your goals and aspirations.";
exports.blogTextHeading4="Implement Data-Driven Decision Making";
exports.blogTextDescription4="Leverage data analytics to gain insights into debtor behavior, payment patterns, and collection trends. Analyze historical data to identify opportunities for improvement and optimize your collection strategies. Data-driven decision-making enables you to prioritize high-value debts, allocate resources effectively, and maximize your collection efforts.";
exports.blogTextHeading5="Offer Flexible Payment Options";
exports.blogTextDescription5="By implementing these strategies, debt collection professionals can enhance their effectiveness and achieve better outcomes in 2024. Embracing technology, prioritizing customer engagement, leveraging data analytics, offering flexible payment options, and investing in training and development are key pillars of successful debt collection in the modern era. With a proactive approach and a commitment to excellence, debt collection professionals can navigate the challenges of 2024 with confidence and achieve their collection goals..";
exports.blogTextHeading6="Invest in Training and Development";
exports.blogTextDescription6="By implementing these strategies, debt collection professionals can enhance their effectiveness and achieve better outcomes in 2024. Embracing technology, prioritizing customer engagement, leveraging data analytics, offering flexible payment options, and investing in training and development are key pillars of successful debt collection in the modern era. With a proactive approach and a commitment to excellence, debt collection professionals can navigate the challenges of 2024 with confidence and achieve their collection goals..";
exports.blogTextHeading7="Summary";
exports.blogTextDescription7="By implementing these strategies, debt collection professionals can enhance their effectiveness and achieve better outcomes in 2024. Embracing technology, prioritizing customer engagement, leveraging data analytics, offering flexible payment options, and investing in training and development are key pillars of successful debt collection in the modern era. With a proactive approach and a commitment to excellence, debt collection professionals can navigate the challenges of 2024 with confidence and achieve their collection goals..";
exports.blogTestimonialHeading="You may also like";
exports.blogTesImgText="Debt Collection Strategies";
exports.blogTesTextDate="17 Jan, 2024 • 7 min for reading";
exports.blogTesTextDate1="15 Jan, 2024 • 10 min for reading";
exports.blogTesTextDate2="12 Jan, 2024 • 8 min for reading";
exports.blogTesHeading=" Maximizing Returns: Tips for Selling Your Debt Portfolio on Online Platforms";
exports.blogTesHeading1="Navigating Regulatory Compliance in Debt Collection: A Guide for Businesses";
exports.blogTesHeading2="The Future of Debt Buying: Trends and Insights for Investors";
exports.blogAvatarName="Alex Rodriguez";
exports.blogAvatarName1="Michael Smith";
exports.blogAvatarName2="Emily Davis";
exports.blogAvatarPosition="Business Development Manager";
exports.blogAvatarPosition1="Compliance Officer";
exports.blogAvatarPosition2="Financial Analyst";










// Customizable Area End
