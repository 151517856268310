import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepConnector, StepIconProps, makeStyles, withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Text from './Text';

const CustomStepConnector = withStyles({
  root: {
    width: '150px',
    "@media (max-width: 400px)": {
      width: "6px"
    },
    "@media (min-width: 401px) and (max-width: 480px)": {
      width: "10px"
    },
    "@media (min-width: 481px) and (max-width: 650px)": {
      width: "30px"
    },
    "@media (min-width: 651px) and (max-width: 767px)": {
      width: "50px"
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width: "65px"
    },
    "@media (min-width: 1025px) and (max-width: 1250px)": {
      width: "90px"
    },
  },
  active: {
    '& $line': {
      borderColor: '#1D4ED8',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1D4ED8',
    },
  },
  line: {
    borderColor: '#94A3B8',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const CustomStep = withStyles({
  root: {
    "@media (max-width: 530px)": {
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0
      }
    },
    "@media (max-width: 400px)": {
      paddingRight: 0,
      paddingLeft: 6,
      '& .MuiStepLabel-iconContainer': {
        paddingRight: 6
      }
    }
  }
})(Step);

const useCustomStepIconStyles = makeStyles({
  root: {
    color: '#1D4ED8',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#1D4ED8 !important',
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: '#94A3B8',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  completed: {
    fontSize: 28,
  },
});

function CustomStepIcon(props: StepIconProps) {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;
  const stepIconClass = [classes.circle, 
    active && classes.active].filter(Boolean)
  .join(' ');


  return (
    <div
      className={classes.root}
    >
      {completed ? <CheckCircleIcon className={classes.completed} /> : 
      <div className={stepIconClass}><Text size='sm' weight='extraBold' variant='white' family='inter'>{props.icon}</Text></div>}
    </div>
  );
}

const StepperWeb: React.FC<any> = ({ activeStep }) => {

  const steps = ['0', '1', '2', '3', '4', '5', '6'];

  return(
    <Box>
      <Stepper activeStep={activeStep} connector={<CustomStepConnector />}>
        {steps.map((label:string) => (
          <CustomStep key={label}>
            <StepLabel StepIconComponent={CustomStepIcon} />
          </CustomStep>
        ))}
      </Stepper>
    </Box>
  )
}

export default StepperWeb;