import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  
  otp: string;
  otpError: string;
  adminOtp: Array<number | undefined>;
  currentLanguage:'en'|'ar'
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SuperAdminGetOtpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    confirmOtpCallId: string =  ""; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),

    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start

      otp: '',
      otpError: '',
      adminOtp: [],
      currentLanguage: 'en'
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.confirmOtpCallId) {
         if (responseJson.error) {
            this.setState({otpError: responseJson.error})
        } else {
          this.handleRouting('SuperAdminResetPassword')
        }
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start 
  async componentDidMount() {
    super.componentDidMount();
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language });
  }

  getLocalData =  async (key: string) => {
    const data = getStorageData(key)
    return data
  }

  handleChangeOtp = {
    onChangeText: (index: any, event: any) => {
      const value = parseInt(event.target.value);
      const numbers = [...this.state.adminOtp];
      if (!isNaN(value)) {
        numbers[index] = value;
      } else {
        numbers[index] = undefined
      }
      this.setState({ adminOtp: numbers });
      this.setState({otpError: ''})
    }
  }

  submitOtp = () => {
    if (this.state.adminOtp.length != 5) {
      this.setState({otpError: "Please enter valid otp"})
    } else {
      this.setState({otp: this.state.adminOtp.join()})
      this.sendData()
    }
  }

  async sendData () {

    const email = await this.getLocalData('email')
    const headers = {"Content-Type": "application/json"};

    const bodyData = {
      admin_user: {
        email: email,
        otp: this.state.otp.replace(/,/g, '')
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      
    this.confirmOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmOtp
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleRouting = (url: any) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), url);

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  // Customizable Area End
}
