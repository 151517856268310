import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { removeStorageData, getStorageData } from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/userPlan.json');

interface ErrorItem {
    cardNumErr: string;
    cardHoldErr: string;
    expDateErr: string;
    cvvErr: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage: "en" | "ar";
    selectedPayMethod: string;
    autoRenewal: boolean;
    showModal: boolean;
    cardNumber: string;
    cardHolderName: string;
    expDate: string;
    cvvNumber: string;
    error: ErrorItem;
    checkAddPayMeth: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserPlanController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage: "en",
            selectedPayMethod: "visa",
            autoRenewal: false,
            showModal: false,
            cardNumber: "",
            cardHolderName: "",
            expDate: "",
            cvvNumber: "",
            error: {
                cardNumErr: "",
                cardHoldErr: "",
                expDateErr: "",
                cvvErr: ""
            },
            checkAddPayMeth: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        const language = await utills.getLanguageOrSetCurrentLanguage();
        let token = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        if (!token || !user ) {
            this.handleNavigationRouting("Home");
        }
        this.setState({
            currentLanguage: language
        });
    };
    
    handleNavigationRouting = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.handleLogOut()
        } else {
            this.handleNavigationRouting(route);
        }
    };

    handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigationRouting("Home");
    };

    engArabicNumeral = (value: string): string => {
        const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        if(this.state.currentLanguage === "ar") {
            return String(value).replace(/\d/g, (d) => arabicNumerals[parseInt(d)]);
        } else {
            return value;
        }
    };

    handlePaymentMethodSelect = (method: string) => {
        this.setState({selectedPayMethod: method});
    };

    handleAutoRenew = () => {
        this.setState({autoRenewal: !this.state.autoRenewal});
    };

    handleModalOpen = () => {
        this.setState({showModal: true});
    };

    handleModalClose = () => {
        this.setState({
            showModal: false,
            checkAddPayMeth: false,
            cardHolderName: "",
            cardNumber: "",
            expDate: "",
            cvvNumber: ""
        });
    };

    handleCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const sanitizedValue = value.replace(/\D/g, '');
        const limitedValue = sanitizedValue.slice(0, 16);
        const formattedValue = limitedValue.replace(/(\d{4})(?=\d)/g, '$1 ');
        this.setState({cardNumber: formattedValue}, () => this.handleErrorCheck());
    };

    handleCardHolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({cardHolderName: event.target.value}, () => this.handleErrorCheck());
    };

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        
        value = value.replace(/\D/g, '');
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
        if (value.length > 2 && !value.includes('/')) {
            value = value.slice(0, 2) + '/' + value.slice(2);
        }
        let parts = value.split('/');
        if (value.length > 2) {
            const month = parseInt(parts[0], 10);
            if (month > 12) {
                parts[0] = '12';
            } else if (month < 1) {
                parts[0] = '01';
            }
            parts[0] = parts[0].slice(0, 2);
        }
        if (parts[1] && parts[1].length === 4) {
            parts = this.handleValidYear(parts[0], parts[1]);
        }
        value = parts.join('/');
        this.setState({ expDate: value }, () => this.handleErrorCheck());
    };

    handleValidYear = (monthGet: string, yearGet: string) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth()+1;
        let year = yearGet;
        let month = monthGet;
        if (parseInt(yearGet, 10) < currentYear) {
            year = currentYear.toString();
            if(parseInt(monthGet, 10) > currentMonth) {
                if(currentMonth < 10) {
                    month = `0${currentMonth.toString()}`;
                } else {
                    month = currentMonth.toString();
                }
            }
        }
        return [month, year];
    };

    handleCvvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const sanitizedValue = value.replace(/\D/g, '');
        const limitedValue = sanitizedValue.slice(0, 4);
        this.setState({cvvNumber: limitedValue}, () => this.handleErrorCheck());
    };

    handleErrorCheck  = () => {
        let cardNumErr = "", cardHoldErr = "", expDateErr = "", cvvErr = "";
        const { cardNumber, cardHolderName, expDate, cvvNumber, currentLanguage } = this.state;
        const { errors } = languageTranslationData[currentLanguage];
        if(cardNumber.length < 19) {
            cardNumErr = errors.cardNumErr;
        }
        if(cardHolderName.length < 3) {
            cardHoldErr = errors.cardHoldErr;
        } 
        if(expDate.length < 7) {
            expDateErr = errors.expDateErr;
        } 
        if(cvvNumber.length < 3) {
            cvvErr = errors.cvvErr;
        }
        this.setState({
            error: { cardNumErr, cardHoldErr, expDateErr, cvvErr }
        });
        return { cardNumErr, cardHoldErr, expDateErr, cvvErr };
    };

    handleAddPaymentMethod = () => {
        const error  = this.handleErrorCheck();
        this.setState({
            checkAddPayMeth: true
        });
        let checkErrors = Object.values(error).every((value) =>
            value === undefined || value.length === 0
        );
        if( checkErrors ) {
            this.setState({
                checkAddPayMeth: false,
                showModal: false
            });
        }
    };

    // Customizable Area End
}