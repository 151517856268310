import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import AdminDashboardController, { Props, configJSON } from "./AdminDashboardController.web";
import { Avatar, Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from "@material-ui/core";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { tredingUp, tredingDown } from "./assets";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/AdminDashboard.json');
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const collectionData = [
    ["#669059", "Medical Debt Package", "Accepted"],
    ["#669059", "Auto Loan Portfolio", "Accepted"],
    ["#669059", "Personal Loan Debt", "Accepted"],
    ["#669059", "Retail Credit Accounts", "Pending"],
    ["#669059", "Healthcare Debt Portfolio", "Pending"],
    ["#669059", "Healthcare Debt Portfolio", "Pending"],
    ["#669059", "Healthcare Debt Portfolio", "Pending"]
];

const AuditData = [
    ["Sarah Collins", "Created", "User Account"],
    ["Michael Thompson", "Updated", "User Account"],
    ["Sarah Collins", "Deleted", "User Account"],
    ["Sarah Collins", "Logged In", "User Account"],
    ["Michael Thompson", "Logged Out", "User Account"]
];

const TransactionData = [
    ["#669059", "Yearly", "Pending"],
    ["#669059", "Single Use", "Successful"],
    ["#669059", "Yearly", "Failed"],
    ["#669059", "Single Use", "Pending"],
    ["#669059", "Yearly", "Successful"]
];

const chartXLabels = [
    "May 1",
    "",
    "",
    "May 16",
    "",
    "",
    "May 32",
    "",
    "",
    "May 32",
    "",
    "",
    "May 16",
  ];

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    mainContainer : {
        width: "100%",
        padding:"20px",
        boxSizing:"border-box"
    }
    // Customizable Area End
}

export class AdminDashboard extends AdminDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderTable = (tableID: string, heading: string, tabHeading: string[], data: string[][], route: string) => {
        const { language } = this.state;
        const configLang = configJSON.commonLanguageData[language];
        const cellStyle: React.CSSProperties = {
            textAlign: language === "ar" ? 'right' : 'left'
        };
        return (
            <Box>
                <Box style={{display: "flex", justifyContent: "space-between", marginBottom: "12px"}}>
                    <Typography style={webStyle.mainHeadingText}>{heading}</Typography>
                    <Box 
                        style={webStyle.seeAllTextStyle}
                        data-test-id = {`${tableID}SeeAllTestID`}
                        onClick={() => this.handleNavigationRoute(route)}
                    >
                        <Typography style={{whiteSpace: 'nowrap'} as React.CSSProperties}>{configLang.Seeall}</Typography>
                        {
                            language === "en"? 
                                <ArrowForwardRoundedIcon style={{width: "20px"}}/>:
                                <ArrowBackRounded style={{width: "20px"}}/>
                        }
                    </Box>
                </Box>
                <TableContainer style={webStyle.tableContainerStyle} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <CustomHeadingRowStyle>
                                {tabHeading.map((value, index) =>
                                    <React.Fragment key={index}>
                                        {index !== 2 ?
                                            <TableCell style={cellStyle}>{value}</TableCell> :
                                            <TableCell colSpan={2} style={cellStyle}>{value}</TableCell>
                                        }
                                    </React.Fragment>
                                )}
                            </CustomHeadingRowStyle>
                        </TableHead>
                        <TableBody>
                            {data.map((value, index) =>
                                <CustomBodyRowStyle key={index}>
                                    {value.map((item, index) =>
                                        <React.Fragment key={index}>
                                            {this.renderTableCell(tableID, item, index, value)}
                                        </React.Fragment>
                                    )}
                                    <TableCell key={index} style={{textAlign: language === "en"? "right": "left"}}>
                                        <IconButton>
                                            <MoreVertIcon style={{ color: "#64748B", cursor: "pointer" }} />
                                        </IconButton>
                                    </TableCell>
                                </CustomBodyRowStyle>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };

    renderTableCell = (tableID: string, item: string, index: number, value: string[]) => {
        const cellStyle: React.CSSProperties = {
            textAlign: this.state.language === "ar" ? 'right' : 'left'
        };
        return (
            <>
            {((index !== 2 && tableID!=="audit") || (index !== 1 && tableID==="audit"))?
                <>
                    { (index === 0 && tableID==="audit")?
                        <TableCell style={cellStyle}>
                            <Box style={webStyle.tableUserBox}>
                                <Avatar src={""} alt="user_image" style={{width: "40px", height: "40px"}}/>
                                <Typography style={{fontSize: "12px"}}>
                                    {item}
                                </Typography>
                            </Box>
                        </TableCell>:
                        <TableCell style={cellStyle}>{item}</TableCell>
                    }
                </>:
                <TableCell style={cellStyle}>
                    <span
                        style={{
                            ...webStyle.statusStyleSpan,
                            ...this.handleStatusColor(tableID==="audit"?value[1]:value[2])
                        }}
                    >
                        {tableID==="audit"?value[1]:value[2]}
                    </span>
                </TableCell>
            }
        </>
        );
    };

    renderChart = () => {
        const { Sales } = languageTranslationData[this.state.language];
        const options: ApexOptions = {
            chart: {
              id: "basic-bar",
              toolbar: {
                show: false
              },
            },
            legend: {
              show: false
            },
            dataLabels: {
              enabled: false
            },
            yaxis: {
              labels: {
                show: false
              },
            },
            xaxis: {
              categories: chartXLabels,
              axisTicks: {
                show: false
              },
              tooltip: {
                enabled: false
              },
              labels: {
                style: {
                  colors: "#64748B",
                  fontSize: "12px"
                },
              },
            },
            fill: {
              type: "gradient",
              colors: ["#375ABF", "#1E3A8A"],
              gradient: {
                shadeIntensity: 0,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              }
            },
            grid: {
              show: true,
              borderColor: "#E2E8F0",
              strokeDashArray: 10,
              position: "back",
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: false
                }
              }
            },
            tooltip: {
              custom: ({
                series,
                seriesIndex,
                dataPointIndex
              }: {
                series: number[][];
                seriesIndex: number;
                dataPointIndex: number;
              }) => {
                const value = series[seriesIndex][dataPointIndex];
                return `<Box style=" padding: 5px 25px; color: white; background: #030229; border: 1px solid #ddd; border-radius: 10px;">
                    <span style="color: #CBD5E1; font-size: 12px">${Sales}</span><br>
                    <span style="color: #fff">${this.engArabicNumeral(`${value.toString().substring(0,1)},${value.toString().substring(1)}`)}</span>
                  </Box>`;
              },
            },
          };
      
          const series = [
            {
              name: "commit",
              data: [
                2454, 2454, 2394, 2054, 2054, 2143, 2003, 2343, 2143, 2043, 2700,
                2343, 2343
              ]
            }
          ];
          
          return (
            <StyledChartWrapper>
                <Chart
                  options={{
                    ...options,
                    stroke: { width: 2 },
                    colors: ["#1E3A8A", "red"]
                  }}
                  series={series}
                  type="area"
                  height={370}
                />
            </StyledChartWrapper>
          );
    };

    renderChartCount = () => {
        const languageData = languageTranslationData[this.state.language];
        const { countData, chartCountData } = languageData;
        return (
            <>
                {[
                    {id: "newUser", value: "2,2", title: chartCountData.Newusers},
                    {id: "newAcc", value: "1,3", title: chartCountData.Newaccounts},
                    {id: "transaction", value: "6,3", title: chartCountData.Transactions}
                ].map((value, index)=> 
                    <Box 
                        key={index} 
                        data-test-id = {`${value.id}TestID`}
                        style={{
                            ...webStyle.chartCountItemBox,
                            borderLeft: index === 0? "none": "1px solid #E2E8F0",
                            borderBottom: this.state.selChartCount === value.id? "none": "1px solid #E2E8F0",
                            background: this.state.selChartCount === value.id? "white": "#F8FAFC"
                        }}
                        onClick = {() => this.handleChartCountSelect(value.id)}
                    >
                        <Typography 
                            style={{
                                ...webStyle.chartCountValue,
                                color: this.state.selChartCount === value.id? "#1D4ED8": "#64748B"
                            }}
                        >
                            {`${this.engArabicNumeral(value.value)}${countData.kilok}`}
                        </Typography>
                        <Typography 
                            style={{
                                ...webStyle.chartCountLabel,
                                color: this.state.selChartCount === value.id? "#0F172A": "#94A3B8"
                            }}
                        >
                            {value.title}
                        </Typography>
                    </Box>
                )}
            </>
        );
    };

    renderDateInput = () => {
        const { language } = this.state;
        const dir = language === "en" ? "ltr" : "rtl";
        const configLang = configJSON.commonLanguageData[language];
        return (
            <Box style={webStyle.chartInpWrapper}>
                <Box style={{width: "100%"}}>
                    <Typography dir={dir} style={webStyle.filtDateLabel}>{configLang.From}:</Typography>
                        <FiltDateInputField
                            type="date"
                            data-test-id = "startDateInputTestID"
                            variant="outlined"
                            value={this.state.startDate}
                            name="startDate"
                            fullWidth
                            onChange={this.handleDateChange}
                        />
                </Box>
                <Box style={{width: "100%"}}>
                    <Typography dir={dir} style={webStyle.filtDateLabel}>{configLang.To}:</Typography>
                        <FiltDateInputField
                            type="date"
                            variant="outlined"
                            name="endDate"
                            fullWidth
                            value={this.state.endDate}
                            onChange={this.handleDateChange}
                        />
                </Box>
            </Box>
        );
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { language } = this.state;
        const languageData = languageTranslationData[language];
        const { 
                countData, 
                Collections, 
                AuditLogs, 
                RecentPlantransactions, 
                CollHeading, 
                AudHeading, 
                TranHeading
              } = languageData;
        const CollHeadingArr: string[] = Object.values(CollHeading);
        const AudHeadingArr: string[] = Object.values(AudHeading);
        const TranHeadingArr: string[] = Object.values(TranHeading);
        return (
            <UserProfileDrawer
                handleClick={this.handleClickOfDrawer}
                pageName={"dashboard"}
                data-test-id="drawerTestID"
                userType={"admin_account"}
                setBackground={true}
                navigation={this.props.navigation}
            >
                <Typography style={webStyle.pageHeading}>{languageData.Dashboard}</Typography>
                <Box style={webStyle.countMainWrapper}>
                    {[
                        {label: countData.Users, value: "12,2", trendValue: "+13.5"},
                        {label: countData.ActiveListings, value: "5,4", trendValue: "+13.5"},
                        {label: countData.Transactions, value: "16,1", trendValue: "+13.5"},
                        {label: countData.Verifications, value: "1,1", trendValue: "-13.5"}
                    ].map((value, index)=>
                        <Box key={index} style={webStyle.countItemBox}>
                            <Typography style={webStyle.countLabel}>{value.label}</Typography>
                            <Typography style={webStyle.countValue}>{`${this.engArabicNumeral(value.value)}${countData.kilok}`}</Typography>
                            <Box style={{...webStyle.countPercentValue, ...this.handleCountPercStyle(value.trendValue)}}>
                                <span>{this.engArabicNumeral(value.trendValue)}%</span>
                                {
                                    value.trendValue[0]==="+"?
                                    <img src={tredingUp} alt="treding_up_icon"/>:
                                    <img src={tredingDown} alt="treding_down_icon"/>
                                }
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box>
                    <MainGrid container spacing={4} style={{marginBottom: "20px"}}>
                        <RightGrid item>
                            <Box>
                                <Box style={webStyle.chartCountWrapper}>
                                    {this.renderChartCount()}
                                </Box>
                                <Box style={webStyle.chartWrapper}>
                                    {this.renderDateInput()}
                                    {this.renderChart()}
                                </Box>
                            </Box>
                        </RightGrid>
                        <LeftGrid item>
                            {this.renderTable("collection", Collections, CollHeadingArr, collectionData, "dashboardCollections")}
                        </LeftGrid>
                    </MainGrid>
                    <MainGrid container spacing={4}>
                        <RightGrid item>
                            {this.renderTable("audit", AuditLogs, AudHeadingArr, AuditData, "dashboardAuditlog")}
                        </RightGrid>
                        <LeftGrid item>
                            {this.renderTable("transaction", RecentPlantransactions, TranHeadingArr, TransactionData, "dashboardPlanTransaction")}
                        </LeftGrid>
                    </MainGrid>
                </Box>
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(AdminDashboard);

const MainGrid = styled(Grid)({
    flexWrap: "nowrap",
    marginTop: "15px",
    "@media(max-width: 992px)":{
        flexWrap: "wrap"
    }
});

const FiltDateInputField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px",
            top: "0px",
            border: "1px solid #CBD5E1"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        fontWeight: "400",
        color: "#0F172A",
        fontSize: "14px",
        top: "0px",
        "&.Mui-disabled": {
            WebkitTextFillColor: "#0F172A"
        }
    },
    "& .MuiInputBase-input::placeholder": {
        opacity: 1,
        fontSize: "16px",
        color: "#94A3B8",
    }
});

const StyledChartWrapper = styled("div")({
    "& .apexcharts-tooltip": {
      backgroundColor: "transparent !important",
      border: "none !important",
      boxShadow: "none !important"
    }
  });

const RightGrid = styled(Grid)({
    flexBasis: "59%",
    width: "268px",
    "@media(max-width: 992px)":{
        flexBasis: "100%",
        width: "100%"
    }
});

const LeftGrid = styled(Grid)({
    flexBasis: "42%",
    width: "224px",
    "@media(max-width: 992px)":{
        flexBasis: "100%",
        width: "100%"
    }
});

const CustomHeadingRowStyle = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        padding: "10px 16px",
        color: "#475569",
        fontWeight: 600,
        fontSize: "14px",
        whiteSpace: "nowrap"
    }
});

const CustomBodyRowStyle = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#1E293B",
        padding: "3px 16px",
        fontSize: "12px"
    }
});

const webStyle = {
    chartCountWrapper: {
        display: "flex",
        border: "1px solid #E2E8F0",
        borderBottom: "none",
        borderRadius: "16px 16px 0 0",
        overflow: "hidden"
    },
    tableUserBox: {
        display: "flex",
        gap: "15px",
        alignItems: "center",
        color: "#1E293B",
        fontSize: '12px',
        width: "fit-content"
    },
    chartWrapper: {
        position: "relative",
        marginTop: "25px"
    } as React.CSSProperties,
    chartInpWrapper: {
        position: "absolute",
        display: "flex",
        gap: "20px",
        paddingBottom: "10px",
        zIndex: 4,
        background: "white",
        width: "100%",
        maxWidth: "380px"
    } as React.CSSProperties,
    filtDateLabel: {
        fontWeight: 600,
        color: "#334155",
        fontSize: "12px"
    },
    chartCountItemBox: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "16px 24px",
        borderBottom: "1px solid #E2E8F0",
        flexGrow: 1,
        cursor: "pointer"
    } as React.CSSProperties,
    chartCountValue: {
        fontSize: "24px",
        color: "#64748B",
        fontWeight: 500
    } as React.CSSProperties,
    chartCountLabel: {
        fontSize: "16px",
        color: "#94A3B8",
        fontWeight: 500
    } as React.CSSProperties,
    countLabel: {
        fontSize: "16px",
        color: "#94A3B8",
        fontWeight: 500
    },
    seeAllTextStyle: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        width: "fit-content",
        cursor: "pointer",
        color: "#1E3A8A",
        fontSize: "16px",
        fontWeight: 700
    },
    mainHeadingText: {
        fontSize: "20px",
        color: "#0F172A",
        fontWeight: 700
    },
    tableContainerStyle: {
        borderRadius: "16px",
        width: "100%",
        border: "1px solid #E2E8F0",
        boxShadow: "none",
        overflow: "auto"
    },
    countValue: {
        fontSize: "30px",
        color: "#0F172A",
        fontWeight: 600
    },
    statusStyleSpan: {
        color: "#059669",
        textTransform: "uppercase",
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#D1FAE5",
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    countPercentValue: {
        fontSize: "12px",
        fontWeight: 600,
        borderRadius: "25px",
        border: "1px solid",
        padding: "5px 15px",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    pageHeading: {
        fontSize: "30px",
        color: "#0F172A",
        fontWeight: 700
    },
    countMainWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "15px"
    } as React.CSSProperties,
    countItemBox: {
        border: "1px solid #E2E8F0",
        borderRadius: "16px",
        padding: "20px",
        width: "160px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        background: "white"
    } as React.CSSProperties,
}

// Customizable Area End
