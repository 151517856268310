import React from "react";
// Customizable Area Start
import UserLoginWebController, {
    Props
} from "./UserLoginPageController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import { Box, FormControl, IconButton, InputAdornment } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import Button from "../../../components/src/design-system/Button/Button";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { LoginFlow } from "../../../components/src/LoginFlow.web";
import TextInput from "../../../components/src/design-system/TextInput";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Login.json')
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    labelText: {
        fontWeight: 700,
        fontSize: "12px",
        margin: "0px",
        color: "#222222",
    },
    containerAuto: {
        margin: '0 auto',
        "@media (max-width: 960px)": {
            padding: "40px 20px"
        }
    },
    containerLogin: {
        padding: '44px 100px',
        height: '100%',
        boxSizing: 'border-box',
        "@media (max-width: 960px)": {
            padding: "40px 20px"

        },
        "@media (max-width: 600px)": {
            padding: "40px 20px",
        },
    },
    link: {
        marginLeft: 5,
        "&:hover": {
            textDecoration: 'underline'
        },
        cursor: 'pointer',
    },
    gridWrapper:{
        borderRadius: '10px', overflow: 'auto' 
    },
    errorBox: {
        padding: '12px 16px 12px 16px',
        borderRadius: '4px 0px 0px 0px',
        background: '#FEE2E2',
        borderLeft: '4px solid #DC2626',
        color: '#DC2626',
        marginBottom: 10
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class UserLoginWeb extends UserLoginWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage, errors } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <LoginFlow>
                <Box className={classes.containerLogin}>
                    <Box display="flex"  mb={3}><Text variant="primary" size="xl" weight="extraBold" family="inter">{languageData.Buyer.Title}</Text></Box>
                    {!this.isStringNullOrBlank(errors) && 
                    <Box className={classes.errorBox}>
                        <Text>{errors}</Text>
                    </Box>}
                    <Box mb={3} >
                        <FormControl variant="outlined" fullWidth>
                            <TextInput
                                type="text"
                                label={languageData.Buyer.Email}
                                id="Username-input"
                                value={this.state.userName}
                                error={this.state.localErrors.Email}
                                placeholder={languageData.Buyer.EmailPlaceholder}
                                onChange={this.txtInputEmailWebPrpos.onChangeText}
                                aria-describedby="Username-helper-text"
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl variant="outlined" fullWidth>
                            <TextInput
                                id="password-input"
                                label={languageData.Buyer.Password}
                                type={this.state.showPassword ? "text" : "password"}
                                value={this.state.password}
                                onChange={this.txtInputPasswordWebPrpos.onChangeText}
                                aria-describedby="password-helper-text"
                                error={this.state.localErrors.Password}
                                placeholder={languageData.Buyer.PasswordPlaceholder}
                                endAdornment={
                                    <InputAdornment position="end" style={{ position: 'absolute', top: "27px", right: "0px" }}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {this.state.showPassword ? (
                                                <VisibilityOutlinedIcon />
                                            ) : (
                                                <VisibilityOffOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                    <Box pr={1} mb={2}>
                        <span id="password-forgot"
                            onClick={() => this.handleNavigation('NewPassword')}
                            className={classes.link}
                        >
                            <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="xx">
                                {languageData.Buyer.ForgotPassword}?
                            </Text>
                        </span>
                    </Box>
                    <Button
                        data-testid="login-btn"
                        onClickHandle={() => this.loginButton.onClick()}
                        themes="dark"
                    >
                        {languageData.Buyer.LoginBtn}
                    </Button>

                    <Box mt={2} mb={0}>
                        <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
                            {languageData.Buyer.DontHaveAccount}
                            <span className={classes.link} id="test-signup" onClick={() => this.handleNavigation('SellerRegistration')}>
                            <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Buyer.Signup}</Text></span>
                        </Text>
                    </Box>
                </Box>
            </LoginFlow>
                        
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(UserLoginWeb);
