import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills')

export interface IndustriesPageData {
    id: string;
    type: string;
    attributes: {
        id: number;
        sequence: number;
        title: string;
        description: string;
        image: string | undefined;
        title_in_arabic: string;
        description_in_arabic: string;
    };
}

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    industriesPageData: IndustriesPageData[];
    currentLanguage: 'en' | 'ar'
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class IndustrialPageWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    industriesPageGetApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            industriesPageData: [],
            currentLanguage: configJSON.EN
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.industriesPageGetApiCallId) {
                    this.setState({ industriesPageData: responseJson.data, loading: false })
                }
            }
            if (errorReponse) {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({ currentLanguage: language });
        this.getIndustriesPageData()        
        // Customizable Area End
    }

    // Customizable Area Start

    getIndustriesPageData = () => {
        this.setState({ loading: true })
        const header = {
            "Content-Type": configJSON.validationApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.industriesPageGetApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.industriesPageEndPoint
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}
