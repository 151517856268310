// Customizable Area Start
import React from "react";
import { Box, Button, IconButton, InputAdornment, Popover, styled, Checkbox } from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import SellerRegistrationController, { ISelectDoc } from "./SellerRegistrationController.web";
import { Props } from "./EmailAccountRegistrationController";
import { uploadIcon } from "./assets";
import Image from "../../../components/src/design-system/Image/Image";
import Text from "../../../components/src/design-system/Text";
import StepperWeb from "../../../components/src/design-system/Stepper.web";
import AccountTypeWeb from "../../../components/src/sign-up/AccountType.web";
import AccountSubTypeWeb from "../../../components/src/sign-up/AccountSubType.web";
import VerificationWeb from "../../../components/src/sign-up/Verification.web";
import DetailInformationWeb from "../../../components/src/sign-up/DetailInformation.web";
import BasicInformationWeb from "../../../components/src/sign-up/BasicInformation.web";
import DebtPortfolioWeb from "../../../components/src/sign-up/DebtPortfolio.web";
import BusinessInterestWeb from "../../../components/src/sign-up/BusinessInterests.web";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import FinalReviewWeb from "../../../components/src/sign-up/FinalReview.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json')
import NotesIcon from '@material-ui/icons/Notes';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PasswordValidationChecker from "../../../components/src/design-system/PasswordValidationChecker.web";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

// Customizable Area Start
const classes = {
  rootMain: {
    width: "100%",
    padding: "40px 0 0 0",
    display: "flex",
    alignItems: "center",
    flexDirection: 'column',
    "& *": {
      boxSizing: 'border-box',
    },
    "@media (max-width: 767px)": {
      padding: "40px 15px",
      boxSizing: 'border-box',
    }
  },
  root: {
    margin: "0 auto",
    padding: "40px 101px 36px 101px",
    maxWidth: "1440px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& h4": {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "600",
      color: "#1E293B",
      marginBottom: "18px",
    },
    "& h5": {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      color: "#334155",
      marginBottom: "67px",
      marginTop: '0px',
    },
    "@media (max-width: 970px)": {
      padding: "40px 0px 36px"
    },
    "@media (max-width: 767px)": {
      padding: "20px 30px 16px 30px",
      "& h4": {
        textAlign: 'center',
      },
      "& h5": {
        textAlign: 'center',
        marginBottom: "25px",
      },
    },
    "@media (max-width: 400px)": {
      padding: "20px 0px 16px"
    }
  },
  form: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: 20,
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      border: 'none',
      "& input": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
      '& input::placeholder': {
        color: '#94A3B8', 
        fontSize: '16px',
        fontWeight: '400',
        opacity: 1
      },
    },
    "& .MuiFormControl-root.MuiTextField-root": {
      marginBottom: "5px",
      width: 380,
      "@media (min-width: 1920px)": {
        width: 430,
      },
    },
    "@media (min-width: 1920px)": {
      gap: 30,
      marginBottom: 15
    },
    "@media (max-width: 767px)": {
      flexDirection: 'column',
      width: '100%',
      gap: "0px",
      "& .MuiFormControl-fullWidth": {
        width: '100% !important',
      }
    }
  },
  formBox: {
    width: "100%",
    marginBottom: 5,
    "& .MuiFormControl-root.MuiTextField-root": {
      marginBottom: "24px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'rgb(255, 0, 0)',
    },
  },
  password: {
    marginBottom: "40px",
    width: "100%",
  },
  imgMargin: {
    marginBottom: "67px",
  },
  rootHeading: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    color: "#1E293B",
    marginBottom: "18px",
  },
  rootSubHeading: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#334155",
    marginBottom: "67px",
  },
  formMain: {
    display: "flex",
    gap: "20px",
    maxWidth: "780px",
    width: "100%",
    "@media (max-width: 767px)": {
      flexDirection: 'column',
    }
  },
  formMainHalf: {
    width: "50%",
    marginTop: 20,
    "@media (max-width: 767px)": {
      width: "100%",
    }
  },
  fileInputWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    borderRadius: "8px",
    gap: "14px",
  },
  noteIconBox: {
    backgroundColor: "#DBEAFE",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "56px",
    height: "56px",
    cursor: "pointer",
  },
  noteIcon: {
    color: "#2563EB",
  },
  editBtn: {
    textTransform: "none",
    color: '#1E3A8A',
    fontWeight: "700",
    fontFamily: "inter"
  },
  fileMoreIcon: {
    color: "#475569",
    cursor: "pointer",
  },
  formFileLabelFile: {
    width: "100%",
    marginBottom: "24px",
    "& .BusinessRegistration": {
      display: "none",
    },
    "& .fileBorder": {
      borderRadius: "4px",
      border: "1px dashed #CBD5E1",
      padding: "25px 50px",
      display: "inline-block",
      width: "calc(100% - 0px)",
      boxSizing: "border-box",
      height: "auto"
    },
    "& .inputBorderText": {
      textAlign: "center",
      width: "100%",
    },
    "& .inputBorderTextDrag": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#0F172A",
      marginBottom: "10px",
      width: "100%",
      overflowWrap: 'break-word',
      fontFamily: "inter"
    },
    "& .inputBorderTextDragSupported": {
      fontSize: "12px",
      fontFamily: "inter",
      fontWeight: "400",
      color: "#64748B",
      width: "100%",
    },
  },
  uploadIcon: {
    marginBottom: "20px",
  },
  formBorderBox: {
    borderRadius: '24px',
    boxShadow: '0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008',
    padding: '40px'
  },
  popoverContent: {
    position: "relative",
    "&::before": {
      backgroundColor: "red",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      top: 166,
      transform: "rotate(45deg)",
      left: "calc(50% - 6px)"
    }
  },
  termAlert: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "inter",
    color: '#DC2626',
    width: 384,
    borderRadius: 4,
    borderLeft: "4px solid #DC2626"
  },
  formBtn: {
    background: "#1E3A8A",
    color: '#fff',
    borderRadius: "8px",
    padding: 12,
    textTransform: "none" as const,
    fontSize: 16,
    fontWeight: 700,
    width: 320
  },
  backBtn: {
    background: "#EFF6FF",
    color: '#0F172A',
    borderRadius: "8px",
    padding: 12,
    textTransform: "none" as const,
    fontSize: 16,
    fontWeight: 700,
    width: 320
  },
  uploadBtn: {
    background: "#EFF6FF",
    color: '#0F172A',
    borderRadius: "8px",
    padding: 12,
    textTransform: "none" as const,
    fontSize: 16,
    fontWeight: 700,
    width: 140,
    zIndex: -1
  },
  buttons: {
    flexDirection: "row",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    }
  }
};

export const configJSON = require("./config");

const Arrow = styled(Box) ({
  position: "absolute",
  top: "100%",
  left: "10%",
  transform: "translate(-50%)",
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderTop: "10px solid #fff"
});

const NextButton = styled(Button)({
  "@media (max-width: 400px)": {
    width: "300px !important"
  }
});

// Customizable Area End

class SellerAccountRegistration extends SellerRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFileBox = (value: File) => {
    return (
      <Box style={classes.fileInputWrapper}>
        <Box style={classes.noteIconBox}>
          <NotesIcon style={classes.noteIcon}/>
        </Box>
        <Box style={{flexGrow:1}}>
          <Text size="sm" weight="minBold" variant="lightBlue">{value.name}</Text>
          <Text size="sm" weight="minBold" variant="neutrals">{value.size / 1000} KB</Text>
        </Box>
        <IconButton 
            id={value.name}
            aria-label="more1"
            aria-haspopup="true"
            disabled
            onClick={() => {}}
        >
            <MoreVertIcon style={classes.fileMoreIcon} />
        </IconButton>
      </Box>
    );
  };

  renderDragAndDropInput = (props: ISelectDoc) => {
    const { onChange, label, errorName, selectedDocInfo,testId } = props;
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    return <Box sx={classes.formBox}>
      <Box marginBottom="5px">
        <Text size="xx" weight="extraBold" family="inter" variant="grey">
          {label}
        </Text>
      </Box>
      <Box sx={classes.formFileLabelFile}>
        <label className={!selectedDocInfo ? 'fileBorder' : ""}>
          <Box className="BusinessRegistration">
            <TextInput
              id={testId}
              label=""
              manualWidth={380}
              onChange={onChange}
              type="file"
            />
          </Box>
          <Box className="inputBorderText">
            {selectedDocInfo && selectedDocInfo.name ? (
              this.renderFileBox(selectedDocInfo)
            ) : (
              <>
                <Box sx={classes.uploadIcon}>
                  <Image src={uploadIcon} alt="icon" />
                </Box>
                <Box className="inputBorderTextDrag">
                  {languageData.DetailedInformationData.UploadDocument}
                </Box>
                <Box className="inputBorderTextDragSupported">
                  {languageData.DetailedInformationData.UploadDocumentDesc}
                </Box>
                <Box mt={3} display="flex" justifyContent="center">
                  <Button style={classes.uploadBtn}>{languageData.DetailedInformationData.UploadDocumentButton}</Button>
                </Box>
              </>
            )}
          </Box>
        </label>
        {
          this.state.errors[errorName] &&
          <Text variant="error" family="inter" weight="minBold" size="xs">{this.state.errors[errorName]}</Text>
        }
      </Box>
    </Box>
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { currentLanguage, passwordFieldChecklist, activeStep, accountType, accountSubType, verificationCode } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    const isArabic =  currentLanguage === 'ar';
    const renderTextInput = (id: string, label: string, value: string, onChange: (value: any) => void, type: string, error: string, endAdornment: boolean = false, disabled: boolean = false) => (
      <>
      <TextInput
        id={id}
        label={label}
        onChange={onChange}
        value={value}
        type={type}
        error={error}
        disabled={disabled}
        placeholder={languageData.Placeholder[id]}
        isArabic={isArabic}
        startAdornment={id === 'password' && 
          <IconButton onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}>
            <HelpOutlineIcon 
              data-testid="password-help"
              htmlColor="#64748B" style={{ position: "absolute",
              top: "-18px",left: isArabic ? 'auto' : 60, right: isArabic ? 60 : 'auto', width: 20, height: 20 }} /></IconButton> }
        endAdornment={ endAdornment && (<InputAdornment position="end" style={{ position: 'absolute', top: "22px", right: currentLanguage === 'ar' ? "auto" : 0, left: currentLanguage === 'ar' ? "0" : "auto" }}>
        <IconButton
            aria-label="toggle password visibility"
            onClick={this.handleClickShowPassword}
            id="show-hide-password"
        >
            {this.state.showPassword ? (
                <VisibilityOutlinedIcon style={{color: "#94A3B8"}}/>
            ) : (
                <VisibilityOffOutlinedIcon style={{color: "#94A3B8"}}/>
            )}
        </IconButton>
      </InputAdornment>)
  }

      />
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: 'none',
        }}
        PaperProps={{
          style: {
            marginBottom: 20,
            backgroundColor: "#fff",
            overflow: 'visible',
            borderRadius: 8,
            boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
          }
        }}
        open={Boolean(this.state.anchorEl)}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={this.handlePopoverClose}
        disableRestoreFocus
    >
        <PasswordValidationChecker passwordFieldChecklist={passwordFieldChecklist} currentLanguage={currentLanguage} />
        <Arrow />
      </Popover>
     </>
    );
    return (
      <ThemeProvider theme={this.theme}>
        <Box sx={classes.rootMain}>
          <StepperWeb activeStep={activeStep} />
          <Box sx={classes.root}>
            {activeStep === 0 && <AccountTypeWeb             
              accountType={accountType} handleAccountType={this.handleAccountType}
              currentLanguage={currentLanguage} />}
            {activeStep === 1 && <AccountSubTypeWeb accountSubType={accountSubType}
            accountType={accountType} handleAccountSubType={this.handleAccountSubType}
            currentLanguage={currentLanguage}
            />}
            {activeStep === 2 && <BasicInformationWeb
              showPassword={this.state.showPassword}
              basicInformationInputChange={this.basicInformationInputChange}
              basicInformation={this.state.basicInformation}
              renderTextInput={renderTextInput}
              currentLanguage={currentLanguage}
              errors={this.state.errors}
              classes={classes}
              showPersonalDetail={this.showPersonalDetail}
              showBankDetail={this.showBankDetail}
              showAgencyDetail={this.showAgencyDetail}
              showServiceProviderDetail={this.showServiceProviderDetail}
            ></BasicInformationWeb>}
            {activeStep === 3 && <VerificationWeb 
            verificationCode={verificationCode} error={this.state.errors["OTP"]}
            currentLanguage={currentLanguage} email={this.state.basicInformation.email} handleVerificationCode={this.handleVerificationCode}
            />}
            {activeStep === 4 && <DetailInformationWeb accountType={accountType} 
              renderTextInput={renderTextInput}
              currentLanguage={currentLanguage} 
              classes={classes}
              errors={this.state.errors}
              renderDragAndDropInput={this.renderDragAndDropInput} 
              showPersonalDetail={this.showPersonalDetail}
              showBankDetail={this.showBankDetail}
              showAgencyDetail={this.showAgencyDetail}
              showServiceProviderDetail={this.showServiceProviderDetail}
              detailInformationStates={this.state.detailInformation}
              detailInformationInputChange={this.detailInformationInputChange}
            />}
            {activeStep === 5 && this.showDebtPortfolio() && <DebtPortfolioWeb 
              currentLanguage={currentLanguage}
              renderTextInput={renderTextInput}
              errors={this.state.errors}
              debtPortfolioInputChange={this.debtPortfolioInputChange}
              debtPortfolio={this.state.debtPortfolio}
              classes={classes}
            />}
             {activeStep === 5 && this.showBusinessInterest() && <BusinessInterestWeb 
              currentLanguage={currentLanguage}
              renderTextInput={renderTextInput}
              errors={this.state.errors}
              businessInterestInputChange={this.businessInterestInputChange}
              businessInterest={this.state.businessInterest}
              classes={classes}
            />}
            {activeStep === 6 && <FinalReviewWeb 
              showPassword={this.state.showPassword}
              isEditBasicInfo={this.state.isEditBasicInfo}
              isEditDetailInfo={this.state.isEditDetailInfo}
              isEditDebtPortfolio={this.state.isEditDebtPortfolio}
              isEditBusinessInterest={this.state.isEditBusinessInterest}
              basicInformation={this.state.basicInformation}
              basicInformationInputChange={this.basicInformationInputChange}
              debtPortfolio={this.state.debtPortfolio}
              detailInformationStates={this.state.detailInformation}
              renderDragAndDropInput={this.renderDragAndDropInput}
              detailInformationInputChange={this.detailInformationInputChange}
              debtPortfolioInputChange={this.debtPortfolioInputChange} businessInterest={this.state.businessInterest}
              showDebtPortfolio={this.showDebtPortfolio} showBusinessInterest={this.showBusinessInterest}
              businessInterestInputChange={this.businessInterestInputChange} currentLanguage="en" renderTextInput={renderTextInput} classes={classes} showPersonalDetail={this.showPersonalDetail}
              showBankDetail={this.showBankDetail} showAgencyDetail={this.showAgencyDetail} showServiceProviderDetail={this.showServiceProviderDetail} errors={this.state.errors}
              handleBasicEditClick={this.isEditBasicInfo}
              handleDetailEditClick={this.isEditDetailInfo}
              handleDebtPortfolioEditClick={this.isEditDebtPortfolio}
              handleBusinessInterestEditClick={this.isEditBusinessInterest}
              handleFinalReviewCancel={this.handleFinalReviewCancel} updateData={() => this.updateData()} />}
              {activeStep === 6 && this.canSubmitRegistration() && 
              <Box marginTop={5} marginBottom={3} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="6px">
                  {this.state.termsError && <Alert style={classes.termAlert} icon={false} onClose={() => this.closeTermError()} severity="error">
                    {languageData.TermsError}
                  </Alert>}
                  <Box display="flex" alignItems="center">
                    <Checkbox color="primary" data-testid="terms" checked={this.state.terms} onChange={() => this.setTerms() } /><Text size="sm" weight="minBold" variant="lightBlue">{languageData.Terms}</Text>
                  </Box>
                </Box>}
          </Box>
          <Box display="flex" alignItems="center" mb={5} flexDirection="column">
            <Box display="flex" gridGap={8} sx={classes.buttons}>
              {(activeStep !== 0 && this.canSubmitRegistration()) && 
              <NextButton fullWidth style={classes.backBtn} data-testid="back" onClick={() => this.handleBack()}>{languageData.Back}</NextButton>}
              {activeStep === 6  && this.canSubmitRegistration() &&
                <NextButton  fullWidth style={classes.formBtn} onClick={() => { this.createSellerAccount()}} data-testid="submitRegistration">{languageData.SubmitRegistration}</NextButton>
                }
              {activeStep !== 6 && <NextButton  fullWidth style={classes.formBtn} data-testid="nextStep"
                onClick={() => this.createSellerAccount()}>{languageData.NextStep}</NextButton>}
            </Box>
            <Box display="flex" alignItems="center" mt={2.25}>
              <Text size="sm" variant="grey" family="inter" weight="minBold">{languageData.AlreadyHaveAnAccount} </Text>
              <Button variant="text" onClick={() => this.handleRoute('SellerLogin')} style={{ textTransform: "none", cursor: 'pointer' }}>
                <Text variant="primaryBlue" align="center" family="inter" weight="extraBold" size="sm">{languageData.Login}</Text>
              </Button>
            </Box>
        </Box>
      </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  theme = createTheme({
    palette: {
      primary: {
        main: "#1E3A8A",
        contrastText: "#fff",
      },
    },
    typography: {
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  // Customizable Area End
}

// Customizable Area Start
export default SellerAccountRegistration;
// Customizable Area End
