import React from "react";
// Customizable Area Start
import ResetForgotPasswordController, {
    Props
} from "./ResetForgotPasswordController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Text from "../../../components/src/design-system/Text";
import { Button,
    Box as ContainerBox, FormControl as Form, FormHelperText as HelperText, 
} from "@material-ui/core";
import { LoginFlow } from "../../../components/src/LoginFlow.web";
import TextInput from "../../../components/src/design-system/TextInput";
const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')

// Customizable Area End

const loginStyle: StyleRules = {
    // Customizable Area Start
    containerLogin: {
      height: '100%',
      boxSizing: 'border-box',
      padding: '44px 100px',
      "@media (max-width: 960px)": {
          padding: "40px 20px"
  
      },
      "@media (max-width: 600px)": {
          padding: "40px 20px",
          borderRadius: "10px 10px 0px 0px"
      },
    },
    linkText: {
      cursor: 'pointer',
      marginLeft: 5,
      color: '#1E3A8A',
      "&:hover": {
        textDecoration: 'underline'
      },
    },
      inputField: {
        "& .MuiInputBase-root": {
          '& fieldset': {
              top: '-5.5px !important',
          },
        }
    },
    submitBtn: {
        background: "#1E3A8A",
        color: '#fff',
        borderRadius: "8px",
        padding: 12,
        textTransform: "none",
        fontSize: 16,
        fontWeight: 700,
    
        "&:hover": {
            background: "#1E3A8A",
            color: '#fff',    
        }
      }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class SuperAdminForgotPassword extends ResetForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <LoginFlow>
            <ContainerBox className={classes?.containerLogin}>
              <ContainerBox mb={3}>
                <Text variant="primary" size="xl"weight="extraBold">{languageData.Heading}</Text>
              </ContainerBox>
                <ContainerBox mb={2}>
                    <Text size="xm" weight="extraBold">{languageData.Title}</Text>
                </ContainerBox>
                <ContainerBox mb={3}>
                    <Text size="sm" weight="minBold" family="inter">{languageData.AdminDescription}</Text>
                </ContainerBox>
                <ContainerBox display="flex" justifyContent="center">
                    <Text size="1xl" weight="extraBold" family="inter">{languageData.ForgotPassword}</Text></ContainerBox>
                    <ContainerBox className={classes?.inputField} >
                    <ContainerBox mb={'22px'} >
                        <TextInput
                            id="email"
                            label={languageData.EmailAddress+' / '+languageData.Mobile}
                            value={this.state.email}
                            onChange={this.txtInputEmailWebPrpos.onChangeText}
                            placeholder={languageData.EmailPlaceholder +" / "+ languageData.Mobile}
                            isArabic={currentLanguage === 'ar'}
                            error={this.state.errors.Email}
                        />
                    </ContainerBox>
                    <Button
                        fullWidth
                        variant="contained"
                        data-testid="submit-btn"
                        onClick={this.forgotPasswordButton.onClick}
                        className={classes.submitBtn}
                    >
                        {languageData.SendOTP}
                    </Button>
                    </ContainerBox>
                <ContainerBox mt={3} mb={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
                    {languageData.BackTo}
                    <span className={classes.linkText} id="signup-test" onClick={() => this.handleRouting('SuperAdminLogin')}>
                      <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Login}</Text></span>
                  </Text>
              </ContainerBox>
            </ContainerBox>
        </LoginFlow>
        )
        // Customizable Area End
    }
}

export default withStyles(loginStyle)(SuperAdminForgotPassword);
