import React from "react";
// Customizable Area Start
import ListingSummaryController, { Props } from "./ListingSummaryController.web";
import { Box, Select, MenuItem, Typography, Chip, Button, TextField, Divider, IconButton, Card, Breadcrumbs } from "@material-ui/core"
import { viewcircle, image_notes, image } from "./assets";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styled } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { configJSON } from "./EmailAccountLoginController";
// Customizable Area End


// Customizable Area Start
const CustomBreadcrumbs = styled(Breadcrumbs)({
  padding: "0 120px",
  marginTop: '2rem',
});

const CustomBox = styled(Box)({
  display: 'flex',
  gap: '24px',
  justifyContent: 'center',
  marginTop: '12px',
  padding: "0 120px"
});

const InfoBox = styled(Box) ({
  padding: '40px',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
  marginTop: '20px',
  marginBottom: '100px',
  borderRadius: '24px',
  width: '895px',
  height: '880px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const ProfileSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const ServicesBox = styled(Box)({
  paddingTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const ChipBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
});

const OfferBox = styled(Box)({
  height: '600px',
  width: '285px',
  marginTop: '20px',
  padding: '24px',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
});

const StyledTextField = styled(TextField)({
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  marginBottom: '8px',
  '& .MuiInputBase-root': {
    fontSize: '16px',
    fontWeight: 600,
    color: '#94A3B8',
    wordSpacing: '6px',
  },
});

const customStyle = {
  apexHeading: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#0F172A'
  },
  apexLink: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#334155',
    textDecoration: 'underline',
    textDecorationColor: '#334155'
  },
  boxOne: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '40px'
  },
  headOne: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#0F172A'
  },
  descriptionOne: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#334155'
  },
  headTwo: {
    fontWeight: 600,
    fontSize: '20px',
    color: '#0F172A'
  },
  chipStyle: {
    padding: '8px 16px',
    fontSize: '14px',
    backgroundColor: '#E2E8F0',
    fontWeight: 500
  },
  aboutCompany: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#0F172A'
  },
  boxTwo: {
    display: 'flex',
    gap: '24px',
    paddingTop: '40px'
  },
  aboutCompanyTwo: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#0F172A'
  },
  portHEad: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155',
    marginLeft: '8px',
    marginBottom: '2px'
  },
  cardOne: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
    gap: '16px'
  },
  cardTwo: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    padding: '8px 4px'
  },
  subBoxTwo: {
    backgroundColor: '#DBEAFE',
    display: 'flex',
    gap: '10px',
    padding: '16px',
    borderRadius: '8px'
  },
  docSize: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#64748B'
  },
  subBox3: {
    padding: '16px',
    display: 'flex',
    gap: '10px',
    borderRadius: '8px',
    backgroundColor: '#DBEAFE'
  },
  docSizeOne: {
    fontWeight: 400,
    color: '#64748B',
    fontSize: '16px'
  },
  makeOffer: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#334155'
  },
  offerType: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155'
  },
  optionMenu: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#94A3B8'
  },
  amountValue: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155'
  },
  dollorSign: {
    marginRight: '8px',
    fontSize: '16px',
    fontWeight: 700,
    color: '#64748B'
  },

}
// Customizable Area End

export class ListingSummary extends ListingSummaryController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Box >
        <CustomBreadcrumbs data-test-id="breadCrumsTest" separator={<NavigateNextIcon fontSize="small" />}>
          <Typography data-test-id="homePage" color="inherit" onClick={() => this.handleNavigateForUserList('LandingPage')}>Home</Typography>
          <Typography data-test-id="servicePage" color="inherit" onClick={() => this.handleNavigateForUserList('Service')}>MarketPlace</Typography>
          <Typography color="textPrimary">Buyers Profile</Typography>
        </CustomBreadcrumbs>
        <CustomBox>
          <InfoBox>
            <ProfileSection>
              <img src={viewcircle} alt="View Circle" />
              <Box>
                <Typography style={customStyle.apexHeading}>Apex Financial Solutions</Typography>
                <Typography data-test-id="testWebId" style={customStyle.apexLink}>
                  www.apexfinancialsolutions.com
                </Typography>
              </Box>
            </ProfileSection>
            <Divider />
            <Box style={customStyle.boxOne}>
              <Box>
                <Typography style={customStyle.headOne}>Financial Services</Typography>
                <Typography style={customStyle.descriptionOne}>Industry/Sector Section</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>CRN-456789123</Typography>
                <Typography style={customStyle.descriptionOne}>Registration number</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>United States</Typography>
                <Typography style={customStyle.descriptionOne}>Country</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>Florida</Typography>
                <Typography style={customStyle.descriptionOne}>State</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>Miami</Typography>
                <Typography style={customStyle.descriptionOne}>City</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>33101</Typography>
                <Typography style={customStyle.descriptionOne}>Zip</Typography>
              </Box>
            </Box>
            <Divider />
            <ServicesBox>
              <Typography style={customStyle.headTwo}>Services offered</Typography>
              <ChipBox>
                <Chip label="Financial Consultation" style={customStyle.chipStyle} />
                <Chip label="Debt Collection Services" style={customStyle.chipStyle} />
                <Chip label="Business Valuation" style={customStyle.chipStyle} />
                <Chip label="Risk Management" style={customStyle.chipStyle} />
                <Chip label="Market Research and Analysis" style={customStyle.chipStyle} />
              </ChipBox>
            </ServicesBox>
            <Divider />
            <ServicesBox>
              <Typography style={customStyle.headTwo}>Bio</Typography>
              <Typography style={customStyle.aboutCompany}>
                {configJSON.listingDetailsData}
              </Typography>
            </ServicesBox>
            <Divider />
            <Box style={customStyle.boxTwo}>
              <Box style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '4px' }} >
                <Typography style={customStyle.portHEad}>Portfolio</Typography>
                <Card style={customStyle.cardOne}>
                  <Box style={customStyle.subBoxTwo}>
                    <img src={image_notes} alt="Notes" />
                  </Box>
                  <Box style={{ flex: '1 0 auto', marginLeft: '4px' }}>
                    <Typography style={customStyle.aboutCompany}>File name.doc</Typography>
                    <Typography style={customStyle.docSize}>128KB</Typography>
                  </Box>
                  <IconButton>
                    <img src={image} alt="View" />
                  </IconButton>
                </Card>
              </Box>
              <Box style={{ display: 'flex', width: '50%', gap: '4px', flexDirection: 'column' }}>
                <Typography style={customStyle.portHEad}>Portfolio</Typography>
                <Card style={customStyle.cardTwo}>
                  <Box style={customStyle.subBox3}>
                    <img src={image_notes} alt="Notes" />
                  </Box>
                  <Box style={{ marginLeft: '4px', flex: '1 0 auto' }}>
                    <Typography style={customStyle.aboutCompanyTwo}>File name.doc</Typography>
                    <Typography style={customStyle.docSizeOne}>128KB</Typography>
                  </Box>
                  <IconButton>
                    <img src={image} alt="View" />
                  </IconButton>
                </Card>
              </Box>
            </Box>
          </InfoBox>
          <OfferBox>
            <Typography variant="h6" style={customStyle.makeOffer}>Make Offer</Typography>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography style={customStyle.offerType}>Offer Type</Typography>
              <Select defaultValue={"1"} variant="outlined" fullWidth style={customStyle.optionMenu} displayEmpty
              IconComponent={ExpandMoreIcon}
              >
                <MenuItem value="1" disabled>Select Offer Type</MenuItem>
                <MenuItem value="2">Offer type 1</MenuItem>
                <MenuItem value="3">Offer type 2</MenuItem>
              </Select>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography style={customStyle.amountValue}>Enter purchase amount</Typography>
              <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Box component="span" style={customStyle.dollorSign}>$</Box>
                  ),
                }}
                placeholder="0.00"
                style={customStyle.makeOffer}
              />
            </Box>
            <StyledTextField
              fullWidth
              multiline
              placeholder="Describe the details of your debt"
              minRows={12}
              variant="outlined"
            />
            <Button
              variant="contained"
              fullWidth
              style={{
                fontSize: '16px',
                fontWeight: 700,
                backgroundColor: '#1E3A8A',
                color: 'white',
                padding: '10px 16px',
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Make Offer
            </Button>
          </OfferBox>
        </CustomBox>
      </Box>



    )
    // Customizable Area End
  }
}
export default (ListingSummary)