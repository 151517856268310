import React from "react";
// Customizable Area Start
import ProfileWebController, { Props } from "./ProfileController.web";
import { withStyles, StyleRules, styled } from "@material-ui/core/styles";
import { 
    Avatar, 
    Box, 
    Button, 
    IconButton, 
    Menu,
    Typography,
    Badge,
    TextField,
    InputAdornment,
    Grid
 } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NotesIcon from "@material-ui/icons/Notes";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { imgProfile, uploadIcon} from "./assets";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json');
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web"
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    rightSideInputBox: {
      display: "flex",
        gap: "20px",
        margin: "20px 0px 76px 0px"
    },
    inputNoFileButton: {
        flex: 1
    },
    
    // Customizable Area End
}

// Customizable Area Start

const CustomGrid = styled(Grid)({
  "& .MuiTypography-root": {
    color: "#334155",
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  "& .MuiTextField-root": {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
      },
    },
    "& .MuiInputBase-input": {
      height: "30px",
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: "400",
      "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      opacity: 1,
    },
  },
});

const CustomPopover = styled(Box)({
    bottom: "18px",
    left: "-6px",
    width: "252px",
    height: "98px",
});

const SaveResetWrapper = styled(Box)({
  flexWrap: "wrap",
  "@media(max-width: 500px)": {
    justifyContent:"center"
  }
});

const SaveButton = styled(Button)({
    backgroundColor: "#1E3A8A",
    width: "160px",
    height: "44px",
    borderRadius: "8px",
    color: "white",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#1E3A8A",
    },
});
  
const ResetButton = styled(Button)({
    backgroundColor: "#EFF6FF",
    width: "160px",
    height: "44px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#EFF6FF",
    },
});

const webStyle = {
    errorText: {
      fontSize: "12px",
      fontWeight: 400,
      color: "red",
      margin: "5px 0 0 0",
    },
    passHelpWrapper: {
      position: "relative",
      flexGrow: 0,
      cursor: "pointer",
    },
    passCheckText: {
      color: "#0F172A",
      fontSize: "12px",
      fontWeight: 400,
      marginBottom: 0,
    },
    checkIcon: { width: "12px", height: "12px" },
    editBasicButtonBox: {
      marginTop: "35px",
      gap: "18px",
    },
    deleteBox: {
      gap: "5px",
      cursor: "pointer",
      width: "170px"
    },
    deleteIcon: {
      color: "#DC2626",
      width: "24px",
    },
    deleteText: {
      color: "#DC2626",
      fontWeight: 700,
      fontSize: "18px",
    },
    fileMoreIcon: {
      color: "#475569",
      cursor: "pointer",
    },
    noteIcon: {
      color: "#2563EB",
    },
    fileNameStyle: {
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 400,
      marginBottom: "0",
    },
    fileSizeStyle: {
      color: "#64748B",
      fontWeight: 400,
      fontSize: "16px",
      marginBottom: "0",
    },
    fileInputWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "8px",
      boxShadow: "0px 0px 8px 4px #00000008",
      borderRadius: "8px",
      gap: "14px",
    },
    noteIconBox: {
      backgroundColor: "#DBEAFE",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "56px",
      height: "56px",
      cursor: "pointer",
    },
    passHelpBox: {
      display: "flex",
      gap: "5px",
      marginBottom: "9px"
    },
    passText: {
      marginBottom: "0",
    },
    passHelpIcon: {
      color: "#64748B",
      width: "16px",
      height: "16px",
    },
    sizeTextStyle: {
      color: "#334155",
      fontSize: "14px",
      fontSeight: 400,
    },
    profileTextStyle: {
      color: "#0F172A",
      fontSize: "18px",
      fontWeight: 600,
    },
    profileEditBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "32px",
      height: "32px",
      borderRadius: "8px",
      backgroundColor: "#EFF6FF",
      cursor: "pointer",
    },
    profilePicEditIcon: {
      color: "#0F172A",
      width: "16px",
      height: "16px",
    },
    profileTextWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    } as React.CSSProperties,
    avatarTextWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      marginBottom: "20px",
    },
    mainSectionBox: {
      marginBottom: "40px",
    },
    pageHeadingBox: {
      marginBottom: "32px",
    },
    avatarStyle: {
      width: "80px",
      height: "80px",
    },
    sectionHeadingWrapper: {
      alignItems: "baseline",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    editWrapper: {
      display: "flex",
      gap: "8px",
      cursor: "pointer",
    },
    editText: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#1E3A8A",
    },
    editIcon: {
      color: "#1E3A8A",
      width: "15px",
    },
    pageHeading: {
      fontSize: "30px",
      fontWeight: 700,
      color: "#0F172A",
    },
    sectionHeading: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#0F172A",
    },
    sectionLowerBox: {
      padding: "40px",
      gap: "24px",
      borderRadius: "24px",
      boxShadow: "0px 0px 8px 4px #00000008",
    },
  };
// Customizable Area End

export class ProfileWeb extends ProfileWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area

    renderSectionUpperBox = (title: string, select: string) => {
      const languageData = languageTranslationData[this.state.currentLanguage];
        return (
          <Box
            style={{
              ...webStyle.sectionHeadingWrapper,
              display: this.state.commonHide ? "none" : "flex"
            }}
          >
            <Typography style={webStyle.sectionHeading}>{title}</Typography>
            <Box
                data-test-id={`${select}EditButtonTestID`}
                style={webStyle.editWrapper}
                onClick={() => this.handleEdit(select)}
            >
              <Typography style={webStyle.editText}>{languageData.Edit}</Typography>
              <EditIcon style={webStyle.editIcon} />
            </Box>
          </Box>
        );
    };

    renderPopover = () => {
      const languageData = languageTranslationData[this.state.currentLanguage];
        return (
          <CustomPopover data-test-id="passHelpPopoverTestID" style={{ position: "absolute", left: "-19px" }}>
            <Box style={{ position: "relative", height: "98px" }}>
              <Box
                style={{
                  position: "absolute",
                  borderRadius: "8px",
                  backgroundColor: "white",
                  height: "98px",
                  padding: "12px",
                  width: "252px",
                  bottom: "9px",
                  boxShadow: "0px 0px 6px 4px #10182808",
                }}
              >
                <Typography
                  style={{ color: "#334155", fontWeight: 600, fontSize: "12px" }}
                >
                  {languageData.passwordHelpHeading}
                </Typography>
                <Box>
                  {this.state.passCheckArray.map((item, index) => (
                    <Box
                      key={index}
                      style={{ display: "flex", gap: "10px", alignItems: "center" }}
                    >
                      <CheckCircleIcon
                        style={{
                          ...webStyle.checkIcon,
                          color: item.valid ? "#34D399" : "#94A3B8",
                        }}
                      />
                      <Typography style={webStyle.passCheckText}>
                        {this.handlePopupOptionLanguage(index)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                style={{
                  position: "absolute",
                  width: 0,
                  height: 0,
                  left: "19px",
                  bottom: "2px",
                  borderLeft: "8px solid transparent",
                  borderRight: "8px solid transparent",
                  borderTop: "8px solid white",
                }}
              ></Box>
            </Box>
          </CustomPopover>
        );
      };
    
    renderSectionFirst = () => {
        const {
          editBasic,
          showPassword,
          showPopover,
          errorCheck,
          checkSave,
        } = this.state;
        const userProfileData = languageTranslationData[this.state.currentLanguage].UserProfile;
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
          <Box style={webStyle.mainSectionBox}>
            {this.renderSectionUpperBox(languageData.BasicInformation, "editBasic")}
            <Box style={webStyle.sectionLowerBox}>
              <Box style={webStyle.avatarTextWrapper}>
                <Box style={{ position: "relative" }}>
                  <Badge
                    data-test-id="avatarBadgeTestID"
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      this.state.editBasic &&
                      (
                        <Box
                          id="profilePicture"
                          onClick={this.handleMoreClick}
                          style={webStyle.profileEditBox}
                        >
                          <EditIcon
                            style={webStyle.profilePicEditIcon}
                          />
                        </Box>
                      )
                    }
                  >
                    <Avatar
                        data-test-id="avatar"
                        alt="profile_image"
                        src={this.state.profilePicture ?? imgProfile}
                        style={webStyle.avatarStyle}
                    />
                  </Badge>
                </Box>
                <Box style={webStyle.profileTextWrapper}>
                  <Typography style={webStyle.profileTextStyle}>
                    {userProfileData.ProfilePictures}
                  </Typography>
                  <Typography style={webStyle.sizeTextStyle}>
                    {userProfileData.SizeShow}
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={4} >
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.FullName}*</Typography>
                    <TextField
                        data-test-id="fullNameInTestID"
                        disabled={!editBasic}
                        value={this.state.fullName}
                        size="small"
                        fullWidth
                        variant="outlined"
                        onChange={this.handleChange("fullName")}
                        onBlur={()=>this.handleFullNameBlur()}
                    />
                    {editBasic && checkSave && errorCheck.fullNameError && (
                      <Typography style={webStyle.errorText}>
                        {errorCheck.fullNameError}
                      </Typography>
                    )}
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.Email}*</Typography>
                    <TextField
                      id="email"
                      disabled={!editBasic}
                      value={this.state.profile[2].value}
                      size="small"
                      fullWidth
                      name="email"
                      variant="outlined"
                      onChange={this.handleProfileChange}
                    />
                    {editBasic && checkSave && errorCheck.emailError && (
                      <Typography style={webStyle.errorText}>
                        {errorCheck.emailError}
                      </Typography>
                    )}
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.PhoneNumber}*</Typography>
                    <TextField
                      id="full_phone_number"
                      disabled={!editBasic}
                      value={this.state.formattedPhoneNumber}
                      size="small"
                      fullWidth
                      name="full_phone_number"
                      variant="outlined"
                      onChange={this.handlePhoneNumberChange}
                    />
                    {editBasic && checkSave && errorCheck.phoneNumberError && (
                      <Typography style={webStyle.errorText}>
                        {errorCheck.phoneNumberError}
                      </Typography>
                    )}
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Box style={webStyle.passHelpBox}>
                      <Typography style={webStyle.passText}>{userProfileData.Password}</Typography>
                      <Box style={webStyle.passHelpWrapper as React.CSSProperties}>
                        <HelpOutlineIcon
                          data-test-id="passValidHelpTestID"
                          onClick={this.handlePopoverOpen}
                          style={webStyle.passHelpIcon}
                        />
                        {showPopover && this.renderPopover()}
                      </Box>
                    </Box>
                    <TextField
                        data-test-id="passInpTestID"
                      type={showPassword ? "text" : "password"}
                      disabled={!editBasic}
                      value={this.state.password}
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={this.handleChange("password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="showPassIconTestID"
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {editBasic && checkSave && errorCheck.passwordError && (
                      <Typography style={webStyle.errorText}>
                        {errorCheck.passwordError}
                      </Typography>
                    )}
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.Country}</Typography>
                    <TextField
                        data-test-id="country"
                        disabled={!editBasic}
                        value={this.state.profile[7].value}
                        size="small"
                        fullWidth
                        name="country"
                        variant="outlined"
                        onChange={this.handleProfileChange}
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.State}</Typography>
                    <TextField
                        data-test-id="state"
                        disabled={!editBasic}
                        value={this.state.profile[6].value}
                        size="small"
                        name="state"
                        fullWidth
                        variant="outlined"
                        onChange={this.handleProfileChange}
                    />
                  </CustomGrid>
                
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.City}</Typography>
                    <TextField
                        data-test-id="city"
                        disabled={!editBasic}
                        value={this.state.profile[5].value}
                        size="small"
                        name="city"
                        fullWidth
                        variant="outlined"
                        onChange={this.handleProfileChange}
                    />
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileData.Zip}</Typography>
                    <TextField
                        data-test-id="zipcode"
                        disabled={!editBasic}
                        value={this.state.profile[8].value}
                        size="small"
                        fullWidth
                        name = "zipcode"
                        variant="outlined"
                        onChange={this.handleZipChange}
                    />
                  </CustomGrid>
              </Grid>
            </Box>
            <SaveResetWrapper
              style={{
                ...webStyle.editBasicButtonBox,
                display: editBasic ? "flex" : "none",
              }}
            >
                <SaveButton 
                    data-test-id="save-profile" 
                    onClick={()=>this.handleBasicSave()}
                >
                    {userProfileData.Save}
                </SaveButton>
                <ResetButton data-test-id="basicResetButtonTestID" onClick={()=>this.handleBasicReset()}>
                    {userProfileData.Reset}
                </ResetButton>
            </SaveResetWrapper>
          </Box>
        );
      };

      renderSectionSecond = () => {
        const {
          editDetail,
          errorCheck,
          dateDay,
          dateMonth,
          dateYear,
          nationalId,
          checkSave,
        } = this.state;
        const userProfileDetailedData = languageTranslationData[this.state.currentLanguage].UserProfileDetailed;
        const userProfileData = languageTranslationData[this.state.currentLanguage].UserProfile;
        const languageData = languageTranslationData[this.state.currentLanguage]
        return (
          <Box style={webStyle.mainSectionBox}>
            {this.renderSectionUpperBox(languageData.DetailedInformation, "editDetailed")}
            <Box style={webStyle.sectionLowerBox}>
              <Grid container spacing={4}>
                <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileDetailedData.DateofBirth}</Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap:"20px"
                      }}
                    >
                      <TextField
                        data-test-id="monthInputTestID"
                        value={dateMonth}
                        disabled={!editDetail}
                        placeholder="MM"
                        size="small"
                        variant="outlined"
                        onChange={this.handleMonthChange}
                        onBlur={()=>this.handleBlur("month")}
                      />
                      <TextField
                        data-test-id="dayInputTestID"
                        value={dateDay}
                        disabled={!editDetail}
                        placeholder="DD"
                        size="small"
                        variant="outlined"
                        onChange={this.handleDayChange}
                        onBlur={()=>this.handleBlur("day")}
                      />
                      <TextField
                        data-test-id="yearInputTestID"
                        value={dateYear}
                        disabled={!editDetail}
                        placeholder="YYYY"
                        onBlur={()=>this.handleBlur("year")}
                        size="small"
                        variant="outlined"
                        onChange={this.handleYearChange}
                      />
                    </Box>
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{userProfileDetailedData.NationalIdLabel}*</Typography>
                    <TextField
                      data-test-id="nationalIDInputTestID"
                      value={nationalId}
                      disabled={!editDetail}
                      size="small"
                      variant="outlined"
                      placeholder = "Your National ID"
                      onChange={this.handleChange("nationalId")}
                      fullWidth
                    />
                    {editDetail && checkSave && errorCheck.nationalIDError && (
                      <Typography style={webStyle.errorText}>
                        {errorCheck.nationalIDError}
                      </Typography>
                    )}
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>
                      {userProfileDetailedData.IdProof}
                    </Typography>
                    {this.renderFileBox("idProof")}
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>
                      {userProfileDetailedData.AddProof}
                    </Typography>
                    {this.renderFileBox("addressPoof")}
                  </CustomGrid>
              </Grid>

              
            </Box>
            <SaveResetWrapper
              style={{
                ...webStyle.editBasicButtonBox,
                display: editDetail ? "flex" : "none",
              }}
            >
              <SaveButton 
                data-test-id="detailSaveButtonTestID" 
                onClick={this.handleDetailedSave}
              >
                {userProfileData.Save}
              </SaveButton>
              <ResetButton 
                data-test-id="detailResetButtonTestID" 
                onClick={this.handleDetailedReset}
              >
                {userProfileData.Reset}
              </ResetButton>
            </SaveResetWrapper>
          </Box>
        );
    };

    renderFileBox = (value: string) => {
        return (
          <Box style={webStyle.fileInputWrapper}>
            <Box style={webStyle.noteIconBox}>
              <NotesIcon style={webStyle.noteIcon}/>
            </Box>
            <Box style={{flexGrow:1}}>
              <Typography style={webStyle.fileNameStyle}>File name.doc</Typography>
              <Typography style={webStyle.fileSizeStyle}>128 KB</Typography>
            </Box>
            <IconButton 
                id={value}
                aria-label="more1"
                aria-haspopup="true"
                disabled={!this.state.editDetail}
                onClick={this.handleMoreClick}
            >
                <MoreVertIcon style={webStyle.fileMoreIcon} />
            </IconButton>
          </Box>
        );
    };
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const VisuallyHiddenInput = styled('input')({
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1
        });
        const MenuButton = styled(Box)({
            borderRadius: '8px',
            cursor: "pointer",
            background: '#EFF6FF',
            display: 'flex',
            textTransform: 'capitalize',
            padding: '7px 16px',
            alignItems: 'center',
            gap: '8px',
            flexShrink: 0,
            alignSelf: 'stretch',
            transition: 'background-color 0.3s',
            '&:hover': {
                backgroundColor: "#EFF6FF"
            }
        });
        const MenuButtonDelete = styled(Box)({
            borderRadius: '8px',
            cursor: "pointer",
            background: '#FFF',
            display: 'flex',
            textTransform: 'capitalize',
            padding: '7px 16px',
            alignItems: 'center',
            marginTop: "10px",
            gap: '8px',
            flexShrink: 0,
            alignSelf: 'stretch',
            transition: 'background-color 0.3s',
            color:"#DC2626",
            '&:hover': {
                background: '#FEFEFE'
            }
        });

        const languageData = languageTranslationData[this.state.currentLanguage];
        const isEnglish = this.state.currentLanguage === "en";
        const {editDetail, commonHide, editBasic} = this.state
        return (
            <UserProfileDrawer 
                data-test-id="drawerComponentTestID" 
                handleClick={this.handleDrawerClick}
                breadScrumArray={this.state.breadScrumArray}
                pageName="UserProfile"
            >
                <Box>
                    <Box style = { webStyle.pageHeadingBox} >
                        <Typography style={webStyle.pageHeading}>
                            {this.state.pageHeading}
                        </Typography>
                    </Box>
                    <Box style={{ display: editDetail ? "none" : "block" }}>
                        {this.renderSectionFirst()}
                    </Box>
                    <Box style={{ display: editBasic ? "none" : "block" }}>
                        {this.renderSectionSecond()}
                    </Box>
                    <Box
                        style={{
                            ...webStyle.deleteBox,
                            display: commonHide ? "none" : "flex"
                        }}
                    >
                        <DeleteOutlineRoundedIcon style={webStyle.deleteIcon} />
                        <Typography style={webStyle.deleteText}>{languageData.deleteAccount}</Typography>
                    </Box>
                    {this.state.anchorEl && <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleCloseMenu}
                        PaperProps={{
                            style: {
                                width: '198px',
                                borderRadius: "8px",
                                border: "1px solid #E2E8F0",
                                background: "#FFF",
                                padding: "0px 8px",
                                boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
                            },
                        }}
                    >
                        <MenuButton component="label">
                            <img src={uploadIcon} alt="upload" style={{ marginRight: "10px" }} />
                            {languageData.UserProfile.UploadPhoto}
                            <VisuallyHiddenInput type="file" data-test-id='upload-btn' onChange={this.handleFileChange} />
                        </MenuButton>
                        <MenuButtonDelete onClick={this.handleFileRemove} data-test-id='remove-btn' component="label">
                            <DeleteOutlineRoundedIcon id="modalID" style={{ width: "18px" }} />
                            {languageData.UserProfile.RemovePhoto}
                        </MenuButtonDelete>
                    </Menu>}
                </Box>
            </UserProfileDrawer>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ProfileWeb);
