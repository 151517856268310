import React from "react";
// Customizable Area Start
import {
    Box,
    styled
} from "@material-ui/core";
import ProductDescriptionController, {
    configJSON
} from "./ProductDescriptionController";
import { applicationLogo } from "./assets";
import Text from "../../../../packages/components/src/design-system/Text";
import CustomButton from "../../../../packages/components/src/design-system/Button/Button";
const languageTranslation = require('../../languagesupport/src/LanguageData/ApplicationSubmitted.json')
// Customizable Area End

export default class ApplicationSummited extends ProductDescriptionController {
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const {currentLanguage} = this.state;
        const languageData = languageTranslation[currentLanguage];
        return (
            <>
                <SubmittedMainContainer>
                    <Box className="contentContainer">
                        <img src={applicationLogo} alt="img" className="contentContainerImage" />
                        <Box sx={{ lineHeight: "40px", letterSpacing: "-0.15px", marginTop: "58px" }}>
                            <Text size="1xl" weight="extraBold" family="inter" align="center" variant="black">{languageData.ApplicationSubmittedSuccessfully}</Text>
                        </Box>
                        <Box sx={{ lineHeight: 28, marginTop: "64px", width:"60%"}} >
                            <Text size="mm" weight="minBold" family="inter" align="center" variant="black">{languageData.YourApplicationIsUnderReview}</Text>
                        </Box>

                    <Box className="container">
                    <CustomButton                      
                        themes="dark"
                        onClickHandle={this.handleSubmittionNavigate}
                        dataTestId="serviceCardStartButtonTestId"                     
                      > 
                      {languageData.CheckProgress}
                        </CustomButton>
                    </Box>  
                    </Box>
                </SubmittedMainContainer>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const SubmittedMainContainer = styled("div")({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "#F1F5F9",
    
    "& .container":{
        marginTop:"50px",
        marginBottom:"15px",
        width:"400px",
        "@media(max-width:718px)": {
            width: "250px",

        },
        "@media(max-width:456px)": {
            width: "160px",
        },
    },
    "& .contentContainer": {
        width: "60%",
        borderRadius: "18px",
        background: "#fff",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        padding: "42px"
    },
    "& .contentContainerImage": {
        width: "120px",
        height: "120px",
        "@media(max-width:576px)": {
            width: "100px",
            height: "100px"
        },
    }
});

// Customizable Area End
