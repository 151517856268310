import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import { withStyles, WithStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    height: "1px",
    marginTop: "18px",
    marginBottom: "11px",
  },
};

interface CustomDividerProps {
  height?: string;
  marginTop?: string;
  marginBottom?: string;
}

type PropsWithStyles = CustomDividerProps & WithStyles<typeof styles>;

class CustomDivider extends Component<PropsWithStyles> {
  render() {
    const { classes, height, marginTop, marginBottom, ...otherProps } = this.props;

    return (
      <Divider
        classes={{ root: classes.root }}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(CustomDivider);