import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";

export interface UserProfile {
    id: string;
    attributes: {
        admin_profile_image: null;
        created_at: string;
        email: string;
        phone_number: string;
        updated_at: string;
        user_name: string;
    }
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string;
    password: string;
    phonenumber: string;
    errors: any;
    UserProfile: UserProfile;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminProfileController extends BlockComponent<
    Props,
    S,
    SS
>
{

    // Customizable Area Start
    passwordReg: RegExp;
    emailReg: RegExp;
    numberReg: RegExp;
    getUserProfileId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            email: '',
            password: '',
            phonenumber: '',
            errors: '',
            UserProfile: {
                id: "",
                attributes: {
                    admin_profile_image: null,
                    created_at: "",
                    email: "",
                    phone_number: "",
                    updated_at: "",
                    user_name: "",
                }
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
        this.numberReg = /^\d{10}$/;
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getUserProfileId) {
                    this.handleUserResponse(responseJson.admin.data)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        this.getShowUserProfile()
        // Customizable Area End
    }

    handleLogout = async () => {
        await removeStorageData("accessToken")
        await removeStorageData("profilePic")
        await removeStorageData("userType")
        this.handleNavigation("SuperAdminLogin")
    }

    handleNavigation(route: string) {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationTargetMessage), route);

        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg);
    }

    handleDrawerClick = (route: string) => {
        if(route==="logOut"){
            this.handleLogout();
        } else {
            this.handleNavigation(route);
        }
    };

    getShowUserProfile = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserProfileId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.adminApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleUserResponse = (res: UserProfile) => {
        this.setState({ UserProfile: res })
    }
    // Customizable Area End
}

