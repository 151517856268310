import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: any;
  onClick?: () => void; // Specify the type of 'onClick'
}

const CustomImage: React.FunctionComponent<ImageProps> = ({ src, alt, className, style, onClick }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      style={style}
      onClick={onClick}
    />
  );
};

export default CustomImage;