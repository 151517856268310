import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import DashboardController, { Props } from "./DashboardController.web";
import { Box, IconButton, Typography,  Button, TextField, styled, TableContainer, Paper, Avatar } from "@material-ui/core";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json')
import SearchIcon from "@material-ui/icons/Search";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DocUpload } from "./assets";
import TablePagination from "../../../components/src/design-system/Pagination.web";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    // Customizable Area End
}

export class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (

            <UserProfileDrawer
                handleClick={this.handleDrawerNavigation1}
                pageName={this.state.userType === "consumer" ? "UserProfile" : "dashboardAuditlog"}
                data-test-id="drawerTestID"
                userType={this.state.userType === "consumer" ? "consumer" : "admin_account"}
                navigation={this.props.navigation}
            >
                <Box data-test-id="cta-Tables">
                    <Typography style={classes.devTypographyHeader}>{languageData.AuditLogs}</Typography>
                    <Box style={classes.devTableWrapperBox}>
                        <DevTableUpBox>
                            <Box style={classes.devSearchFieldWrapper}>
                                <SearchIcon style={classes.devSearchIcon} />
                                <DevSearchTextField
                                    id="blankInput"
                                    data-test-id="searchInputTestID"
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.searchTerm}
                                    placeholder={languageData.Search}
                                    inputProps={{ 'aria-label': 'description' }}
                                />
                            </Box>
                            <Box style={{ display: "flex", gap: "20px", position: "relative" }}>
                                <DevFilterButton
                                    style={{ height: "44px" }}
                                    data-test-id="filterButtonTestID"
                                >
                                    <Box style={classes.devCreateButton}>
                                        <FilterListRoundedIcon />
                                        {languageData.Filter}
                                    </Box>
                                </DevFilterButton>
                                <DevFileButton
                                    data-test-id="upload-files"
                                    variant="contained"
                                    startIcon={<img src={DocUpload} />}>
                                    {languageData.ExportLog}
                                </DevFileButton>
                            </Box>
                        </DevTableUpBox>

                        <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                            <Table style={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <DevCustomHeadingRow>
                                        <TableCellText>{languageData.User}</TableCellText>
                                        <TableCellText>{languageData.Email}</TableCellText>
                                        <TableCellText style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.Action}</TableCellText>
                                        <TableCellText>{languageData.Date}</TableCellText>
                                        <TableCellText>{languageData.Subject}</TableCellText>
                                        <TableCellText></TableCellText>

                                    </DevCustomHeadingRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.docData[currentLanguage].map((item, index) => (
                                        <DevCustomBodyRow key={index}>
                                            <TableCellText>
                                                <Box style={classes.devUserProfileCell}>
                                                    <DevUserAvatar src={item[0]} alt="user" />
                                                    {item[1]}
                                                </Box>
                                            </TableCellText>
                                            <TableCellText>{item[2]}</TableCellText>
                                            <TableCellText>
                                                <span
                                                    style={{
                                                        ...classes.devstatusStyle,
                                                        ...this.handleStatusColor(this.state.docData.en[index][3]),
                                                    }}
                                                >
                                                    {item[3]}
                                                </span>
                                            </TableCellText>
                                            <TableCellText>{item[4]}</TableCellText>
                                            <TableCellText>{item[5]}</TableCellText>
                                            <TableCellText>
                                                <IconButton data-test-id="publishedMoreButtonTestID">
                                                    <MoreVertIcon style={classes.devmoreOpStyle} />
                                                </IconButton>
                                            </TableCellText>
                                        </DevCustomBodyRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                        <Box style={classes.devmainResultBox}>
                            <Box style={classes.devpaginationBox}>
                                <TablePagination
                                    data-test-id="paginationTestID"
                                    page={this.state.currentPage}
                                    count={8}
                                    handlePageChange={this.handlePageChange}
                                />
                            </Box>
                            <DevCustomResultBox>
                                <Typography style={{ color: "#64748B", fontSize: "12px" }}>
                                    {languageData.totalresuls}
                                </Typography>
                            </DevCustomResultBox>
                        </Box>
                    </Box>
                </Box>
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(Dashboard);

const TableCellText = styled(TableCell)({
    whiteSpace: "nowrap"
});
const DevTableUpBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    gap: "20px",
    flexWrap: "wrap"
});
const DevSearchTextField = styled(TextField)({
    maxWidth: "320px",
    flexGrow: 1,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                boxShadow: "none !important",
                border: "none !important",
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#94A3B8",
        fontSize: "16px",
        opacity: 1
    }
});
const DevFilterButton = styled(Button)({
    borderRadius: "8px",
    padding: "10px 16px",
    display: "flex",
    border: "1px solid #CBD5E1",
    gap: "8px",
    textTransform: "capitalize",
    alignItems: "center",

});
const DevCustomHeadingRow = styled(TableRow)({
    background: "#E2E8F0",
    "& .MuiTableCell-root": {
        fontWeight: 600,
        color: "#64748B",
        fontSize: "14px",
        whiteSpace: "nowrap",
        padding: "20px 16px 8px",
    }
});
const DevCustomBodyRow = styled(TableRow)({
    height: "54px",
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});
const DevUserAvatar = styled(Avatar)({
    borderRadius: "100px",
    height: "40px",
    width: "40px",
    border: "1px solid #D9D9D9",
    objectFit: "cover"

});
const DevCustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end"
    }
});
const DevFileButton = styled(Button)({
    borderRadius: '8px',
    padding: '10px 16px',
    backgroundColor: '#1E3A8A',
    color: '#FFFFFF',
    cursor: "pointer",
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'left',
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#1E3A8A',
    },
});
const classes = {
    devTypographyHeader: {
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: "0.5%",
        lineHeight: "44px",
        color: "#0F172A",

    },
    devverificationBox: {
        alignItems: 'center',
        height: '80vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'

    },
    devverificationInnerBox: {
        alignItems: 'center',
        display: 'flex',
        gap: '20px',
        height: '328px',
        flexDirection: 'column',
        width: '287px'

    },
    devgroupImg: {
        width: '150px',
        height: "166px",

    },
    devTableWrapperBox: {
        width: "100%",
        background: "#FFFFFF",
        borderRadius: "12px",
        padding: "16px",
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        margin: "20px 0 80px 0",
    },
    devSearchFieldWrapper: {
        border: "1px solid #CBD5E1",
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        borderRadius: "8px",
        height: "24px",
        padding: "10px 8px",
        maxWidth: "304px"
    },
    devSearchIcon: {
        color: "#94A3B8",
        marginLeft: '3.48px',

    },
    devCreateButton: {
        gap: "10px",
        display: 'flex',
    },
    devUserProfileCell: {
        alignItems: "center",
        display: "flex",
        gap: "8px"
    },
    devstatusStyle: {
        fontSize: "12px",
        borderRadius: "25px",
        padding: "9px 10px 5px",
        backgroundColor: "#D1FAE5",
        fontWeight: 700,
        color: '#059669'
    },
    devmoreOpStyle: {
        cursor: "pointer",
        color: "#64748B",
    },
    devviewButtonText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 400,
    },
    devdeleteButtonText: {
        fontSize: "14px",
        color: "#DC2626",
        fontWeight: 400,
        alignItems: "center",
        display: "flex",
        gap: "8px"
    },
    devmainResultBox: {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        paddingRight: "20px",
        paddingTop: "16px",
    } as React.CSSProperties,

    devpaginationBox: {
        justifyContent: "center",
        display: "flex",
        flexGrow: 1,
    },
}

// Customizable Area End
