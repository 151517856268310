// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills')
// Customizable Area End

export interface ContentProps {
    id: string;
    classes: any;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

export interface AboutPageData {
    id: string;
    type: string;
    attributes: {
        id: number;
        sequence: number;
        title: string;
        title_in_arabic:string;
        description: string;
        description_in_arabic:string;
        image: string;
    };
}


interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    aboutUsData: AboutPageData[];
    currentLanguage:'en'|'ar'
    // Customizable Area End
}

export default class IndustrialPageWebController extends BlockComponent<
    ContentProps,
    S,
    SS
> {

    // Customizable Area Start
    getAboutPageDataApiCall: string = ""
    // Customizable Area End

    constructor(props: ContentProps) {

        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            aboutUsData: [],
            currentLanguage:configJSON.EN
            // Customizable Area End
        };


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            runEngine.debugLog("API Message Recived", message);

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getAboutPageDataApiCall) {
                    this.setState({ aboutUsData: responseJson.data, loading:false });
                }
            }
            if(errorReponse){
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({ currentLanguage: language })
        this.getAboutUsPageData()
        // Customizable Area End
    }

    // Customizable Area Start

    getAboutUsPageData = () => {
        this.setState({loading:true})
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAboutPageDataApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.aboutUsEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}
