import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData,removeStorageData } from "framework/src/Utilities";
import { ChangeEvent, MouseEvent } from "react";
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');


// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: { name: "All" | "Archive" | "Blocked", key: string }[],
    selectedTab: "All" | "Archive" | "Blocked"
    rowsPerPage: number,
    page: number,
    users: {
        id: string,
        name: string,
        email: string,
        createdAt: {
            date: string,
            time: string
        }
    }[],
    searchTerm: string,
    anchorEl: HTMLElement | null | SVGSVGElement
    showArchiveModal: boolean,
    selectedUser: string,
    showBlockedModal: boolean,
    loading: boolean,
    isFirstModalOpen: boolean,
    verificationPage: number,
    dot: HTMLElement | null,
    currentLanguage: 'ar' | 'en',
    listingType: string,
    status: string,
    showFilter: boolean,
    headingText: string,
    userType: string,
    showDetail: boolean;
    breadscrumValues: string[];
    currentPage: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class UserManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchUsersCallId: any;
    chageStatusCallId: any;
    regularStatusCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs: [{ name: "All", key: "company_users" }, { name: "Archive", key: "archived_users" }],
            selectedTab: "All",
            rowsPerPage: 5,
            page: 0,
            users: [],
            searchTerm: "",
            anchorEl: null,
            showArchiveModal: false,
            selectedUser: "",
            showBlockedModal: false,
            loading: false,
            isFirstModalOpen: false,
            verificationPage: 1,
            dot: null,
            currentLanguage: 'en',
            listingType: "",
            status: "",
            showFilter: false,
            headingText: "",
            userType: "",
            showDetail: false,
            breadscrumValues: [],
            currentPage: 1,


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.fetchUsersCallId) {
                    this.handleUserResponse(responseJson)
                } else if (apiRequestCallId === this.chageStatusCallId) {
                    this.setState({
                        loading: false,
                        selectedTab: "All",
                        selectedUser: "",
                        showArchiveModal: false,
                        showBlockedModal: false
                    })
                    this.fetchUsers("company_users")
                } else if (apiRequestCallId === this.regularStatusCallId) {
                    this.setState({
                        loading: false,
                        selectedUser: "",
                    })
                    this.fetchUsers(this.state.selectedTab === "Archive" ? "archived_users" : "blocked_users")
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        this.fetchUsers("company_users")
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Signup;
        this.setState({
            currentLanguage: language,
            headingText: heading,
        });
        // Customizable Area End
    }

    async handleNavigation(route: string) {
        await setStorageData("selectedUser", this.state.selectedUser)
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg);
    }

    handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            searchTerm: e.target.value
        })
    }

    convertTimestampToCreatedAt(timestamp: string): { date: string, time: string } {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
        const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

        return {
            date: formattedDate,
            time: `${formattedTime}`
        };
    }

    handleUserResponse = (users: any) => {
        if (!users.errors) {
            const newUsers = users.data.map((user: { id: string, attributes: { full_name: string, email: string, created_at: string } }) => {
                return (
                    {
                        id: user.id,
                        name: user.attributes.full_name,
                        email: user.attributes.email,
                        createdAt: this.convertTimestampToCreatedAt(user.attributes.created_at)
                    }
                )
            })
            this.setState({
                users: newUsers,
                loading: false
            })
        } else {
            this.setState({
                users: [],
                loading: false
            })
        }
    }

    fetchUsers = async (which: string) => {
        this.setState({
            loading: true
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchUsersCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.fetchUsersEndPoint}${which}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    }

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({
            page: newPage
        })
    }

    changeStatus = async (status: string) => {
        this.setState({
            loading: true
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.chageStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/user_lists/${this.state.selectedUser}/update_status`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        const data = {
            "status": status
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        const headers = {
            "Content-Type": "application/json",
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUnArchive = async () => {
        this.setState({ anchorEl: null, loading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.regularStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/user_lists/${this.state.selectedUser}/unarchived_user`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        const data = {
            "status": "regular"
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        const headers = {
            "Content-Type": "application/json",
            "token": await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUnBlock = async () => {
        this.setState({ anchorEl: null, loading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.regularStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/user_lists/${this.state.selectedUser}/unblock_user`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        const data = {
            "status": "regular"
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        const headers = {
            "Content-Type": "application/json",
            "token": await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    handleFirstModalOpen = () => {
        this.setState({ isFirstModalOpen: true });
    };

    handleFirstModalClose = () => {
        this.setState({ isFirstModalOpen: false });
    };
    handleVerificationPagination = (evetn: ChangeEvent<unknown>, page: number) => {
        this.setState({ verificationPage: page });
    };
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ dot: event.currentTarget });

    };
    handleUserData = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
        const target = event.currentTarget as SVGSVGElement;
        this.setState({ anchorEl: target, selectedUser: id })
    }

    handleClose = () => {
        this.setState({ dot: null });
    };

    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
            listingType: "",
            status: ""
        });
    };

    handleToggleFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };
    handleCloseFilter = () => {
        this.setState({ showFilter: false });
    };
    handleDrawerNavigation1 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle1()
        } else {
            if(routing.includes("breadScrum")) {
                // this.handlebreadScrumClick1(routing);
            } else {
                this.handleNavigatingRoute1(routing);
            }
        }
    };

    logoutHandle1 = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigatingRoute1("Home");
    };
    handleNavigatingRoute1 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };
    handlePageChange = (page: number) => {
        this.setState({ currentPage: page });
    };
    // Customizable Area End
}
