import React from "react";
// Customizable Area Start
import Link from '@material-ui/core/Link';
import UserProfileWebController, { Props } from "./UserProfileController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import { Box, Typography, Button, IconButton, Container, FormControl, FormHelperText, Select, Grid, MenuItem, OutlinedInput } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import { groupReImg, infoReImg, vectorImg } from "./assets";
import { Description, MoreVert } from "@material-ui/icons";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import { configJSON } from "./EmailAccountLoginController";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    labelText: {
        fontSize: "12px",
        color: "#fff",
        margin: "0px",
        fontWeight: 700,
        display: "flex"
    },
    container: {
        justifyContent: "center",
        gap: '20px',
        alignItems: "center",
        display: 'flex',
        flexDirection: "column",
    },
    mainBoxConatiner: {
        padding: "50px 100px",
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "#1E293B",
        marginBottom: "32px"
    },
    lableTextBox: {
        fontFamily: "poppins",
        fontWeight: 600,
        fontSize: "24px",
        marginBottom: "25px",
        color: "#fff",
    },
    gridMainContainer: {
        alignItems: "center",
        gap: '20px',
        justifyContent: "start",
        display: 'flex',
    },
    formControllerBox: {
        margin: "12px 0px",
        width: '100%'
    },
    reqireText: {
        color: "red"
    },
    inputTextFiled: {
        border: '1px solid var(--neutrals-cool-gray-300, #CBD5E1)',
        background: 'var(--basic-white, #FFF)',
        width: "100%",
        borderRadius: "8px",
        height: "44px",
        fontFamily: "Inter"
    },
    documnetIcon: {
        backgroundColor: "#C2D5FF",
        marginLeft: "16px",
        height: "56px",
        width: "56px",
        justifyContent: "center",
        borderRadius: "8px",
        alignItems: "center",
        display: "flex",
    },
    documentMainBox: {
        padding: "16px 0",
        backgroundColor: "#fff",
        marginTop: "5px",
        borderRadius: "8px",
        gap: "16px",
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    documnetName: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
        fontFamily: "inter",
    },
    documnetSizeText: {
        color: "#64748B",
        fontFamily: "inter",
        fontSize: "16px",
        fontWeight: 400,
    },
    optionBox: {
        marginRight: "16px",
        marginLeft: "auto"
    },
    activeTab: {
        color: "#0F172A",
        fontWeight: 700
    },
    mainHeadBoxRe: {
        height: "30px",
        width: "264px",
        display: "flex",
        gap: "10px",
        alignItems: "center"
    },
    headBoxText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
    },
    headBoxText2: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
    },
    reportLine : {
        position: 'relative' as 'relative',
        margin: '20px 0',
        padding: '0px 0px',
        '&::before': {
          content: '""',
          position: 'absolute' as 'absolute',
          left: '28px',
          top: 0,
          bottom: 0,
          width: '1px',
          backgroundColor: '#E2E8F0',
        },
      },
    // Customizable Area End
}


export class RequetWeb extends UserProfileWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props;

        const breadcrumbs = [
            <Link underline="hover" key="1" color="inherit">
                Profile
            </Link>,
            <Link underline="hover" key="1" color="inherit" href="/UserProfileWeb">
                Requests
            </Link>,
            <Link
                underline="hover"
                key="2"
                className={classes.activeTab}
            >
                {"Requests 1"}
            </Link>,
        ];


        return (
            <UserProfileDrawer
                data-test-id="drawerComponentTestID"
                handleClick={this.handleDrawerClick}
                pageName="UserRequests"
            >
                <Box>
                    <Box className={classes.mainHeadBoxRe}>
                        <Typography onClick={() => this.props.navigation.navigate('UserRequests')} className={classes.headBoxText}>{configJSON.requestVR}</Typography>
                        <img style={{ height: "9.31px", width: "5.49px", color: "#64748B" }} src={vectorImg} alt='icon' />
                        <Typography className={classes.headBoxText2}>{configJSON.requestVR2}</Typography>

                    </Box>
                    <Typography style={{ fontSize: "30px", fontWeight: 700, color: "#0F172A", letterSpacing: "-0.5%", marginTop: "25px" }}>Details on Verification Requests</Typography>

                    <Box style={{ height: "62px", display: "flex", flexDirection: "column", gap: "8px", marginTop: "25px" }}>
                        <Box style={{ display: "flex", gap: "12px" }}>
                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: "#1E293B" }}>#669059</Typography>
                            <Typography style={{ color: "#059669", fontSize: "12px", fontWeight: 700, textTransform: "uppercase", background: "#D1FAE5", borderRadius: "40px", height: "22px", width: "81px", display: "flex", alignItems: "center", justifyContent: "center" }}>Approved</Typography>
                        </Box>
                        <Typography style={{ fontSize: "24px", fontWeight: 600, letterSpacing: "-0.5%", color: "#1E293B" }}>Apex Financial Solutions</Typography>
                    </Box>

                    <Box style={{ padding: '40px', display: 'flex', flexDirection: 'column', gap: '18px', height: '630px', width: '793px', borderRadius: '24px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: '600', color: '#1E293B', marginLeft: "16px" }}>Verification Timeline</Typography>
                        <Box className={classes.reportLine}>
                            <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', paddingLeft: '40px' }}>
                                <Box style={{ padding: '8px', backgroundColor: '#EFF6FF', color: '#1D4ED8', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '8px', top: '0px', fontSize: '18px', fontWeight: 600, }}>1</Box>
                                <Box style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#475569' }}>22 Feb 2022</Typography>
                                    <Typography style={{ fontSize: '16px', fontWeight: '500', color: '#1E293B' }}>You have requested a verification for Apex Financial Solutions.</Typography>
                                </Box>
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', position: 'relative', paddingLeft: '40px' }}>
                                <Box style={{ padding: '8px', backgroundColor: '#EFF6FF', color: '#1D4ED8', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '8px', top: '0px', fontSize: '18x', fontWeight: 600,}}>2</Box>
                                <Box style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#475569' }}>26 Feb 2022</Typography>
                                    <Typography style={{ fontSize: '16px', fontWeight: '500', color: '#1E293B' }}>Apex Financial Solutions has accepted the verification request.</Typography>
                                </Box>
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', position: 'relative', paddingLeft: '40px' }}>
                                <Box style={{ padding: '8px', backgroundColor: '#EFF6FF', color: '#1D4ED8', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '8px', top: '0px', fontSize: '18px', fontWeight: 600 }}>3</Box>

                                <Box style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#475569' }}>26 Feb 2022</Typography>
                                    <Typography style={{ fontSize: '16px', fontWeight: '500', color: '#1E293B' }}>The verification of Apex Financial Solutions has been completed successfully.</Typography>
                                </Box>
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '40px', position: 'relative', paddingLeft: '40px' }}>
                                <Box style={{ padding: '8px', backgroundColor: '#EFF6FF', color: '#1D4ED8', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '8px', top: '0px', fontSize: '18px', fontWeight: 600 }}>4</Box>

                                <Box style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                    <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#475569' }}>26 Feb 2022</Typography>

                                    <Box style={{ display: 'flex', gap: '16px' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '10px', padding: '8px 4px 8px 8px', borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
                                            <img style={{ marginRight: '10px',height:"56px",width:"56px" }} src={groupReImg} alt="group"/>
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontSize: '16px', fontWeight: '400', color: '#0F172A' }}>File name.doc</Typography>
                                                <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#64748B' }}>128 KB</Typography>
                                            </Box>
                                            <Box style={{ marginLeft: 'auto' }}>
                                                <IconButton size="small">
                                                    <MoreVert />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '10px', padding: '8px 4px 8px 8px', borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
                                            <img style={{ marginRight: '10px',height:"56px",width:"56px" }} src={groupReImg} alt="group"/>
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontSize: '16px', fontWeight: '400', color: '#0F172A' }}>File name.doc</Typography>
                                                <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#64748B' }}>128 KB</Typography>
                                            </Box>
                                            <Box style={{ marginLeft: 'auto' }}>
                                                <IconButton size="small">
                                                    <MoreVert />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography style={{ display: 'flex', alignItems: 'center', color: '#059669', fontSize: '16px', fontWeight: 500,gap:"8px" }}>
                                       <img style={{height:"20px",width:"20px"}} src={infoReImg} alt="info"/>  Low Risk Business
                                    </Typography>
                                </Box>
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center', position: 'relative', paddingLeft: '40px' }}>
                                <Box style={{ padding: '8px', backgroundColor: '#EFF6FF', color: '#1D4ED8', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '8px', top: '0px', fontSize: '18px', fontWeight: 600}}>5</Box>

                                <Box style={{ marginLeft: '20px', textAlign: 'center' }}>
                                    <Button variant="contained" style={{ background: '#1E3A8A', fontSize: '14px', fontWeight: '700', textTransform: 'none', color: "#FFFFFF" }}>Create Business Deal</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </UserProfileDrawer>
        )
    }
}

export default withStyles(styles)(RequetWeb);