import React from "react";
// Customizable Area Start
import AdminProfileController, { Props } from "./AdminProfileController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import { Box, Button as Btn, InputAdornment, Typography } from "@material-ui/core";
import CustomImage from "../../../../packages/components/src/design-system/Image/Image";
import TextInput from "../../../../packages/components/src/design-system/TextInput";
import { admin } from './assets';
const configJson = require("./config.js");
import Text from "../../../../packages/components/src/design-system/Text";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    // Customizable Area End
}

export class AdminProfile extends AdminProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <UserProfileDrawer 
                handleClick={this.handleDrawerClick}
                pageName={"dashboard"}
                breadScrumArray={[]}
                data-test-id = "drawerTestID"
                userType = {"admin_account"}
                navigation={this.props.navigation}
            >
            <Box sx={webStyle.mainWrapper}>
                <Box sx={webStyle.imgcontainer} >
                    <CustomImage src={admin} alt="Verified" style={webStyle.image} className={classes.imgClas} /></Box>
                <Typography variant="inherit" className={classes.text}>{configJson.navADMIN}</Typography>
                <Box style={webStyle.inputBox}>
                    <TextInput
                        data-test-id={"EmailtxtInput"}
                        variant="standard"
                        InputProps={{ className: classes.adminroot }}
                        label="Email address"
                        color="#747A80"
                        type="text"
                        value={this.state.UserProfile.attributes.email} />
                    <TextInput
                        data-test-id={"NumbertxtInput"}
                        InputProps={{ className: classes.adminroot }}
                        variant="standard"
                        color="#747A80"
                        label="Phone number"
                        type="text"
                        value={this.state.UserProfile.attributes.phone_number}
                    />
                    <TextInput
                        data-test-id={"passwordtxtInput"}
                        InputProps={{ className: classes.adminroot }}
                        variant="standard"
                        label="Password"
                        customStyle={classes.adminroots}
                        type="password"
                        border-bottom="0px"
                        value={this.state.password}
                        color="#747A80"
                        endAdornment={(
                            <InputAdornment position="end" color='red' style={{ position: 'absolute', top: "15px", right: "0px" }}>
                                <Text variant="coolBlue" size="xx" weight="extraBold">CHANGE</Text>
                            </InputAdornment>
                        )}
                    />
                </Box>
                <Box style={webStyle.buttonBox} >
                    <Btn data-test-id={"log_out_admin"} style={webStyle.button} variant="outlined" color={"primary"} className={classes.btnClass} onClick={this.handleLogout} >Logout</Btn>
                    <Btn data-test-id={"buttonupdate"} style={webStyle.updateButton} className={classes.btnClass} variant="outlined"
                    >Update Details</Btn>
                </Box>
            </Box>
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(AdminProfile);

const webStyle = {

    mainWrapper: {
        maxWidth: '1119px',
        minHeight: '722px',
        borderRadius: '22px',
        gap: '28px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        backgroundColor: '#FFFFFF',
        "@media (max-width: 600px)": {
            padding: "30px 0px",
            width: '85%',
            gap: '26px'
        },
        "@media (max-width: 900px)": {
            padding: "40px 0px",
            width: '100%',
            gap: '26px'
        },
    },
    imgcontainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        marginTop: '40px'
    },
    image: {
        top: '202px',
        left: '784px',
        borderRadius: '50%',
        border: '2px solid #3751FF',
        Top: "135px",
        Left: "235px",
    },
    inputBox: {
        gap: '11px',
        marginTop: "40px",
        width: "90%",
        background: '#E5E5E5',
        top: '472px',
        display: 'flex',
        flexDirection: "column" as const,
        left: '341px',
        opacity: '0.7px',
        padding: '20px',
        borderRadius: "8px",
    },
    buttonBox: {
        display: 'flex' as const,
        justifyContent: 'space-between' as const,
        margin: '50px 0px',
        width: '93.1%',
        marginTop: '10px',
        "@media (max-width: 600px)": {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: "22px",
            alignItems: 'center'
        }
    },
    button: {
        border: "1px solid #3751FF",
        height: '46px',
    },
    updateButton: {
        fontFamily: "Inter",
        height: '46px',
        border: "1px solid #3751FF",
        background: "#0052FF",
        color: "#fff",
        fontSize: "14px",
        fontWeight: "700",
    },
};

// Customizable Area End