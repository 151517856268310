import React from "react";
// Customizable Area Start
import UserLoginWebController, {
    Props
} from "./UserLoginPageController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import { Box, Typography } from "@material-ui/core";
import { imgContact, imgLocation, imgQuestion, imgSetting, imgSetting2 } from "./assets";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    Root: {
        backgroundColor: '#1E293B',
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        borderRadius: '20px'
    },
    containerBox: {
        height: '184px',
        borderRadius: '10px',
        width: '387px',
        backgroundColor: '#CBD5E1',
        alignSelf: 'center',
        color: '#334155',
        fontSize: '20px',
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class ServiceInternalTypeWeb extends UserLoginWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Paper style={{ padding: '160px 120px' }} >
                <Box className={classes.Root} >
                    <Box style={{ padding: '46px 46px 64px' }}>
                        <Typography style={{ fontFamily: 'Poppins', color: '#F1F5F9', fontSize: '36px', fontWeight: 600, textAlign: 'center' }}>Login to the accreditation platform</Typography>
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '2rem' }}>
                        <Box className={classes.containerBox} >
                            Individual
                        </Box>
                        <Box className={classes.containerBox} >
                            Business
                        </Box>
                    </Box>
                    <Box style={{ position: 'absolute', left: '180px', top: '280px' }} >
                        <img src={imgQuestion} alt='poster' />
                    </Box>
                    <Box style={{ position: 'absolute', left: '150px', top: '680px' }} >
                        <img src={imgSetting} alt='poster' />
                    </Box>
                    <Box style={{ position: 'absolute', left: '980px', top: '660px' }} >
                        <img src={imgSetting2} alt='poster' />
                    </Box>
                    <Box style={{ position: 'absolute', right: '62px', top: "369px" }} >
                        <img src={imgContact} alt='poster' />
                    </Box>
                    <Box style={{ position: 'absolute', left: '80px', top: '426px' }} >
                        <img src={imgLocation} alt='poster' />
                    </Box>
                </Box>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ServiceInternalTypeWeb);
