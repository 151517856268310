Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name";
exports.lastName = "Last Name";
exports.labelUserName = "User Name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.labelPhone = "Phone Number";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password is invalid";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.errorInvalidPhone = "Phone number is invalid";
exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts/";

exports.verifyEmailAPiEndPoint =
  "account_block/accounts/verify_email_otp";

exports.accountDetailInformationApiEndPoint = "account_block/accounts/detailed_information";

exports.debtPortfolioApiEndPoint = "account_block/dept_portfolio";

exports.businessInterestApiEndPoint = "account_block/business_interest";

exports.activateUserAccountApiEndPoint = "/account_block/accounts/activate"

exports.fetchProfileEndPoint = 'bx_block_profile/profiles/user_profile';

exports.apiMethodTypeverifyEmail = "PATCH";

exports.apiMethodTypeAddDetail = "POST";

exports.apiMethodFetch = "GET";

exports.errorEmailNotValid = "Email not valid.";

exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.userType = "consumer";
exports.signUpHeading = "Enter your information to sign up";
exports.dropMessage = "Drag & drop files or Browse"
exports.format = "Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT";
exports.EN = 'en';
exports.AR = 'ar';
exports.Email = 'Email';
exports.FirstName = "FirstName";
exports.FullName = "FullName";
exports.BankName = "BankName";
exports.ServiceProviderName = "ServiceProviderName";
exports.AgencyName = "AgencyName";
exports.LastName = 'LastName';
exports.PhoneNumber = 'Phone';
exports.UserName = 'UserName';
exports.Password = 'Password';
exports.Country = 'Country';
exports.City= 'City';
exports.State = 'State';
exports.Zip = 'Zip';
exports.OTP = 'OTP';
exports.NationalId = 'NationalId';
exports.CommercialRegistration = "CommercialRegistration";
exports.BankLicenseNumber = "BankLicenseNumber";
exports.AgencyRegistrationNumber = "AgencyRegistrationNumber";
exports.AgencyLicenseNumber = "AgencyLicenseNumber";
exports.RegistrationNumber =  "RegistrationNumber";
exports.ServiceLicenseNumber = "ServiceLicenseNumber";

exports.ConfirmPassword = 'ConfirmPassword';
exports.CreatePassword = 'CreatePassword';
exports.CommercialRegistrationNumber = 'CommercialRegistrationNumber';
exports.BusinessRegistrationDocument = "BusinessRegistrationDocument";
exports.LicenseOrCertification = "LicenseOrCertification";
exports.TaxRegistrationDocument = "TaxRegistrationDocument"
exports.UnifyingNumber = "UnifyingNumber";
// Customizable Area End
