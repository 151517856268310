import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { removeStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const languageTranslationData = require('../../languagesupport/src/LanguageData/PlanTransactions.json');
const utills = require('../../utilities/src/utills');
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage: string;
    userType: string;
    breadscrumarray: Array<string>;
    showPublished: boolean;
    publishAnchorEl: HTMLElement | null;
    listingType: string;
    status: string;
    showDetailPreview: boolean;
    showFilter: boolean;
    pageHeadingText: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class PlanTransactionsController extends BlockComponent<
Props,
S,
SS
> {

    constructor (props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage:"en",
            userType: 'admin',
            breadscrumarray: [],
            showPublished: false,
            publishAnchorEl: null,
            listingType: 'block',
            status: '',
            showDetailPreview: false,
            showFilter: false,
            pageHeadingText: ""
            // Customizable Area End
        }
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiRequestCallId && responseJson) {
          }
        }

        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const languageData = languageTranslationData[language];
        this.setState({ 
            currentLanguage: language,
            pageHeadingText: languageData.Heading
        });
        // Customizable Area End
    }

    // Customizable Area Start
    handleRouting = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawer = (route:string)=>{
        if(route==="logOut"){
            this.handleLogOut();
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrumClick(route);
            } else {
                this.handleRouting(route);
            }
        }
    };

    handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleRouting("Home");
    };

    handleBreadScrumClick = (clickOn: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        if(clickOn==="breadScrum0"){
            this.setState({
                showDetailPreview: false, 
                breadscrumarray:[],
                publishAnchorEl: null,
                pageHeadingText: languageData.MyListings
            });
        }
    };

    handleMenuClose = () => {
        this.setState({ publishAnchorEl: null });
    }

    handleDetailPreviewShow = () => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        this.setState({
            showDetailPreview: true,
            breadscrumarray: [languageData.Heading, languageData.OfferTransactionDetails],
            publishAnchorEl: null,
            pageHeadingText: languageData.OfferDetails
        });
    }


    handlePublishMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({publishAnchorEl:event.currentTarget});
    };

    handleExportTransactions () {
        console.log("export transactions");
    }

    handleToggleFilter () {
        console.log("toggle filters");
    }
    // Customizable Area End
}

