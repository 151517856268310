import React from "react";
// Customizable Area Start
import ResetForgotPasswordController, {
    Props
} from "./ResetForgotPasswordController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import { Box, Button } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import TextInput from "../../../components/src/design-system/TextInput";
import { LoginFlow } from "../../../components/src/LoginFlow.web";

const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start

  containerAuto: {
      maxWidth: '996px',
      padding: '120px 20px',
      margin: '0 auto',
      "@media (max-width: 960px)": {
          padding: "40px 20px"
      }
  },
  containerLogin: {
    height: '100%',
    boxSizing: 'border-box',
    padding: '44px 100px',
    "@media (max-width: 960px)": {
        padding: "40px 20px"

    },
    "@media (max-width: 600px)": {
        padding: "40px 20px",
        borderRadius: "10px 10px 0px 0px"
    },
  },
  linkText: {
    cursor: 'pointer',
    color: '#1E3A8A',
    "&:hover": {
      textDecoration: 'underline'
    },
  },
    inputField: {
      "& .MuiInputBase-root": {
        '& fieldset': {
            top: '-5.5px !important',
        },
      }
    },
    submitBtn: {
      background: "#1E3A8A",
      color: '#fff',
      borderRadius: "8px",
      padding: 12,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 700,

      "&:hover": {
          background: "#1E3A8A",
          color: '#fff',    
      }
  }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class ResetForgotPassword extends ResetForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage, emailSent, email } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        
        return (
          <LoginFlow>
            <Box className={classes?.containerLogin}>
              <Box mb={3}>
                <Text variant="primary" size="xl"weight="extraBold">{languageData.Heading}</Text>
              </Box>
              { !emailSent && <>
              <Box mb={2}>
                <Text size="xm" weight="extraBold">{languageData.Title}</Text>
              </Box>
              <Box mb={3}>
                <Text size="sm" weight="minBold" family="inter">{languageData.Description}</Text>
              </Box>
              <Box display="flex" justifyContent="center"><Text size="1xl" weight="extraBold" family="inter">{languageData.ForgotPassword}</Text></Box>
                <Box className={classes?.inputField} >
                  <Box mb={'22px'} >
                    <TextInput
                        id="email"
                        label={languageData.EmailAddress}
                        onChange={this.txtInputEmailWebPrpos.onChangeText}
                        placeholder={languageData.EmailPlaceholder}
                        value={this.state.email}
                        error={this.state.errors.Email}
                    />
                  </Box>
                  <Button
                      fullWidth
                      variant="contained"
                      data-testid="submit-btn"
                      onClick={this.signupButton.onPress}
                      className={classes.submitBtn}
                  >
                    {languageData.RequestLink}
                  </Button>
                </Box></>}
                { emailSent && <>
                <Box mb={2}>
                  <Text size="xm" weight="extraBold">{languageData.CheckEmail}?</Text>
                </Box>
                <Box mb={3}>
                  <Text size="sm" weight="minBold" family="inter">{languageData.CheckEmailDescription} {email}</Text>
                </Box>
              </>}
                <Box mt={3} mb={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {emailSent && 
                  <span className={classes.linkText} id="resend-email">
                    <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.ResendEmail}</Text> 
                  </span>
                  }
                  <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
                    {languageData.BackTo}
                    <span style={{ marginLeft: 5}} className={classes.linkText} id="signup-test" onClick={() => this.handleRouting('SellerLogin')}>
                      <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Login}</Text></span>
                  </Text>
              </Box>
            </Box>
          </LoginFlow>
                  
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ResetForgotPassword);
