import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import React from "react";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Collections.json');
const editLanguageData = require('../../languagesupport/src/LanguageData/Listing.json');

interface BasicInfoErrors {
    portfolioSizeErr: string;
    listingTitleErr: string;
    averageBalanceErr: string;
    accountCountErr: string
};

interface DetailInfoErrorsItem {
    totalNumErr: string;
    totalAmtErr: string;
    totalJudgmentsErr: string;
    totalMonthErr: string;
    pastPayErr: string;
    monthAvgErr: string;
    lastCollectErr: string;
    lastCredErr: string;
};

interface DebtCharErrItem {
    typeOfDebtErr: string;
    ageOfDebtErr: string;
    recoveryStatusErr: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    language: "en" | "ar";
    breadcrumbArray: string[];
    mainHeading: string;
    filterAnchor: HTMLElement | null;
    moreAnchor: HTMLElement | null;
    selectPage: number;
    startDate: Date | null;
    endDate: Date | null;
    status: string;
    show: "collTable" | "collDetail" | "listingMarketplace" | "editListing";
    payMethod: string,
    payTerm: string;
    installmentRecieved: boolean;
    modalOpen: boolean;
    dummyFile: File | null;
    checkSave: boolean;
    portfolioSize: string;
    listingTitle: string;
    averageBalance: string;
    accountCount: string;
    basicInfoErrors: BasicInfoErrors;
    totalNumber: string;
    totalAmount: string;
    totalJudgement: string;
    totalMonth: string;
    pastPay: string;
    monthAvg: string;
    lastCollDay: string;
    lastCollMonth: string;
    lastCollYear: string;
    lastCredDay: string;
    lastCredMonth: string;
    lastCredYear: string;
    detailInfoErrors:DetailInfoErrorsItem;
    debtCharErr: DebtCharErrItem;
    typeOfDebt:string;
    ageOfDebt:string;
    recoveryStatus:string;
    supportDocArray: (File | null)[];
    otherDocArray: (File | null)[];
    documentErr: string;
    otherDocError: string;
    setMargin: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CollectionsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            language: "en",
            breadcrumbArray: [],
            mainHeading: "",
            filterAnchor: null,
            moreAnchor: null,
            selectPage: 1,
            startDate: null,
            endDate: null,
            status: "",
            show: "collTable",
            payMethod: "",
            payTerm: "",
            installmentRecieved: false,
            modalOpen: false,
            dummyFile: null,
            checkSave: false,
            portfolioSize: "",
            listingTitle: "",
            averageBalance: "",
            accountCount: "",
            basicInfoErrors: {
                portfolioSizeErr: "",
                listingTitleErr: "",
                averageBalanceErr: "",
                accountCountErr: ""
            },
            detailInfoErrors:{
                totalNumErr: "",
                totalAmtErr: "",
                totalJudgmentsErr: "",
                totalMonthErr: "",
                pastPayErr: "",
                monthAvgErr: "",
                lastCollectErr: "",
                lastCredErr: ""
            },
            debtCharErr: {
                typeOfDebtErr: "",
                ageOfDebtErr: "",
                recoveryStatusErr: ""
            },
            totalNumber: "",
            totalAmount: "",
            totalJudgement: "",
            totalMonth: "",
            pastPay: "",
            monthAvg: "",
            lastCollDay: "",
            lastCollMonth: "",
            lastCollYear: "",
            lastCredDay: "",
            lastCredMonth: "",
            lastCredYear: "",
            typeOfDebt: "",
            ageOfDebt: "",
            recoveryStatus: "",
            supportDocArray: Array(2).fill(null),
            otherDocArray: Array(2).fill(null),
            documentErr: "",
            otherDocError: "",
            setMargin: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    componentDidMount= async () => {
        let token = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        let language = await getStorageData("currentLanguage");
        let languageData = await languageTranslationData[language];
        const dummyFile = new File([''], 'File name.doc', { type: 'text/plain' });
        if (!token || user === "consumer" || user === "provider") {
            this.handleNavigation("Home");
        }
        this.setState({
            language: language,
            mainHeading: languageData.Collections,
            dummyFile: dummyFile,
            supportDocArray: [dummyFile, dummyFile],
            otherDocArray: [dummyFile, dummyFile]
        });
    };

    handleNavigation = (route: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleSideDrawerClick = (route: string) => {
        if(route==="logOut"){
            this.handleLogout()
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadcrumbClick(route);
            } else {
                this.handleNavigation(route);
            }
        }
    };

    handleBreadcrumbClick = (click: string) => {
        const languageData = languageTranslationData[this.state.language];
        if(click==="breadScrum0"){
            this.setState({
                show: "collTable", 
                breadcrumbArray:[],
                mainHeading: languageData.Collections,
                setMargin: false
            });
        };
        if(click==="breadScrum1"){
            this.handleViewDetailOpen();
        };
        if(click==="breadScrum2"){
            this.handlePreviewOpen();
        };
    };

    handleLogout = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };

    handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({moreAnchor:event.currentTarget});
    };

    handleStatusStyle = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "Pending" || value==="Due") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "Rejected") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor
        };
    };
    
    handlePageChange = (value: number) => {
        this.setState({selectPage: value});
    };

    handleMenuClose = () => {
        this.setState({
            filterAnchor: null,
            moreAnchor: null
        });
    };

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>);
    };

    handleSelect = (event: React.ChangeEvent<{ value:unknown}>, name: string) => {
        let {value} = event.target;
        this.setState({
            [name]: value as string
        } as unknown as Pick<S, keyof S>, ()=>{
            if(this.state.show==="editListing") {
                this.handelErrorCheck();
            }
        });
    };

    resetDate = () => {
        this.setState({startDate: null, endDate: null});
    };

    resetSelect = () => {
        this.setState({status: ""});
    };

    resetAll = () => {
        this.setState({
            startDate: null, 
            endDate: null,
            status: ""
        });
    };

    handleFilterToggle = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({filterAnchor:event.currentTarget});
    };

    engArabicNumeral = (value: string): string => {
        const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        if(this.state.language === "ar") {
            return String(value).replace(/\d/g, (d) => arabicNumerals[parseInt(d)]);
        } else {
            return value
        }
    };

    handleViewDetailOpen = () => {
        const { language } = this.state;
        const languageData = languageTranslationData[language];
        this.setState({
            mainHeading : languageData.Collectiondetails,
            breadcrumbArray: [languageData.Collections, languageData.Collectiondetails],
            show: "collDetail",
            moreAnchor: null,
            setMargin: false
        });
    };

    handlePreviewOpen = () => {
        const { language } = this.state;
        const languageData = languageTranslationData[language];
        this.setState({
            mainHeading : languageData.Convertlistingtomarketplace,
            breadcrumbArray: [languageData.Collections, languageData.Collectiondetails, languageData.Convertlistingtomarketplace],
            show: "listingMarketplace",
            modalOpen: false,
            setMargin: true
        });
    };

    handleEditListingOpen = () => {
        const { language } = this.state;
        const languageData = languageTranslationData[language];
        this.setState({
            mainHeading : languageData.Editlistings,
            breadcrumbArray: [
                languageData.Collections, 
                languageData.Collectiondetails, 
                languageData.Convertlistingtomarketplace,
                languageData.Editlistings
            ],
            show: "editListing",
            setMargin: true
        });
    };

    handleBorderShow = (index: number) => {
        const { payTerm, payMethod, installmentRecieved } = this.state;
        let border = "1px solid #E2E8F0";
        if (index == 4 || index === 5 || index === 6) {
            if (index === 6) {
                border = "none";
            } else {
                if ( index === 4 && !installmentRecieved && (!payTerm || !payMethod)) {
                    border = "none";
                }
                if (index === 5 && !installmentRecieved) {
                    border = "none";
                }
            }
        }
        return border;
    };

    handleInstructinClick = () => {
        this.setState({
            installmentRecieved: true,
            payTerm: "",
            payMethod: ""
        });
    };

    handleModalOpen = () => {
        this.setState({ modalOpen : true});
    };

    handleModalClose = () => {
        this.setState({ modalOpen : false});
    };

    handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        if(name!=='listingTitle'){
            value = value.replace(configJSON.replaceAlphabetRegex, "");
        }
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>, ()=>this.handelErrorCheck());
    };

    handleMenuItem = (menuItem:string) => {
        const {language} = this.state;
        const languageData = editLanguageData[language];
        let array;
        switch(menuItem){
            case "pastPay":{
                array=[{value: "Yes", label: languageData.Yes}, {value: "No", label: languageData.No}]
                break;
            }
            case "monthAvg":{
                array=[
                    {value: "1", label: "1"}, 
                    {value: "2", label: "2"}, 
                    {value: "3", label: "3"},
                    {value: "4", label: "4"},
                    {value: "5", label: "5"}
                ]
                break;
            }
            case "typeOfDebt":{
                const value:string[] = Object.values(editLanguageData['en'].DebtCharacFields.typeMenu)
                const label:string[] = Object.values(languageData.DebtCharacFields.typeMenu)
                array = label.map((item, index) => {return {value:`${value[index]}`, label:item}})
                break;
            }
            default:{
                const value:string[] = Object.values(editLanguageData['en'].DebtCharacFields.currentMenu)
                const label:string[] = Object.values(languageData.DebtCharacFields.currentMenu)
                array = label.map((item, index) => {return {value:`${value[index]}`, label:item}})
                break;
            }
        }
        return array;
    };

    handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value, name } = event.target;
        value = value.replace(configJSON.replaceAlphabetRegex, "");
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        if (value !== "" && (parseInt(value, 10) < 0 || parseInt(value, 10) > 12)) {
          value = "";
        }
        this.setState({
          [name]: value
        }  as unknown as Pick<S, keyof S>,
        ()=>this.handelErrorCheck());
    };

    handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value, name } = event.target;
        let { lastCollMonth, lastCollYear, lastCredMonth, lastCredYear } = this.state;
        let numberOfDays = 31;
        let dateName = name.substring(0,8), dateMonth = lastCredMonth, dateYear = lastCredYear;
        value = value.replace(configJSON.replaceAlphabetRegex, "");
        if(dateName==="lastColl"){
            dateMonth = lastCollMonth;
            dateYear = lastCollYear
        }
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        if (dateMonth && dateYear) {
          numberOfDays = (new Date(Number(dateYear), Number(dateMonth), 0).getDate());
        } else if (dateMonth && !dateYear) {
          numberOfDays = (new Date(2001, Number(dateMonth), 0).getDate());
        }
        if (
          value !== "" &&
          (parseInt(value, 10) < 0 || parseInt(value, 10) > numberOfDays)
        ) {
          value = "";
        }
        this.setState({
          [name]: value
        } as unknown as Pick<S, keyof S>,
        ()=>this.handelErrorCheck());
    };
    
    handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let {value, name} = event.target
        let inputYear = value.trim();
        const currentYear = new Date().getFullYear();
        const isValidYear = parseInt(inputYear, 10) >= 1 && parseInt(inputYear, 10) <= currentYear;
        if (!isValidYear) {
          inputYear=""
        }
        this.setState({
          [name]: inputYear
        } as unknown as Pick<S, keyof S>, 
        ()=>this.handelErrorCheck());
    };
    
    handleBlur = (valueName: string, value: string) => {
        const date = valueName.substring(8);
        switch (date) {
          case "Month": {
            if (value.length === 1 || value === "00") {
              if (value === "0" || value === "00") {
                value = "";
              } else {
                value = `0${value}`;
              }
            }
            break;
          }
          case "Day": {
            this.handleDayBlur(valueName, value);
            break;
          }
          default: {
            if (Number(value) < 1900) {
                value="1900"
            }
            break;
          }
        }
        if(date!=="Day"){
            this.setState({
                [valueName]: value,
            } as unknown as Pick<S, keyof S>,
            ()=>this.handelErrorCheck());
        }
    };

    handleDayBlur = (valueName: string, value: string) => {
        if (value.length === 1 || value === "00") {
            if (value === "0" || value === "00") {
                value = "";
            } else {
                value = `0${value}`;
            }
        }
        this.setState({
            [valueName]: value,
        } as unknown as Pick<S, keyof S>,
        ()=>this.handelErrorCheck());
    };

    handleAddPortfolio = (event: React.ChangeEvent<HTMLInputElement>, index: number | null, array:string) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        let {supportDocArray, otherDocArray } = this.state;
        let fileArray = array==="supportDocArray"? supportDocArray: otherDocArray;
        let newIndex = index;
        if(index === null) {
            for (let i = 0; i < fileArray.length; i++) {
                if (fileArray[i] === null) {
                  newIndex = i;
                  break;
                }
            }
        }
        if (file) {
            this.changeFile(newIndex, array, file);
        }
    };

    changeFile = (newIndex:number|null, array: string, file: File) => {
        this.setState(prevState => {
            const updatedArray = array==="supportDocArray"? [...prevState.supportDocArray]: [...prevState.otherDocArray];
            if (newIndex !== null && newIndex >= 0 && newIndex < updatedArray.length) {
                updatedArray[newIndex] = file;
            } else {
                updatedArray.push(file);
            }
            return { [array]: updatedArray, moreAnchor : null } as unknown as Pick<S, keyof S>;
        }, ()=>this.handelErrorCheck());
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const anchor = this.state.moreAnchor;
        if (anchor && anchor.id) {
            const lastChar = anchor.id.slice(-1);
            const indexNumber = Number(lastChar);
            const docType = anchor.id.substring(0, (anchor.id).length - 1)
            this.handleAddPortfolio(event, indexNumber, docType);
        }
    };

    handleFileRemove = () => {
        const anchor = this.state.moreAnchor
        if (anchor && anchor.id) {
            const lastChar = anchor.id.slice(-1);
            const indexNumber = Number(lastChar);
            const docType = anchor.id.substring(0, (anchor.id).length - 1)
            this.handlePortfolioRemove(indexNumber, docType);    
        }
    };

    handlePortfolioRemove = (index:number, fileArray:string) => {
        const {supportDocArray, otherDocArray } = this.state;
        let array = fileArray==="supportDocArray"? supportDocArray: otherDocArray;
        const arrayName = fileArray==="supportDocArray"? "supportDocArray": "otherDocArray"
        if (index >= 0 && index < array.length) {
            if(array.length>2) {
                array.splice(index, 1);
            } else {
                if(index===0 && array[1]!==null){
                    let value = array[1];
                    array[0] = value;
                    array[1] = null;
                } else {
                    array[index] = null;
                }
            }
            this.setState({ 
                [arrayName]: array, 
                moreAnchor: null 
            } as unknown as Pick<S, keyof S>,
            ()=>this.handelErrorCheck()
        );
        }
    };

    handelErrorCheck = () => {
        let {supportDocArray, otherDocArray} = this.state;
        let basicInfoError = this.checkBasicInfoError();
        let detailInfoError = this.checkDetailInfoError();
        let debtCharaErr = this.checkDebtCharError();
        let supportDocErr = this.handleDocError(supportDocArray, "documentErr");
        let otherDocErr = this.handleDocError(otherDocArray, "otherDocError");
        let noError = [
            basicInfoError,
            detailInfoError, 
            debtCharaErr, 
            supportDocErr, 
            otherDocErr
        ].every(value=>!value);
        return noError;
    };

    checkBasicInfoError = () => {
        const { portfolioSize, listingTitle, averageBalance, accountCount, language} =this.state;
        let portfolioSizeErr = "", listingTitleErr = "", averageBalanceErr = "", accountCountErr = "";
        const errorLang = editLanguageData[language].basicInfofields
        if(!portfolioSize){
            portfolioSizeErr = errorLang.PortfolioSizeErr;
        }
        if(!listingTitle){
            listingTitleErr = errorLang.ListingTitleErr;
        }
        if(!averageBalance){
            averageBalanceErr = errorLang.AverageBalanceErr;
        }
        if(!accountCount){
            accountCountErr = errorLang.AccountCountErr;
        }
        let errorObject = {
            portfolioSizeErr, 
            listingTitleErr, 
            averageBalanceErr, 
            accountCountErr
        }
        this.setState({
            basicInfoErrors: errorObject
        });
        let checkErrors = Object.values(errorObject).every((value) =>
            value === undefined || value.length === 0
        );
        return !checkErrors;
    };

    checkDetailInfoError = () => {
        const {
            language, 
            totalNumber, 
            totalAmount,
            totalJudgement,
            totalMonth,
            pastPay,
            monthAvg,
            lastCollDay,
            lastCollMonth,
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear
        } =this.state;
        let totalNumErr= "", totalAmtErr= "", totalJudgmentsErr= "", totalMonthErr= "", pastPayErr= "", monthAvgErr= "";
        let lastCollectErr= "", lastCredErr= ""
        const errorLang = editLanguageData[language].detailInfoFields
        if(!totalNumber){
            totalNumErr = errorLang.totalNumDebtErr;
        }
        if(!totalAmount){
            totalAmtErr = errorLang.totalAmoDebtErr;
        }
        if(!totalJudgement){
            totalJudgmentsErr = errorLang.totalJudgErr;
        }
        if(!totalMonth){
            totalMonthErr = errorLang.totalMonCustErr;
        }
        if(!pastPay){
            pastPayErr = errorLang.pastPayErr;
        }
        if(!monthAvg){
            monthAvgErr = errorLang.avgMonErr;
        }
        if(!lastCollDay|| !lastCollMonth|| !lastCollYear){
            lastCollectErr = errorLang.lastCollErr;
        }
        if(!lastCredDay || !lastCredMonth ||!lastCredYear){
            lastCredErr = errorLang.lastCredErr;
        }

        let errorObject = {
            totalNumErr,
            totalAmtErr,
            totalJudgmentsErr,
            totalMonthErr,
            pastPayErr, 
            monthAvgErr,
            lastCollectErr,
            lastCredErr
        }
        this.setState({ detailInfoErrors:errorObject});
        let checkErrors = Object.values(errorObject).every((value) =>
            value === undefined || value.length === 0
        );
        return !checkErrors;
    };

    checkDebtCharError = () => {
        const {
            language, 
            typeOfDebt,
            ageOfDebt,
            recoveryStatus
        } =this.state;
        let typeOfDebtErr= "", ageOfDebtErr= "", recoveryStatusErr= ""
        const errorLang = editLanguageData[language].DebtCharacFields
        if(!typeOfDebt){
            typeOfDebtErr = errorLang.typeErr;
        }
        if(!ageOfDebt){
            ageOfDebtErr = errorLang.ageErr;
        }
        if(!recoveryStatus){
            recoveryStatusErr = errorLang.currentErr;
        }
        let errorObject = {
            typeOfDebtErr, 
            ageOfDebtErr, 
            recoveryStatusErr
        }
        this.setState({
            debtCharErr: errorObject
        });
        let checkErrors = Object.values(errorObject).every((value) =>
            value === undefined || value.length === 0
        );
        return !checkErrors;
    };

    handleDocError = (array: (File|null)[], errorFor: string) => {
        let {language} = this.state;
        const errors = editLanguageData[language].documentErr;
        let isError = array.some(item=>item===null);
        let errorMessage = ""
        if(isError){
            errorMessage = errors
        }
        this.setState({[errorFor]: errorMessage} as unknown as Pick<S, keyof S>);
        return isError;
    };

    handleEditSave = () => {
        this.setState({
            checkSave: true
        });
        let noError = this.handelErrorCheck();
        if(noError){
            this.setState({
                checkSave: false
            }, ()=>this.handlePreviewOpen());
        }
    };

    // Customizable Area End
}
