export const blogImage = require('../assets/Blog_banner.png')
export const Image1 = require('../assets/image1.png')
export const Image2 = require('../assets/image2.png')
export const Image3 = require('../assets/image3.png')
export const Image4 = require('../assets/image4.png')
export const Image5 = require('../assets/image5.png')
export const Image6 = require('../assets/image6.png')
export const avatar1 = require('../assets/avtar1.png')
export const avatar2 = require('../assets/avtar2.png')
export const avatar3 = require('../assets/avtar3.png')
export const avatar4 = require('../assets/avtar4.png')
export const avatar5 = require('../assets/avtar5.png')
export const avatar6 = require('../assets/avtar6.png')




