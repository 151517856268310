import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    IconButton,
    Modal,
    Paper,
    Switch,
    SwitchProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddIcon from '@material-ui/icons/Add';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { visaLogo, masterCardLogo } from "./assets";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/userPlan.json');
// Customizable Area End

import UserPlanController, {
    Props,
    configJSON
} from "./UserPlanController";

// Customizable Area Start
// Customizable Area End

export default class UserPlan extends UserPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderPlanAndPricing = () => {
        const { currentLanguage } = this.state;
        const LanguageData = languageTranslationData[currentLanguage];
        return (
          <Box style={{width: "100%"}}>
            <Box style={webStyle.subHeadingBox}>
              <Typography style={webStyle.subHeading}>{LanguageData.PlanAndPricing}</Typography>
                <Box 
                    style={webStyle.editBox}
                    data-test-id="editButtonTestID"
                    onClick = {() => this.handleNavigationRouting("Customisableusersubscriptions")}
                >
                <Typography style={{fontWeight: 700}}>{configJSON.commonLanguageData[currentLanguage].Edit}</Typography>
                <EditIcon style={{ width: "20px" }}/>
              </Box>
            </Box>
            <Box style={webStyle.planDetailBox}>
                <Box 
                    style={
                        currentLanguage==="en"? 
                        {...webStyle.activeBox, right: "15px"}:
                        {...webStyle.activeBox, left: "15px"}
                    }
                >
                    {LanguageData.Active}
                </Box>
              <Typography style={{ color: "#0F172A" }}>
                <span style={webStyle.perYearValue}>{this.engArabicNumeral("$500")}</span> /{LanguageData.perYear}
              </Typography>
              <Typography style={webStyle.perYearValue}>
                {LanguageData.YearlySubscription}
              </Typography>
              <Box style={webStyle.subsItemWrapp}>
                {[
                  LanguageData.subBenItems.opt1,
                  LanguageData.subBenItems.opt2,
                  LanguageData.subBenItems.opt3,
                  LanguageData.subBenItems.opt4,
                  LanguageData.subBenItems.opt5,
                  LanguageData.subBenItems.opt6
                ].map((value, index) => (
                  <Box key={index} style={webStyle.subsItemBox}>
                    <CheckCircleIcon style={webStyle.subsCheckIcon} />
                    <Typography style={webStyle.subsItemLabel}>{value}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        );
    };
    
    renderPaymentMethod = () => {
        const { currentLanguage, selectedPayMethod } = this.state;
        const LanguageData = languageTranslationData[currentLanguage];
        return (
            <PaymentMethodBox
                style={{margin: currentLanguage === "en"? "0 60px 0 0": "0 0 0 60px"}}
            >
                <Box style={webStyle.subHeadingBox}>
                    <Typography style={webStyle.subHeading}>{LanguageData.Paymentmethod}</Typography>
                    <Box data-test-id="addNewBottonTestId" style={webStyle.editBox} onClick={this.handleModalOpen}>
                        <Typography style={{fontWeight: 700}}>{LanguageData.Addnew}</Typography>
                        <AddIcon style={{ width: "20px" }}/>
                    </Box>
                </Box>
                <Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        {[
                            { id: "visa", cardNum: "12345678", type: LanguageData.Visa, imgSrc: visaLogo },
                            { id: "mastercard", cardNum: "12345678", type: LanguageData.Mastercard, imgSrc: masterCardLogo }
                        ].map((value, index) => (
                            <Box
                                key={index}
                                data-test-id={`paymentMethod${index}`}
                                style={{
                                    ...webStyle.paymentItemWrapper,
                                    border:
                                    selectedPayMethod === value.id
                                        ? "1px solid #3B82F6"
                                        : "1px solid #E2E8F0",
                                    background:
                                    selectedPayMethod === value.id ? "#EFF6FF" : "white"
                                }}
                                onClick = {() => this.handlePaymentMethodSelect(value.id)}
                            >
                                <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                    <span
                                        style={{
                                            display: "block",
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "25px",
                                            border:
                                                selectedPayMethod === value.id
                                                ? "6px solid #1D4ED8"
                                                : "1px solid #64748B",
                                            boxSizing: "border-box"
                                        }}
                                    ></span>
                                <Box>
                                    <Typography
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            color: "#0F172A",
                                        }}
                                    >
                                        {`**** ${value.cardNum.substring(4)}`}
                                    </Typography>
                                    <Box style={{ display: "flex", gap: "5px" }}>
                                    <Typography
                                        style={{ fontSize: "14px", color: "#64748B" }}
                                    >
                                        {value.type}
                                    </Typography>
                                    <Typography
                                        style={{ fontSize: "14px", color: "#E2E8F0" }}
                                    >
                                        |
                                    </Typography>
                                    <Typography
                                        style={{ fontSize: "14px", color: "#64748B" }}
                                    >
                                        {configJSON.commonLanguageData[currentLanguage].Edit}
                                    </Typography>
                                    </Box>
                                </Box>
                                </Box>
                                <img src={value.imgSrc} alt={`${value.type + index}_image`} />
                            </Box>
                        ))}
                        <Box style={{marginTop: "8px"}}>
                            <Box 
                                data-test-id="autoRenewButtonTestID"
                                style={webStyle.switchButtonWrapper}
                                onClick = {() => this.handleAutoRenew()}
                            >
                                <IOSSwitch checked={this.state.autoRenewal}/> 
                                <Typography style={{color: "#0F172A"}}>
                                    {LanguageData.Automaticrenewal}
                                </Typography>
                            </Box>
                            <Typography style={webStyle.planRenewPara}>
                                {LanguageData.planRenew}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </PaymentMethodBox>
        );
    };

    renderTable = () => {
        const { currentLanguage } = this.state;
        const LanguageData = languageTranslationData[currentLanguage];
        const textAlign = currentLanguage === "ar" ? 'right' : 'left';
        const cellStyle: React.CSSProperties = {
            textAlign
        };
        return (
            <Box style={{marginTop: "40px"}}>
                <Box style={webStyle.subHeadingBox}>
                    <Typography style={webStyle.subHeading}>{LanguageData.Payments}</Typography>
                    <Box style={webStyle.editBox}>
                        <Typography style={{fontWeight: 700}}>{LanguageData.Viewall}</Typography>
                    </Box>
                </Box>
                <TableContainer style={webStyle.tableConStyle} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <CustomHeadingRow>
                                <TableCell style={cellStyle}>{LanguageData.tableHeading.Amount}</TableCell>                                
                                <TableCell style={cellStyle}>{LanguageData.tableHeading.TypeofPlan}</TableCell>
                                <TableCell style={cellStyle}>{LanguageData.tableHeading.Paymentdate}</TableCell>
                                <TableCell style={cellStyle} colSpan={2}>{LanguageData.tableHeading.Cardusedtopay}</TableCell>
                            </CustomHeadingRow>
                        </TableHead>
                        <TableBody>
                            {Array(3).fill(null).map((value, index)=>
                                <CustomBodyRow key={index}>
                                    <TableCell style={cellStyle}>$1,080.00</TableCell>
                                    <TableCell style={cellStyle}>Yearly Subscription</TableCell>
                                    <TableCell style={cellStyle}>Apr 14, 2022</TableCell>
                                    <TableCell style={cellStyle}>**** 8239</TableCell>
                                    <TableCell 
                                        style={{
                                            ...webStyle.seeDeatailStyle,
                                            textAlign: currentLanguage === "ar" ? 'left' : 'right'
                                        }}
                                    >{LanguageData.SeeDetails}</TableCell>
                                </CustomBodyRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };

    renderInput = (
        label: string, 
        value: string, 
        stateName: string, 
        placeholder: string, 
        handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        error: string
    ) => {
        return (
            <Box style={{width: "100%"}}>
                <Typography style={webStyle.inputLabel}>{label}</Typography>
                <TextFieldStyle
                    data-test-id={`${stateName}TestID`}
                    value = {value}
                    name = {stateName}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder={placeholder}
                    onChange = {handleChange}
                />
                {this.state.checkAddPayMeth && 
                    <Typography style={webStyle.errorText}>
                        {error}
                    </Typography>
                }
            </Box>
        );
    };

    renderModal = () => {
        const { currentLanguage, cardNumber, cardHolderName, expDate, cvvNumber, error } = this.state;
        const LanguageData = languageTranslationData[currentLanguage];
        const { Cardnumber, Cardholdername, ExpiryDate, datePlace, cvPlace, Addpaymentmethod, Cancel } = LanguageData;
        const dir = currentLanguage === "ar"? "rtl": "ltr";
        return (
            <Modal 
                data-test-id="modalTestID"
                open={this.state.showModal}
                onClose={this.handleModalClose}
            >
                <Box style={webStyle.modalMainBox}>
                    <Box dir={dir} style={webStyle.modalBodyBox}>
                        <Box style={webStyle.modHeadingBox}>
                            <Typography style={webStyle.modHeading}>
                                {LanguageData.Addnewpaymentmethod}
                            </Typography>
                            <IconButton onClick={this.handleModalClose}>
                                <CloseRoundedIcon style={{color: "#0F172A"}}/>
                            </IconButton>
                        </Box>
                        <Box style={webStyle.modMiddleBox}>
                            {this.renderInput(Cardnumber, cardNumber, "cardNumber", "0000 0000 0000 0000", this.handleCardNumberChange, error.cardNumErr)}
                            {this.renderInput(Cardholdername, cardHolderName, "cardHolderName", Cardholdername, this.handleCardHolderChange, error.cardHoldErr)}
                            <Box style={webStyle.lastInputBox}>
                                {this.renderInput(ExpiryDate, expDate, "expDate", datePlace, this.handleDateChange, error.expDateErr)}
                                {this.renderInput("CVV", cvvNumber, "cvvNumber", cvPlace, this.handleCvvChange, error.cvvErr)}
                            </Box>
                        </Box>
                        <Box style={webStyle.modButtonBox}>
                            <ResetButton onClick={this.handleModalClose}>{Cancel}</ResetButton>
                            <CreateButton 
                                data-test-id="addPaySubmitButtonTestID"
                                onClick={this.handleAddPaymentMethod}
                            >
                                {Addpaymentmethod}
                            </CreateButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentLanguage } = this.state;
        const LanguageData = languageTranslationData[currentLanguage];
        // Customizable Area End
        return (
            // Customizable Area Start
            <UserProfileDrawer
                data-test-id="drawerTestID"
                handleClick={this.handleDrawerClick}
                pageName={"UserPlan"}
                breadScrumArray={[]}
            >
                <Typography style={webStyle.mainHeading}>{LanguageData.Plan}</Typography>
                <UpperBox style={webStyle.upperBox}>
                    {this.renderPlanAndPricing()}
                    {this.renderPaymentMethod()}
                </UpperBox>
                {this.renderTable()}
                {this.renderModal()}
            </UserProfileDrawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const CreateButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    width: "248px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "56px",
    width: "248px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const TextFieldStyle = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
      }
    },
    "& .MuiInputBase-input": {
      height: "30px",
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: "400",
      "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
      }
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      opacity: 1
    }
});

const PaymentMethodBox = styled(Box)({
    width: "100%",
    "@media(max-width: 1300px)": {
        margin: "0px !important"
    }
});

const UpperBox = styled(Box)({
    "@media(max-width: 650px)": {
        flexDirection: "column"
    }
});

const CustomHeadingRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 500,
        fontSize: "12px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});

const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#000000",
        fontSize: "12px"
    }
});

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 48,
    height: 28,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 1,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            left: 3,
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#1D4ED8',
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[600]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 26,
        height: 26,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));

const webStyle = {
    errorText: {
        color: "#DC2626",
        fontSize: "12px",
        marginTop: "6px"
    },
    modHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        padding: "25px 17px 17px 35px",
        borderBottom: "1px solid #E2E8F0",
        alignItems: "baseline"
    },
    seeDeatailStyle: {
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700,
        cursor: "pointer"
    },
    modButtonBox: {
        display: "flex",
        gap: "20px",
        padding: "40px 35px 35px"
    },
    lastInputBox: {
        display: "flex",
        gap: "20px",
        paddingBottom: "25px",
        borderBottom: "1px solid #E2E8F0"
    },
    inputLabel: {
        fontSize: "14px",
        color: "#334155",
        fontWeight: 700,
        marginBottom: "7px"
    },
    modMiddleBox: {
        display: "flex",
        padding: "25px 35px 0",
        gap: "20px",
        flexDirection: "column"
    } as React.CSSProperties,
    modHeading: {
        fontSize: "24px",
        color: "#0F172A",
        fontWeight: 700
    },
    tableConStyle: {
        boxShadow: "none",
        width: "100%",
        overflow: "auto",
        borderRadius: "16px",
        border: "1px solid #E2E8F0"
    },
    switchButtonWrapper: {
        display: "flex",
        gap: "14px",
        width: "fit-content",
        alignItems: "center",
        cursor: "pointer"
    },
    planRenewPara: {
        fontSize: "12px",
        color: "#64748B",
        marginTop: "10px"
    },
    subsItemWrapp: {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    } as React.CSSProperties,
    paymentItemWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 20px",
        borderRadius: "8px",
        border: "1px solid #3B82F6",
        cursor: "pointer"
    },
    modalMainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
    modalBodyBox: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008"
    },
    subsItemLabel: {
        fontSize: "14px",
        color: "#475569",
        fontWeight: 500
    },
    subsItemBox: {
        display: "flex",
        gap: "10px"
    },
    subsCheckIcon: {
        color: "#34D399",
        width: "20px",
        height: "20px"
    },
    perYearValue: {
        fontSize: "20px",
        color: "#0F172A",
        fontWeight: 600
    },
    planDetailBox: {
        borderRadius: "28px",
        border: "1px solid #BFDBFE",
        background: "#EFF6FF66",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "32px"
    } as React.CSSProperties,
    activeBox: {
        color: "#1D4ED8",
        fontSize: "14px",
        fontWeight: 400,
        borderRadius: "26px",
        background: "#DBEAFE",
        position: "absolute",
        padding: "6px 12px",
        top: "15px"
    } as React.CSSProperties,
    editBox: {
        display: "flex",
        gap: "10px",
        color: "#1E3A8A",
        fontWeight: 700,
        fontSize: "16px",
        alignItems: "center",
        cursor: "pointer"
    },
    subHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    subHeading: {
        fontSize: "20px",
        color: "#0F172A",
        fontWeight: 600
    },
    mainHeading: {
        fontSize: "30px",
        color: "#0F172A",
        fontWeight: 700
    },
    upperBox: {
        marginTop: "15px",
        display: "flex",
        gap: "70px",
        width: "100%"
    }
};

// Customizable Area End