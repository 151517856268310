import React from "react";
import { Box, Radio } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

interface Props {
  accountType: string;
  handleAccountType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currentLanguage: string;
}

const webStyle={
  mainBox: {
    "@media(min-width: 1920px), (mix-height: 1080px)": {
      paddingTop: 180,
      marginBottom: 280
    }
  },
  items: {
    "@media (min-width: 1920px), (min-height: 1080px)": {
      gap: 16
    }
  }
}

class AccountTypeWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

    render() {
      const languageData = languageTranslationData[this.props.currentLanguage];
      const { accountType, handleAccountType } = this.props;
      return(
        <Box sx={webStyle.mainBox as CSSProperties} paddingTop={10} marginBottom={10} flexDirection="column" display="flex" alignItems="center">
          <Box>
            <Text size="xl" weight="extraBold" lineHeight="32px">{languageData.AccountType}</Text>
          </Box>
          <Box mt={2}>
            <Text size="sm" weight="minBold" lineHeight="24px">{languageData.AccountTypeDesc}</Text>
          </Box>
          <Box display="flex" sx={webStyle.items as CSSProperties} mt={3} flexDirection="column">
            <Box display="flex" alignItems="center">
              <Radio
                checked={accountType === "debtOwner"}
                color="primary"
                value={"debtOwner"}
                name="debt-owner"
                data-testid="debtOwner"
                inputProps={{ 'aria-label': 'debt-owner' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountType(event)}
              />
                <Text size="sm" weight="minBold">{languageData.DebtOwner}</Text>
            </Box>
            <Box display="flex" alignItems="center">
            <Radio
              checked={accountType === "debtBuyer"}
              color="primary"
              value="debtBuyer"
              name="debt-buyer"
              data-testid="debtBuyer"
              inputProps={{ 'aria-label': 'debt-buyer' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountType(event)}
            />
              <Text size="sm" weight="minBold">{languageData.DebtBuyer}</Text>
            </Box>
          </Box>
        </Box>
      )
    }
}

export default AccountTypeWeb;