import React from 'react';
import {
  Grid,
  Box,
  Container,
  Breadcrumbs,
} from '@material-ui/core';
import TextInput from '../../../components/src/design-system/TextInput';
import CustomButton from "../../../../packages/components/src/design-system/Button/Button";
import {
  ServiceInternalHeroImage, Duration,
  Financial, Language, Service, Target
}
  from './assets';
import Text from "../../../../packages/components/src/design-system/Text";
import SubmitRfqController from './SubmitRfqController.web';
import Image from "../../../../packages/components/src/design-system/Image/Image";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Rating } from '../../../blocks/productdescription/src/ProductDescription.web';
import { SellerLogo } from "../../../blocks/productdescription/src/assets";
const languageTranslation = require('../../languagesupport/src/LanguageData/SubmitRFQ.json')
export const configJSON = require("./config");

export default class SubmitRfq extends SubmitRfqController {

  render() {
    const { currentLanguage } = this.state;
    const languageData = languageTranslation[currentLanguage];

    return (
      <Container maxWidth="lg">
        <Box sx={{ pt: 3 }}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} aria-label="breadcrumb">
            <Text size="sm" weight="extraBold" variant="lightGrey">{languageData.Services}</Text>
            <Text size="sm" weight="extraBold" variant="primary">Company Name</Text>
          </Breadcrumbs>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Text size='2xxl' weight='lightBold' family='poppins'>
            Company Name
          </Text>
          <Box sx={{ display: 'inline-flex' }}>
            <Box>
              <Image
                src={SellerLogo}
                alt="Sample Image"
                style={{ maxWidth: '50px', borderRadius: '50%' }}
              />
            </Box>
            <Box pl={2}>
              <Box>
                <Text size='xx' weight='extraBold' family='inter'>Seller Name</Text>
              </Box>
              <Box>
                <Rating
                  value={4}
                  precision={0.5}
                  size="small"
                  className={"rating"}
                />
              </Box>
              <Box><Text size='xs' weight='minBold' family='inter' >4.0(120)</Text></Box>
            </Box>
          </Box>

        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box sx={webStyle.mainContainer}>
              <Box sx={webStyle.imgBox}>
                <Image
                  src={ServiceInternalHeroImage}
                  alt="Sample Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>
            </Box>
            <Box mt={3} mb={2}>
              <Text size='xm' weight='extraBold'>
                {languageData.Brief}
              </Text>
            </Box>
            <Box mb={9}>
              <Text size='sm' weight='bold' family='montserrat'>
                The platform provides many services to various government agencies,
                the private sector, and individuals, which enhances the partnership
                between them to achieve the goals of development projects in the Kingdom.
                To enable the digital transformation of these services, increase and efficiency,
                and facilitate the conduct of services transparency.
              </Text>
            </Box>
            <Box mb={9}>
              <form>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextInput label={languageData.Name} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label={languageData.Email} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label={languageData.PhoneNumber} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextInput label={languageData.Country} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label={languageData.CompanyName} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label={languageData.Debut} />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextInput label={languageData.Messages} />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box sx={webStyle.cardGridRight}>
              <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                <Box mr={2}>
                  <Image
                    src={Target}
                    alt="Image"
                  />
                </Box>
                <Box>
                  <Text size="sm" weight="extraBold" variant="primary">{languageData.TargetGroup}</Text>
                  <Text size="xs" weight="light" variant="primary">
                    {configJSON.sideBarHeadingFirstDescription}
                  </Text>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', marginBottom: '12px' }}>
                <Box mr={2}>
                  <Image
                    src={Duration}
                    alt="Image"
                  />
                </Box>
                <Box>
                  <Text size="sm" weight="extraBold" variant="primary">{languageData.DurationService}</Text>
                  <Text size="xs" weight="light" variant="primary">
                    {configJSON.sideBarHeadingSecondDescription}
                  </Text>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', marginBottom: '28px' }}>
                <Box mr={2}>
                  <Image
                    src={Service}
                    alt="Image"
                  />
                </Box>
                <Box>
                  <Text size="sm" weight="extraBold" variant="primary">{languageData.ServiceDeliveryChannels}</Text>
                  <Text size="xs" weight="light" variant="primary">{configJSON.sideBarHeadingThirdDescription}</Text>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', marginBottom: '28px' }}>
                <Box mr={2}>
                  <Image
                    src={Language}
                    alt="Image"
                  /></Box>
                <Box>
                  <Text size="sm" weight="extraBold" variant="primary">
                    {languageData.ThelanguageUsedForTheService}
                  </Text>
                  <Text size="xs" weight="light" variant="primary">{configJSON.sideBarHeadingsideBarHeadingFourthDescription}</Text>
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box mr={2}>
                  <Image
                    src={Financial}
                    alt="Image"
                  /></Box>
                <Box mb={2}>
                  <Text size="sm" weight="extraBold" variant="primary">{languageData.FinancialCompensation}</Text>
                  <Text size="xs" weight="light" variant="primary">{configJSON.sideBarHeadingsideBarHeadingFifthDescription}</Text>
                </Box>
              </Box>
              <Box mb={2} mt={2}>
                <CustomButton display='primary' themes='dark' fontSize='x' weight='semiBold' >{languageData.SubmitRequest}</CustomButton>
              </Box>
              <Box>
                <CustomButton display='light' themes='dark' fontSize='xl' weight='semiBold'>{languageData.ContactUs}</CustomButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const webStyle = {
  mainContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%"
  },
  imgBox: {
    height: "500px",
    width: "100%",
    backgroundColor: "#0f1729",
    marginBottom: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "79px 194px 77px 131px",
    boxSizing: "border-box"
  },
  cardGridRight: {
    padding: '23px 36px',
    backgroundColor: '#E2E8F0',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
};
