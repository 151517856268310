import React from "react";

// Customizable Area Start
import {
    Avatar,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Modal,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled
} from "@material-ui/core";
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import SearchIcon from "@material-ui/icons/Search";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import NotesIcon from "@material-ui/icons/Notes";
import EditIcon from "@material-ui/icons/Edit";
import { acitveIcon, rejectIcon, pendingIcon, avgIcon, fileUploadIcon, componyNameLogo, dummyQrCode } from "./assets";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Collections.json');
const editLanguageData = require('../../languagesupport/src/LanguageData/Listing.json');
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import TablePagination from "../../../components/src/design-system/Pagination.web";
import PreviewListing from "../../../components/src/design-system/PreviewListing/PreviewListing.web";
// Customizable Area End

import CollectionsController, {
    Props,
    configJSON,
} from "./CollectionsController";

// Customizable Area Start

interface ProfileInfo {
    heading: string,
    image:string, 
    name: string, 
    email: string, 
    phone: string,
    country: string,
    state: string,
    city: string,
    zipcode: string
};

interface SelectOptionItem {
    value: string;
    label: string
};

const collectionData = [
    ["#669059", "Strategic Finance Partners", "Strategic Finance Partners", "Medical Debt Package", "16 Dec 2022", "Accepted"],
    ["#669023", "James Carter", "James Carter", "Auto Loan Portfolio", "21 Mar 2022", "Accepted"],
    ["#669345", "Blue Horizon Credit", "Blue Horizon Credit", "Personal Loan Debt", "22 Jan 2019", "Accepted"],
    ["#669748", "Sunrise Credit Solutions", "Sunrise Credit Solutions", "Retail Credit Accounts", "22 Feb 2022", "Pending"],
    ["#669023", "James Carter", "James Carter", "Healthcare Debt Portfolio", "21 Feb 2022", "Pending"],
    ["#669023", "James Carter", "James Carter", "Healthcare Debt Portfolio", "21 Mar 2012", "Pending"]
];

const paymentDetail = [
    ["#669059", "1", "3,000,000", "16 Dec 2022", "Paid"],
    ["#669023", "2", "1,166,667", "21 Mar 2022", "Due"],
    ["#234143", "3", "1,166,667", "03 Dec 2014", "Due"],
    ["#669345", "4", "1,166,667", "22 Jan 2019", "Due"],
    ["#669748", "5", "1,166,667", "22 Feb 2022", "Due"],
    ["#349059", "6", "1,166,667", "23 July 2022", "Due"]
];

const userData = {
    time: "54",
    portfolioSize: "2,500",
    listingTitle: "Medical Debt Package",
    averageBalance: "500",
    accountCount: "1",
    totalNumber: "1",
    totalAmount: "2,500",
    totalJudgement: "0",
    totalMonth: "3",
    pastPay: "Yes",
    monthAvg: "1",
    lastCollDay: "01",
    lastCollMonth: "02",
    lastCollYear: "2024",
    lastCredDay: "01",
    lastCredMonth: "02",
    lastCredYear: "2024",
    typeOfDebt: "Credit Card",
    ageOfDebt: "1",
    recoveryStatus: "ACTIVE COLLECTION",
    name: "James Carter",
    email: "james.carter@example.com",
    country: "United States",
    state: "Florida",
    city: "Miami",
    phoneNumber: "5678901234",
    zip: "33101"
};

// Customizable Area End

export default class Collections extends CollectionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderTable = () => {
        const { language } = this.state
        const { count, tableHeading } = languageTranslationData[language];
        const configLang = configJSON.commonLanguageData[language];
        const cellStyle: React.CSSProperties = {
            textAlign: language === "ar" ? 'right' : 'left'
        };
        return (
            <Box>
                <Box style={webStyle.countWrapper}>
                    {[
                        {
                            icon: <img src={acitveIcon} alt="activeIcon" width="24px" />,
                            title: count.Active,
                            value: "14"
                        },
                        {
                            icon: <DoneOutlineRoundedIcon style={{ color: '#1D4ED8' }} />,
                            title: count.Completed,
                            value: "2"
                        },
                        {
                            icon: <img src={rejectIcon} alt="activeIcon" width="24px" />,
                            title: count.Rejected,
                            value: "5"
                        },
                        {
                            icon: <img src={pendingIcon} alt="activeIcon" width="24px" />,
                            title: count.Pending,
                            value: "12"
                        }
                    ].map((value, index) =>
                        <Box key={index} style={webStyle.countBox}>
                            {value.icon}
                            <Typography style={webStyle.countTitle}>{value.title}</Typography>
                            <Typography style={webStyle.countValue}>{this.engArabicNumeral(value.value)}</Typography>
                        </Box>
                    )}
                </Box>
                <TableWrapper data-test-id="tableTestID" style={webStyle.tableWrapper}>
                    <Box style={webStyle.tableUppBox}>
                        <Box style={webStyle.searchFieldBox}>
                            <SearchIcon style={{ color: "#94A3B8" }} />
                            <SearchField
                                data-test-id="searchInputTestID"
                                fullWidth
                                variant="outlined"
                                placeholder={configLang.Search}
                            />
                        </Box>
                        <Button
                            data-test-id="filterButtonTestID"
                            style={webStyle.filterBotton}
                            onClick={this.handleFilterToggle}
                        >
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <FilterListRoundedIcon/>
                                {configLang.Filters}
                            </Box>
                        </Button>
                    </Box>
                    <TableContainer style={webStyle.tableContainerStyle} component={Paper}>
                        <Table style={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <HeadingRowStyle>
                                    {[
                                        tableHeading.ID,
                                        tableHeading.DebtBuyerName,
                                        tableHeading.DebtOwnerName,
                                        tableHeading.ListingName,
                                        tableHeading.Date,
                                        tableHeading.Status
                                    ].map((value, index) =>
                                        <React.Fragment key={index}>
                                            {index !== 5 ?
                                                <TableCell style={cellStyle}>{value}</TableCell> :
                                                <TableCell colSpan={2} style={cellStyle}>{value}</TableCell>
                                            }
                                        </React.Fragment>

                                    )}
                                </HeadingRowStyle>
                            </TableHead>
                            <TableBody>
                                {collectionData.map((value, index) =>
                                    <BodyRowStyle key={index}>
                                        {value.map((item, index) =>
                                            <React.Fragment key={index}>
                                                {index !== 5 ?
                                                    <TableCell style={cellStyle}>{item}</TableCell> :
                                                    <TableCell style={cellStyle}>
                                                        <span
                                                            style={{
                                                                ...webStyle.statusStyleSpan,
                                                                ...this.handleStatusStyle(value[5])
                                                            }}
                                                        >
                                                            {value[5]}
                                                        </span>
                                                    </TableCell>
                                                }
                                            </React.Fragment>
                                        )}
                                        <TableCell key={index} style={cellStyle}>
                                            <IconButton
                                                data-test-id={`moreIcon${index}TestID`}
                                                onClick={this.handleMoreMenuOpen}
                                            >
                                                <MoreVertIcon style={{ color: "#64748B", cursor: "pointer" }} />
                                            </IconButton>
                                        </TableCell>
                                    </BodyRowStyle>
                                )}
                                <BodyRowStyle>
                                    <TableCell colSpan={5} style={cellStyle}>
                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                            <TablePagination
                                                data-test-id="tablePaginationTestID"
                                                page={this.state.selectPage}
                                                count={8}
                                                handlePageChange={this.handlePageChange}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        style={{ textAlign: language === "en" ? "right" : "left" }}
                                    >
                                        <ResultBoxStyle>
                                            <Typography style={{ color: "#64748B", fontSize: "12px" }}>
                                                {
                                                    `${this.engArabicNumeral("1")} - ${this.engArabicNumeral("12")} ${configLang.of} ${this.engArabicNumeral("48")} ${configLang.results}`
                                                }
                                            </Typography>
                                        </ResultBoxStyle>
                                    </TableCell>
                                </BodyRowStyle>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>
            </Box>
        );
    };

    renderCollectionDetail = () => {
        const languageData = languageTranslationData[this.state.language]
        return (
            <Box style={{display: "flex", gap: "50px"}}>
                <Box style={webStyle.ViewDetailWrapper}>
                    <Box style={webStyle.detailChildBox}>
                        <Box style={webStyle.descUpBox}>
                            <Box>
                                <Typography style={webStyle.descUppValue}>Service</Typography>
                                <Typography>{languageData.OfferType}</Typography>
                            </Box>
                            <Box>
                                <Typography style={webStyle.descUppValue}>$10,000</Typography>
                                <Typography>{languageData.Purchaseamount}</Typography>
                            </Box>
                        </Box>
                        <Typography>
                            {configJSON.dummyOfferDescription}
                        </Typography>
                    </Box>
                    <Box style={webStyle.detailChildBox}>
                        <Box>
                            <Box style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px"
                            }}
                            >
                                <Typography style={webStyle.listHeadingText}>
                                    Medical Debt Package
                                </Typography>
                                <IconButton>
                                    <ExpandMoreRoundedIcon />
                                </IconButton>
                            </Box>
                            <Box style={webStyle.statusTimeWrapper}>
                                <Typography style={webStyle.activeText}>Active Collection</Typography>
                                <Typography style={webStyle.timeText}>Posted 54 minutes ago</Typography>
                            </Box>
                        </Box>
                        <Divider style={webStyle.dividerStyle} />
                        <Box style={webStyle.secondBox}>
                            {[
                                { icon: <MonetizationOnOutlinedIcon />, value: "2,500", label: languageData.PortfolioSize },
                                { icon: <PersonOutlinedIcon />, value: "1", label: languageData.AccountCount },
                                { icon: <img src={avgIcon} alt="avg_icon" />, value: "500", label: languageData.AverageBalanceperAccount }
                            ].map((item, index) =>
                                <Box key={index} style={{ display: "flex", gap: "12px" }}>
                                    <Box>{item.icon}</Box>
                                    <Box>
                                        <Typography style={webStyle.secondValueText}>{index === 1 ? item.value : `$${item.value}`}</Typography>
                                        <Typography style={{ color: "#334155" }}>{item.label}</Typography>
                                    </Box>
                                </Box>)}
                        </Box>
                    </Box>
                    <Box style={webStyle.detailChildBox}>
                    {this.renderOwnerInfoBox({ 
                            heading: languageData.DebtOwnerInformation, 
                            image: "", 
                            name: "James Carter",
                            email: "james.carter@example.com",
                            phone: "5678901234",
                            country: "United State",
                            state: "Florida",
                            city: "Miami",
                            zipcode: "33101"})
                        }
                        <Divider style={webStyle.dividerStyle} />
                        {this.renderOwnerInfoBox({ 
                            heading: languageData.DebtBuyerInformation, 
                            image: "", 
                            name: "John Doe",
                            email: "john.doe@example.com",
                            phone: "5678901234",
                            country: "United State",
                            state: "Florida",
                            city: "Miami",
                            zipcode: "33101"})
                        }
                    </Box>
                    <TimelineWrapperTwo>
                        {this.renderTimeline()}
                        <AcceptButton
                            style={{width: "100%", marginTop: "40px"}}
                            data-test-id = "convertToMarketplaceButtonTestID"
                            onClick={this.handleModalOpen}
                        >
                            {languageData.ConverttoMarketplace}
                        </AcceptButton>
                    </TimelineWrapperTwo>
                    {this.state.installmentRecieved && this.renderPaymentDetailTable()}
                </Box>
                <TimelineWrapperOne>
                    {this.renderTimeline()}
                    <AcceptButton
                        style={{width: "100%", marginTop: "40px"}}
                        data-test-id = "convertToMarketplaceButtonTestID"
                        onClick={this.handleModalOpen}
                    >
                        {languageData.ConverttoMarketplace}
                    </AcceptButton>
                </TimelineWrapperOne>
            </Box>
        );
    };

    renderPrevMarketListing = () => {
        const { language , dummyFile } = this.state;
        const configLang = configJSON.commonLanguageData[language];
        const languageData = languageTranslationData[language];
        return (
            <Box data-test-id="previewDetailBoxTestID">
                <Typography style={{color: "#475569", fontSize: "14px"}}>
                    {languageData.prevSubheading}
                </Typography>
                <Box style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0 18px" }}>
                    <Box
                        style={webStyle.editMainWrapper}
                        data-test-id = "editListingButtonTestID"
                        onClick={this.handleEditListingOpen}
                    >
                        <Typography style={webStyle.edit}>{configLang.Edit}</Typography>
                        <EditIcon style={webStyle.iconEdit} />
                    </Box>
                </Box>
                <PreviewListing 
                    previewValues={{
                        ...userData,
                        supportDocArray: [dummyFile, dummyFile],
                        otherDocArray: [dummyFile, dummyFile]
                    }} 
                />
                <Box style={{display: "flex", gap: "20px", marginTop: "50px"}}>
                    <AcceptButton 
                        data-test-id="prevOkButtonTestID"
                        style={{width: "240px"}}
                        onClick={() => this.handleBreadcrumbClick("breadScrum0")}
                    >
                        {languageData.ConverttoMarketplace}
                    </AcceptButton>
                    <CancelButton 
                        style={{width: "240px"}}
                        onClick={this.handleViewDetailOpen}
                    >
                        {configLang.Cancel}
                    </CancelButton>
                </Box>
            </Box>
        );
    };

    renderEditListing = () => {
        const { 
            language,
            portfolioSize,
            listingTitle,
            averageBalance,
            accountCount,
            basicInfoErrors,
            totalNumber,
            totalAmount,
            totalJudgement,
            totalMonth,
            pastPay,
            monthAvg,
            lastCollDay,
            lastCollMonth,
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear,
            detailInfoErrors,
            typeOfDebt,
            ageOfDebt,
            recoveryStatus,
            debtCharErr,
            supportDocArray,
            otherDocArray
         } = this.state;
        const editLangData = editLanguageData[language];
        const configLang = configJSON.commonLanguageData[language];
        const languageData = languageTranslationData[language];
        const {Yes, DebtCharacFields } = editLangData;
        const fieldData = editLangData.basicInfofields;
        const detailInfoData = editLangData.detailInfoFields;
        const detailLabel = editLangData.listingSummary;
        return (
            <Box style={{marginTop: "35px"}}>
                <Box style={{...webStyle.detailChildBox, marginBottom: "40px"}}>
                    <Typography style={webStyle.boxHeadingText}>{editLangData.BasicInformation}</Typography>
                    <Grid container spacing={4}>
                        {this.renderCustomGrid(fieldData.PortfolioSize, portfolioSize, fieldData.PortfolioSizePlace, basicInfoErrors.portfolioSizeErr, "portfolioSize")}
                        {this.renderCustomGrid(fieldData.ListingTitle, listingTitle, fieldData.ListingTitlePlace, basicInfoErrors.listingTitleErr, "listingTitle")}
                        {this.renderCustomGrid(fieldData.AverageBalance, averageBalance, fieldData.AverageBalancePlace, basicInfoErrors.averageBalanceErr, "averageBalance")}
                        {this.renderCustomGrid(fieldData.AccountCount, accountCount, fieldData.AccountCountPlace, basicInfoErrors.accountCountErr, "accountCount")}
                    </Grid>
                </Box>
                <Box style={{...webStyle.detailChildBox, marginBottom: "40px"}}>
                    <Typography style={webStyle.boxHeadingText}>{editLangData.DetailedInformation}</Typography>
                    <Grid container spacing={4}>
                        {this.renderCustomGrid(detailLabel.TotalNumber, totalNumber, detailInfoData.totalNumDebtPlace, detailInfoErrors.totalNumErr, "totalNumber")}
                            {this.renderCustomGrid(detailLabel.TotalAmount, totalAmount, detailInfoData.totalAmoDebtPlace, detailInfoErrors.totalAmtErr, "totalAmount")}
                            {this.renderCustomGrid(detailLabel.TotalJudgements, totalJudgement, detailInfoData.totalJudgPlace, detailInfoErrors.totalJudgmentsErr, "totalJudgement")}
                            {this.renderCustomGrid(detailLabel.TotalMonthAsCustomer, totalMonth, detailInfoData.totalMonCust, detailInfoErrors.totalMonthErr, "totalMonth")}
                            {this.renderSelect(detailLabel.PastPaymentMade, pastPay, Yes, detailInfoErrors.pastPayErr, "pastPay")}
                            {this.renderSelect(detailLabel.MonthAveragePayTime, monthAvg, "1", detailInfoErrors.monthAvgErr, "monthAvg")}
                            <CustomGrid item xs={12} sm={12} md={6}>
                                <Typography>{detailLabel.LastCollectorContact}</Typography>
                                <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap:"20px"
                                }}
                                >
                                    <TextField
                                        value={lastCollMonth}
                                        name={"lastCollMonth"}
                                        data-test-id="lastCollMonthTestID"
                                        placeholder="MM"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleMonthChange}
                                        onBlur={()=>this.handleBlur("lastCollMonth", this.state.lastCollMonth)}
                                    />
                                    <TextField
                                        value={lastCollDay}
                                        name={"lastCollDay"}
                                        data-test-id="lastCollDayTestID"
                                        placeholder="DD"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleDayChange}
                                        onBlur={()=>this.handleBlur("lastCollDay", this.state.lastCollDay)}
                                    
                                    />
                                    <TextField
                                        value={lastCollYear}
                                        name={"lastCollYear"}
                                        data-test-id="lastCollYearTestID"
                                        placeholder="YYYY"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleYearChange}
                                        onBlur={()=>this.handleBlur("lastCollYear", lastCollYear)}
                                    />
                                </Box>
                                {(detailInfoErrors.lastCollectErr && this.state.checkSave) && (
                                    <Typography style={webStyle.errorText}>
                                        {detailInfoErrors.lastCollectErr}
                                    </Typography>
                                )}
                            </CustomGrid>
                            <CustomGrid item xs={12} sm={12} md={6}>
                                <Typography>{detailLabel.LastCreditorContact}</Typography>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap:"20px"
                                    }}
                                >
                                    <TextField
                                        value={lastCredMonth}
                                        name={"lastCredMonth"}
                                        data-test-id="lastCredMonthTestID"
                                        placeholder="MM"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleMonthChange}
                                        onBlur={()=>this.handleBlur("lastCredMonth", lastCredMonth)}
                                    />
                                    <TextField
                                        value={lastCredDay}
                                        name={"lastCredDay"}
                                        data-test-id="lastCredDayTestID"
                                        placeholder="DD"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleDayChange}
                                        onBlur={()=>this.handleBlur("lastCredDay", lastCredDay)}
                                    />
                                    <TextField
                                        value={lastCredYear}
                                        name={"lastCredYear"}
                                        data-test-id="lastCredYearTestID"
                                        placeholder="YYYY"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleYearChange}
                                        onBlur={()=>this.handleBlur("lastCredYear", lastCredYear)}
                                    />
                                </Box>
                                {(detailInfoErrors.lastCredErr && this.state.checkSave) && (
                                    <Typography style={webStyle.errorText}>
                                        {detailInfoErrors.lastCredErr}
                                    </Typography>
                                )}
                            </CustomGrid>
                        </Grid>
                </Box>
                <Box style={{...webStyle.detailChildBox, marginBottom: "40px"}}>
                    <Typography style={webStyle.boxHeadingText}>{DebtCharacFields.heading}</Typography>
                    <Grid container spacing={4}>
                        {this.renderSelect(DebtCharacFields.type, typeOfDebt, DebtCharacFields.typePlace, debtCharErr.typeOfDebtErr, "typeOfDebt")}
                        {this.renderCustomGrid(DebtCharacFields.age, ageOfDebt, DebtCharacFields.agePlace, debtCharErr.ageOfDebtErr, "ageOfDebt")}
                        {this.renderSelect(DebtCharacFields.current, recoveryStatus, DebtCharacFields.currentPlace, debtCharErr.recoveryStatusErr, "recoveryStatus")}
                    </Grid>
                </Box>
                <Box style={{...webStyle.detailChildBox, marginBottom: "40px"}}>
                    <Typography style={webStyle.boxHeadingText}>{editLangData.SupportingDocumentation}</Typography>
                    {this.renderFileUploadBox(supportDocArray, this.state.documentErr ,"supportDocArray")}
                </Box>
                <Box style={webStyle.detailChildBox}>
                    <Typography style={webStyle.boxHeadingText}>{editLangData.Otherdocuments}</Typography>
                    {this.renderFileUploadBox(otherDocArray, this.state.otherDocError, "otherDocArray")}
                </Box>
                <Box style={{display: "flex", gap: "20px", marginTop: "50px"}}>
                    <AcceptButton 
                        style={{width: "240px"}}
                        data-test-id="editSaveButtonTestID"
                        onClick={this.handleEditSave}
                    >
                        {languageData.Saveedits}
                    </AcceptButton>
                    <CancelButton 
                        style={{width: "240px"}}
                        onClick={this.handlePreviewOpen}
                    >
                        {configLang.Cancel}
                    </CancelButton>
                </Box>
            </Box>
        );
    };

    renderCustomGrid = (label:string, value: string, placeholder:string, error:string, stateName: string) => {
        return (
            <CustomGrid item xs={12} sm={12} md={6}>
                <Typography>{label}</Typography>
                    <TextField
                        data-test-id={`${stateName}TestID`}
                        value = {value}
                        name = {stateName}
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder={placeholder}
                        onChange={this.handleEditChange}
                    />
                    {(error && this.state.checkSave) && (
                        <Typography style={webStyle.errorText}>
                            {error}
                        </Typography>
                    )}
            </CustomGrid>
        );
    };

    renderSelect = (label: string, value: string, placeholder: string, error: string, stateName: string) => {
        const getMenuItem = this.handleMenuItem(stateName);
        return (
            <CustomGrid item xs={12} sm={12} md={6}>
                <Typography>{label}</Typography>
                <FormControl fullWidth>
                    <FiltInputLabel style={{ lineHeight: "0.5", display: value? "none": "block"}}>
                        {placeholder}
                    </FiltInputLabel>
                    <FiltSelect
                        fullWidth
                        data-test-id={`${stateName}TestID`}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        variant="outlined"
                        value={value}
                        name = {stateName}
                        onChange={(event)=>this.handleSelect(event, stateName)}
                    >
                        { getMenuItem.map((item:SelectOptionItem, index: number)=>
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        )}
                    </FiltSelect>
                </FormControl>
                {(error && this.state.checkSave) && (
                    <Typography style={webStyle.errorText}>
                        {error}
                    </Typography>
                )}
            </CustomGrid>
        );
    };

    renderFileUploadBox = (array:(File|null)[], error: string, docType: string) => {
        const {inputFields} = editLanguageData[this.state.language];
        return (
            <Grid container spacing={4}>
                {array.map((item, index) =>
                    (item===null)
                        ? this.renderEmptyPortfolio(index, error, docType)
                        : this.renderPortfolioFile(item, index, docType)
                )}
                <CustomGrid
                    style={{ display: "flex", justifyContent: "center" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <FileButtonBox 
                        style={{width:"224px"}} 
                        component={"label"}
                    >
                        <Box style={{display: "flex", gap: "10px"}}>
                            <img 
                                src={fileUploadIcon}
                                style={{width:"24px"}}
                                alt="file_upload_icon"
                            /> 
                            {inputFields.Addmoredoc}
                        </Box>
                        <VisuallyHiddenInput 
                            type="file" 
                            data-test-id={`addMore${docType}`} 
                            onChange={(event)=>this.handleAddPortfolio(event, null, docType)}
                        />
                    </FileButtonBox>
                </CustomGrid>
            </Grid>
        );
    };

    renderEmptyPortfolio = (index:number, error: string, arrayName:string) =>{
        const {inputFields} = editLanguageData[this.state.language];
        const { checkSave } = this.state;
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Box style={webStyle.porfolioBox}>
                    <EmptyUploadIcon 
                        src={fileUploadIcon} 
                        style={{ fontSize: "22px", color: "#94A3B8" }}
                        alt= "file_upload_icon"
                    />
                    <Typography style={webStyle.upPortText}>
                        {inputFields.Uploaddocument}
                    </Typography>
                    <PortfolioBodyBox>
                        <Typography style={webStyle.portFolioBodyText}>{inputFields.docSuppFor}</Typography>
                        <Typography style={webStyle.portFolioBodyText}>{configJSON.formatSupport}</Typography>
                    </PortfolioBodyBox>
                    <FileButtonBox 
                        style={{width:"137px"}}
                        component={"label"}
                    >
                        {inputFields.ChooseaFile}
                        <VisuallyHiddenInput 
                            data-test-id={`${arrayName+index}TestID`}
                            type="file"
                            onChange={(event)=>this.handleAddPortfolio(event, index, arrayName)} 
                        />
                    </FileButtonBox>
                </Box>
                {(error && checkSave) && (
                    <Typography style={webStyle.errorText}>
                        {error}
                    </Typography>
                )}
            </CustomGrid>
        );
    };

    renderPortfolioFile = (file:File | null, index:number, docType: string) => {
        const languageData = editLanguageData[this.state.language];
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Typography>
                    {languageData.Portfolio}
                </Typography>
                <Box style={webStyle.fileInputWrapper}>
                    <Box style={webStyle.noteIconBox}>
                        <NotesIcon style={{color: "#2563EB"}}/>
                    </Box>
                    {file !== null && <Box style={{flexGrow:1, width:"50%"}}>
                        <Typography style={webStyle.fileNameStyle}>{file.name}</Typography>
                        <Typography style={webStyle.fileSizeStyle}>{((file.size)/1024).toFixed(2)} KB</Typography>
                    </Box>}
                    <IconButton 
                        data-test-id={`fileMoreTestID${docType+index}`}
                        aria-label="more1"
                        aria-haspopup="true"
                        id = {`${docType+index}`}
                        onClick = {this.handleMoreMenuOpen}
                    >
                        <MoreVertIcon style={webStyle.fileMoreIcon} />
                    </IconButton>
                </Box> 
            </CustomGrid>
        );
    };

    renderTimeline = () => {
        const { language, payTerm, payMethod, installmentRecieved }= this.state;
        const languageData = languageTranslationData[language].timelineTable;
        return (
            <Box style={{...webStyle.detailChildBox, marginTop: "30px", padding: "30px 30px 0", height: "fit-content"}}>
                <Typography style={webStyle.timelineHeading}>{languageData.heading}</Typography>
                <Box style={{paddingLeft: "12px"}}>
                    {[
                        {date: "26 Feb 2022", text: languageData.labelOne},
                        {date: "26 Feb 2022", text: languageData.labelTwo},
                        {date: "26 Feb 2022", text: languageData.labelThree},
                        {date: "26 Feb 2022", text: languageData.labelFour},
                        {date: "26 Feb 2022", text: languageData.labelFive},
                        {date: "", text: languageData.labelSix},
                        {date: "26 Feb 2022", text: languageData.labelSeven}
                    ].map((step, index) => (
                        <React.Fragment key={index}>
                            {(index !== 5 && index !== 6) && this.renderTimelineBox(step, index)}
                            {(index === 5 && ((payTerm && payMethod) || installmentRecieved)) && 
                                this.renderTimelineBox(step, index)
                            }
                            {((index === 6) && installmentRecieved) && this.renderTimelineBox(step, index)}
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        );
    };

    renderTimelineBox = (step: {date: string, text: string}, index: number) => {
        const { language } = this.state;
        return (
            <Box style={language === "en"? {
                ...webStyle.stepperBox, 
                borderLeft: this.handleBorderShow(index)
            }:
            {
                ...webStyle.stepperBox, 
                borderRight: this.handleBorderShow(index)
            }
        }
        >
            <span 
                style={language === "en"?
                    {...webStyle.stepperSpan, left: "-13px"}:
                    {...webStyle.stepperSpan, right: "-13px"} 
                }
            >
                {this.engArabicNumeral((index+1).toString())}
            </span>
            { index !== 5 ?
                <>
                    <Typography
                        style={{ color: "#475569", fontSize: "12px", marginBottom: "7px" }}
                    >
                        {step.date}
                    </Typography>
                    <Typography
                        style={{ color: "#1E293B", fontSize: "14px", fontWeight: 500 }}
                    >
                        {step.text}
                    </Typography>
                </>: 
                <AcceptButton
                    style={{width: "218px", height: "44px", fontSize: "14px"}}
                    data-test-id = "sendInstButtonTestID"
                    onClick={this.handleInstructinClick}
                >
                    {step.text}
                </AcceptButton>
            }
            {
                (index === 1 || index === 2 || index === 3)  &&
                this.renderFileBox()
            }
            {
                index === 4  &&
                this.customizePayment()
            }
        </Box>
        )
    };

    renderPaymentDetailTable = () => {
        const { language } = this.state;
        const { payTable } = languageTranslationData[language];
        const cellStyle: React.CSSProperties = {
            textAlign: language === "ar" ? 'right' : 'left'
        };
        return (
            <Box data-test-id="businessDealShowDataTestID">
            <Typography style={{fontSize: "24px", fontWeight: 700, color: "#0F172A"}}>
                {payTable.PaymentDetails}
            </Typography>
            <PayTableBox style={{...webStyle.tableContainer, marginTop: "25px"}}>
                <TableContainer 
                    style={{
                        ...webStyle.tableContainerStyle, 
                        border: "1px solid #E2E8F0", 
                        marginBottom: "25px"}} 
                        component={Paper}
                    >
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <HeadingRowStyle>
                                <TableCell style={cellStyle}>{payTable.TotalAmounttobePaid}</TableCell>
                                <TableCell style={cellStyle} colSpan={2}>{payTable.PaymentTerms}</TableCell>
                            </HeadingRowStyle>
                        </TableHead>
                            <TableBody>
                                <BodyRowStyle>
                                    <TableCell style={cellStyle}>$10,000,000</TableCell>
                                    <TableCell style={cellStyle}>30% Down payment, then monthly installments over 6 months</TableCell>
                                    <TableCell style={cellStyle}>
                                        <IconButton>
                                            <MoreVertIcon style={{color: "#64748B", cursor: "pointer"}}/>
                                        </IconButton>
                                    </TableCell>
                                </BodyRowStyle>
                            </TableBody>  
                    </Table>
                </TableContainer>
                <TableContainer style={{...webStyle.tableContainerStyle, border: "1px solid #E2E8F0"}} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <HeadingRowStyle>
                                <TableCell style={cellStyle}>{payTable.PaymentID}</TableCell>
                                <TableCell style={cellStyle}>{payTable.Installment}</TableCell>
                                <TableCell style={cellStyle}>{payTable.Amount}</TableCell>
                                <TableCell style={cellStyle}>{payTable.DueDate}</TableCell>
                                <TableCell style={cellStyle}>{payTable.Status}</TableCell>
                                <TableCell style={cellStyle}>{payTable.Action}</TableCell>
                            </HeadingRowStyle>
                        </TableHead>
                            <TableBody>
                                {paymentDetail.map((item, index) => (
                                    <BodyRowStyle key={index}>
                                        <TableCell style={cellStyle}>{item[0]}</TableCell>
                                        <TableCell style={cellStyle}>{item[1]}</TableCell>
                                        <TableCell style={cellStyle}>${item[2]}</TableCell>
                                        <TableCell style={{whiteSpace: "nowrap", ...cellStyle}}>{item[3]}</TableCell>
                                        <TableCell style={cellStyle}>
                                            <span
                                                style={{...webStyle.statusStyleSpan, 
                                                ...this.handleStatusStyle(item[4])}}
                                            >
                                                {item[4]}
                                            </span>
                                        </TableCell>
                                        <TableCell style={{width: "180px", ...cellStyle}}>
                                            {item[4]==="Paid"?                
                                                <CancelButton style={{width: "180px", height: "44px"}}>
                                                    {payTable.DownloadInvoice}
                                                </CancelButton>:
                                                <CancelButton style={{width: "180px", height: "44px", color: "#64748B"}}>
                                                    {payTable.DownloadInvoice}
                                                </CancelButton>
                                            }
                                        </TableCell>
                                    </BodyRowStyle>
                                ))}
                            </TableBody>  
                    </Table>
                </TableContainer>
            </PayTableBox>
        </Box>
        );
    };

    renderFileBox = () => {
        return (
            <Box style={webStyle.fileWrapper}>
                <Box style={webStyle.noteIconBoxStyle}>
                    <NotesIcon style={{color: "#2563EB"}}/>
                </Box>
                <Box style={{flexGrow:1, width:"50%"}}>
                    <Typography style={webStyle.fileName}>File name.doc</Typography>
                    <Typography style={webStyle.fileSize}>{(450000/1024).toFixed(2)} KB</Typography>
                </Box>
                <IconButton 
                    aria-label="more1"
                    aria-haspopup="true"
                >
                    <MoreVertIcon style={{color: "#475569", cursor: "pointer"}} />
                </IconButton>
            </Box>
        );
    };

    customizePayment = () => {
        const {language} = this.state;
        const {
            PaymentTerms,
            PaymentTermsOp,
            PaymentMethod,
            PaymentMethodOp
        } = languageTranslationData[language];
        return (
            <Box>
                <Box style = {{margin: "18px 0 25px"}}>
                    <Typography style={{ ...webStyle.filtLabel, marginBottom: "4px" }}>{PaymentTerms}</Typography>
                    <FormControl fullWidth style={{maxWidth: "240px"}}>
                        <FiltInputLabel style={{ display: this.state.payTerm ? "none" : "block", lineHeight: "0.5" }}>
                            {PaymentTermsOp.option1}
                        </FiltInputLabel>
                        <FiltSelect
                            data-test-id="selectPayTermTestID"
                            fullWidth
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            variant="outlined"
                            value={this.state.payTerm}
                            name="payTerm"
                            onChange={(event)=>this.handleSelect(event, "payTerm")}
                        >   
                            {
                                [
                                    PaymentTermsOp.option1,
                                    PaymentTermsOp.option2,
                                    PaymentTermsOp.option3,
                                    PaymentTermsOp.option4,
                                    PaymentTermsOp.option5,
                                    PaymentTermsOp.option6,
                                    PaymentTermsOp.option7
                                ].map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                ))
                            }
                        </FiltSelect>
                    </FormControl>
                </Box>
                <Box>
                    <Typography style={{ ...webStyle.filtLabel, marginBottom: "4px" }}>{PaymentMethod}</Typography>
                    <FormControl fullWidth style={{maxWidth: "240px"}}>
                        <FiltInputLabel style={{ display: this.state.payMethod ? "none" : "block", lineHeight: "0.5" }}>
                            {PaymentMethodOp.option1}
                        </FiltInputLabel>
                        <FiltSelect
                            data-test-id="selectPayMethodTestID"
                            fullWidth
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            variant="outlined"
                            value={this.state.payMethod}
                            name="payMethod"
                            onChange={(event)=>this.handleSelect(event, "payMethod")}
                        >
                            {
                                [
                                    PaymentMethodOp.option1,
                                    PaymentMethodOp.option2,
                                    PaymentMethodOp.option3
                                ].map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                ))
                            }
                        </FiltSelect>
                    </FormControl>
                </Box>
            </Box>
        );
    };
    
    renderOwnerInfoBox = ( parameter: ProfileInfo ) => {
        const { heading, image, name, email, phone, country, state, city, zipcode } = parameter;
        return (
            <Box>
                <Typography style={webStyle.headingBoxText}>{heading}</Typography>
                <Box style={webStyle.avatarTextWrapper}>
                    <Avatar
                        src={image}
                        alt="logo"
                        style={{width: "80px", height: "80px"}}
                    />
                    <Box style={webStyle.profileTextWrapper}>
                        <Typography style={webStyle.profileTextStyle}>
                            {name}
                        </Typography>
                        <Typography style={webStyle.sizeTextStyle}>
                            {email}
                        </Typography>
                    </Box>
                </Box>
                {this.renderAddUpp(phone, country, state, city, zipcode)}
            </Box>
        );
    };

    renderAddUpp = (
        phone: string,
        country: string,
        state: string,
        city: string,
        zipcode: string
    ) => {
        const {profileAdd} = languageTranslationData[this.state.language];
        return (
            <PrevInBox style={webStyle.prevInWrapper}>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{`(${phone.substring(0,3)}) ${phone.substring(3,6)}-${phone.substring(6)}`}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{profileAdd.PhoneNumber}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{country}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{profileAdd.Country}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{state}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{profileAdd.State}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{city}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{profileAdd.City}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{zipcode}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{profileAdd.Zip}</Typography>
                </Box>
            </PrevInBox>
        )
    };

    renderFilter = () => {
        const { language } = this.state;
        const dir = language === "en" ? "ltr" : "rtl";
        const configLang = configJSON.commonLanguageData[language];
        return (
            <Menu
                id={"simple-popper"}
                data-test-id="filterMenuTestID"
                open={Boolean(this.state.filterAnchor)}
                anchorEl={this.state.filterAnchor}
                elevation={0}
                getContentAnchorEl={null}
                onClose={this.handleMenuClose}
                dir={dir}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                        minWidth: "160px",
                    }
                }}
            >
                <Box style={webStyle.filtermainWrapper}>
                    <Box style={webStyle.filtCloseBox}>
                        <Box style={webStyle.filtHeadingBox}>
                            <FilterListRoundedIcon style={{ width: "20px" }} />
                            {configLang.Filters}
                        </Box>
                        <IconButton
                            data-test-id="filterCloseButtonTestID"
                            onClick={this.handleMenuClose}
                        >
                            <CloseRoundedIcon style={{ width: "20px", color: "#0F172A" }} />
                        </IconButton>
                    </Box>
                    <Box style={webStyle.filtMiddleBox}>
                        <Box>
                            <FiltResetWrapper>
                                <Button
                                    data-test-id="resetDateTestID"
                                    onClick={this.resetDate}
                                >
                                    {configLang.Reset}
                                </Button>
                            </FiltResetWrapper>
                            <Box style={{ display: "flex", gap: "20px" }}>
                                <Box>
                                    <Typography dir={dir} style={webStyle.filtLabel}>{configLang.From}:</Typography>
                                    <FiltDateField
                                        data-test-id="dateInputTestID"
                                        type="date"
                                        variant="outlined"
                                        value={this.state.startDate}
                                        name="startDate"
                                        onChange={this.handleDateChange}
                                    />
                                </Box>
                                <Box>
                                    <Typography dir={dir} style={webStyle.filtLabel}>{configLang.To}:</Typography>
                                    <FiltDateField
                                        type="date"
                                        variant="outlined"
                                        name="endDate"
                                        value={this.state.endDate}
                                        onChange={this.handleDateChange}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <FiltResetWrapper>
                                <Button
                                    data-test-id="resetSelectTestID"
                                    onClick={() => this.resetSelect()}
                                >
                                    {configLang.Reset}
                                </Button>
                            </FiltResetWrapper>
                            <Typography dir={dir} style={{ ...webStyle.filtLabel, marginBottom: "4px" }}>{configLang.Status}</Typography>
                            <FormControl fullWidth>
                                <FiltInputLabel style={{ display: this.state.status ? "none" : "block", lineHeight: "0.5" }}>
                                    {configLang.Accepted}
                                </FiltInputLabel>
                                <FiltSelect
                                    data-test-id="selectInputTestID"
                                    fullWidth
                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                    variant="outlined"
                                    value={this.state.status}
                                    name="status"
                                    onChange={(event)=>this.handleSelect(event, "status")}
                                >
                                    <MenuItem value={"Accepted"}>{configLang.Accepted}</MenuItem>
                                    <MenuItem value={"Rejected"}>{configLang.Rejected}</MenuItem>
                                </FiltSelect>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box dir={dir} style={webStyle.filtBottomBox}>
                        <CancelButton
                            style={{ height: "44px" }}
                            data-test-id="resetAllTestID"
                            onClick={this.resetAll}
                        >
                            {configLang.Reset}
                        </CancelButton>
                        <AcceptButton
                            style={{ fontSize: "14px", height: "44px" }}
                            onClick={this.handleMenuClose}
                        >
                            {configLang.Applynow}
                        </AcceptButton>
                    </Box>
                </Box>
            </Menu>
        );
    };

    renderMoreMenu = () => {
        const { language, show } = this.state;
        const cellStyle: React.CSSProperties = {
            textAlign: language === "ar" ? 'right' : 'left'
        };
        const dir = language === "en" ? "ltr" : "rtl";
        const configLang = configJSON.commonLanguageData[language];
        const editLangData = editLanguageData[language];
        return (
            <Menu
                id={"simple-popper"}
                data-test-id="moreMenuTestID"
                open={Boolean(this.state.moreAnchor)}
                anchorEl={this.state.moreAnchor}
                elevation={0}
                getContentAnchorEl={null}
                onClose={this.handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        boxShadow: '0px 0px 8px 4px #00000008',
                        minWidth: "160px",
                        borderRadius: "8px",
                        border: "1px solid #E2E8F0"
                    }
                }}
            >
                {show === "collTable" && 
                    <MenuBox>
                        <Typography
                            data-test-id="viewDetailButtonTestID"
                            onClick={this.handleViewDetailOpen}
                            style={cellStyle}
                        >
                            {configLang.ViewDetails}
                        </Typography>
                        <MenuDeleteBox dir={dir}>
                            <DeleteOutlineRoundedIcon style={{ width: "18px", color: "#DC2626" }} />
                            <Typography style={{ color: "#DC2626", paddingLeft: "8px", paddingRight: "8px" }}>
                                {configLang.Delete}
                            </Typography>
                        </MenuDeleteBox>
                    </MenuBox>
                }
                 {show === "editListing" && 
                    <MenuBox>
                        <MenuDeleteBox dir={dir} component="label">
                            <img
                                src={fileUploadIcon}
                                alt="upload"
                                style={{ width: "18px" }}
                            />
                            <Typography style={{ color: "#0F172A", paddingLeft: "8px", paddingRight: "8px" }}>
                                {editLangData.UploadDocument}
                            </Typography>
                            <VisuallyHiddenInput
                                type="file"
                                data-test-id="menuuploadbtn"
                                onChange={this.handleFileChange}
                            />
                        </MenuDeleteBox>
                        <MenuDeleteBox
                            dir={dir}
                            onClick={this.handleFileRemove}
                            data-test-id="removeBtnTestID"
                        >
                            <DeleteOutlineRoundedIcon style={{ width: "18px", color: "#DC2626" }} />
                            <Typography style={{ color: "#DC2626", paddingLeft: "8px", paddingRight: "8px" }}>
                                {editLangData.RemoveDocument}
                            </Typography>
                        </MenuDeleteBox>
                    </MenuBox>
                }           
            </Menu>
        );
    };

    renderModal = () => {
        const languageData = languageTranslationData[this.state.language];
        const configLang = configJSON.commonLanguageData[this.state.language];
        const dir = this.state.language === "en" ? "ltr" : "rtl";
        return (
            <Modal 
                open={this.state.modalOpen}
                data-test-id = "modalTestID"
                onClose={this.handleModalClose}
            >
                <Box style={webStyle.modalMainBox}>
                    <Box style={webStyle.modalBodyBox}>
                        <Box style={{
                                padding: "32px", 
                                borderBottom: "1px solid #E2E8F0",
                                maxWidth: "508px"
                            }}
                        >
                            <Box dir={dir} style={webStyle.modCloseWrapper}>
                                <Typography style={{color: "#0F172A", fontSize: "20px", fontWeight: 700}}>
                                    {languageData.modalHeadingText}
                                </Typography>
                                <IconButton
                                    data-test-id="modalCloseIconButton"
                                    onClick={this.handleModalClose}
                                >
                                <CloseRoundedIcon
                                    style={{ width: "24px", color: "#0F172A" }}
                                />
                                </IconButton>
                            </Box>
                            <Typography style={{maxWidth: "510px", color: "#475569", fontSize: "14px"}}>
                                {languageData.modalBodyText}
                            </Typography>
                        </Box>
                        <Box dir={dir} style={{display: "flex", gap: "20px", padding: "32px"}}>
                            <CancelButton 
                                style={{width: "240px"}}
                                onClick={this.handleModalClose}
                            >
                                {configLang.Cancel}
                            </CancelButton>
                            <AcceptButton 
                                data-test-id="modalAcceptButtonTestID"
                                style={{width: "240px"}}
                                onClick={this.handlePreviewOpen}
                            >
                                {configLang.Confirm}
                            </AcceptButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    renderPrintInvoice = () => {
        const { language } = this.state;
        const payInvLangData = languageTranslationData[language].payInvLangData;
        const cellStyle: React.CSSProperties = {
            textAlign: language === "ar" ? 'right' : 'left'
        };
        const dir = language === "en" ? "ltr" : "rtl";
        return (
            <Paper dir={dir} style={{ padding: "45px", boxShadow: "none" }}>
                <img
                    src={componyNameLogo}
                    style={{ width: "172px", marginBottom: "45px" }}
                    alt="compony_name_logo"
                />
                <Box style={webStyle.colleInv}>
                    <Typography
                        style={{ fontSize: "20px", color: "#0F172A", fontWeight: 600 }}
                    >
                        {payInvLangData.CollectionsInvoice}
                    </Typography>
                    <Typography
                        style={{ fontSize: "20px", color: "#0F172A", fontWeight: 500 }}
                    >
                        INV-1001
                    </Typography>
                </Box>
                <PrintDivider />
                <Box style={webStyle.colleInv}>
                    <Typography>
                        <span style={{ color: "#64748B", fontSize: "14px" }}>
                            {payInvLangData.Listingname}
                        </span>
                        <strong> Medical Debt Package</strong>
                    </Typography>
                    <Typography>
                        <span style={{ color: "#64748B", fontSize: "14px" }}>
                            {payInvLangData.InvoiceDate}
                        </span>
                        <strong> July 1, 2024</strong>
                    </Typography>
                    <Typography>
                        <span style={{ color: "#64748B", fontSize: "14px" }}>
                            {payInvLangData.DueDate}
                        </span>
                        <strong> July 31, 2024</strong>
                    </Typography>
                </Box>
                <PrintDivider style={{ marginBottom: "35px" }} />
                <Box style={{ ...webStyle.colleInv, marginBottom: "45px" }}>
                    <Box style={webStyle.fromToBox}>
                        <Typography style={{ color: "#1D4ED8" }}>{payInvLangData.From}</Typography>
                        <Typography
                            style={{ fontSize: "18px", color: "#0F172A", fontWeight: 700 }}
                        >
                            John Doe
                        </Typography>
                        <Typography>1234 Market Street</Typography>
                        <Typography>City, State, ZIP</Typography>
                        <Typography>(123) 456-7890</Typography>
                        <Typography>info@debtcollections.com</Typography>
                    </Box>
                    <Box style={webStyle.fromToBox}>
                        <Typography style={{ color: "#1D4ED8" }}>{payInvLangData.To}</Typography>
                        <Typography
                            style={{ fontSize: "18px", color: "#0F172A", fontWeight: 700 }}
                        >
                            John Doe
                        </Typography>
                        <Typography>1234 Market Street</Typography>
                        <Typography>City, State, ZIP</Typography>
                        <Typography>(321) 654-0987</Typography>
                        <Typography>jane.smith@example.com</Typography>
                    </Box>
                </Box>
                <Table style={{ marginTop: 20 }}>
                    <TableHead>
                        <PrintTableHeadStyle>
                            <TableCell style={{ borderRadius: "12px 0 0 12px", ...cellStyle }}>
                                {payInvLangData.Description}
                            </TableCell>
                            <TableCell style={cellStyle}>{payInvLangData.Tax}</TableCell>
                            <TableCell style={{ borderRadius: "0 12px 12px 0", ...cellStyle }}>
                                {payInvLangData.Amount}
                            </TableCell>
                        </PrintTableHeadStyle>
                    </TableHead>
                    <TableBody>
                        <PrintTableBodyStyle>
                            <TableCell style={cellStyle}>First Installment Payment</TableCell>
                            <TableCell style={cellStyle}>0%</TableCell>
                            <TableCell style={cellStyle}>$5,000.00</TableCell>
                        </PrintTableBodyStyle>
                    </TableBody>
                </Table>
                <Divider style={{ marginBottom: "25px" }} />
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Box style={{ width: "fit-content" }}>
                        <Typography
                            style={{ ...webStyle.subTotal, marginBottom: "10px" }}
                        >
                            {payInvLangData.SubtotalAmountDue}
                            <span style={webStyle.subTotalValue}> $5,000.00</span>
                        </Typography>
                        <Typography style={webStyle.subTotal}>
                            {payInvLangData.TotalAmountDue}
                            <span style={webStyle.subTotalValue}> $5,000.00</span>
                        </Typography>
                    </Box>
                </Box>
                <PrintDivider style={{ margin: "55px 0 35px" }} />
                <Box
                    style={{
                        ...webStyle.colleInv,
                        gap: "50px",
                        color: "#64748B",
                        fontSize: "14px",
                        fontWeight: 400
                    }}
                >
                    <Box>
                        <Typography style={webStyle.paymentInstHeading}>
                            {payInvLangData.PaymentInstructions}
                        </Typography>
                        <Typography style={{ color: "#0F172A" }}>
                            {payInvLangData.PayInst}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography>
                            {payInvLangData.DueDate}
                            <span style={webStyle.pamInstValue}> July 31, 2024</span>
                        </Typography>
                        <Typography style={{ whiteSpace: "nowrap" }}>
                            {payInvLangData.PaymentMethods}
                            <span style={webStyle.pamInstValue}>
                            {" "}
                            Bank Transfer, Credit Card
                            </span>
                        </Typography>
                        <Typography>
                            {payInvLangData.BankName}<span style={webStyle.pamInstValue}> ABC Bank</span>
                        </Typography>
                        <Typography>
                            {payInvLangData.AccountNumber}
                            <span style={webStyle.pamInstValue}> 98765432</span>
                        </Typography>
                    </Box>
                </Box>
                <img
                    src={dummyQrCode}
                    style={{ width: "84px", marginTop: "45px" }}
                    alt="qr_code"
                />
            </Paper>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { show } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <UserProfileDrawer
                handleClick={this.handleSideDrawerClick}
                pageName={"dashboardCollections"}
                breadScrumArray={this.state.breadcrumbArray}
                data-test-id="drawerTestID"
                userType={"admin_account"}
                setMargin = {this.state.setMargin}
                navigation={this.props.navigation}
            >
                <Box>
                    <Box style={{ marginBottom: "16px" }}>
                        <Typography style={webStyle.headingText}>
                            {this.state.mainHeading}
                        </Typography>
                    </Box>
                    {show === "collTable" && this.renderTable()}
                    {show === "collDetail" && this.renderCollectionDetail()}
                    {show === "listingMarketplace" && this.renderPrevMarketListing()}
                    {show === "editListing" && this.renderEditListing()}
                </Box>
                {this.renderFilter()}
                {this.renderMoreMenu()}
                {this.renderModal()}
                <div style={{display: "none"}}>
                    {this.renderPrintInvoice()}
                </div>
            </UserProfileDrawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'relative',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

const PrintTableHeadStyle = styled(TableRow)({
    backgroundColor: "#F8FAFC",
    "& .MuiTableCell-root": {
      borderBottom: "none",
      fontSize: "14px",
      fontWeight: 600,
      color: "#64748B"
    }
});
  
const PrintDivider = styled(Divider)({
    margin: "22px 0"
});
  
const PrintTableBodyStyle = styled(TableRow)({
    "& .MuiTableCell-root": {
      borderBottom: "none",
      fontSize: "14px",
      fontWeight: 500,
      color: "#0F172A"
    }
});

const PortfolioBodyBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
});

const FileButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EFF6FF",
    justifyContent: "center",
    cursor: "pointer",
    height: "44px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700
});

const EmptyUploadIcon = styled("img")({
    filter: "invert(62%) sepia(23%) saturate(452%) hue-rotate(165deg) brightness(97%) contrast(50%)",
    width: "25px",
    height: "25px"
});

const CustomGrid = styled(Grid)({
    "& .MuiTypography-root": {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "8px"
    },
    "& .MuiTextField-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "30px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
      }
    }
});

const MenuBox = styled(Box)({
    padding: "0 8px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    "& .MuiTypography-root": {
        padding: "4px 15px",
        cursor: "pointer",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 400,
        borderRadius: "8px",
        "&:hover": {
            background: "#EFF6FF"
        }
    }
});

const PrevInBox = styled(Box)({
    justifyContent: "space-between",
    "@media(max-width: 1200px)":{
        justifyContent: "flex-start"
    }
});

const TimelineWrapperOne = styled(Box)({
    "@media(max-width: 1100px)":{
        display: "none"
    }
});

const TimelineWrapperTwo = styled(Box)({
    display: "none",
    "@media(max-width: 1100px)":{
        display: "block",
        "& .MuiBox-root": {
            marginTop: "0px !important"
        }
    }
});

const PayTableBox = styled(Box)({
    "@media(max-width: 1450px)":{
        width: "650px"
    },
    "@media(max-width: 1400px)":{
        width: "600px"
    },
    "@media(max-width: 1350px)":{
        width: "550px"
    },
    "@media(max-width: 1300px)":{
        width: "500px"
    },
    "@media(max-width: 1250px)":{
        width: "450px"
    },
    "@media(max-width: 1200px)":{
        width: "400px"
    },
    "@media(max-width: 1150px)":{
        width: "350px"
    },
    "@media(max-width: 1100px)":{
        width: "650px"
    },
    "@media(max-width: 1050px)":{
        width: "600px"
    },
    "@media(max-width: 1000px)":{
        width: "550px"
    },
    "@media(max-width: 992px)":{
        width: "auto"
    },
    "@media(max-width: 850px)":{
        width: "700px"
    },
    "@media(max-width: 800px)":{
        width: "650px"
    },
    "@media(max-width: 750px)":{
        width: "600px"
    },
    "@media(max-width: 700px)":{
        width: "550px"
    },
    "@media(max-width: 650px)":{
        width: "500px"
    },
    "@media(max-width: 600px)":{
        width: "450px"
    },
    "@media(max-width: 550px)":{
        width: "400px"
    },
    "@media(max-width: 500px)":{
        width: "350px"
    }
});

const FiltResetWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
        textTransform: "capitalize",
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700
    }
});

const CancelButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "56px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const MenuDeleteBox = styled(Box)({
    padding: "0 10px",
    cursor: "pointer",
    display: "flex",
    borderRadius: "8px",
    "&:hover": {
        background: "#EFF6FF"
    }
});

const AcceptButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const FiltDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px",
            top: "0px",
            border: "1px solid #CBD5E1"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        fontWeight: "400",
        color: "#0F172A",
        fontSize: "14px",
        top: "0px",
        "&.Mui-disabled": {
            WebkitTextFillColor: "#0F172A"
        }
    },
    "& .MuiInputBase-input::placeholder": {
        opacity: 1,
        fontSize: "16px",
        color: "#94A3B8",
    }
});

const FiltSelect = styled(Select)({
    color: "#0F172A",
    height: "44px",
    fontSize: "14px",
    fontWeight: 400,
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    }
});

const FiltInputLabel = styled(InputLabel)({
    fontSize: "16px",
    top: "-5px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
        display: "none"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const ResultBoxStyle = styled(Box)({
    "@media(max-width: 540px)": {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    }
});

const HeadingRowStyle = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        padding: "20px 16px 8px",
        color: "#475569",
        fontWeight: 500,
        fontSize: "14px",
        whiteSpace: "nowrap"
    }
});

const BodyRowStyle = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#475569",
        fontSize: "14px"
    }
});

const SearchField = styled(TextField)({
    maxWidth: "320px",
    flexGrow: 1,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const TableWrapper = styled(Box)({
    "@media(max-width: 1250px)": {
        width: "850px"
    },
    "@media(max-width: 1200px)": {
        width: "800px"
    },
    "@media(max-width: 1150px)": {
        width: "750px"
    },
    "@media(max-width: 1100px)": {
        width: "700px"
    },
    "@media(max-width: 1050px)": {
        width: "650px"
    },
    "@media(max-width: 1000px)": {
        width: "600px"
    },
    "@media(max-width: 990px)": {
        width: "auto"
    }
});

const webStyle = {

    colleInv: {
        display: "flex",
        justifyContent: "space-between"
    },
    fromToBox: {
        fontSize: "14px",
        color: "#1E293B",
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    } as React.CSSProperties,
    subTotal: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#1E293B",
        display: "flex",
        width: "300px",
        justifyContent: "space-between"
    },
    subTotalValue: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#0F172A",
        textAlign: "right"
    } as React.CSSProperties,
    paymentInstHeading: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#0F172A"
    },
    pamInstValue: {
        fontWeight: 500,
        color: "#0F172A",
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    errorText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "red",
        margin: "5px 0 0 0"
    },
    fileInputWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px"
    },
    fileMoreIcon: {
        color: "#475569",
        cursor: "pointer"
    },
    fileSizeStyle: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0"
    },
    fileNameStyle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    noteIconBox: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px",
        cursor: "pointer"
    },
    portFolioBodyText:{
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400,
        margin: "0px"
    },
    upPortText: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 700,
        margin: "0"
    },
    porfolioBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px dashed #CBD5E1",
        borderRadius: "8px",
        padding: "25px",
        gap: "12px"
    } as React.CSSProperties,
    boxHeadingText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 700,
        marginBottom: "28px"
    },
    tableContainer: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px 4px #00000008",
        marginTop: "40px",
        background: "white"
    },
    fileSize: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "14px",
        marginBottom: "0"
    },
    modCloseWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:  "baseline",
        marginBottom: "12px"
    },
    edit: {
        fontWeight: 700,
        color: "#1E3A8A",
        fontSize: "16px"
    },
    iconEdit: {
        width: "15px",
        color: "#1E3A8A"
    },
    editMainWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer"
    },
      modalMainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
      modalBodyBox: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008"
    },
    fileName: {
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    noteIconBoxStyle: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        minWidth: "48px",
        height: "48px"
    },
    fileWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px",
        maxWidth: "350px",
        marginTop: "15px"
    },
    prevInWrapper:{
        display:"flex",
        gap:"30px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    secondValueText: {
        fontWeight: 600,
        color: "#0F172A",
        fontSize: "18px",
        marginBottom: "10px"
    },
    prevInUpValText: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "18px",
        marginBottom: "10px"
    },
    profileTextWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    } as React.CSSProperties,
    secondBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: "700px",
        gap: "25px"
    } as React.CSSProperties,
    listHeadingText: {
        fontWeight: 700,
        fontSize: "24px",
        color: "#0F172A"
    },
    stepperBox: {
        position: "relative",
        padding: "0 25px 33px 25px"
    } as React.CSSProperties,
    dividerStyle: {
        margin: "25px 0 45px",
        background: "#E2E8F0",
        display: "block !important"
    },
    stepperSpan: {
        position: "absolute",
        background: "#EFF6FF",
        color: "#1D4ED8",
        fontSize: "14px",
        fontWeight: 500,
        width: "24px",
        height: "24px",
        top: "-1px",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,
    timeText: {
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
    },
    sizeTextStyle: {
        color: "#334155",
        fontSize: "16px",
        fontSeight: 400
    },
    timelineHeading: {
        fontSize: "18px",
        color: "#1E293B",
        fontWeight: 600,
        marginBottom: "20px"
    },
    headingBoxText: {
        fontWeight: 600,
        color: "#0F172A",
        fontSize: "20px",
        marginBottom: "15px"
    },
    profileTextStyle: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    },
    activeText: {
        backgroundColor: "#D1FAE5",
        borderRadius: "40px",
        color: "#059669",
        fontWeight: 700,
        fontSize: "12px",
        padding: "3px 10px",
        width: "fit-content",
        textTransform: "uppercase"
    } as React.CSSProperties,
    filtLabel: {
        fontWeight: 700,
        color: "#334155",
        fontSize: "14px"
    },
    avatarTextWrapper: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        marginBottom: "25px"
    },
    ViewDetailWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        marginTop: "30px"
    } as React.CSSProperties,
    filtHeadingBox: {
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 600,
        gap: "8px"
    },
    detailChildBox: {
        padding: "40px",
        borderRadius: "24px",
        boxShadow: "0px 0px 8px 4px #00000008",
        color: "#334155",
        fontSize: "16px",
        background: "white"
    },
    filtBottomBox: {
        padding: "20px",
        display: "flex",
        gap: "20px",
        justifyContent: "space-between"
    },
    descUpBox: {
        display: "flex",
        gap: "20px",
        justifyContent: "space-between",
        maxWidth: "265px",
        marginBottom: "30px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    descUppValue: {
        color: "#0F172A",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "12px"
    },
    filtCloseBox: {
        display: "flex",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 20px"
    },
    statusTimeWrapper: {
        display: "flex",
        gap: "15px",
        alignItems: "center",
        flexWrap: "wrap"
    } as React.CSSProperties,
    filtMiddleBox: {
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1",
        display: "flex",
        gap: "10px",
        flexDirection: "column"
    } as React.CSSProperties,
    filtermainWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        background: "white"
    } as React.CSSProperties,
    statusStyleSpan: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#D1FAE5",
        color: "#059669",
        textTransform: "uppercase"
    } as React.CSSProperties,
    tableContainerStyle: {
        borderRadius: "16px",
        border: "1px solid #E2E8F0",
        boxShadow: "none",
        width: "100%",
        overflow: "auto"
    },
    filterBotton: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#0F172A",
        height: "44px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        textTransform: "none",
        padding: "10px 16px",
        width: "fit-content"
    } as React.CSSProperties,
    headingText: {
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F172A"
    },
    searchFieldBox: {
        display: "flex",
        height: "23px",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        maxWidth: "400px",
        padding: "10px 8px"
    },
    tableUppBox: {
        display: "flex",
        marginBottom: "26px",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    tableWrapper: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px 4px #00000008",
        background: "white",
        marginTop: "36px"
    },
    countWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "15px"
    } as React.CSSProperties,
    countTitle: {
        marginTop: "18px",
        fontSize: "18px",
        fontWeight: 500,
        color: "#94A3B8"
    },
    countBox: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        gap: "10px",
        borderRadius: "20px",
        border: "1px solid #E2E8F0",
        width: "160px",
        background: "white"
    } as React.CSSProperties,
    countValue: {
        color: "#0F172A",
        fontSize: "24px",
        fontWeight: 600
    },
}
// Customizable Area End
