import React from "react";
import { Box, Paper, Radio, RadioGroup } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

interface Props {
  accountType: string;
  accountSubType: string;
  handleAccountSubType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currentLanguage: string;
}

const webStyle = {
  mainBox: {
    "@media (min-width: 1920px), (min-height: 1080px)": {
      paddingTop: 180,
      marginBottom: 220
    }
  },
  items: {
    "@media (min-width: 1920px), (min-height: 1080px)": {
      gap: 16
    }
  }
}

class AccountSubTypeWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

    render() {
      const { accountSubType, accountType, currentLanguage, handleAccountSubType } = this.props;
      const languageData = languageTranslationData[currentLanguage];
      return(
        <Box sx={webStyle.mainBox as CSSProperties} paddingTop={10} marginBottom={8} flexDirection="column" display="flex" alignItems="center">
          <Box>
            <Text size="xl" weight="extraBold" lineHeight="32px">{languageData.AccountSubType}</Text>
          </Box>
          <Box mt={2}>
            <Text size="sm" weight="minBold"   lineHeight="24px">{languageData.AccountSubTypeDesc}</Text>
          </Box>
          <Box sx={webStyle.items as CSSProperties} display="flex" mt={3} flexDirection="column">
            <Box display="flex" alignItems="center">
              <Radio
                  checked={accountSubType === "individual"}
                  value="individual"
                  name="individual"
                  color="primary"
                  data-testid="individual"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountSubType(event)}
                  inputProps={{ 'aria-label': 'individual' }}
              /><Text size="sm" weight="minBold">{languageData.Individual}</Text>
            </Box>
            <Box display="flex" alignItems="center">
                <Radio
                    checked={accountSubType === "bank"}
                    value="bank"
                    name="bank"
                    color="primary"
                    data-testid="bank"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountSubType(event)}
                    inputProps={{ 'aria-label': 'bank' }}
                /><Text size="sm" weight="minBold">{languageData.Bank}</Text>
            </Box>
            {accountType === "debtOwner" && <Box display="flex" alignItems="center">
                <Radio
                    checked={accountSubType === "agency"}
                    color="primary"
                    value="agency"
                    name="agency"
                    data-testid="agency"
                    inputProps={{ 'aria-label': 'agency' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountSubType(event)}
                /><Text size="sm" weight="minBold">{languageData.Agency}</Text>
            </Box>}
            {accountType === "debtBuyer" && <Box display="flex" alignItems="center">
                <Radio
                    checked={accountSubType === "investor"}
                    value="investor"
                    color="primary"
                    name="investor"
                    data-testid="investor"
                    inputProps={{ 'aria-label': 'investor' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountSubType(event)}
                /><Text size="sm" weight="minBold">{languageData.Investor}</Text>
            </Box>}
            {accountType === "debtBuyer" && <Box display="flex" alignItems="center">
                <Radio
                    checked={accountSubType === "serviceProvider"}
                    value="serviceProvider"
                    name="serviceProvider"
                    color="primary"
                    data-testid="serviceProvider"
                    inputProps={{ 'aria-label': 'serviceProvider' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccountSubType(event)}
                /><Text size="sm" weight="minBold">{languageData.ServiceProvider}</Text>
            </Box>}
          </Box>
        </Box>)
      // Customizable Area End
    }
}

export default AccountSubTypeWeb;