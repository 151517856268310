import React, { FunctionComponent } from 'react';
import {OutlinedInput, InputAdornment, Box} from "@material-ui/core";
import {
    makeStyles
} from "@material-ui/core/styles";

interface InputFieldProps {
    classes?: any
    type: 'text' | 'password',
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    labelWidth?: 0,
    id?: string,
    endAdornment?: any,
    border?: 'solidLight' | 'none',
    borderRadius?: 'sm',
    background?: 'white',
    height?: 'xxl',
    styles?: any,
    placeholder? : string;
    maxLength?: string;
    step?: string;
}

const useStyles = makeStyles((theme) => ({

    inputField: {
        "& .MuiInputBase-root": {
            width: '100%',
            '& input': {
                paddingTop: '12.5px',
                paddingBottom: '12.5px',
            },
            '& fieldset': {
                top: '-5.5px !important',
            },
        }
    }
}))

const CustomOutlinedInput: FunctionComponent<InputFieldProps> = (props) => {
    
    const {
        classes,
        type,
        value,
        onChange,
        labelWidth,
        id,
        endAdornment,
        border,
        borderRadius,
        background,
        height,
        placeholder,
        maxLength,
        step,
    } = props;

    const minHeight = {
        'xxl': '44px',
    }

    const borderInput = {
        'solidLight': '1px solid #CBD5E1',
        'none' : "0px solid transparent"
    }

    const radius = {
        'sm': '8px',
    }

    const backgroundColor = {
        'white': '#FFF',
    }

    const style = {
        height: minHeight[height || 'xxl'],
        background: backgroundColor[background || 'white'],
        type: type,
        borderRadius: radius[borderRadius || 'sm'],
        overflow : "hidden"
    }

    const styles = useStyles();

    return (
        <Box className={styles.inputField}>
            <OutlinedInput
                classes={classes}
                type={type ? type: 'text'}
                value={value}
                onChange={onChange}
                labelWidth={labelWidth}
                id={id}
                style={style}
                placeholder={placeholder}
                endAdornment={endAdornment && (
                    <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
                )}
                inputProps={{ maxLength: maxLength, step: step }}
            />
        </Box>
    );
};

export default (CustomOutlinedInput);
