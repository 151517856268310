import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData } from "framework/src/Utilities";
import { calendarIcon, tickIcon } from "./assets";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslation = require('../../languagesupport/src/LanguageData/ListingSummary.json')
// Customizable Area End


interface Document {
    file_name: string;
    url: string;
    size: string;
}

interface Attributes {
    id: number;
    total_number_of_debts: number;
    total_amount_of_debts: number;
    total_judgements: number;
    last_collectors_contact: number;
    last_creditors_contact: number;
    collections_used_in_past: number;
    total_months_as_costomer: number;
    pas_payment_made: boolean;
    month_average_paytime: number;
    other_documents: Document[][];
    account_id: number;
    supporting_documents: Document[][];
}

interface UserListing {
    id: string;
    type: string;
    attributes: Attributes;
}

type NumberOrStringOrUndefined = number | string | undefined;

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    totals: {
        label: string,
        id: string,
        value: NumberOrStringOrUndefined
    }[],
    pastValues: {
        title: string,
        value: NumberOrStringOrUndefined
    }[],
    cards: {
        label1: string,
        label2: string,
        value: NumberOrStringOrUndefined,
        approved: boolean,
        icon:string
    }[],
    creditor: {
        name: string,
        website: string,
        address: string,
        industry: string,
        title: string
    },
    currentLanguage: 'ar' | 'en',
    userListingDetails: Partial<UserListing>,
    amount: string,
    offerType: string,
    pitch: string,
    errors: { [key: string]: string },
    userListId:number|null
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ListingSummaryController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    listingSummaryPageApiCallId: string = "";
    makeOfferApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            totals: [
                {
                    label: "Total Number of debts",
                    value: "0",
                    id: "total_number_of_debts"
                },
                {
                    label: "Total Amount of debts",
                    value: "$0",
                    id: "total_amount_of_debts"
                },
                {
                    label: "Total Judgements",
                    value: "$0",
                    id: "total_judgements"
                }
            ],
            pastValues: [
                {
                    title: "Last Collector Contact",
                    value: "Unknown No"
                },
                {
                    title: "Last Creditor Contact",
                    value: "Unknown No"
                },
                {
                    title: "Collections User in Past",
                    value: "No"
                }
            ],
            cards: [
                {
                    label1: "Month",
                    label2: "as Customer",
                    value: "0",
                    approved: false,
                    icon:calendarIcon
                },
                {
                    label1: "Past",
                    label2: "Paymens Made",
                    value: "Yes",
                    approved: true,
                    icon:tickIcon
                },
                {
                    label1: "Months",
                    label2: "Average Pay Time",
                    value: "0",
                    approved: false,
                    icon:calendarIcon
                }
            ],
            creditor: {
                name: "Austin Corporate Cleaners",
                website: "loreumipisum",
                address: "500 Lysol Drive, Austin, TX 73301",
                industry: "Accommodation and foods Services",
                title: "This Listing is offered by Austin Corporate Cleaners"
            },
            userListingDetails: {},
            currentLanguage: 'en',
            amount: '',
            offerType: '',
            pitch: '',
            errors: {},
            userListId:null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const userListId = this.props.navigation.getParam("navigationBarTitleText");
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({ currentLanguage: language,userListId:userListId });
        this.getListingSummaryPageData(userListId)
        // Customizable Area End
    }

    // Customizable Area Start

    getArabicLabel = (keyName: string) => {
        if (keyName === 'offerType') {
            return languageTranslation[this.state.currentLanguage].OfferType
        } else {
            return languageTranslation[this.state.currentLanguage].Amount
        }
    }

    setError = (field: string, message: string = "") => {
        const fieldName = this.getArabicLabel(field);
        let errorMessage = message || this.state.currentLanguage === 'en' ? `${fieldName} is required` : `${fieldName} مطلوب`
        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [field]: errorMessage,
            },
        }));
    }

    resetError(field: string) {
        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [field]: '',
            },
        }));
    }

    handleChangeAmountInput = {
        onChange: (text: string) => {
            const cleanedInput = text.replace(/\D/g, '');
            this.setState({ amount: cleanedInput });
            this.resetError("amount")
        }
    }

    handleChangeOfferType = {
        onChange: (event: React.ChangeEvent<{ value: unknown }>) => {
            this.setState({ offerType: String(event.target.value) });
            this.resetError("offerType")
        }
    }

    handleChangePitch = {
        onChange: (text:string) => {
            this.setState({ pitch: text });
        }
    }

    isStringNullOrBlank(str: string) {
        return str === null || str.length === 0;
    }

    isValidate = () => {
        let error = false;
        if (this.isStringNullOrBlank(this.state.amount)) {
            this.setError("amount");
            error = true;
        }
        if (this.isStringNullOrBlank(this.state.offerType )) {
            this.setError("offerType");
            error = true;
        }
        return error;
    }

    handleMakeOffer = async () => {
        if (!this.isValidate()) {
            const header = {
                token: await getStorageData("accessToken")
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.makeOfferApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.loginAPiMethod
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                header
            );
            let formdata = new FormData();
            formdata.append("[data][offer_amount]", this.state.amount);
            formdata.append("[data][offer_pitch]", this.state.pitch)
            formdata.append("[data][offer_type]", this.state.offerType)
            formdata.append("[data][user_list_id]", `${this.state.userListId}`)
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.makeOfferEndPoint
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    getListingSummaryPageData = async (id: number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.listingSummaryPageApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.UserListingDetailsEndPoint}/${id}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFormattedTotals = () => [
        {
            label: languageTranslation[this.state.currentLanguage].TotalNumberOfDebts,
            value: this.state.userListingDetails.attributes?.total_number_of_debts,
            id: "total_number_of_debts"
        },
        {
            label: languageTranslation[this.state.currentLanguage].TotalAmountOfDebts,
            value: `$${this.state.userListingDetails.attributes?.total_amount_of_debts}`,
            id: "total_amount_of_debts"
        },
        {
            label: languageTranslation[this.state.currentLanguage].TotalJudgements,
            value: `$${this.state.userListingDetails.attributes?.total_judgements}`,
            id: "total_judgements"
        }
    ]

    getFormattedPastValues = () => [
        {
            title: languageTranslation[this.state.currentLanguage].LastCollectorContact,
            value: this.state.userListingDetails.attributes?.last_collectors_contact
        },
        {
            title: languageTranslation[this.state.currentLanguage].LastCreditorContact,
            value: this.state.userListingDetails.attributes?.last_creditors_contact
        },
        {
            title: languageTranslation[this.state.currentLanguage].CollectionsUserInPast,
            value: this.state.userListingDetails.attributes?.collections_used_in_past ? languageTranslation[this.state.currentLanguage].Yes : languageTranslation[this.state.currentLanguage].No
        }
    ]

    getFormattedCardData = () => [
        {
            label1: languageTranslation[this.state.currentLanguage].Month,
            label2: languageTranslation[this.state.currentLanguage].AsCustomer,
            value: this.state.userListingDetails.attributes?.total_months_as_costomer,
            approved: false,
            icon:calendarIcon
        },
        {
            label1: languageTranslation[this.state.currentLanguage].Past,
            label2: languageTranslation[this.state.currentLanguage].PaymensMade,
            value: this.state.userListingDetails.attributes?.pas_payment_made ? languageTranslation[this.state.currentLanguage].Yes : languageTranslation[this.state.currentLanguage].No,
            approved: true,
            icon:tickIcon
        },
        {
            label1: languageTranslation[this.state.currentLanguage].Months,
            label2: languageTranslation[this.state.currentLanguage].AveragePayTime,
            value: this.state.userListingDetails.attributes?.month_average_paytime,
            approved: false,
            icon:calendarIcon
        }
    ]
    handleNavigateForUserList = (routes : string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), routes);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    
    // Customizable Area End
}
