import React, { Component } from "react";
import {
    Box,
    IconButton,
    Typography,
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
const utills = require('../../../blocks/utilities/src/utills')

type Props = {
    handlePageChange: (route: number) => void;
    page: number;
    count: number;
};

interface S {
    language: string;
    count: number;
    currentPage: number;
    itemArray: (string | number)[];
}

const toArabicNumeral = (num: number): string => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(num).replace(/[0-9]/g, (d) => arabicNumerals[parseInt(d)]);
};

export default class TablePagination extends Component<Props, S> {
    constructor(props: Props) {
        super(props);
        this.state = {
            language: "en",
            count: this.props.count,
            currentPage: this.props.page,
            itemArray: [],
        };
    }

    componentDidMount = async () => {
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            language: selectedLanguage
        });
        this.handleShow();
    };

    handleChange = (type: string, value: number) => {
        let { page, count } = this.props;
        if (type === "number") {
            page = value;
        } else {
            if (value === -1) {
                if (page !== 1) {
                    page = page - 1;
                }
            } else {
                if (page !== count) {
                    page = page + 1;
                }
            }
        }
        this.setState({ currentPage: page }, () => {
            this.handleShow();
            this.props.handlePageChange(page);
        });
    };

    handleShow = () => {
        let { currentPage, count } = this.state;
        var array: (string | number)[] = [];
        if (currentPage <= 3) {
            for (let i = 1; i <= currentPage + 1; i++) {
                array.push(i);
            }
        } else {
            for (let i = 1; i < currentPage + 2; i++) {
                if (
                    i === 1 ||
                    i === currentPage - 1 ||
                    i === currentPage ||
                    i === currentPage + 1
                ) {
                    array.push(i);
                    if (i === count) {
                        i++;
                    }
                } else {
                    array.push("...");
                    i = currentPage - 2;
                }
            }
        }
        if (count - currentPage <= 2) {
            for (let i = currentPage + 2; i <= count; i++) {
                array.push(i);
            }
        } else {
            for (let i = 1; i <= 2; i++) {
                if (i === 2) {
                    array.push(count);
                } else {
                    array.push("...");
                }
            }
        }
        this.setState({ itemArray: array });
    };

    renderItem = () => {
        return (
            <Box style={webStyle.pageItemWrapper}>
                {this.state.itemArray.map((value, index) => (
                    <React.Fragment key={index}>
                        {typeof value === "string" ? (
                            <Typography>...</Typography>
                        ) : (
                            <IconButton
                                style={{ width: "30px", height: "30px" }}
                                onClick={() => this.handleChange("number", value)}
                            >
                                <Typography
                                    style={
                                        this.state.currentPage === value
                                            ? webStyle.pageItemSelected
                                            : webStyle.pageItem
                                    }
                                >
                                    {this.state.language === "en"
                                        ? value
                                        : toArabicNumeral(value)}
                                </Typography>
                            </IconButton>
                        )}
                    </React.Fragment>
                ))}
            </Box>
        );
    };

    render() {
        return (
            <Box
                dir={this.state.language === "en" ? "ltr" : "rtl"}
                style={webStyle.mainBox}
            >
                <IconButton
                    style={webStyle.arrowButton}
                    data-test-id="prevButtonTestID"
                    onClick={() => this.handleChange("button", -1)}
                >
                    {this.state.language === "en" ? (
                        <ArrowBackIosRoundedIcon style={webStyle.arrow} />
                    ) : (
                        <ArrowForwardIosRoundedIcon style={webStyle.arrow} />
                    )}
                </IconButton>
                {this.renderItem()}
                <IconButton
                    style={webStyle.arrowButton}
                    onClick={() => this.handleChange("button", +1)}
                >
                    {this.state.language === "en" ? (
                        <ArrowForwardIosRoundedIcon style={webStyle.arrow} />
                    ) : (
                        <ArrowBackIosRoundedIcon style={webStyle.arrow} />
                    )}
                </IconButton>
            </Box>
        );
    }
}

const webStyle = {
    arrow: {
        width: "15px",
        color: "#475569"
    },
    arrowButton: {
        width: "30px",
        height: "30px"
    },
    mainBox: {
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    pageItemSelected: {
        fontWeight: 700,
        fontSize: "16px",
        width: "fit-content",
        height: "fit-content",
        color: "#1D4ED8"
    },
    pageItem: {
        fontSize: "16px",
        width: "fit-content",
        height: "fit-content",
        textDecoration: "underline",
        color: "#0F172A"
    },
    pageItemWrapper: {
        display: "flex",
        alignItems: "end",
        gap: "4px"
    }
};
