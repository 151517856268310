import React from "react";
// Customizable Area Start
import SuperAdminGetOtpController, {
    Props
} from "./SuperAdminGetOtpController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Text from "../../../components/src/design-system/Text";
import {
    Box as OtpBox, FormControl as Form,
    TextField, Button
} from "@material-ui/core";
import { LoginFlow } from "../../../components/src/LoginFlow.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')
// Customizable Area End

const loginStyle: StyleRules = {
    // Customizable Area Start
    containerAuto: {
        maxWidth: '996px',
        padding: '120px 20px',
        margin: '0 auto',
        "@media (max-width: 960px)": {
            padding: "40px 20px"
        }
    },
    containerLogin: {
      height: '100%',
      boxSizing: 'border-box',
      padding: '44px 100px',
      "@media (max-width: 960px)": {
          padding: "40px 20px"
  
      },
      "@media (max-width: 600px)": {
          padding: "40px 20px",
          borderRadius: "10px 10px 0px 0px"
      },
    },
    textLabel: {
        margin: "0px 0px 4px 0px",
        color: "#334155",
        fontWeight: 700,
        fontSize: "12px",
    },
    loginContainer: {
        padding: '312px 80px 40px 80px',
        "@media (max-width: 960px)": {
            padding: "40px 20px"
        },
        boxSizing: 'border-box',
        background: '#F1F5F9',
        height: '100%',
    },
    container: {
        backgroundColor: "#0F172A",
    },
    linkText: {
        cursor: 'pointer',
        marginLeft: 5,
        color: '#1E3A8A',
        "&:hover": {
          textDecoration: 'underline'
        },
    },
    otpForm: {
        width: '100%',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        "& input": {
            textAlign: 'center',
        }
    },
    otpFormText: {
        marginTop: '42px',
        textAlign: 'center',
        '& p': {
            textAlign: 'center !important',
            marginBottom: '0px',
        }
    },
    nextBtn: {
        background: "#1E3A8A",
        color: '#fff',
        borderRadius: "8px",
        padding: 12,
        textTransform: "none",
        fontSize: 16,
        fontWeight: 700,
    
        "&:hover": {
            background: "#1E3A8A",
            color: '#fff',    
        }
      }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class SuperAdminOtp extends SuperAdminGetOtpController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const length = 5;
        return (
        <LoginFlow>
            <OtpBox className={classes?.containerLogin}>
                <OtpBox mb={3}>
                    <Text variant="primary" size="xl" weight="extraBold">{languageData.Heading}</Text>
                </OtpBox>
                <OtpBox mb={2}>
                    <Text size="xm" weight="extraBold">{languageData.Title}</Text>
                </OtpBox>
                <OtpBox mb={3}>
                    <Text size="sm" weight="minBold" family="inter">{languageData.OtpDescription}</Text>
                </OtpBox>
                <OtpBox pl={5} pr={5}>
                    <Form className={classes.otpForm}>
                        <OtpBox display="flex" gridGap="8px">
                            {Array.from({ length }).map((_, otpIndex) =>
                                <TextField style={{ width: "48px" }}
                                    variant="outlined"
                                    id={`digit-${otpIndex}`}
                                    value={this.state.adminOtp[otpIndex] !== undefined ? `${this.state.adminOtp[otpIndex]}` : ''}
                                    InputProps={{
                                    style: { borderRadius: 8, border: '1px solid #CBD5E1' }
                                    }}
                                    inputProps={{ maxLength: 1 }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.handleChangeOtp.onChangeText(otpIndex, event)}}
                                    key={otpIndex} />
                                )}
                        </OtpBox>
                    </Form>
                    <OtpBox>
                    {this.state.otpError && <Text variant="error" family="inter" weight="minBold" size="xs">{this.state.otpError}</Text>}
                    </OtpBox>
                </OtpBox>
                <OtpBox textAlign="center" mb={4}>
                    <Button variant="text" style={{ textTransform: 'none' }}>
                        <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">
                        {languageData.Resend}</Text>
                    </Button>
                </OtpBox>
                <Button
                    onClick={this.submitOtp}
                    data-testid="next-btn"
                    variant="contained"
                    fullWidth
                    className={classes.nextBtn}
                >
                    {languageData.Next}
                </Button>
                <OtpBox mt={3} mb={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
                    {languageData.BackTo}
                    <span className={classes.linkText} id="signup-test" onClick={() => this.handleRouting('SuperAdminLogin')}>
                      <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Login}</Text></span>
                  </Text>
              </OtpBox>
            </OtpBox>
        </LoginFlow>   
    )
        // Customizable Area End
    }
}

export default withStyles(loginStyle)(SuperAdminOtp);
