import React from "react";
// Customizable Area Start
import CreateListingController, { Props, configJSON} from "./CreateListingController.web";
import { withStyles, StyleRules, styled } from "@material-ui/core/styles";
import { 
    Box, 
    FormControl, 
    Button, 
    IconButton,
    Typography,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Menu
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotesIcon from "@material-ui/icons/Notes";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {fileUploadIcon } from "./assets";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Listing.json')
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import PreviewListing from "../../../components/src/design-system/PreviewListing/PreviewListing.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    inputFileDocBox: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        width: "100%",
        padding: "16px 12px 16px 16px",
        borderRadius: "8px",
        backgroundColor: "#FFF",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        boxSizing: "border-box",
        marginBottom: "24px"
    },
    inputFileDocIconBox: {
        boxSizing: "border-box",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyConent: "center",
        backgroundColor: "#C2D5FF",
        borderRadius: "8px"
    },
    inputFileDocIcon: {
        boxSizing: "border-box",
        width: "24px",
        height: "24px"
    },
    inputNoFileDocButton: {
        flex: 1
    },
    inputFileDocButton: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "2px"
    },
    inputFileDocTitle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        margin: "0px",
        width: "15ch",
        overflow: "hidden",
        whiteSpace: "pre",
    },
    inputFileDocDescription: {
        color: "#64748B",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        margin: "0px",
        width: "15ch",
        overflow: "hidden",
        whiteSpace: "pre"
    },
    selectInput: {
        border: "1px solid rgba(0,0,0,0.25)",
        borderRadius: "4px",
        backgroundColor: "white",
        padding: "11.5px 14px",
        fontSize: "1rem",
        color: "rgba(0,0,0,0.35)",
        fontWeight: 400,
    }
    // Customizable Area End
}

// Customizable Area Start
interface SelectOptionItem {
    value: string;
    label: string
}
// Customizable Area End

export class CreateListing extends CreateListingController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    renderCustomGrid = (label:string, value: string, placeholder:string, error:string, stateName: string) => {
        return (
            <CustomGrid item xs={12} sm={12} md={6}>
                <Typography>{label}</Typography>
                    <TextField
                        data-test-id={`${stateName}TestID`}
                        value = {value}
                        name = {stateName}
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder={placeholder}
                        onChange={this.handleChange}
                    />
                    {(error && this.state.checkSave) && (
                        <Typography style={webStyle.errorText}>
                            {error}
                        </Typography>
                    )}
            </CustomGrid>
        );
    };

    renderSelect = (label: string, value: string, placeholder: string, error: string, stateName: string) => {
        const getMenuItem = this.handleMenuItem(stateName);
        return (
            <CustomGrid item xs={12} sm={12} md={6}>
                <Typography>{label}</Typography>
                <FormControl fullWidth>
                    <CustomInputLabel shrink={false} style={{ lineHeight: "0.5", display: value? "none": "block"}}>
                        {placeholder}
                    </CustomInputLabel>
                    <CustomSelect
                        fullWidth
                        data-test-id={`${stateName}TestID`}
                        IconComponent={KeyboardArrowDownRoundedIcon}
                        variant="outlined"
                        value={value}
                        name = {stateName}
                        onChange={(event)=>this.handleSelectChange(event, stateName)}
                    >
                        { getMenuItem.map((item:SelectOptionItem, index: number)=>
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        )}
                    </CustomSelect>
                </FormControl>
                {(error && this.state.checkSave) && (
                    <Typography style={webStyle.errorText}>
                        {error}
                    </Typography>
                )}
            </CustomGrid>
        );
    };

    renderEmptyPortfolio = (index:number, error: string, arrayName:string) =>{
        const {inputFields} = languageTranslationData[this.state.currentLanguage];
        const { checkSave } = this.state;
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Box style={webStyle.porfolioBox}>
                    <EmptyUploadIcon 
                        src={fileUploadIcon} 
                        style={{ fontSize: "22px", color: "#94A3B8" }}
                        alt= "file_upload_icon"
                    />
                    <Typography style={webStyle.upPortText}>
                        {inputFields.Uploaddocument}
                    </Typography>
                    <PortfolioBodyBox>
                        <Typography style={webStyle.portFolioBodyText}>{inputFields.docSuppFor}</Typography>
                        <Typography style={webStyle.portFolioBodyText}>{configJSON.formatSupport}</Typography>
                    </PortfolioBodyBox>
                    <FileButtonBox 
                        style={{width:"137px"}}
                        component={"label"}
                    >
                        {inputFields.ChooseaFile}
                        <VisuallyHiddenInput 
                            data-test-id={`${arrayName+index}TestID`}
                            type="file" 
                            onChange={(event)=>this.handleAddPortfolio(event, index, arrayName)} 
                        />
                    </FileButtonBox>
                </Box>
                {(error && checkSave) && (
                    <Typography style={webStyle.errorText}>
                        {error}
                    </Typography>
                )}
            </CustomGrid>
        )
    };

    renderPortfolioFile = (file:File | null, index:number, docType: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Typography>
                    {languageData.Portfolio}
                </Typography>
                <Box style={webStyle.fileInputWrapper}>
                    <Box style={webStyle.noteIconBox}>
                        <NotesIcon style={{color: "#2563EB"}}/>
                    </Box>
                    {file !== null && <Box style={{flexGrow:1, width:"50%"}}>
                        <Typography style={webStyle.fileNameStyle}>{file.name}</Typography>
                        <Typography style={webStyle.fileSizeStyle}>{((file.size)/1024).toFixed(2)} KB</Typography>
                    </Box>}
                    <IconButton 
                        data-test-id={`fileMoreTestID${docType+index}`}
                        aria-label="more1"
                        aria-haspopup="true"
                        id = {`${docType+index}`}
                        disabled={this.state.showPreview}
                        onClick={this.handleMoreDocClick}
                    >
                        <MoreVertIcon style={webStyle.fileMoreIcon} />
                    </IconButton>
                </Box>
                
            </CustomGrid>
        );
    };

    renderFileUploadBox = (array:(File|null)[], error: string, docType: string) => {
        const {inputFields} = languageTranslationData[this.state.currentLanguage];
        return (
            <Grid container spacing={4}>
                {array.map((item, index) =>
                    (item===null)
                        ? this.renderEmptyPortfolio(index, error, docType)
                        : this.renderPortfolioFile(item, index, docType)
                )}
                <CustomGrid
                    style={{ display: "flex", justifyContent: "center" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <FileButtonBox 
                        style={{width:"224px"}} 
                        component={"label"}
                    >
                        <Box style={{display: "flex", gap: "10px"}}>
                            <img 
                                src={fileUploadIcon}
                                style={{width:"24px"}}
                                alt="file_upload_icon"
                            /> 
                            {inputFields.Addmoredoc}
                        </Box>
                        <VisuallyHiddenInput 
                            type="file" 
                            data-test-id={`addMore${docType}`} 
                            onChange={(event)=>this.handleAddPortfolio(event, null, docType)}
                        />
                    </FileButtonBox>
                </CustomGrid>
            </Grid>
        );
    };

    renderEditButtonBox = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <Box style={{display:"flex", justifyContent:"flex-end", margin: "20px 0 18px"}}>
                <Box
                    style={webStyle.editWrapper}
                    onClick={()=>{this.handleBreadScrum("breadScrum1")}}
                >
                    <Typography style={webStyle.editText}>{languageData.Edit}</Typography>
                    <EditIcon style={webStyle.editIcon} />
                </Box>
            </Box>
        );
    };
    
    renderPreview = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <Box data-test-id="previewTestID">
                <Typography style={webStyle.prevSubHead}>{languageData.prevSubheading}</Typography>
                {this.renderEditButtonBox()}
                {<PreviewListing previewValues={this.state.previewValues}/>}
                <Box style={{display:"flex", gap:"20px", marginTop: "50px"}}>
                    <SaveButton 
                        style={{width:"200px"}}
                        data-test-id="prevConfirmTestID"
                        onClick={this.createList}
                    >
                        {languageData.Confirmsaving}
                    </SaveButton>
                    <ResetButton 
                        style={{width:"200px"}}
                        onClick={this.createList}
                    >
                        {languageData.Saveasadraft}
                    </ResetButton>
                </Box>
            </Box>
        );
    };

    renderCreateListing = () => {
        const { 
            currentLanguage, 
            portfolioSize, 
            listingTitle, 
            averageBalance, 
            accountCount, 
            basicInfoErrors,
            totalNumber,
            totalAmount,
            totalJudgement,
            totalMonth,
            pastPay,
            monthAvg,
            lastCollDay,
            lastCollMonth,
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear,
            detailInfoErrors,
            typeOfDebt,
            ageOfDebt,
            recoveryStatus,
            debtCharErr,
            supportDocArray,
            otherDocArray
        } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const {Yes, DebtCharacFields} = languageData;
        const fieldData = languageData.basicInfofields;
        const detailInfoData = languageData.detailInfoFields;
        const detailLabel = languageData.listingSummary;
        return (
            <React.Fragment>
                <Box style={{marginTop:'30px'}}>
                    <Box style={webStyle.basicInfoBox}>
                        <Typography style={webStyle.boxHeadingText}>{languageData.BasicInformation}</Typography>
                        <Grid container spacing={4}>
                            {this.renderCustomGrid(fieldData.PortfolioSize, portfolioSize, fieldData.PortfolioSizePlace, basicInfoErrors.portfolioSizeErr, "portfolioSize")}
                            {this.renderCustomGrid(fieldData.ListingTitle, listingTitle, fieldData.ListingTitlePlace, basicInfoErrors.listingTitleErr, "listingTitle")}
                            {this.renderCustomGrid(fieldData.AverageBalance, averageBalance, fieldData.AverageBalancePlace, basicInfoErrors.averageBalanceErr, "averageBalance")}
                            {this.renderCustomGrid(fieldData.AccountCount, accountCount, fieldData.AccountCountPlace, basicInfoErrors.accountCountErr, "accountCount")}
                        </Grid>
                    </Box>
                    <Box style={webStyle.basicInfoBox}>
                        <Typography style={webStyle.boxHeadingText}>{languageData.DetailedInformation}</Typography>
                        <Grid container spacing={4}>
                            {this.renderCustomGrid(detailLabel.TotalNumber, totalNumber, detailInfoData.totalNumDebtPlace, detailInfoErrors.totalNumErr, "totalNumber")}
                            {this.renderCustomGrid(detailLabel.TotalAmount, totalAmount, detailInfoData.totalAmoDebtPlace, detailInfoErrors.totalAmtErr, "totalAmount")}
                            {this.renderCustomGrid(detailLabel.TotalJudgements, totalJudgement, detailInfoData.totalJudgPlace, detailInfoErrors.totalJudgmentsErr, "totalJudgement")}
                            {this.renderCustomGrid(detailLabel.TotalMonthAsCustomer, totalMonth, detailInfoData.totalMonCust, detailInfoErrors.totalMonthErr, "totalMonth")}
                            {this.renderSelect(detailLabel.PastPaymentMade, pastPay, Yes, detailInfoErrors.pastPayErr, "pastPay")}
                            {this.renderSelect(detailLabel.MonthAveragePayTime, monthAvg, "1", detailInfoErrors.monthAvgErr, "monthAvg")}
                            <CustomGrid item xs={12} sm={12} md={6}>
                                <Typography>{detailLabel.LastCollectorContact}</Typography>
                                <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap:"20px"
                                }}
                                >
                                    <TextField
                                        value={lastCollMonth}
                                        name={"lastCollMonth"}
                                        data-test-id="lastCollMonthTestID"
                                        placeholder="MM"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleMonthChange}
                                        onBlur={()=>this.handleBlur("lastCollMonth", this.state.lastCollMonth)}
                                    />
                                    <TextField
                                        value={lastCollDay}
                                        name={"lastCollDay"}
                                        data-test-id="lastCollDayTestID"
                                        placeholder="DD"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleDayChange}
                                        onBlur={()=>this.handleBlur("lastCollDay", this.state.lastCollDay)}
                                    
                                    />
                                    <TextField
                                        value={lastCollYear}
                                        name={"lastCollYear"}
                                        data-test-id="lastCollYearTestID"
                                        placeholder="YYYY"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleYearChange}
                                        onBlur={()=>this.handleBlur("lastCollYear", lastCollYear)}
                                    />
                                </Box>
                                {(detailInfoErrors.lastCollectErr && this.state.checkSave) && (
                                    <Typography style={webStyle.errorText}>
                                        {detailInfoErrors.lastCollectErr}
                                    </Typography>
                                )}
                            </CustomGrid>
                            <CustomGrid item xs={12} sm={12} md={6}>
                                <Typography>{detailLabel.LastCreditorContact}</Typography>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap:"20px"
                                    }}
                                >
                                    <TextField
                                        value={lastCredMonth}
                                        name={"lastCredMonth"}
                                        data-test-id="lastCredMonthTestID"
                                        placeholder="MM"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleMonthChange}
                                        onBlur={()=>this.handleBlur("lastCredMonth", lastCredMonth)}
                                    />
                                    <TextField
                                        value={lastCredDay}
                                        name={"lastCredDay"}
                                        data-test-id="lastCredDayTestID"
                                        placeholder="DD"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleDayChange}
                                        onBlur={()=>this.handleBlur("lastCredDay", lastCredDay)}
                                    />
                                    <TextField
                                        value={lastCredYear}
                                        name={"lastCredYear"}
                                        data-test-id="lastCredYearTestID"
                                        placeholder="YYYY"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleYearChange}
                                        onBlur={()=>this.handleBlur("lastCredYear", lastCredYear)}
                                    />
                                </Box>
                                {(detailInfoErrors.lastCredErr && this.state.checkSave) && (
                                    <Typography style={webStyle.errorText}>
                                        {detailInfoErrors.lastCredErr}
                                    </Typography>
                                )}
                            </CustomGrid>
                        </Grid>
                    </Box>
                    <Box style={webStyle.basicInfoBox}>
                        <Typography style={webStyle.boxHeadingText}>{DebtCharacFields.heading}</Typography>
                        <Grid container spacing={4}>
                            {this.renderSelect(detailLabel.type, typeOfDebt, DebtCharacFields.typePlace, debtCharErr.typeOfDebtErr, "typeOfDebt")}
                            {this.renderCustomGrid(fieldData.age, ageOfDebt, DebtCharacFields.agePlace, debtCharErr.ageOfDebtErr, "ageOfDebt")}
                            {this.renderSelect(detailLabel.current, recoveryStatus, DebtCharacFields.currentPlace, debtCharErr.recoveryStatusErr, "recoveryStatus")}
                        </Grid>
                    </Box>

                    <Box style={webStyle.basicInfoBox}>
                        <Typography style={webStyle.boxHeadingText}>{languageData.SupportingDocumentation}</Typography>
                        {this.renderFileUploadBox(supportDocArray, this.state.documentErr ,"supportDocArray")}
                    </Box>

                    <Box style={webStyle.basicInfoBox}>
                        <Typography style={webStyle.boxHeadingText}>{languageData.Otherdocuments}</Typography>
                        {this.renderFileUploadBox(otherDocArray, this.state.otherDocError, "otherDocArray")}
                    </Box>
                </Box>
                <Box style={{display:"flex", gap:"20px", marginTop: "50px"}}>
                    <SaveButton 
                        style={{width:"200px"}}
                        data-test-id="submitFormTestID"
                        onClick = {this.handleSaveListing}
                    >
                        {languageData.SaveListing}
                    </SaveButton>
                    <ResetButton 
                        style={{width:"200px"}}
                        onClick = {this.handleSaveListing}
                    >
                        {languageData.Saveasadraft}
                    </ResetButton>
                </Box>
            </React.Fragment>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {currentLanguage, showPreview, anchorEl} = this.state
        const languageData = languageTranslationData[currentLanguage];
        return (
            <UserProfileDrawer 
                data-test-id="drawerComponentTestID" 
                handleClick={this.handleDrawerClick}
                breadScrumArray={this.state.breadscrumArray}
                pageName={this.state.userType==="consumer"? "UserMyListing" : "dashboardListing"}
                userType = {this.state.userType==="consumer"? "consumer" : "admin_account"}
                navigation={this.props.navigation}
                setMargin = { true }
            >

                <Box>
                    <Box style = {{marginBottom:"16px"}} >
                        <Typography style={webStyle.pageHeading}>
                            {this.state.pageHeading}
                        </Typography>
                    </Box>
                    {showPreview? 
                        this.renderPreview(): 
                        this.renderCreateListing()
                    }
                </Box>
                {anchorEl && (
                    <Menu
                        id="long-menu"
                        data-test-id="menuTestID"
                        MenuListProps={{
                        "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleCloseMenu}
                        PaperProps={{
                        style: {
                            width: "198px",
                            borderRadius: "8px",
                            border: "1px solid #E2E8F0",
                            background: "#FFF",
                            padding: "0px 8px",
                            boxShadow:
                            "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
                        },
                        }}
                    >
                        <MenuButton component="label">
                            <img
                                src={fileUploadIcon}
                                alt="upload"
                                style={{ marginRight: "10px" }}
                            />
                            {languageData.UploadDocument}
                            <VisuallyHiddenInput
                                type="file"
                                data-test-id="menuuploadbtn"
                                onChange={this.handleFileChange}
                            />
                            </MenuButton>
                        <MenuButtonDelete
                            onClick={this.handleFileRemove}
                            data-test-id="removeBtnTestID"
                            component="label"
                        >
                            <DeleteOutlineRoundedIcon
                                id="modalID"
                                style={{ width: "18px" }}
                            />
                            {languageData.RemoveDocument}
                        </MenuButtonDelete>
                    </Menu>
                )}
            </UserProfileDrawer>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(CreateListing);

// Customizable Area Start

const MenuButton = styled(Box)({
    borderRadius: '8px',
    cursor: "pointer",
    background: '#EFF6FF',
    display: 'flex',
    textTransform: 'capitalize',
    padding: '7px 16px',
    alignItems: 'center',
    flexShrink: 0,
    alignSelf: 'stretch',
    transition: 'background-color 0.3s',
    whiteSpace: "nowrap",
    '&:hover': {
        backgroundColor: "#EFF6FF"
    }
});

const MenuButtonDelete = styled(Box)({
    borderRadius: '8px',
    cursor: "pointer",
    background: '#FFF',
    display: 'flex',
    textTransform: 'capitalize',
    padding: '7px 16px',
    alignItems: 'center',
    marginTop: "10px",
    gap: '8px',
    flexShrink: 0,
    alignSelf: 'stretch',
    transition: 'background-color 0.3s',
    color:"#DC2626",
    whiteSpace: "nowrap",
    '&:hover': {
        background: '#FEFEFE'
    }
});

const SaveButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1E3A8A"
    }
});

const ResetButton = styled(Button)({
    backgroundColor: "#EFF6FF",
    height: "56px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#EFF6FF"
    }
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'relative',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

const FileButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EFF6FF",
    justifyContent: "center",
    cursor: "pointer",
    height: "44px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700
});

const PortfolioBodyBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
});

const EmptyUploadIcon = styled("img")({
    filter: "invert(62%) sepia(23%) saturate(452%) hue-rotate(165deg) brightness(97%) contrast(50%)",
    width: "25px",
    height: "25px"
});

const CustomGrid = styled(Grid)({
    "& .MuiTypography-root": {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "8px"
    },
    "& .MuiTextField-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "30px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
      }
    }
});

const CustomSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1"
      
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});

const CustomInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
      display: "none",
      color: "#94A3B8"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const webStyle = {
    editWrapper: {
        display: "flex",
        gap: "8px",
        cursor: "pointer",
        alignItems: "center"
    },
    editText: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#1E3A8A"
    },
    editIcon: {
        color: "#1E3A8A",
        width: "15px"
    },
    fileMoreIcon: {
        color: "#475569",
        cursor: "pointer"
    },
    fileSizeStyle: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0"
    },
    fileNameStyle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fileInputWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px",
    },
    noteIconBox: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px",
        cursor: "pointer"
    },
    portFolioBodyText:{
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400,
        margin: "0px"
    },
    upPortText: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 700,
        margin: "0"
    },
    porfolioBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px dashed #CBD5E1",
        borderRadius: "8px",
        padding: "25px",
        gap: "12px"
    } as React.CSSProperties,
    boxHeadingText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 700,
        marginBottom: "25px"
    },
    basicInfoBox: {
        padding: "40px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "24px",
        marginBottom: "42px",
        background: "white"
    },
    pageHeading: {
        color: "#0F172A",
        fontSize: "30px",
        fontWeight: 700 
    },
    errorText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "red",
        margin: "5px 0 0 0"
    },
    prevSubHead: {
        color: "#0F172A",
        fontSize: "16px"
    }
}

// Customizable Area End