import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
const languageTranslationData = require('../../languagesupport/src/LanguageData/AdminDashboard.json');


// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    language: "en" | "ar";
    pageHeading: string;
    selChartCount: string;
    startDate: Date | null;
    endDate: Date | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            language: "en",
            pageHeading: "",
            selChartCount: "newUser",
            startDate: null,
            endDate: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        let token = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        let language = await getStorageData("currentLanguage");
        let languageData = await languageTranslationData[language];
        if (!token || user === "consumer" || user === "provider") {
            this.handleNavigationRoute("Home");
        }
        this.setState({
            language: language,
            pageHeading: languageData.Dashboard
        });
        // Customizable Area End
    }
    
    handleClickOfDrawer =  async (route: string) => {
        if(route==="logOut"){
            await removeStorageData("accessToken");
            await removeStorageData("userType");
            await removeStorageData("profilePic");
            this.handleNavigationRoute("Home");
        } else {
            this.handleNavigationRoute(route);
        }
    };

    handleNavigationRoute = (route: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    engArabicNumeral = (value: string): string => {
        const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        if(this.state.language === "ar") {
            return String(value).replace(/\d/g, (d) => arabicNumerals[parseInt(d)]);
        } else {
            return value
        }
    };

    handleCountPercStyle = (value: string) => {
        let backgroundColor= "#D1FAE533", borderColor= "#D1FAE5", color= "#059669";
        if(value[0]==="-") {
            backgroundColor = "#FEE2E233";
            borderColor = "#FEE2E2";
            color = "#DC2626";
        }
        return { backgroundColor, borderColor, color};
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "Pending" || value==="Created") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "Rejected" || value === "Deleted" || value === "Failed") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        if (value === "Logged In") {
            changeBackground = "#DBEAFE";
            changeColor = "#1D4ED8";
        }
        if (value === "Logged Out") {
            changeBackground = "#F1EFFF";
            changeColor = "#6C5CCA";
        }
        return {
            background: changeBackground,
            color: changeColor
        };
    };

    handleChartCountSelect = (value: string) => {
        this.setState({selChartCount: value});
    };

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target;
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>);
    };
    
    // Customizable Area End
}
