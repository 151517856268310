


import React from "react";
// Customizable Area Start
import SearchIcon from "@material-ui/icons/Search";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@material-ui/core";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProductDescriptionController, {
  configJSON
} from "./ProductDescriptionController";
import { StyleRules, styled } from "@material-ui/core/styles";
import CustomTab from "../../../../packages/components/src/design-system/ButtonTabs.web";
import AppBackground from "../../../../packages/components/src/design-system/BackgroundColor/AppBackground";
import CustomBox from "../../../../packages/components/src/design-system/Card/FilterCard";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
const deviceBasedDynamicDimension = MergeEngineUtilities.deviceBasedDynamicDimension;
const languageTranslation = require('../../languagesupport/src/LanguageData/Services.json')
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { image_banner, viewcircle, group,Path } from './assets'

// Customizable Area End

// Customizable Area Start
const mockData = {
  debtSizes: ['Under $10,000', '$10,000 - $50,000', '$50,000 - $100,000', 'Over $100,000'],
  debtCategories: ['Credit Card Debt', 'Medical Debt', 'Auto Loans', 'Personal Loans', 'Business Loans'],
  debtAgeRanges: ['Under 30 days', '30-60 days', '60-90 days', 'Over 90 days'],
  debtOwnerTypes: ['Individual', 'Bank', 'Agency'],
};
const CustomCheckbox = withStyles({
  root: {
    '& .MuiSvgIcon-root': {
      color: '#64748B',
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: '#1D4ED8',
      },
    },
  },
})(Checkbox);

// Customizable Area End


class ServiceType extends ProductDescriptionController {
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { currentLanguage } = this.state;
    const { expanded } = this.state;
    const languageData = languageTranslation[currentLanguage];


    return (
      <AppBackground variant="white">
        {this.state.serviceData[0] &&

          <ImageTextCard
            image={image_banner}
            heading={<HeadingFont>
              <RandomClassTypography className="RandomClass" variant="h4">
                {configJSON.marketListingHead1}
                <StyledBoxD
                  component="span"
                >
                  {configJSON.marketListingHead2}
                </StyledBoxD>
                {configJSON.marketListingHead3}
              </RandomClassTypography >
              <RandomClassTypography className="RandomClass" variant="h4">
                {configJSON.marketListingHead4}
              </RandomClassTypography>
              {configJSON.marketListingHead5}
            </HeadingFont>}
            description={configJSON.marketListingDescription}
            darkTheme={false}
            alignLeft={false}
          />
        }

        <Container style={{ backgroundColor: 'white', marginTop: '-50px' }}>
          <Grid className={classes.filterGrid} container spacing={8}>
            <Grid item xs={12} sm={12} md={9} lg={9} style={{ maxWidth: "100%" }}>
              <TextField
                className={classes.filterInput}
                variant="outlined"
                fullWidth
                placeholder="Search for listings..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              <Box style={{ borderBlockEnd: '1px solid #CBD5E1' }}>
                <CustomBox >
                  <CustomTab data-test-id='custom-tabs' data={languageData.ServiceTabData} activeBtn={this.state.activeBtn} handleTab={this.handleTab} />
                </CustomBox>
              </Box>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3} lg={3} className={classes.formFilters}>
                <Box className={classes.filterContainer}>
                  <Typography className={classes.filterTitle}>Filters:</Typography>
                  <Accordion className={classes.accordion} expanded={expanded.debtSize} onChange={this.handleAccordionChange('debtSize')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.accordionTitle}>Debt Size</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {mockData.debtSizes.map((size) => (
                          <FormControlLabel
                            key={size}
                            control={<CustomCheckbox />}
                            label={size}
                            className={classes.formControlLabel}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion} expanded={expanded.debtCategory} onChange={this.handleAccordionChange('debtCategory')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.accordionTitle}>Debt Category</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {mockData.debtCategories.map((category) => (
                          <FormControlLabel
                            key={category}
                            control={<CustomCheckbox />}
                            label={category}
                            className={classes.formControlLabel}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion} expanded={expanded.debtAgeRange} onChange={this.handleAccordionChange('debtAgeRange')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.accordionTitle}>Debt Age Range</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {mockData.debtAgeRanges.map((range) => (
                          <FormControlLabel
                            key={range}
                            control={<CustomCheckbox />}
                            label={range}
                            className={classes.formControlLabel}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion} expanded={expanded.debtOwnerType} onChange={this.handleAccordionChange('debtOwnerType')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.accordionTitle}>Debt Owner Type</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {mockData.debtOwnerTypes.map((type) => (
                          <FormControlLabel
                            key={type}
                            control={<CustomCheckbox />}
                            label={type}
                            className={classes.formControlLabel}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Button className={classes.applyButton}>
                    Apply
                  </Button>
                </Box>
              </Grid>
              {(this.state.activeBtn === "Listings" || this.state.activeBtn === "شركات") &&
                <ServiceBoxContainer item xs={12} sm={12} md={9} lg={9}>
                  <Grid container spacing={3}>
                    {this.state.serviceListData.slice(0, 5).map((card, index) => (

                      <Grid key={index} item xs={12} sm={12} md={12}>
                        <Box className={classes.companyBox} onClick={() => this.props.navigation.navigate('ProductDescription')}>
                          <Box>
                            <Typography className={classes.companyName}>{configJSON.companyName}</Typography>
                            <Box className={classes.companyContainer} >
                              <Typography className={classes.companyActive}>
                                {configJSON.activeCollection}
                              </Typography>
                              <Typography className={classes.companyPost}>{configJSON.postTime}</Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box className={classes.companyInfo}>
                            <Box className={classes.companyInfoContainer}>
                              <Box>
                                <MonetizationOnOutlinedIcon />
                              </Box>
                              <Box className={classes.companyValueContainer}>
                                <Typography className={classes.companyInfoHead}>{configJSON.companyValue}</Typography>
                                <Typography className={classes.companyInfoDescription} >{configJSON.companyPortfolio}</Typography>
                              </Box>
                            </Box>

                            <Box className={classes.companyInfoContainer}>
                              <Box>
                                <img style={{ height: "18.67px", width: "18.67px" }}
                                  src={Path}
                                  alt="icon"
                                />
                              </Box>
                              <Box className={classes.companyValueContainer}>
                                <Typography className={classes.companyInfoHead} >{configJSON.companyUser}</Typography>
                                <Typography className={classes.companyInfoDescription}  >{configJSON.companyAccount}</Typography>
                              </Box>
                            </Box>

                            <Box className={classes.companyInfoContainer}>
                              <Box>
                                <img
                                  style={{ height: "18.85px", width: "18.84px" }}
                                  src={group}
                                  alt="icon"
                                />
                              </Box>
                              <Box className={classes.companyValueContainer}>
                                <Typography className={classes.companyInfoHead}>{configJSON.averageBalance}</Typography>
                                <Typography className={classes.companyInfoDescription} >{configJSON.averageAcoountBalance}</Typography>
                              </Box>
                            </Box>

                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </ServiceBoxContainer>
              }
              {(this.state.activeBtn === 'Buyer profiles' || this.state.activeBtn === "عروض") &&
                <ServiceBoxContainer item xs={12} sm={12} md={9} lg={9}>
                  <Grid container spacing={3}>
                    {this.state.serviceListData.slice(0, 5).map((card, index) => (
                      <Grid key={index} item xs={12} sm={12} md={12}>

                        <Box className={classes.companyBox} onClick={() => this.props.navigation.navigate('ListingSummary')}>
                          <Box>
                            <Box className={classes.header}>
                              <Box>
                                <img src={viewcircle} />
                              </Box>
                              <Box>
                                <Typography className={classes.title}>
                                  Apex Financial Solutions
                                </Typography>
                                <Typography style={{textDecoration:"underline",fontSize:"14px",fontWeight:400,color:"#334155"}}> www.apexfinancialsolutions.com</Typography>
                              </Box>
                            </Box>
                            <Divider className={classes.divider} />
                            <Box className={classes.services}>
                              <Typography className={classes.servicesTitle}>
                                Services offered
                              </Typography>
                              <Box className={classes.chipContainer}>
                                <Chip label="Financial Consultation" className={classes.chip} />
                                <Chip label="Debt Collection Services" className={classes.chip} />
                                <Chip label="Business Valuation" className={classes.chip} />
                                <Chip label="Risk Management" className={classes.chip} />
                                <Chip label="Market Research and Analysis" className={classes.chip} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </ServiceBoxContainer>
              }
            </Grid>
          </Grid>
        </Container>
      </AppBackground>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ServiceBoxContainer = styled(Grid)({
  marginBottom:"60px",
  "& .MuiGrid-spacing-xs-3 > .MuiGrid-item": {
    padding: deviceBasedDynamicDimension(16, true, 1)
  }
})
const HeadingFont = styled(Box)({
  fontSize: "36px",
  '@media(max-width:767px)': {
    fontSize: '24px',
    whiteSpace: 'normal'
  }
})

const RandomClassTypography = styled(Typography)({
  '&.RandomClass': {
    fontSize: '36px',
    marginTop: '0px',
    marginBottom: '2px',
    '@media(max-width:767px)': {
      fontSize: '24px',
      whiteSpace: 'normal'
    }
  }
});
const StyledBoxD = styled(Box)({
  display: 'inline-block',
  alignContent: 'center',
  backgroundColor: '#DBEAFE',
  fontWeight: 600,
  color: '#1D4ED8',
  borderRadius: '16px',
  marginLeft: '8px',
  marginRight: '8px',
  fontSize: '36px',
  '@media(max-width:767px)': {
    fontSize: '24px',
    whiteSpace: 'normal'
  }
});

const useStyles: StyleRules = {
  companyBox: {
    padding: '40px',
    borderRadius: '24px',
    boxShadow: '0px 4px 4px 2px #0000000F, 0 6px 20px 0 #0000000F'
  },

  companyContainer: {
    display: 'flex',
    padding: "20px 0px",
    gap: '24px',
    alignItems: 'center'
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 700
  },
  companyPost: {
    fontSize: '12px',
    color: '#6B7280'
  },

  companyActive: {
    fontSize: '12px',
    backgroundColor: '#D1FAE5',
    color: '#059669',
    borderRadius: '12px',
    padding: '4px 12px',
    textTransform: 'uppercase'
  },

  companyInfo: {
    display: "flex",
    gap: '72px',
    paddingTop: '40px'
  },
  companyInfoContainer: {
    display: 'flex',
    gap: '12px'
  },
  companyInfoHead: {
    color: '#0F172A',
    fontSize: '18px',
    fontWeight: 600
  },
  companyInfoDescription: {
    color: '#334155',
    fontSize: '16px',
    fontWeight: 400
  },
  companyInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  companyValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },

  serviceBox: {
    height: "418px",
    backgroundColor: "white",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
    marginRight: '10rem',
    "@media (max-width:380px)": {
      height: "480px",
    },
  },
  ServiceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: '545px',
    marginTop: '2rem'
  },
  serviceDescBox: {
    width: "545px",
    fontSize: "18px",
    "@media (max-width:768px)": {
      fontSize: "14px",
      width: "100%",
    },
  },
  ServiceBtn: {
    width: "315px",
    height: "44px",
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    marginBottom: "45px",
    textTransform: "capitalize",
    "@media (max-width:520px)": {
      width: "250px",
      height: "44px",
    },
  },
  filterGrid: {
    marginTop: "80px",
    marginBottom: "20px",
    "@media (max-width:960px)": {
      marginTop: "5px",
    },
  },
  filterCatagoryInput: {
    "& .MuiInputBase-input": {},
    "& .MuiOutlinedInput-root": {
      height: "44px",
    },
  },
  filterInput: {
    marginBottom: '18px',
    width: ' 1220px',
    padding: '10px 8px 10px 8px',
    border: '1px',
    borderRadius: '8px',

    "& .MuiInputBase-input": {},
    "& .MuiOutlinedInput-root": {
      height: "44px",
    },
    "@media (max-width:580px)": {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  filterResultTxt: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
  },
  CatagoryWraper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  serviceCardWrapper: {
    display: " flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: '272px',
    width: "100%",
    borderRadius: "10px",
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  serviceImgWrapper: {
    width: "100%",
    height: "240px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    position: "relative",
  },
  serviceImg: {
    height: "80%",
    width: "80%",
    objectFit: "cover"
  },
  cardBox: {
    display: "flex",
    width: "100%",
    position: "absolute",
    bottom: "12px",
    left: "14px"
  },
  searchIcon: {
    color: "94A3B8"
  },
  filterContainer: {
    marginLeft: "50px"
  },
  filterTitle: {
    fontSize: "20px",
    fontWeight: 600
  },
  formFilters: {
    marginLeft: "-13px"
  },
  accordion: {
    border: "none",
    boxShadow: "none"
  },
  accordionTitle: {
    marginLeft: '-16px',
    fontSize: "16px",
    fontWeight: "bold",
    color: "#0F172A",

  },
  formControlLabel: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: '-28px',
  },
  applyButton: {
    marginTop: 2,
    fontStyle: "inter",
    textTransform: "none",
    width: "248px",
    height: "44px",
    borderRadius: "8px",
    color: "#FFFFFF",
    backgroundColor: "#1E3A8A",
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: '-12px',
    '&:hover': {
      backgroundColor: '#1E3A8A',
      boxShadow: 'none',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: '24px',
    boxShadow: '0px 4px 4px 2px #0000000F, 0 6px 20px 0 #0000000F'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'flex-start',
    padding: '30px',
    width: '895px',
    background: 'white',
    borderRadius: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  header: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#0F172A',
  },
  link: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#334155',
  },
  divider: {
    width: '100%',
    backgroundColor: '#E2E8F0',
  },
  services: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '40px',
  },
  servicesTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  chip: {
    padding: '8px 16px 8px 16px',
    backgroundColor: '#E2E8F0',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#334155',
  },
  pagination: {
    marginTop: '50px',
    display: 'flex',
    // justifyContent: 'right',
    marginLeft: "400px"
  }

};
// Customizable Area End
export default withStyles(useStyles)(ServiceType);
export { ServiceType };
