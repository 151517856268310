
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Box,
  styled,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  List,
  Paper
} from "@material-ui/core";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";
import { Add } from "@material-ui/icons";
const configJSON = require("./config");
import { image1, image2, image3, image4, image5, image6, image7 } from "./assets";
import DiscoverCard from "../../../components/src/design-system/Card/DiscoverCard";

// Customizable Area End
import DebtBuyerController, { Props } from './DebtBuyerController.web'
import Loader from "../../../components/src/Loader.web";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

type TextAlign = "left" | "right" | "center" | "justify";

const headingParts = configJSON.debtByuercard1head.split('Comprehensive Guide');
// Customizable Area End
export default class DebtBuyer extends DebtBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { currentLanguage, loading } = this.state;
    const landingSection = this.state.userDataList[0];
    const secondSection = this.state.userDataList[1];
    const thirdSection = this.state.userDataList[2];
    const fourthSection = this.state.userDataList[3];
    const isEnglish = currentLanguage === 'en';
    const descriptionKey = isEnglish ? 'description' : "arabic_description";
    const titleKey = isEnglish ? "title" : "arabic_title"
    return (
      // Customizable Area Start





      <ThemeProvider theme={theme}>
        <Loader loading={loading} />


        <Container style={{ padding: '40px 0px', maxWidth: '100%' }}>
          <StyledSecondaryWrapper>

            <ImageTextCard
              image={image2}
              heading={<HeadingFont>
                {configJSON.debtBuyercardSubHead}

                <RandomClassTypography className="RandomClass" variant="h4">
                  {configJSON.debtBuyercardSubHead1}
                  <StyledBoxD
                    component="span"

                  >
                    {configJSON.debtBuyercardSubHead2}
                  </StyledBoxD>
                </RandomClassTypography >
                <RandomClassTypography className="RandomClass" variant="h4">
                  {configJSON.debtBuyercardSubHead3}
                </RandomClassTypography>
              </HeadingFont>}



              description={configJSON.debtByuercard1des}
              darkTheme={false}
              alignLeft={false}
            />





            <ImageTextCard
              image={image7}
              layer={configJSON.debtByuercard2layer}
              heading={configJSON.debtByuercard2head}
              description={configJSON.debtByuercard2des}
              darkTheme={false}
              alignLeft={true}
            />

          </StyledSecondaryWrapper>
        </Container>

        <StyledBox>
          <StyledContainerB>
            <StyledTitle>
              {configJSON.dbdtcarshead}
            </StyledTitle>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3}>
                  <PaperTitle gutterBottom>
                    {configJSON.dbdtcard1head}
                  </PaperTitle>
                  <PaperText>
                    {configJSON.dbdtcard1des}
                  </PaperText>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3}>
                  <PaperTitle gutterBottom>
                    {configJSON.dbdtcard2head}
                  </PaperTitle>
                  <PaperText>
                    {configJSON.dbdtcard2des}
                  </PaperText>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3}>
                  <PaperTitle gutterBottom>
                    {configJSON.dbdtcard3head}
                  </PaperTitle>
                  <PaperText>
                    {configJSON.dbdtcard3des}
                  </PaperText>
                </StyledPaper>
              </Grid>
            </Grid>
          </StyledContainerB>
        </StyledBox>

        <Container style={{ padding: '0px 0px 40px 0px', maxWidth: '100%' }}>
          <StyledSecondaryWrapper>
            <ImageTextCard
              image={image4}
              layer={configJSON.debtByuercard3layer}
              heading={configJSON.debtByuercard3head}
              description={configJSON.debtByuercard3des}
              subDescription={configJSON.debtByuercard3subdes}
              darkTheme={false}
              alignLeft={true}
            />
            <ImageTextCard
              image={image6}
              layer={configJSON.debtByuercard4layer}
              heading={configJSON.debtByuercard4head}
              description={configJSON.debtByuercard4des}
              subDescription={configJSON.debtByuercard4subdes}
              darkTheme={false}
              alignLeft={false}
            />
          </StyledSecondaryWrapper>
        </Container>


        <Container style={{ maxWidth: '100%' }}>
          <StyledSecondaryWrapper>
            <ImageTextCard
              image={image3}
              layer={configJSON.debtByuercard5layer}
              heading={configJSON.debtByuercard5head}
              description={configJSON.debtByuercard5des}
              subDescription={configJSON.debtByuercard5subdes}
              darkTheme={false}
              alignLeft={true}
            />
            <ImageTextCard
              image={image1}
              layer={configJSON.debtByuercard6layer}
              heading={configJSON.debtByuercard6head}
              description={configJSON.debtByuercard6des}
              subDescription={configJSON.debtByuercard6subdes}
              darkTheme={false}
              alignLeft={false}
            />
          </StyledSecondaryWrapper>
        </Container>


        <Container style={{ maxWidth: '100%' }}>
          <StyledSecondaryWrapper>
            <ImageTextCard
              image={image5}
              layer={configJSON.debtByuercard7layer}
              heading={configJSON.debtByuercard7head}
              description={configJSON.debtByuercard7des}
              subDescription={configJSON.debtByuercard7subdes}
              darkTheme={false}
              alignLeft={true}
            />

          </StyledSecondaryWrapper>
        </Container>



        <MainWrapper>
          <StyledContainer maxWidth="md">
            <Box mb={4}>
              <BlueTypography variant="body1">{configJSON.faqsubmit}</BlueTypography>
              <Typography variant="h4" align="center" className="faqHeader">
                {configJSON.faqhead}
              </Typography>
              <Typography variant="body1" align="center" className="faqsubHeader">
                {configJSON.debtBuyerfaqsubHead}
              </Typography>
            </Box>
            <StyledList>
              <StyledAccordion>
                <AccordionSummary expandIcon={<Add />}>
                  <Typography className="question">{configJSON.debtBuyerfaqQue1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="answer">{configJSON.debtBuyerfaqAns1}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            </StyledList>

            <StyledList>
              <StyledAccordion>
                <AccordionSummary expandIcon={<Add />}>
                  <Typography className="question">{configJSON.debtBuyerfaqQue2}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="answer">{configJSON.debtBuyerfaqAns2}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            </StyledList>

            <StyledList>
              <StyledAccordion>
                <AccordionSummary expandIcon={<Add />}>
                  <Typography className="question">{configJSON.debtBuyerfaqQue3}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="answer">{configJSON.debtBuyerfaqAns3}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            </StyledList>

            <StyledList>
              <StyledAccordion>
                <AccordionSummary expandIcon={<Add />}>
                  <Typography className="question">{configJSON.debtBuyerfaqQue4}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="answer">{configJSON.debtBuyerfaqAns4}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            </StyledList>

            <StyledList>
              <StyledAccordion>
                <AccordionSummary expandIcon={<Add />}>
                  <Typography className="question">{configJSON.debtBuyerfaqQue5}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="answer">{configJSON.debtBuyerfaqAns5}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            </StyledList>

            <StyledList>
              <StyledAccordion>
                <AccordionSummary expandIcon={<Add />}>
                  <Typography className="question">{configJSON.debtBuyerfaqQue6}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="answer">{configJSON.debtBuyerfaqAns6}</Typography>
                </AccordionDetails>
              </StyledAccordion>
            </StyledList>
          </StyledContainer>
        </MainWrapper>

      <DiscoverCard/>
      </ThemeProvider>

      // Customizable Area End

    );
  }
}
// Customizable Area Start

const StyledContainer = styled(Container)(({ theme }) => ({
  "& .faqHeader": {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    marginBottom: "20px",
  },
  "& .faqsubHeader": {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    marginBottom: "20px",
  },
}));

const BlueTypography = styled(Typography)({
  color: '#2563EB',
  textAlign: "center",

});

const StyledBoxD = styled(Box)({
  display: 'inline-block',
  backgroundColor: '#DBEAFE',
  fontWeight: 'bold',
  color: '#1D4ED8',
  borderRadius: '16px',
  padding: '6px',
  marginLeft: '8px',
  fontSize: '30px',
  '@media(max-width:767px)': {
    fontSize: '24px',
    whiteSpace : 'normal'
  }
});

const HeadingFont = styled(Box)({
  fontSize : "36px",
  '@media(max-width:767px)': {
    fontSize: '24px',
    whiteSpace : 'normal'
  }
})

const RandomClassTypography = styled(Typography)({
  '&.RandomClass': {
    fontSize: '36px',
    marginTop: '0px',
    marginBottom: '0px',
    '@media(max-width:767px)': {
      fontSize: '24px',
      whiteSpace : 'normal'
    }
  }
});

const StyledList = styled(List)(({ theme }) => ({
  "& .MuiAccordion-root": {
    borderRadius: "8px",
    marginBottom: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    "&:before": {
      display: "none",
    },
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F8FAFC",
    borderRadius: "8px",
    "&.Mui-expanded": {
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
  },
  "& .question": {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "28px",
    paragraph: '12px',
  },
  "& .answer": {
    color: "#161616",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    paragraph: '8px',
  },
}));

const StyledBox = styled(Box)({
  backgroundColor: '#EFF6FF',
});

const StyledContainerB = styled(Container)({
  padding: '80px 120px',
  display: 'flex',
  flexDirection: 'column',
  gap: '44px',
  '@media(max-width:991px)': {
    padding: '40px'
  },
  '@media(max-width:576px)': {
    padding: "40px 20px",
  }
});

const StyledTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '30px',
  textAlign: 'center',
  '@media(max-width:991px)': {
    fontSize: '24px',
  },
  '@media(max-width:576px)': {
    fontSize: '24px',

  }
});

const StyledPaper = styled(Paper)({
  padding: '40px',
  borderRadius: '24px',
  textAlign: 'center',
});

const PaperTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '20px',
  LineHeight: '32px',
  Letter: '-0.5%',
  whiteSpace: 'nowrap',

});

const PaperText = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: '#475569',
});


const MainWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#F8FAFC",
  padding: "80px 120px 80px 120px",
  '@media(max-width:991px)': {
    padding: '40px'
  },
  '@media(max-width:576px)': {
    padding: "40px 20px",
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: "1px solid #E5E7EB",
  "&:not(:last-child)": {
    borderBottom: "none",
  },
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    minHeight: "56px",
    "&.Mui-expanded": {
      minHeight: "56px",
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
    "&.Mui-expanded": {
      margin: "12px 0",
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: "16px",
  },
}));



const webStyle = {
  sectionOne: {
    display: "flex",
  },
  sectionContain: {
    justifyContent: "center",
    maxWidth: "none",
    background: "#334155",
  },
  imageOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  button: {
    marginTop: "20px",
    width: "max-content",
  },

  section1: {
    height: "100hv",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "none",
    background: "#0F172A",
    minHeight: "548px",
  },
  imageCenter: {
    width: '70vw',
    maxWidth: "488px",
    textAlign: "center" as TextAlign,
  },
  section2: {
    marginTop: "67px",
    marginBottom: "64px",
    justifyContent: "center",
    maxWidth: "none",
    background: "#f0f4f8",
  },
  section3: {
    padding: 0,
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  section4: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  firstRow: {
    maxWidth: "1200px",
    margin: "0px auto",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "64px 0"
  },
  cards: {
    width: '100%',
    background: '#EFF6FF',
    padding: '80px 120px 80px 120px',

  },
  cardHead: {
    textAlign: 'center',

  },
};





const StyledGrid = styled(Grid)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0px auto",
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: "64px 0 ",
  '@media(max-width:1200px)': {
    padding: "64px",
  },
  '@media(max-width:991px)': {
    flexDirection: 'column-reverse'
  },
  '@media(max-width:576px)': {
    padding: "64px 24px",
  }
}))

const StyledMainTitle = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontSize: "46px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "64.205px",
  '@media(max-width:991px)': {
    fontSize: "34px",
    lineHeight: '48px',
    marginTop: '50px'
  },
  '@media(max-width:576px)': {
    fontSize: "24px",
    lineHeight: '38px'
  }
}))

const StyledMainSubTitle = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "30.5px",
  padding: "10px 0 36px 0",
  '@media(max-width:576px)': {
    lineHeight: '22px'
  }
}))

const StyledSecondaryWrapper = styled(Box)(({ theme }) => ({
  padding: " 0 ",
  '@media(max-width:1200px)': {
    padding: "0 ",
    
  },
  '@media(max-width:991px)': {
    flexDirection: 'column-reverse'
  },
  '@media(max-width:576px)': {
    padding: "64px 0px",
  }
}))

const ShareDialogBox = styled(Box)(({ theme }) => ({
  '& .contatiner': {
    height: "100hv",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "none",
    minHeight: "911px",
    padding: "64px 0px 0px 0px",
    "@media (max-width: 1260px)": {
      padding: "60px 0px 40px 0px",
    },
    "@media (max-width: 767px)": {
      padding: "40px 0px 40px 0px",
    },
  },
  '& .typo': {
    color: "#E2E8F0",
    textAlign: "center",
    lineHeight: "39px",
    fontWeight: "600",
    fontStyle: "noraml",
    fontSize: "32px",
    "@media (max-width: 767px)": {
      fontSize: "24px",
    },
  },
  '& .subheading': {
    color: "#E2E8F0",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    marginTop: "30px",

   },
  '&.card .MuiCardContent-root': {
    borderRadius: "20px",
    border: "#FFF",
    background: "#1E293B",
    width: "227px",
    height: "204px",
  },
  '& .landingCard': {
    borderColor: "#FFF",
    background: "#1E293B",
    width: "227px",
    height: "204px",
    border: "solid 3px white",
    borderRadius: "15px"
  },
  '& .landingCard1': {
    borderRadius: "20px",
    border: "#FFF",
    background: "#E2E8F0E",
    width: "227px",
    height: "192px",
  },
  '& .cardHead': {
    color: " #F8FAFC",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
  '& .cardSubhead': {
    color: "#F8FAFC",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    marginTop: "10px",

  },
  '& .scardHead': {
    color: " #334155",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },

  '& .gridMargintop': {
    marginTop: "40px",
  },
  '& .cardMargintop': {
    marginTop: "80px",
  },
  '& .scardSubhead': {
    color: "#334155",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    marginTop: "10px",
  }
}))
// Customizable Area End
