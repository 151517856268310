import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import RequestFormController, { Props } from "./RequestFormController.web"
import { Box, Container, FormControl, FormHelperText, Select, Grid, MenuItem, OutlinedInput, IconButton, Button } from "@material-ui/core";
import ButtonCustom from "../../../components/src/design-system/Button/Button";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dashboard from "./Dashboard.web";
import { AdminNavbar } from "./AdminNavbar.web";
// Customizable Area End


const styles: StyleRules = {
    // Customizable Area Start
    labelText: {
        fontWeight: 700,
        fontSize: "12px",
        margin: "0px",
        color: "#fff",
    },
    container: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    mainBoxConatiner: {
        backgroundColor: "#1E293B",
        width: "75%",
        padding: "50px 80px",
        borderRadius: "20px",
        marginBottom: "32px"
    },
    lableTextBox: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: "poppins",
        color: "#fff",
        position: "relative",
        bottom: "7px"
    },
    gridMainContainer: {
        display: "flex",
        gap: "20px",
        alignItems: "flex-end"
    },
    formControllerBox: {
        margin: "12px 0px",
        width: '100%'
    },
    reqireText: {
        color: "red"
    },
    inputFileBox : {
        display : "flex",
        alignItems : "center",
        gap : "16px",
        width : "100%",
        padding : "16px 12px 16px 16px",
        borderRadius : "8px",
        backgroundColor : "#FFF",
        boxShadow : "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        boxSizing : "border-box",
        marginBottom : "24px"
    },
    inputFileIconBox : {
        boxSizing : "border-box",
        padding : "16px",
        display : "flex",
        alignItems : "center",
        justifyConent : "center",
        backgroundColor : "#C2D5FF",
        borderRadius : "8px"
    },
    inputFileIcon : {
        boxSizing : "border-box",
        width : "24px",
        height : "24px"
    },
    inputNoFileButton : {
        flex : 1
    },
    inputTextFiled: {
        height: "44px",
        borderRadius: "8px",
        border: "1px solid var(--neutrals-cool-gray-300, #CBD5E1)",
        background: "var(--basic-white, #FFF)",
        width: "100%",
        fontFamily: "Inter"
    },
    documnetIcon: {
        justifyContent: "center",
        marginLeft: "16px",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#C2D5FF"
    },
    documentMainBox: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "16px 0",
        borderRadius: "8px",
        gap: "16px",
        marginTop: "5px",
        width: "100%"
    },
    inputBorderTextDrag: {
        backgroundColor: "white",
        height: "136px",
        borderRadius: "8px",
        marginTop: "10px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: "10px",
        width: "100%",
        overflowWrap: "break-word"

    },
    optionBox: {
        fontSize: "12px", 
        fontWeight: 700, 
        color: "white",
         margin: "0px 0px 4px 0px" 
    }
    // Customizable Area End
}


export class ServiceInternalForm extends RequestFormController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
  
    renderInputField = () => {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.mainBoxConatiner}>
                    <span className={classes.lableTextBox}>Debtor's Contact Information:</span>
                    <Grid className={classes.gridMainContainer} >
                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="FullName-helper-text"
                                    className={classes.labelText}
                                >
                                    Full Name
                                    <span className={classes.reqireText}>*</span>
                                </FormHelperText>
                                <OutlinedInput
                                    id="FullName-input"
                                    aria-describedby="FullName-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.firstName}
                                    onChange={(event) => this.firstNameInputProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.full_name}
                                />
                                {this.state.errors.full_name && <FormHelperText error>{this.state.errors.full_name}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="Address-helper-text"
                                    className={classes.labelText}
                                >
                                    Address

                                </FormHelperText>
                                <OutlinedInput
                                    id="Address-input"
                                    aria-describedby="Address-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.address}
                                    onChange={(event) => this.lastAddressProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.Address}

                                />
                                {this.state.errors.Address && <FormHelperText error>{this.state.errors.Address}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid className={classes.gridMainContainer} >
                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="PhoneNumber-helper-text"
                                    className={classes.labelText}
                                >
                                    Phone Number
                                    <span className={classes.reqireText}>*</span>
                                </FormHelperText>
                                <OutlinedInput
                                    id="PhoneNumber-input"
                                    aria-describedby="PhoneNumber-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.phone}
                                    onChange={(event) => this.phoneProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.phone_number}
                                />
                                {this.state.errors.phone_number && <FormHelperText error>{this.state.errors.phone_number}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid xs={6} item>
                            <FormControl variant="outlined" className={classes.formControllerBox}>
                                <FormHelperText
                                    id="Email-helper-text"
                                    className={classes.labelText}
                                >
                                    Email Address
                                    <span className={classes.reqireText}>*</span>
                                </FormHelperText>
                                <OutlinedInput
                                    id="Email-input"
                                    aria-describedby="Email-helper-text"
                                    labelWidth={0}
                                    className={classes.inputTextFiled}
                                    value={this.state.email}
                                    onChange={(event) => this.emailProps.onChangeText(event.target.value)}
                                    error={!!this.state.errors.email}
                                />
                                {this.state.errors.email && <FormHelperText error>{this.state.errors.email}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
            </>
        )
    }

    renderFileUploadOthersPart = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid className={classes.gridMainContainer} >
                    <Grid item xs={6} >
                        <FormControl fullWidth variant="outlined">
                            <FormHelperText className={classes.optionBox}>Tax Registration</FormHelperText>
                            {this.state.taxRegistration ? (
                                <Box className={classes.inputBorderTextDrag}>
                                    {this.state.taxRegistration?.name}
                                </Box>
                            ) : (
                                <label htmlFor="uploadInput2">
                                    <Box className={classes.inputFileBox}>
                                        <Box className={classes.inputFileIconBox}>
                                            <Box className={classes.inputFileIcon}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 11.01L3 11V13H21V11.01ZM3 16H15V18H3V16ZM21 6H3V8.01L21 8V6Z" fill="#407BFF" />
                                                </svg>
                                            </Box>
                                        </Box>
                                        <input
                                            id="uploadInput2"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={this.handleTaxChange}
                                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                                        />
                                        <Box className={classes.inputNoFileButton}>
                                            <Button
                                                component="label"
                                                variant="text"
                                                htmlFor="uploadInput2"
                                            >
                                                No File
                                            </Button>
                                        </Box>
                                        <IconButton
                                            aria-label="more"
                                            aria-haspopup="true"
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                </label>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <FormHelperText className={classes.optionBox}>Other</FormHelperText>
                            {this.state.otherDocument ? (
                                <Box className={classes.inputBorderTextDrag}>
                                    {this.state.otherDocument?.name}
                                </Box>
                            ) : (
                                <label htmlFor="uploadInput3">
                                    <Box className={classes.inputFileBox}>
                                        <Box className={classes.inputFileIconBox}>
                                            <Box className={classes.inputFileIcon}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 11.01L3 11V13H21V11.01ZM3 16H15V18H3V16ZM21 6H3V8.01L21 8V6Z" fill="#407BFF" />
                                                </svg>
                                            </Box>
                                        </Box>
                                        <input
                                            id="uploadInput3"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={this.handleOtherDocumentChange}
                                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                                        />
                                        <Box className={classes.inputNoFileButton}>
                                            <Button
                                                component="label"
                                                variant="text"
                                                htmlFor="uploadInput3"
                                            >
                                                No File
                                            </Button>
                                        </Box>
                                        <IconButton
                                            aria-label="more"
                                            aria-haspopup="true"
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                </label>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </>
        )
    }

    renderFileUploadPart = () => {
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.mainBoxConatiner}>
                    <span className={classes.lableTextBox}>Upload Documents</span>
                    <Grid className={classes.gridMainContainer} >
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <FormHelperText className={classes.optionBox}>Business Registration</FormHelperText>
                                {this.state.selectedFile ? (
                                    <Box className={classes.inputBorderTextDrag}>
                                        {this.state.selectedFile?.name}
                                    </Box>
                                ) : (
                                    <label htmlFor="uploadInput">
                                        <Box className={classes.inputFileBox}>
                                            <Box className={classes.inputFileIconBox}>
                                                <Box className={classes.inputFileIcon}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21 11.01L3 11V13H21V11.01ZM3 16H15V18H3V16ZM21 6H3V8.01L21 8V6Z" fill="#407BFF" />
                                                    </svg>
                                                </Box>
                                            </Box>
                                            <input
                                                id="uploadInput"
                                                type="file"
                                                style={{ display: 'none' }}
                                                onChange={this.handleFileChange}
                                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                                            />
                                            <Box className={classes.inputNoFileButton}>
                                                <Button
                                                    component="label"
                                                    variant="text"
                                                    htmlFor="uploadInput"
                                                >
                                                    No File
                                                </Button>
                                            </Box>
                                            <IconButton
                                                aria-label="more"
                                                aria-haspopup="true"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Box>
                                    </label>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <FormHelperText className={classes.optionBox}>License or Certification</FormHelperText>

                                {this.state.License ? (
                                    <Box className={classes.inputBorderTextDrag}>
                                        {this.state.License?.name}
                                    </Box>
                                ) : (
                                    <label htmlFor="uploadInput1">
                                        <Box className={classes.inputFileBox}>
                                            <Box className={classes.inputFileIconBox}>
                                                <Box className={classes.inputFileIcon}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21 11.01L3 11V13H21V11.01ZM3 16H15V18H3V16ZM21 6H3V8.01L21 8V6Z" fill="#407BFF" />
                                                    </svg>
                                                </Box>
                                            </Box>
                                            <input
                                                id="uploadInput1"
                                                type="file"
                                                style={{ display: 'none' }}
                                                onChange={this.handleLicenseFileChange}
                                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                                            />
                                            <Box className={classes.inputNoFileButton}>
                                                <Button
                                                    component="label"
                                                    variant="text"
                                                    htmlFor="uploadInput1"
                                                >
                                                    No File
                                                </Button>
                                            </Box>
                                            <IconButton
                                                aria-label="more"
                                                aria-haspopup="true"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Box>
                                    </label>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        this.renderFileUploadOthersPart()
                    }
                </Box>
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props;

        return (
            <>
              <Dashboard id="dashboard" navigation={this.props.navigation}>
              {/* <AdminNavbar id="AdminNavbar" navigation={this.props.navigation} title="Requests" /> */}
                    <Container maxWidth='lg' style={{ display: 'flex', flexDirection: 'column', marginTop: "100px" }}>

                        <Grid className={classes.container} >
                              {this.renderInputField()}
                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>Debt Details:</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Account-helper-text"
                                                className={classes.labelText}
                                            >
                                                Account Number or Reference Number
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Account-input"
                                                aria-describedby="Account-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.accountNumber}
                                                onChange={(event)=>this.accountNumberInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.account_number}
                                            />
                                            {this.state.errors.account_number && <FormHelperText error>{this.state.errors.account_number}</FormHelperText>}                       
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Original-helper-text"
                                                className={classes.labelText}
                                            >
                                                Original Creditor's Name

                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Original-input"
                                                aria-describedby="Original-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.creditorName}
                                                onChange={(event)=>this.creditorNameInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.CreditorName}
                                            />
                                            {this.state.errors.CreditorName && <FormHelperText error>{this.state.errors.CreditorName}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Debt-helper-text"
                                                className={classes.labelText}
                                            >
                                                Current Debt Amount Owed
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Debt-input"
                                                aria-describedby="Debt-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.DebtAmount}
                                                onChange={(event)=>this.DebtAmountInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.current_debt_amount}
                                            />
                                            {this.state.errors.current_debt_amount && <FormHelperText error>{this.state.errors.current_debt_amount}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Email-helper-text"
                                                className={classes.labelText}
                                            >
                                                Date of the Last Payment
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="lastPayment"
                                                aria-describedby="Email-helper-text"
                                                labelWidth={0}
                                                type="date"                                
                                                className={classes.inputTextFiled}
                                                value={this.state.dateOfLastPayment}
                                                onChange={(event)=>this.dateOfLastPaymentInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.last_payment_date}
                                            />
                                            {this.state.errors.last_payment_date && <FormHelperText error>{this.state.errors.last_payment_date}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid className={classes.gridMainContainer}>
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Account-helper-text"
                                                className={classes.labelText}
                                            >
                                                Any Past Payments Made (with dates)
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="pastPayment"
                                                aria-describedby="Account-helper-text"
                                                labelWidth={0}
                                                type="date"
                                                inputProps={{ placeholder: '' }}
                                                className={classes.inputTextFiled}
                                                value={this.state.pastPayment}
                                                onChange={(event)=>this.pastPaymentInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.past_payment_date}
                                            />
                                            {this.state.errors.past_payment_date && <FormHelperText error>{this.state.errors.past_payment_date}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>Financial Information:</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Employment-helper-text"
                                                className={classes.labelText}
                                            >
                                                Employment Status
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Employment-input"
                                                aria-describedby="Employment-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentStatus}
                                                onChange={(event) => this.employmentStatustInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.employment_status}
                                            />
                                             {this.state.errors.employment_status && <FormHelperText error>{this.state.errors.employment_status}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Current-helper-text"
                                                className={classes.labelText}
                                            >
                                                Current Employer's Name
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Current-input"
                                                aria-describedby="Current-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentName}
                                                onChange={(event) => this.employmentNameInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.EmploymentName}
                                            />
                                             {this.state.errors.EmploymentName && <FormHelperText error>{this.state.errors.EmploymentName}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Employe-helper-text"
                                                className={classes.labelText}
                                            >
                                                Current Employer's Contact
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Employe-input"
                                                aria-describedby="Employe-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentContact}
                                                onChange={(event) => this.employmentContactInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.current_employer_phone_number}
                                            />
                                             {this.state.errors.current_employer_phone_number && <FormHelperText error>{this.state.errors.current_employer_phone_number}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Income-helper-text"
                                                className={classes.labelText}
                                            >
                                                Monthly Income
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Income-input"
                                                aria-describedby="Income-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.employmentMonthly}
                                                onChange={(event) => this.employmentMonthlyInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.monthly_income}
                                            />
                                             {this.state.errors.monthly_income && <FormHelperText error>{this.state.errors.monthly_income}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Expenses-helper-text"
                                                className={classes.labelText}
                                            >
                                                Monthly Expenses (e.g., rent/mortgage, utilities, groceries)
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Expenses-input"
                                                aria-describedby="Expenses-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.monthlyExpenses}
                                                onChange={(event) => this.monthlyExpensesInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.monthly_expenses}
                                            />
                                               {this.state.errors.monthly_expenses && <FormHelperText error>{this.state.errors.monthly_expenses}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Outstanding-helper-text"
                                                className={classes.labelText}
                                            >
                                                Other Outstanding Debts
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Outstanding-input"
                                                aria-describedby="Outstanding-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.otherOutstandingDebts}
                                                onChange={(event) => this.otherOutstandingDebtsInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.other_outstandianing_debts}
                                            />
                                         {this.state.errors.other_outstandianing_debts && <FormHelperText error>{this.state.errors.other_outstandianing_debts}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>Banking Information:</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Bank-helper-text"
                                                className={classes.labelText}
                                            >
                                                Bank Account Details (only if necessary for payment arrangements)
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Bank-input"
                                                aria-describedby="Bank-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.bankAccountDetails}
                                                onChange={(event) => this.BankAccountDetailsInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.bank_account_details}
                                            />
                                             {this.state.errors.bank_account_details && <FormHelperText error>{this.state.errors.bank_account_details}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Preferred-helper-text"
                                                className={classes.labelText}
                                            >
                                                Preferred Payment Method
                                            </FormHelperText>
                                            <Select
                                                labelId="Preferred-simple-select-label"
                                                id="Preferred-simple-select"
                                                className={classes.inputTextFiled}
                                                value={this.state.selectPayment}
                                                onChange={this.handlePaymentChange}
                                                placeholder="Net Banking"

                                            >
                                                <MenuItem value={10}>Paytm</MenuItem>
                                                <MenuItem value={20}>PhonePe</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.mainBoxConatiner}>
                                <span className={classes.lableTextBox}>Bankruptcy Information:</span>
                                <Grid className={classes.gridMainContainer} >
                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="debtor-helper-text"
                                                className={classes.labelText}
                                            >
                                                Whether the debtor has filed for bankruptcy
                                                <span className={classes.reqireText}>*</span>
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="debtor-input"
                                                aria-describedby="debtor-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.bankruptcy}
                                                onChange={(event) => this.BankruptcyInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.is_bankruptcy_declared}                          
                                            />
                                             {this.state.errors.is_bankruptcy_declared && <FormHelperText error>{this.state.errors.is_bankruptcy_declared}</FormHelperText>}
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6} item>
                                        <FormControl variant="outlined" className={classes.formControllerBox}>
                                            <FormHelperText
                                                id="Details-helper-text"
                                                className={classes.labelText}
                                            >
                                                Details of any ongoing bankruptcy proceedings
                                            </FormHelperText>
                                            <OutlinedInput
                                                id="Details-input"
                                                aria-describedby="Details-helper-text"
                                                labelWidth={0}
                                                className={classes.inputTextFiled}
                                                value={this.state.bankruptcyDetail}
                                                onChange={(event) => this.BankruptcyDetailInputProps.onChangeText(event.target.value)}
                                                error={!!this.state.errors.BankruptcyDetail}
                                            />
                                        {this.state.errors.BankruptcyDetail && <FormHelperText error>{this.state.errors.BankruptcyDetail}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            {this.renderFileUploadPart()}
                        </Grid>
                    </Container >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ButtonCustom
                  size="sm"
                  themes="dark"
                  onClickHandle={this.handleSubmit}
                  data-testid="signup-btn"
                >
                  Sign Up
                </ButtonCustom>
              </Box>
                </Dashboard>
            </>
        )
        // Customizable Area End
    }
}


export default withStyles(styles)(ServiceInternalForm);