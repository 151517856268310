import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Avatar, Button, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Image1, Image2, Image3, Image4, Image5, Image6, avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, blogImage } from "./assets";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";
import { configJSON } from "./CatalogueController";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: any) {
    let value = item.attributes;
    return (
      <Box sx={webStyle.productBox} key={`product-item-${item.id}`}>
        <Box sx={webStyle.ImgContainer}>
          <img style={webStyle.productImg} src={value.images?.[0].url} />
        </Box>
        <Box sx={webStyle.detailContent}>
          <Typography variant="h6">{value.name}</Typography>
          <Typography variant="h6">{value.price}</Typography>
          <Typography variant="h6">{value.average_rating}</Typography>
        </Box>
      </Box>
    );
  }
  renderBlogCards() {
    const blogCards = [
      { image: Image1, avatar: avatar1, date: configJSON.smallcardDate, heading: configJSON.smallcardHeading, name: configJSON.smallcardName, subHead: configJSON.smallcardSubHead },
      { image: Image2, avatar: avatar2, date: configJSON.smallcardDate1, heading: configJSON.smallcardHeading1, name: configJSON.smallcardName1, subHead: configJSON.smallcardSubHead1 },
      { image: Image3, avatar: avatar3, date: configJSON.smallcardDate2, heading: configJSON.smallcardHeading2, name: configJSON.smallcardName2, subHead: configJSON.smallcardSubHead2 },
      { image: Image4, avatar: avatar4, date: configJSON.smallcardDate3, heading: configJSON.smallcardHeading3, name: configJSON.smallcardName3, subHead: configJSON.smallcardSubHead3 },
      { image: Image5, avatar: avatar5, date: configJSON.smallcardDate4, heading: configJSON.smallcardHeading4, name: configJSON.smallcardName4, subHead: configJSON.smallcardSubHead4 },
      { image: Image6, avatar: avatar6, date: configJSON.smallcardDate5, heading: configJSON.smallcardHeading5, name: configJSON.smallcardName5, subHead: configJSON.smallcardSubHead5 },
    ];

    return blogCards.map((card, index) => (
      <Grid item xs={12} sm={6} md={4} lg={4} key={index} onClick={() => this.props.navigation.navigate('ContentManagement')}>
        <Box sx={webStyle.cardMainBox}>
          <Box style={{ ...webStyle.cardSubBox, backgroundImage: `url(${card.image})` }} />
          <Box sx={webStyle.cardImgText}>
            {configJSON.cardImageText}
          </Box>
          <Box sx={webStyle.smallCard}>
            <Typography style={webStyle.smallCardDate}>
              {card.date}
            </Typography>
            <Typography style={webStyle.smallCardHeading}>
              {card.heading}
            </Typography>
            <Box style={webStyle.smallCardAvt}>
              <Avatar style={webStyle.avtImg} src={card.avatar} />
              <Box>
                <Typography style={webStyle.smallCardName}>
                  {card.name}
                </Typography>
                <Typography style={webStyle.smallCardSubHead}>
                  {card.subHead}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    ));
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Box style={webStyle.mainBox}>
          <ImageTextCard
            image={blogImage}
            heading={<Box sx={webStyle.subBoxFlex}>
              {configJSON.mainHeadinText}
              <Box style={webStyle.mainBoxHeadingText}
                component="span"
              >
                {configJSON.mainHeadinSpanText}
                <Typography style={webStyle.headingHeiglightText}>
                  {configJSON.mainHeadinHeiglightText}
                </Typography>
              </Box>

            </Box>}
            description='Stay Informed, Stay Ahead: Discover the Latest
           Trends,Strategies, and Best 
           Practices in Debt Collection and Management'
            darkTheme={false}
            alignLeft={false}
          />

          <Box style={webStyle.blogNavBox}>
            <Typography style={webStyle.blogNavBtn}>{configJSON.blogListAll}</Typography>
            <Typography style={webStyle.blogNavList}>{configJSON.blogList1}</Typography>
            <Typography style={webStyle.blogNavList}>{configJSON.blogList2}</Typography>
            <Typography style={webStyle.blogNavList}>{configJSON.blogList3}</Typography>
            <Typography style={webStyle.blogNavList}>{configJSON.blogList4}</Typography>
            <Typography style={webStyle.blogNavList}>{configJSON.blogList5}</Typography>

          </Box>
          <Grid container spacing={4}>
          {this.renderBlogCards()}
          {this.renderBlogCards()}

          </Grid>
          <Box style={webStyle.moreButtonBox}>
            <MoreButton>
              {configJSON.moreButtonText}
            </MoreButton>
          </Box>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MoreButton = styled(Button)({
  padding: '10px 16px 10px 16px',
  backgroundColor: '#1E3A8A',
  fontSize: 14,
  fontWeight: 700,
  color: '#FFFFFF',
  textTransform: 'none'
})
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  mainBox: {
    padding: '80px 120px'
  },
  subBoxFlex: {
    display: 'flex',
    flexDirection: 'column'
  },
  mainBoxHeadingText: {
    display: 'inline-block',
    fontWeight: 'bold',
    borderRadius: '16px',
    padding: '6px',
    marginLeft: '-10px',
    fontSize: '30px',
    marginBottom: ''
  },
  headingHeiglightText: {
    display: 'inline-block',
    backgroundColor: '#DBEAFE',
    fontWeight: 'bold',
    color: '#1D4ED8',
    borderRadius: '16px',
    padding: '6px',
    marginLeft: '8px',
    fontSize: '30px',

  },
  cardMainBox: {
    position: 'relative',
    height: '438px',
    width: '387px',
    borderRadius: '26px',
    display: "flex",
    flexDirection: "column",
    gap: "12px"

  },
  cardSubBox: {
    height: "280px",
    width: '387px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Image1})`,
    borderRadius: "20px"

  },
  cardSubBox1: {
    height: "280px",
    width: '387px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Image2})`,
    borderRadius: "20px"

  },
  cardSubBox2: {
    height: "280px",
    width: '387px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Image3})`,
    borderRadius: "20px"

  },
  cardSubBox3: {
    height: "280px",
    width: '387px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Image4})`,
    borderRadius: "20px"

  },
  cardSubBox4: {
    height: "280px",
    width: '387px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Image5})`,
    borderRadius: "20px"

  },
  cardSubBox5: {
    height: "280px",
    width: '387px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Image6})`,
    borderRadius: "20px"
  },
  cardImgText: {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: '#DBEAFE',
    padding: '6px 12px 6px 12px',
    color: '#1D4ED8',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '26px'
  },
  smallCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    marginTop: '12px'
  },
  smallCardDate: {
    display: 'flex',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'
  },
  smallCardHeading: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828'
  },
  smallCardAvt: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  },
  avtImg: {
    height: '48px',
    width: '48px',
    borderRadius: '50px'
  },
  smallCardName: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#334155'
  },
  smallCardSubHead: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'
  },
  moreButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: "64px"
  },
  blogNavBox: {
    width: "100%",
    height: "32px",
    marginTop: "200px",
    marginBottom: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  blogNavBtn: {
    height: "32px",
    width: "52px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#1D4ED8",
    borderRadius: "48px",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FFFFFF"
  },
  blogNavList: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#0F172A"
  }

};
// Customizable Area End
