import React from "react";
// Customizable Area Start
import SuperAdminResetPasswordController, {
    Props
} from "./SuperAdminResetPasswordController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Text from "../../../components/src/design-system/Text";
import {
    Box as LoginBox, FormControl as LoginForm, FormHelperText as HelperText,
    IconButton, Button
} from "@material-ui/core";
import Input from "../../../components/src/design-system/InputField/OutlinedInputField";
import { LoginFlow } from "../../../components/src/LoginFlow.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/ForgotPassword.json')
// Customizable Area End

const loginStyleComponent: StyleRules = {
    // Customizable Area Start

    labelHelperText: {
       marginBottom: 2
    },
    containerLoginBox: {
        height: '100%',
        boxSizing: 'border-box',
        padding: '44px 100px',
        "@media (max-width: 960px)": {
            padding: "40px 20px"
    
        },
        "@media (max-width: 600px)": {
            padding: "40px 20px",
            borderRadius: "10px 10px 0px 0px"
        },
    },
    paperText: {
        backgroundColor: "#0F172A",
    },
    inputField: {
        "& .MuiInputBase-root": {
            '& fieldset': {
                top: '-5px !important'
            },
        }
    },
    linkText: {
        cursor: 'pointer',
        marginLeft: 5,
        color: '#1E3A8A',
        "&:hover": {
          textDecoration: 'underline'
        },
    },
    nextBtn: {
        background: "#1E3A8A",
        color: '#fff',
        borderRadius: "8px",
        padding: 12,
        textTransform: "none",
        fontSize: 16,
        fontWeight: 700,
    
        "&:hover": {
            background: "#1E3A8A",
            color: '#fff',    
        }
      }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class SuperAdminResetPassword extends SuperAdminResetPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage} = this.state;
        const languageData = languageTranslationData[currentLanguage];
    
        return (
            <LoginFlow>
                <LoginBox className={classes.containerLoginBox}>
                    <LoginBox mt={0} mb={5.5} textAlign="center">
                        <LoginBox mb={1} style={{textAlign: 'left'}}>
                            <Text variant="primary" size="xl"weight="extraBold" family="inter" display="inline-block">
                                {languageData.ResetPassword}
                            </Text>
                        </LoginBox>
                    </LoginBox>
                    <LoginBox mb={5.5} className={classes.inputField}>
                        <LoginForm style={{ width: '100%' }}>
                            <HelperText
                                id="Username-helper-text"
                                className={classes.labelHelperText}
                            >
                                <Text family="inter" variant="grey" size="xx" weight="extraBold">{languageData.NewPassword}</Text>
                            </HelperText>
                            <Input
                                border="none"
                                type={this.state.showPassword ? "text" : "password"}
                                value={this.state.password}
                                onChange={this.handlePassword.onChangeText}
                                aria-describedby="Username-helper-text"
                                labelWidth={0}
                                id="password"
                                placeholder={languageData.NewPasswordPlaceholder}
                                endAdornment={
                                    <IconButton
                                        aria-label="password visibility"
                                        style={{ marginRight: "-16px" }}
                                        onClick={this.setPasswordField}
                                    >
                                        {this.state.showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                }
                            />
                        </LoginForm>

                    </LoginBox>
                    <LoginBox mb={8.5} className={classes.inputField}>

                        <LoginForm style={{ width: '100%' }}>
                            <HelperText
                                id="password-helper-text"
                                className={classes.labelHelperText}
                            >
                                <Text variant="grey" family="inter" size="xs" weight="extraBold">{languageData.ConfirmPassword}</Text>
                            </HelperText>
                            <Input
                                aria-describedby="password-helper-text"
                                border="none"
                                type={this.state.showConfirmPassword ? "text" : "password"}
                                value={this.state.confirmPassword}
                                onChange={this.handleConfirmPassword.onChangeText}
                                id="confirm-password"
                                labelWidth={0}
                                placeholder={languageData.ConfirmPasswordPlaceholder}
                                endAdornment={
                                    <IconButton
                                        aria-label="password visibility"
                                        style={{ marginRight: "-16px" }}
                                        onClick={this.setConfirmPasswordField}
                                    >
                                        {this.state.showConfirmPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                }
                            />
                        </LoginForm>
                        {this.state.confirmPasswordError ? (
                        <LoginBox mt={1}>
                    <Text family="inter" variant="error" size="xs" weight="extraBold" >{this.state.confirmPasswordError } </Text>  </LoginBox>
                    ) : null}
                    </LoginBox>
                    <Button
                        data-testid="login-btn"
                        fullWidth
                        variant="contained"
                        className={classes.nextBtn}
                        onClick={this.adminButtonProps.onPress}
                    >
                        {languageData.Next}
                    </Button>
                 <LoginBox mt={3} mb={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text display="flex" variant="grey" size="sm" weight="minBold" family="inter">
                    {languageData.BackTo}
                    <span className={classes.linkText} id="signup-test" onClick={() => this.handleRouting('SuperAdminLogin')}>
                      <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">{languageData.Login}</Text></span>
                  </Text>
              </LoginBox>
                </LoginBox>
            </LoginFlow>
        )
        // Customizable Area End
    }
}

export default withStyles(loginStyleComponent)(SuperAdminResetPassword);
