import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    styled,
    Button
} from "@material-ui/core";
// Customizable Area End

import UserDashboardController, {
    Props,
    configJSON
} from "./UserDashboardController";

// Customizable Area Start
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web"
// Customizable Area End

export default class UserDashboard extends UserDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderVerificationRequiestTable = () => {
        const { currentLanguage } = this.state;
        const languageData = configJSON.userDashboardLanguage[currentLanguage];
        return (
            <>
                <TableUpperBox>
                    <Typography style={webStyle.tableHeading}>
                        {languageData.verificationRequest}
                    </Typography>
                    <Box style={webStyle.searchButtonWrapper}>
                        <Box style={webStyle.textFieldWrapper}>
                            <SearchIcon style={webStyle.searchIconStyle} />
                            <CustomTextField
                                data-test-id="searchInputTestID"
                                fullWidth
                                variant="outlined"
                                value={this.state.search}
                                onChange={this.handleSearchChange}
                                placeholder={languageData.search}
                            />
                        </Box>
                        <RequestButton variant="contained">
                            <Typography>{languageData.requestNewVerification}</Typography>
                        </RequestButton>
                    </Box>
                </TableUpperBox>
                <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <CustomHeadingRow>
                                <TableCell>{languageData.verificationTableHeadings.requestID}</TableCell>
                                <TableCell>{languageData.verificationTableHeadings.name}</TableCell>
                                <TableCell>{languageData.verificationTableHeadings.registrationNumber}</TableCell>
                                <TableCell>{languageData.verificationTableHeadings.date}</TableCell>
                                <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.verificationTableHeadings.status}</TableCell>
                            </CustomHeadingRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.map((item, index) => (
                                <CustomBodyRow key={index}>
                                    <TableCell>{item[0]}</TableCell>
                                    <TableCell>{item[1]}</TableCell>
                                    <TableCell>{item[3]}</TableCell>
                                    <TableCell>{item[4]}</TableCell>
                                    <TableCell>
                                        <span
                                            style={{
                                                ...webStyle.statusStyle,
                                                ...this.handleStatusColor(item[2]),
                                            }}
                                        >
                                            {item[2]}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <MoreVertIcon style={webStyle.moreOpStyle} />
                                    </TableCell>
                                </CustomBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    style={webStyle.paginationResultBox}
                >
                    <Box
                        style={webStyle.paginationBox}
                    >
                        <CustomPagination
                            data-test-id="verificationPaginationTestID"
                            page={this.state.verificationPage}
                            count={8}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={this.handleVerificationPagination}
                        />
                    </Box>
                    <CustomVerificationResultBox>
                        <Typography style={webStyle.resultsText}>
                            {languageData.results}
                        </Typography>
                    </CustomVerificationResultBox>
                </Box>
            </>
        );
    };

    renderInvoiceTable = () => {
        const { currentLanguage } = this.state;
        const languageData = configJSON.userDashboardLanguage[currentLanguage];
        return (
            <>
                <TableUpperBox>
                    <Typography style={webStyle.tableHeading}>{languageData.allInvoices}</Typography>
                    <Box style={webStyle.searchButtonWrapper}>
                        <Box style={webStyle.textFieldWrapper}>
                            <SearchIcon style={webStyle.searchIconStyle} />
                            <CustomTextField
                                data-test-id="invoiceSearchTestID"
                                fullWidth
                                variant="outlined"
                                value={this.state.invoiceSearch}
                                onChange={this.handleInvoiceSearchChange}
                                placeholder={languageData.search}
                            />
                        </Box>
                        <RequestButton variant="contained">
                            <Typography>{languageData.generateInvoice}</Typography>
                        </RequestButton>
                    </Box>
                </TableUpperBox>
                <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <CustomHeadingRow>
                                <TableCell>{languageData.invoiceTableHeading.mainId}</TableCell>
                                <TableCell>{languageData.invoiceTableHeading.invoiceId}</TableCell>
                                <TableCell>{languageData.invoiceTableHeading.payment}</TableCell>
                                <TableCell>{languageData.invoiceTableHeading.dueDate}</TableCell>
                                <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.invoiceTableHeading.status}</TableCell>
                            </CustomHeadingRow>
                        </TableHead>
                        <TableBody>
                            {this.state.invoiceData.map((value, index) => (
                                <CustomBodyRow key={index}>
                                    <TableCell>{value.mainID}</TableCell>
                                    <TableCell>{value.invoiceID}</TableCell>
                                    <TableCell>{value.payment}</TableCell>
                                    <TableCell>{value.dueDate}</TableCell>
                                    <TableCell>
                                        <span
                                            style={{
                                                ...webStyle.statusStyle,
                                                ...this.handleStatusColor(value.status),
                                            }}
                                        >
                                            {value.status}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <MoreVertIcon style={webStyle.moreOpStyle} />
                                    </TableCell>
                                </CustomBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    style={{ display: "flex", alignItems: "center", flexWrap:"wrap", paddingTop: "16px" }}
                >
                    <Box
                        style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}
                    >
                        <CustomPagination
                            data-test-id="invoicePaginationTestID"
                            page={this.state.invoicePage}
                            count={8}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={this.handleInvoicePagination}
                        />
                    </Box>
                    <CustomResultBox>
                        <Typography style={webStyle.resultsText}>
                            {languageData.results}
                        </Typography>
                    </CustomResultBox>
                </Box>
            </>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentLanguage } = this.state;
        const languageData = configJSON.userDashboardLanguage[currentLanguage];
        // Customizable Area End
        return (
            // Customizable Area Start
                <UserProfileDrawer 
                    data-test-id="drawerComponentTestID" 
                    handleClick={this.handleDrawerClick}
                    pageName="UserDashboard"
                >
                    <Box style={webStyle.mainBoxWrapper}>                   
                        <Box>
                            <Typography style={webStyle.dashboardStyle}>{languageData.dashboard}</Typography>
                        </Box>
                        <Box style={webStyle.verificatioinTableWrapper}>
                            {this.renderVerificationRequiestTable()}
                        </Box>
                        <Box style={webStyle.verificatioinTableWrapper}>
                            {this.renderInvoiceTable()}
                        </Box>
                    </Box>
                </UserProfileDrawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        display: "flex",
        justifyContent: "flex-end",
        width:"100%"
    }
});

const CustomVerificationResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width:"100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});

const CustomPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        fontSize: "1rem",
        minWidth: "32px",
        height: "32px",
        color: "#0F172A",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "@media(max-width: 421px)": {
            margin: "0px"
        },
        "&:hover": {
            backgroundColor: "#f0f0f0"
        }
    },
    "& .Mui-selected": {
        color: "blue !important",
        fontWeight: 700,
        backgroundColor: "transparent !important",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#f0f0f0 !important"
        }
    },
    "& .MuiPaginationItem-ellipsis": {
        fontSize: "1.2rem",
        textDecoration: "none"
    }
});

const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});

const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});

const CustomTextField = styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const TableUpperBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
    gap: "20px",
    flexWrap: "wrap"
});

const RequestButton = styled(Button)({
    backgroundColor: "#1E3A8A",
    width: "auto",
    height: "44px",
    borderRadius: "8px",
    cursor: "pointer",
    "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        width: "auto",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 700,
        color: "white"
    },
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const webStyle = {
    mainBoxWrapper:{
        display:"flex",
        flexDirection:"column",
        gap:"30px"
    } as React.CSSProperties,
    paginationResultBox:{
        display: "flex", 
        alignItems: "center", 
        paddingTop: "16px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    paginationBox: {
        display: "flex", 
        justifyContent: "center", 
        flexGrow: 1
    },
    moreOpStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7"
    },
    resultsText: {
        color: "#64748B",
        fontSize: "12px"
    },
    textFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px"
    },
    searchIconStyle: {
        color: "#94A3B8"
    },
    dashboardStyle: {
        fontSize: "30px",
        fontWeight: 700
    },
    verificatioinTableWrapper: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px 4px #00000008",
        marginBottom:"24px"
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: "18px",
        whiteSpace: "nowrap"
    },
    searchButtonWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexGrow: 1,
        maxWidth: "583px",
        flexWrap: "wrap"
    } as React.CSSProperties
};

// Customizable Area End
