import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData ,removeStorageData} from "framework/src/Utilities";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Request.json')
import { ChangeEvent, MouseEvent } from "react";
const reqVeriData = [
    ["#669677", "Blue Horizon Investments", "APPROVED", "GHI128789", "09 Jul 2018"],
    ["#669459", "Apex Financial Solutions", "APPROVED", "ABC823456", "20 Jan 2018"],
    ["#869287", "Legacy Financial Group", "PENDING", "MNO458723", "17 Sep 2013"],
    ["#664979", "Zenith Credit Management", "REJECTED", "JKL787654", "29 Aug 2023"],
    ["#679287", "Crestwood Capital Partners", "PENDING", "XYZ709012", "12 May 2023"],
    ["#664559", "Silverline Ventures LLC", "APPROVED", "BEF456789", "24 Jun 2012"]
];
// Customizable Area End
type AllOrNewOrArchived = "All" | "New" | "Archived";
type MyRequestsOrVerificationRequest = "My Requests" | "Verification Request";
interface RequestVerificationAttributes {
    first_name: string;
    last_name: string;
    full_phone_number: string;
    email: string;
    status: string;
    legal_business_name: string;
    uuid: string | null;
    request_date: string | null;
    Company: string | null;
    Debtor: string | null;
}

interface RequestVerificationData {
    id: string;
    type: string;
    attributes: RequestVerificationAttributes;
}

interface ApiResponse {
    data: RequestVerificationData[];
}


export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: Array<AllOrNewOrArchived>,
    selectedTab: AllOrNewOrArchived,
    MyRequestTab: Array<MyRequestsOrVerificationRequest>,
    MyRequestTabSelectedTab: MyRequestsOrVerificationRequest,
    agreeModal: boolean,
    cancelModal: boolean,
    verificationRequestList: RequestVerificationData[],
    currentLanguage: 'ar' | 'en',
    isFirstModalOpen: boolean,
    isSecondModalOpen: boolean,
    isThirdModalOpen: boolean,
    data: typeof reqVeriData;
    search: string;
    verificationPage: number;
    isFilterDrawerOpen: boolean;
    fromDate: string;
    toDate: string;
    status: string;
    anchorEl: HTMLElement | null;
    dot: HTMLElement | null,

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class RequestsWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    verificationRequestListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            verificationRequestList: [],
            tabs: this.tabsList('en'),
            selectedTab: "All",
            MyRequestTab: this.myRequestTabList('en'),
            MyRequestTabSelectedTab: "My Requests",
            agreeModal: false,
            cancelModal: false,
            currentLanguage: 'en',
            isFirstModalOpen: false,
            isSecondModalOpen: false,
            isThirdModalOpen: false,
            data: reqVeriData,
            search: "",
            verificationPage: 1,
            isFilterDrawerOpen: false,
            fromDate: '',
            toDate: '',
            status: '',
            anchorEl: null,
            dot: null,



            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.verificationRequestListApiCallId) {
                    this.setState({ verificationRequestList: responseJson.data })
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getRequestVerificationList();
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
            tabs: this.tabsList(language),
            MyRequestTab: this.myRequestTabList(language),
            selectedTab: languageTranslationData[language].All,
            MyRequestTabSelectedTab: languageTranslationData[language].MyRequests
        })
        // Customizable Area End
    }

    // Customizable Area Start
    tabsList = (language: string) => [
        languageTranslationData[language].All,
        languageTranslationData[language].New,
        languageTranslationData[language].Archived
    ]

    myRequestTabList = (language: string) => [
        languageTranslationData[language].MyRequests,
        languageTranslationData[language].VerificationRequest
    ]

    handleAgreeButtonClick = () => {
        this.setState({ agreeModal: true });
    };

    handleModalConfirm = () => {
        // Handle confirmation logic here
        this.setState({ agreeModal: false });
    };
    handleCancelButtonClick = () => {
        this.setState({ cancelModal: true });
    };

    handleModalBlockConfirm = () => {
        // Handle confirmation logic here
        this.setState({ cancelModal: false });
    };

    handleChangeTab = (tab: MyRequestsOrVerificationRequest) => {
        this.setState({ MyRequestTabSelectedTab: tab })
    }

    handleChangeFilterTab = (tab: AllOrNewOrArchived) => {
        this.setState({ selectedTab: tab })
    }

    getRequestVerificationList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.verificationRequestListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.listRequestVerificationEndPoint
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleFirstModalOpen = () => {
        this.setState({ isFirstModalOpen: true });
    };

    handleFirstModalClose = () => {
        this.setState({ isFirstModalOpen: false });
    };

    handleSecondModalOpen = () => {
        this.setState({ isFirstModalOpen: false, isSecondModalOpen: true });
    };

    handleSecondModalClose = () => {
        this.setState({ isSecondModalOpen: false });
    };

    handleThirdModalOpen = () => {
        this.setState({ isSecondModalOpen: false, isThirdModalOpen: true });
    };
    handleThirdModalClose = () => {
        this.setState({ isThirdModalOpen: false });
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "PENDING" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "REJECTED" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: event.target.value });
    };


    handleVerificationPagination = (evetn: ChangeEvent<unknown>, page: number) => {
        this.setState({ verificationPage: page });
    };

    handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ dot: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ dot: null });
    };
    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };
    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    };
    
    handleNavigateForUserList = (userListId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UserRequest");
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), userListId)
        this.send(message);
      }
      
    // Customizable Area End
}
