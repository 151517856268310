Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.EN = 'en';
exports.UserLoginWeb = "UserLoginWeb";
exports.SellerLoginWeb = "SellerLoginWeb";
exports.Home = "Home";
exports.Creditors = "Creditors";
exports.DebtBuyer = "DebtBuyer";
exports.Industries = "Industries";
exports.About = "Customisableusersubscriptions";
exports.Services = "Services";
exports.Contact = "Contact";
exports.MarketPlace= "MarketPlace"
// Customizable Area End