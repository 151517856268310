import React from "react";
// Customizable Area Start
import { Box, Container, Grid } from "@material-ui/core";
import CreditoreControllerWeb, { Props, SubCreditor } from "./CreditoreController.web";
import { headerRightSideImg, leftSideImage } from "./assets";
import Text from "../../../components/src/design-system/Text";
import { DebtBox, SubmitButton, DetailTextBox, DescriptionText, ContainerBox2, DeptSaleDescriptionTextBox, SecondHeaderMainBox, ImageGrid, FormControllerBox, HeaderMainBox, HeaderMainText, HeaderMainTextBox, PortfolioBtn, ProcessCardBox, StartedMainBox, DescriptionTextBox, ContainerBox, InputHelper, ReqireText, InputTextFiled, StepsMainBox, ProcessCardDescription, ProcessCardTitle, StyledImage, HeaderDescriptionBox, CreditorsLandingButtonWrapper, ThirdSectionSubCreditorsWrapper, ProcessCardInnerBox } from "../../../components/src/design-system/CreditorsPageDesignSystem/CreditorsPageStyledComponents.web";
import Loader from "../../../components/src/Loader.web";
import parse from 'html-react-parser'
const configJSON = require("./config");
const languageTranslation = require('../../languagesupport/src/LanguageData/CreditorsPage.json')
// Customizable Area End

export class CreditoreWeb extends CreditoreControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderMainLandingSection = () => {
        const { creditorsPageData, currentLanguage } = this.state;
        const firstSection = creditorsPageData?.[0]?.attributes;
        const isEnglish = currentLanguage === configJSON.EN;
        const labelHeadingData = languageTranslation[currentLanguage];
        return <HeaderMainBox>
            <ContainerBox>
                <HeaderDescriptionBox item xs={12} sm={12} md={7}>
                    <HeaderMainTextBox>
                        <HeaderMainText data-test-id='creditor-landing-title' >{isEnglish ? firstSection?.title : firstSection?.title_in_arabic}</HeaderMainText>
                    </HeaderMainTextBox>
                    <DescriptionTextBox>
                        <DescriptionText >{isEnglish ? firstSection?.sub_title : firstSection?.sub_title_in_arabic}</DescriptionText>
                    </DescriptionTextBox>
                    <CreditorsLandingButtonWrapper>
                        <PortfolioBtn>{labelHeadingData.SellYourDefaultedPortfolios}</PortfolioBtn>
                    </CreditorsLandingButtonWrapper>
                </HeaderDescriptionBox>
                <ImageGrid item xs={12} sm={12} md={5}>
                    <StyledImage src={firstSection?.image ?? headerRightSideImg} alt="creditor image" />
                </ImageGrid>
            </ContainerBox>
        </HeaderMainBox>
    }


    renderSecondSection = () => {
        const { firstName, lastName, companyName, email, phoneNumber, creditorsPageData, currentLanguage } = this.state
        const secondSection = creditorsPageData?.[1]?.attributes;
        const isEnglish = currentLanguage === configJSON.EN;
        const labelHeadingData = languageTranslation[currentLanguage];
        return <SecondHeaderMainBox>
            <ContainerBox>
                <HeaderDescriptionBox item xs={12} md={12} lg={7}>
                    <Text size="1xl" weight="extraBold" family="inter" variant="grey" >{isEnglish ? secondSection?.title : secondSection?.title_in_arabic}</Text>
                    {parse(`${isEnglish ? secondSection?.description : secondSection?.description_in_arabic}`)}
                </HeaderDescriptionBox>
                <Grid item xs={12} md={12} lg={5}>
                    <StartedMainBox>
                        <Text size="xl" family="inter" weight="extraBold" >{labelHeadingData.GetStartedToday}</Text>
                        <FormControllerBox variant="outlined">
                            <InputHelper
                                id="FullName-helper-text"
                            >
                                {labelHeadingData.FirstName}
                                <ReqireText>*</ReqireText>
                            </InputHelper>
                            <InputTextFiled
                                aria-describedby="FullName-helper-text"
                                id="FullName-input"
                                labelWidth={0}
                                value={firstName}
                                onChange={this.HandleFirstNameChange}
                            />
                        </FormControllerBox>
                        <FormControllerBox variant="outlined">
                            <InputHelper
                                id="LastName-helper-text"
                            >
                                {labelHeadingData.LastName}
                                <ReqireText>*</ReqireText>
                            </InputHelper>
                            <InputTextFiled
                                aria-describedby="LastName-helper-text"
                                id="LastName-input"
                                labelWidth={0}
                                value={lastName}
                                onChange={this.HandleLastNameChange}
                            />
                        </FormControllerBox>
                        <FormControllerBox variant="outlined">
                            <InputHelper
                                id="CompanyName-helper-text"
                            >
                                {labelHeadingData.CompanyName}
                                <ReqireText>*</ReqireText>
                            </InputHelper>
                            <InputTextFiled
                                aria-describedby="CompanyName-helper-text"
                                id="CompanyName-input"
                                labelWidth={0}
                                value={companyName}
                                onChange={this.HandleCompnyNameChange}
                            />
                        </FormControllerBox>
                        <FormControllerBox variant="outlined">
                            <InputHelper
                                id="Email-helper-text"
                            >
                                {labelHeadingData.Email}
                                <ReqireText>*</ReqireText>
                            </InputHelper>
                            <InputTextFiled
                                aria-describedby="Email-helper-text"
                                id="Email-input"
                                labelWidth={0}
                                value={email}
                                onChange={this.HandleEmailChange}
                            />
                        </FormControllerBox>
                        <FormControllerBox variant="outlined">
                            <InputHelper
                                id="PhoneNumber-helper-text"
                            >
                                {labelHeadingData.PhoneNumber}
                                <ReqireText>*</ReqireText>
                            </InputHelper>
                            <InputTextFiled
                                aria-describedby="PhoneNumber-helper-text"
                                id="PhoneNumber-input"
                                labelWidth={0}
                                value={phoneNumber}
                                onChange={this.HandlePhoneNumberChange}
                            />
                        </FormControllerBox>
                        <Box mt={4}>
                            <SubmitButton
                                variant="contained"
                                fullWidth
                                onClick={this.contactUs}
                            >
                                {labelHeadingData.Submit}
                            </SubmitButton>
                        </Box>
                    </StartedMainBox>
                </Grid>
            </ContainerBox>
        </SecondHeaderMainBox>
    }

    renderThirdSection = () => {
        const { creditorsPageData, currentLanguage } = this.state
        const thirdSection = creditorsPageData?.[2]?.attributes;
        const isEnglish = currentLanguage === configJSON.EN;
        return <HeaderMainBox>
            <Box style={{ width: "100%", paddingBottom: "90px", display: "flex", justifyContent: "center" }}>
                <ContainerBox maxWidth='lg'>
                    <ImageGrid item xs={12} sm={12} md={6} >
                        <StyledImage src={thirdSection?.image ?? leftSideImage} alt="" />
                    </ImageGrid>
                    <HeaderDescriptionBox item xs={12} sm={12} md={6}>
                        <HeaderMainTextBox>
                            <Text size="1xl" family="inter" weight="extraBold" variant="naturalCoolGrey" >{isEnglish ? thirdSection?.title : thirdSection?.title_in_arabic}</Text>
                        </HeaderMainTextBox>
                        <DescriptionTextBox>
                            <Text size="xm" family="inter" weight="extraBold" variant="coolWhite" >{isEnglish ? thirdSection?.sub_title : thirdSection?.sub_title_in_arabic}</Text>
                        </DescriptionTextBox>
                        <DetailTextBox>
                            <Text size="sm" family="inter" weight="bold" variant="coolWhite">{parse(`${isEnglish ? thirdSection?.description : thirdSection?.description_in_arabic}`)}</Text>
                        </DetailTextBox>
                    </HeaderDescriptionBox>
                </ContainerBox>
            </Box>
            <ThirdSectionSubCreditorsWrapper>
                <Box>
                    {thirdSection?.sub_creditors?.map((item: SubCreditor, index: number) => (
                        <StepsMainBox key={item.title}>
                            <Text size="xl" weight="extraBold" family="inter" variant="coolGrey">{isEnglish ? item.title : item.title_in_arabic}</Text>
                            <Text size="sm" weight="bold" family="inter" variant="coolGrey">{parse(`${isEnglish ? item.description : item.description_in_arabic}`)}</Text>
                        </StepsMainBox>
                    ))}
                </Box>
            </ThirdSectionSubCreditorsWrapper>
        </HeaderMainBox>
    }

    renderFourthSection = () => {
        const { creditorsPageData, currentLanguage } = this.state
        const fourthSection = creditorsPageData?.[3]?.attributes;
        const isEnglish = currentLanguage === configJSON.EN;
        return <DebtBox>
            <ContainerBox2 maxWidth='lg'>
                <ImageGrid item xs={12} md={12} lg={6} >
                    <StyledImage src={fourthSection?.image ?? leftSideImage} alt="" />
                </ImageGrid>
                <Grid item xs={12} md={12} lg={6}>
                    <HeaderMainTextBox>
                        <Text size="2xxl" family="inter" weight="extraBold" variant="grey" >{isEnglish ? fourthSection?.title : fourthSection?.title_in_arabic}</Text>
                    </HeaderMainTextBox>
                    <DeptSaleDescriptionTextBox>
                        <Text size="xm" family="inter" weight="extraBold" variant="grey" >{isEnglish ? fourthSection?.sub_title : fourthSection?.sub_title_in_arabic}</Text>
                    </DeptSaleDescriptionTextBox>
                    <Box mt={2} mb={2.75}>
                        <Text size="mm" family="inter" weight="minBold" variant="grey">{parse(`${isEnglish ? fourthSection?.description : fourthSection?.description_in_arabic}`)}</Text>
                    </Box>
                </Grid>
            </ContainerBox2>
            <Container>
                <ProcessCardBox>
                    {fourthSection?.sub_creditors?.map((item: SubCreditor, index: number) => {
                        const textColor = !(index % 2) ? '#fff' : '#334155';
                        return <ProcessCardInnerBox style={{ backgroundColor: !(index % 2) ? '#1E293B' : '#E2E8F0', marginTop: !(index % 2) ? '0' : '77px' }} key={item.title}>
                            <ProcessCardTitle style={{ color: textColor }}  >
                                {isEnglish ? item.title : item.title_in_arabic}
                            </ProcessCardTitle>
                            <ProcessCardDescription style={{ color: textColor }} >
                                {parse(`${isEnglish ? item.description : item.description_in_arabic}`)}
                            </ProcessCardDescription>
                        </ProcessCardInnerBox>
                    })}
                </ProcessCardBox>
            </Container>
        </DebtBox>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { loading } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Loader loading={loading} />
                {this.renderMainLandingSection()}
                {this.renderSecondSection()}
                {this.renderThirdSection()}
                {this.renderFourthSection()}
            </>
            // Customizable Area End
        )
    }
}

export default (CreditoreWeb);
