import React from "react";
import {
    Button,
    styled
} from "@material-ui/core";

interface Props {
  onClick?: () => void,
  disabled?: boolean,
  id?: string
}

const MUICustomButton: React.FC<Props> = ({ children, onClick, disabled, id }) => {
  
  const CustomButton = styled(Button)({
    borderRadius: '8px',
    cursor: "pointer",
    background: '#1E3A8A;',
    display: 'flex',
    textTransform: 'capitalize',
    padding: '10px 16px',
    alignItems: 'center',
    justifyContent : "center",
    flexShrink: 0,
    alignSelf: 'stretch',
    fontSize : "14px",
    fontWeight : 700,
    lineHeight : "22px",
    color : "#FFF",
    width : "100%",
    margin : "auto",
    maxWidth : "315px",
    flex : 1,
    transition: 'background-color 0.3s',
    "&.MuiButton-root.Mui-disabled" : {
        color : "#AEAEAE",
        backgroundColor : "#0f2152"
    },
    '&:hover': {
      background: '#223E58'
    }
  })

  return  <CustomButton disabled={disabled} onClick={onClick} id={id}> {children} </CustomButton>
};

export default MUICustomButton;


