import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { removeStorageData } from "framework/src/Utilities";
import { UserEight, UserFifth, UserFirst, UserFourth, UserSecond, UserSeven, UserSix, UserThird } from "./assets";
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');
const docData = {
    en: [
        [UserFirst, "Sarah Collins", "sarah.collins@debtagency.com", "Created", "22 Feb 2022", "User Account"],
        [UserSecond, "Michael Thompson", "michael.thompson@debtagency.com", "Updated", "21 Feb 2022", "User Account"],
        [UserThird, "Sarah Collins", "sarah.collins@debtagency.com", "Deleted", "20 Feb 2022", "User Account"],
        [UserFourth, "Sarah Collins", "sarah.collins@debtagency.com", "Logged In", "20 Feb 2022", "User Account"],
        [UserFifth, "Michael Thompson", "michael.thompson@debtagency.com", "Logged Out", "14 Feb 2022", "User Account"],
        [UserSix, "Emily Davis", "emily.davis@debtagency.com", "Listing Published", "12 Feb 2022", "Listing"],
        [UserSeven, "Michael Thompson", "michael.thompson@debtagency.com", "Profile Updated", "8 Feb 2022", "Profile"],
        [UserEight, "Emily Davis", "emily.davis@debtagency.com", "Payment Made", "4 Feb 2022", "Payment"],
    ],
    ar: [
        [UserFirst, "سارة كولينز", "sarah.collins@debtagency.com", "أنشئت", "٢٢ فبراير ٢٠٢٢", "حساب المستخدم"],
        [UserSecond, "مايكل طومسون", "michael.thompson@debtagency.com", "محدثة", "٢١ فبراير ٢٠٢٢", "حساب المستخدم"],
        [UserThird, "سارة كولينز", "sarah.collins@debtagency.com", "حذفت", "٢٠ فبراير ٢٠٢٢", "حساب المستخدم"],
        [UserFourth, "سارة كولينز", "sarah.collins@debtagency.com", "سجل دخول", "٢٠ فبراير ٢٠٢٢", "حساب المستخدم"],
        [UserFifth, "مايكل طومسون", "michael.thompson@debtagency.com", "سجل خروج", "١٤ فبراير ٢٠٢٢", "حساب المستخدم"],
        [UserSix, "إميلي ديفيس", "emily.davis@debtagency.com", "القائمة المنشورة", "١٢ فبراير ٢٠٢٢", "القائمة"],
        [UserSeven, "مايكل طومسون", "michael.thompson@debtagency.com", "الملف محدث", "٨ فبراير ٢٠٢٢", "الملف"],
        [UserEight, "إميلي ديفيس", "emily.davis@debtagency.com", "المبلغ مدفوع", "٤ فبراير ٢٠٢٢", "الدفع"],
    ]
};
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userType: string,
    showFilter: boolean,
    currentLanguage: 'ar' | 'en',
    headingText: string,
    searchTerm: string,
    loading: boolean,
    docData: typeof docData;
    dot: HTMLElement | null,
    currentPage: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userType: "Admin",
            showFilter: false,
            currentLanguage: 'en',
            headingText: "",
            searchTerm: "",
            loading: false,
            docData: docData,
            dot: null,
            currentPage: 1,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        console.log("Component loaded")
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Profile;
        this.setState({
            currentLanguage: language,
            headingText: heading,
        });
        // Customizable Area End
    }

    handleUserProfile = () => {
        console.log("User Profile")
    }

    handleDrawerNavigation1 = (routing: string) => {
        if (routing === "logOut") {
            this.logoutHandle1()
        } else {
            this.handleNavigatingRoute1(routing);
        }
    };

    logoutHandle1 = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigatingRoute1("Home");
    };
    handleNavigatingRoute1 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };
    handlePageChange = (page: number) => {
        this.setState({ currentPage: page });
    };
    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        switch (value) {
            case "Created":
                changeBackground = "#FEF3C7";
                changeColor = "#D97706";
                break;
            case "Deleted":
                changeBackground = "#FEE2E2";
                changeColor = "#DC2626";
                break;
            case "Logged In":
                changeBackground = "#DBEAFE";
                changeColor = "#1D4ED8";
                break;
            case "Logged Out":
                changeBackground = "#F1EFFF";
                changeColor = "#6C5CCA";
                break;
            case "Listing Published":
                changeBackground = "#F1F5F9";
                changeColor = "#475569";
                break;
            case "Profile Updated":
                changeBackground = "#F9FAD1";
                changeColor = "#898B28";
                break;
            case "Payment Made":
                changeBackground = "#F8E2FE";
                changeColor = "#873F9B";
                break;
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };


    // Customizable Area End
}
