
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
// Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    image: string;
    description: string;
    sub_debut_buyers: Array<{ title: string; description: string; arabic_title: string; arabic_description: string }>;
    arabic_title: string;
    arabic_description: string;
  };
}
// Customizable Area End

export interface Props {
  navigation: object;
  idContent: string | number | object;
}
interface S {
  // Customizable Area Start
  title: string;
  image: string;
  description: string;
  userDataList: ProductItem[]
  currentLanguage: "ar" | "en",
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class DebtBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall: string | Message = ''
  addDataCall: string | Message = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      title: "",
      image: "",
      description: "",
      userDataList: [],
      currentLanguage: "en",
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userDataListCall !== null &&
      this.userDataListCall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)

      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        this.setState({ userDataList: responseJson.data, loading: false });
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language })
    this.getUserDataListApi()
  }
  getUserDataListApi = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDataListCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDebutbuyerdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
