import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');
import {removeStorageData } from "framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs : Array<"All" | "New" | "In review" |"Draft" |"Archived">,
    selectedTab : "All" | "New" | "In review" |"Draft" |"Archived",
    rowsPerPage : number,
    page : number,
    expanded: boolean,
    accord: boolean,
    companyData: {
        HeadText: string;
        smallText: string;

    }[];
    currentLanguage: "ar" | "en",
    showDetail: boolean,
    breadscrumValues: string[],
    userType: string;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    
    // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs : ["All","New","In review","Draft","Archived"],
            selectedTab : "All",
            rowsPerPage : 8,
            page : 0,
            expanded: false,
            accord: false,
            companyData: configJSON.companyData,
            currentLanguage: "en",
            showDetail: false,
            breadscrumValues: [],
            userType: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start
/*  */
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
        });
        // Customizable Area End
    }
 
    handleChange = () => {
        this.setState((prevState) => ({
          expanded: !prevState.expanded,
        }));
      };
     handleAccordian = () => {
        this.setState((prevState) => ({
            accord: !prevState.accord,
        }));
      };

      handleDrawerNavigation3 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle3()
        } else {
                this.handleNavigatingRoute3(routing);
        
        }
    };
    logoutHandle3 = async() => {
        this.handleNavigatingRoute3("Home");
    };


    handleNavigatingRoute3 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    // Customizable Area End
}
