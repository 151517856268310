import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, Theme, createStyles, Button, Container } from '@material-ui/core';
import { ringImage } from '../../../../blocks/landingpage/src/assets';
import { styled } from "@material-ui/core/styles";
import { getLanguageOrSetCurrentLanguage } from '../../../../blocks/utilities/src/utills';
const homeData = require('../../../../blocks/languagesupport/src/LanguageData/homePage.json');
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: '80px'
    },
    light: {
      backgroundColor: '#fff',
    },
    debtContainer: {
      backgroundImage: ` url(${ringImage}), linear-gradient(94.1deg, #0F172A -13.88%, #334F90 103.15%)`,
      backgroundSize: 'cover',
      borderRadius: 32,
      padding: 80,
      display: 'flex',
      alignItems: 'center',
      "@media (max-width: 960px)": {
          padding: "40px"
        },
        "@media (max-width: 600px)": {
          padding: "40px",
        },
    },
    title: {
        color: "#FFFFFF",
        fontSize: 30,
        fontWeight: 600,
        lineHeight: "44px"
    },
    details: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px"
    },
    discoverBtn: {
        width: 200, 
        height: 56, 
        borderRadius: 8, 
        padding: 16,
        backgroundColor: '#FFF',
        color: '#0F172A',
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 700
    }
  })
);

const Section1 = styled(Container)({
    maxWidth: '1200px',
    padding: '60px 0',
    "@media (max-width: 960px)": {
      padding: "40px 40px"
    },
    "@media (max-width: 600px)": {
      padding: "40px 40px",
    },
})

const GridBox = styled(Grid)({
    '& .sectionOneTitle': {
      color: '#000',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '52px',
      fontSize: '36px',
  
      '@media (max-width: 1200px)': {
        fontSize: '25px',
        lineHeight: '30.205px',
      }
    },
    '& .img-banner': {
      '@media (max-width: 1200px)': {
        width: '80%',
      }
    }
})

const DiscoverCard: React.FC = () => {
  const classes = useStyles();
  const [currentLanguage, setCurrentLanguage] = useState('en');

  useEffect(() => {
    getCurrentLanguage();
  });

  const getCurrentLanguage = async() => {
    const language = await getLanguageOrSetCurrentLanguage();
    setCurrentLanguage(language);
  }

  return (
    <Section1 maxWidth={false}>
        <GridBox container className={classes.debtContainer} spacing={2}>
            <Grid item sm={9} xs={12}>
                <Typography
                    className={classes.title}
                    variant="h6"
                >
                    {homeData[currentLanguage].DiscoverSection.title}
                </Typography>
                <Typography
                    className={classes.details}
                    variant="body1"
                >
                    {homeData[currentLanguage].DiscoverSection.description}
                </Typography>
            </Grid>
            <Grid sm={3} xs={12}>
            <Button
                variant='contained'
                className={classes.discoverBtn}                   
            >
                {homeData[currentLanguage].DiscoverSection.buttonText}
            </Button>
            </Grid>
        </GridBox>
    </Section1>
  );
};

export default DiscoverCard;
