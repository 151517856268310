import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills')
// Customizable Area End


export interface SubCreditor {
    id: number;
    creditor_id: number;
    title: string;
    description: string;
    sequence: number;
    title_in_arabic: string | null;
    description_in_arabic: string | null;
}

export interface Creditor {
    id: string;
    type: string;
    attributes: {
        id: number;
        sequence: number;
        title: string;
        title_in_arabic: string | null;
        sub_title: string;
        sub_title_in_arabic: string | null;
        description: string;
        description_in_arabic: string | null;
        image: string | null;
        sub_creditors: SubCreditor[];
    };
}

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    creditorsArrayList: any;
    debtArrayList: any;
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    loading: boolean;
    creditorsPageData: Creditor[];
    currentLanguage: "en" | "ar";
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreditoreControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    contactUsApiCallId: string = "";
    creditorsPageApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            creditorsArrayList: configJSON.creaditorsArray,
            debtArrayList: configJSON.debtProcessArray,
            firstName: "",
            lastName: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            loading: false,
            creditorsPageData: [],
            currentLanguage: configJSON.EN
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({ currentLanguage: language })
        this.getCreditorsPageData();
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.contactUsApiCallId) {
                    this.setState({ loading: false });
                }

                if (apiRequestCallId === this.creditorsPageApiCallId) {
                    this.setState({ creditorsPageData: responseJson.data, loading: false });
                }
            }
            if (errorReponse) {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    HandleFirstNameChange = (event: any) => {
        this.setState({ firstName: event.target.value })
    }

    HandleLastNameChange = (event: any) => {
        this.setState({ lastName: event.target.value })
    }

    HandleCompnyNameChange = (event: any) => {
        this.setState({ companyName: event.target.value })
    }

    HandleEmailChange = (event: any) => {
        this.setState({ email: event.target.value })
    }

    HandlePhoneNumberChange = (event: any) => {
        this.setState({ phoneNumber: event.target.value })
    }

    contactUs = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": "application/json"
        };
        const httpBody = {
            "data": {
                "first_name": this.state.firstName,
                "last_name": this.state.lastName,
                "company_name": this.state.companyName,
                "phone_number": this.state.phoneNumber,
                "email": this.state.email
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.contactUsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_contact_us/contact_us"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCreditorsPageData = () => {
        this.setState({ loading: true })
        const header = {
            "Content-Type": configJSON.validationApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.creditorsPageApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.creditorsPageEndPoint
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}