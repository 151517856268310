import React from "react";
// Customizable Area Start
import UserLoginWebController, {
    Props
} from "./UserLoginPageController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Text from "../../../components/src/design-system/Text";
import {
    Box as LoginBox, FormControl as LoginForm,
     IconButton,
    InputAdornment,
    Button
} from "@material-ui/core";
import { LoginFlow } from "../../../components/src/LoginFlow.web";
import TextInput from "../../../components/src/design-system/TextInput";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Login.json')

// Customizable Area End

const loginStyle: StyleRules = {
    // Customizable Area Start
    containerLogin: {
        height: '100%',
        boxSizing: 'border-box',
        padding: '44px 100px',
        "@media (max-width: 960px)": {
            padding: "40px 20px"
        },
        "@media (max-width: 600px)": {
            padding: "40px 20px",
        },
    },
    linkText: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'end',
        marginTop: 5,
        color: '#1E#A8A',
        "&:hover": {
            textDecoration: 'underline'
        },
    },
    errorBox: {
      padding: '12px 16px 12px 16px',
      borderRadius: '4px 0px 0px 0px',
      background: '#FEE2E2',
      borderLeft: '4px solid #DC2626',
      color: '#DC2626',
      marginBottom: 10
    },
    loginBtn: {
        background: "#1E3A8A",
        color: '#fff',
        borderRadius: "8px",
        padding: 12,
        textTransform: "none",
        fontSize: 16,
        fontWeight: 700,

        "&:hover": {
            background: "#1E3A8A",
            color: '#fff',    
        }
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class SuperAdminLogin extends UserLoginWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage, errors } = this.state;
        const languageData = languageTranslationData[`${currentLanguage}`]
        const isArabic = currentLanguage === 'ar';
        return (
        <LoginFlow>
            <LoginBox className={classes.containerLogin}>
                <LoginBox mt={0} mb={3}>
                    <LoginBox display="flex">
                        <Text variant="primary" size="xl" weight="extraBold" family="inter">
                            {languageData.Seller.Title}
                        </Text>
                    </LoginBox>
                </LoginBox>
                {!this.isStringNullOrBlank(errors) && <LoginBox className={classes.errorBox}>
                    <Text>{errors}</Text>
                </LoginBox>}
                <LoginBox mb={3}>
                    <LoginForm style={{ width: '100%' }}>
                        <TextInput
                          id="username-input"
                          label={languageData.Seller.Email}
                          onChange={this.handleUserNameChange}
                          placeholder={languageData.Seller.EmailPlaceholder}
                          aria-describedby="Username-helper-text"
                          value={this.state.userName}
                          isArabic={isArabic}
                          error={this.state.localErrors.Email}
                      />
                    </LoginForm>

                </LoginBox>
                <LoginBox>
                    <LoginForm style={{ width: '100%' }}>
                        <TextInput
                            id="password-input"
                            type={this.state.showPassword ? "text" : "password"}
                            label={languageData.Seller.Password}
                            onChange={this.handlePasswordChange}
                            placeholder={languageData.Seller.PasswordPlaceholder}
                            aria-describedby="password-input"
                            value={this.state.password}
                            error={this.state.localErrors.Password}
                            isArabic={isArabic}
                            endAdornment={(<InputAdornment position="end" style={{ position: 'absolute', top: "27px", right: isArabic ? "auto": "0px", left: isArabic ? 0 : "auto" }}>
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            id="show-hide-password"
                            >
                            {this.state.showPassword ? (
                                <Visibility style={{color: "#94A3B8"}}/>
                            ) : (
                                <VisibilityOff style={{color: "#94A3B8"}}/>
                            )}
                        </IconButton>
                        </InputAdornment>)
                        }
                        />
                    </LoginForm>
                </LoginBox>
                <LoginBox mb={6}>
                    <a className={classes.linkText} id="forgot-password" onClick={() => this.handleNavigation('SuperAdminForgotPassword')}>
                        <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="xx">
                            {languageData.Seller.ForgotPassword}
                        </Text>
                    </a>
                </LoginBox>
                <Button
                    fullWidth
                    className={classes.loginBtn}
                    variant="contained"
                    data-testid="login-btn"
                    onClick={this.superAdminLoginButton.onClick}
                >
                    {languageData.Seller.LoginBtn}
                </Button>
            </LoginBox>
        </LoginFlow>
        )
        // Customizable Area End
    }
}

export default withStyles(loginStyle)(SuperAdminLogin);
