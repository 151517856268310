import React from "react";
// Customizable Area Start
import IndustrialPageWebController, {
  IndustriesPageData,
  Props,
  configJSON,
} from "./IndustrialPageController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { Box, Card, Container, Grid, Typography } from "@material-ui/core";
import Loader from "../../../../packages/components/src/Loader.web";
// Customizable Area End

const styles: StyleRules = {
  // Customizable Area Start
  root: {
    display: "flex",
    alignItems: "center",
    fontFamily: "inter",
    background: "#0F172A",
    overflowX: "hidden",
    padding: '90px 40px',
    boxSizing: "border-box",
    "@media (max-width: 960px)": {
      padding: "40px 20px"
    },
    "@media (max-width: 600px)": {
      padding: "40px 20px",
    },
  },
  bannerTitle: {
    fontSize: "48px",
    fontWeight: 600,
    color: "white",
    marginBottom: '15px',
    fontStyle: "normal",
    lineHeight: "64.205px",
    '@media(max-width:991px)':{
      fontSize: "34px",
      lineHeight:'48px',
    },
    '@media(max-width:576px)':{
      fontSize: "24px",
      lineHeight:'38px'
    }
  },
  bannerContent: {
    fontFamily: "inter",
    fontSize: "16px",
    color: "white",
  },
  bannerContent1: {
    fontFamily: "inter",
    fontSize: "16px",
  },
  gridContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
    gap: "1rem",
  },
  cardSection: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2rem",
    justifyContent: "center",
    padding: '90px 40px 130px 40px',
    boxSizing: "border-box",
    alignItems: "stretch",
    "@media (max-width: 960px)": {
      padding: "40px 20px 70px 20px"
    },
    "@media (max-width: 600px)": {
      padding: "40px 20px 70px 20px",
    },
  },
  card: {
    background: "#E2E8F0",
    width: "220px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    borderRadius: "12px",
    padding: "10px",
    boxSizing: "border-box"
  },
  cardTitle: {
    fontWeight: 700,
    fontFamily: "Inter",
    textAlign: "center",
    lineHeight: "26px",
    fontSize: "18px",
  },
  oddFeatures: {
    "@media (max-width: 650px)": {
      padding: "20px 0",
    },
    background: "#334155",
    padding: "40px 0",
  },
  oddFeatures2: {
    background: "#fff",
    padding: "40px 0",
  },
  EvenFeatureContainer: {
    display: 'flex',
    flexDirection: 'row',
    "@media (max-width: 650px)": {
      flexDirection: "column-reverse",
      gap: "1rem",
    },
  },
  OddFeatureContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    "@media (max-width: 650px)": {
      flexDirection: "column-reverse",
      gap: "1rem",
    },
  },
  oddFeaturesTitle: {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "32px",
    color: "white",
    marginBottom: "8px",
  },
  oddFeaturesTitle1: {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "32px",
    marginBottom: "1rem",
  },
  imageStyle: {
    maxWidth: "90%"
  },
  cardContainer: {
    background: '#F1F5F9',
  }

  // Customizable Area End
};

// Customizable Area Start
// Customizable Area End

export class IndustrialPageWeb extends IndustrialPageWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area

  renderLandingSection = () => {
    const { classes } = this.props;
    const { currentLanguage, industriesPageData } = this.state;
    const isEnglish = currentLanguage === configJSON.EN;
    const landingSectionAttributes = industriesPageData?.[0]?.attributes;
    return <Paper className={classes.root} elevation={0} square>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} lg={12} >
          <Typography data-test-id='landing-title' className={classes.bannerTitle}>
            {isEnglish ? landingSectionAttributes?.title : landingSectionAttributes?.title_in_arabic}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} >
          <Typography className={classes.bannerContent}>
            {isEnglish ? landingSectionAttributes?.description : landingSectionAttributes?.description_in_arabic}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  }

  renderSecondSection = () => {
    const { classes } = this.props;
    const { currentLanguage, industriesPageData } = this.state;
    const isEnglish = currentLanguage === configJSON.EN;
    const AllFeatures = Array(7).fill(null).map((_, indexes: number) => industriesPageData?.[indexes + 1]);
    return (<Box className={classes.cardContainer}>
      <Container maxWidth="lg">
        <Box className={classes.cardSection}>
          {AllFeatures?.map((item: IndustriesPageData) => {
            const Attributes = item?.attributes;
            const title = isEnglish ? Attributes?.title : Attributes?.title_in_arabic;
            return (<Card elevation={0} key={title} className={classes.card}>
              <img src={Attributes?.image} alt={title} />
              <Typography className={classes.cardTitle}>
                {title}
              </Typography>
            </Card>)
          })}
        </Box>
      </Container>
    </Box>)
  }

  renderThirdSection = () => {
    const { classes } = this.props;
    const { currentLanguage, industriesPageData } = this.state;
    const isEnglish = currentLanguage === configJSON.EN;
    const FeaturesInDetails = industriesPageData.slice(8, industriesPageData?.length)
    return FeaturesInDetails?.map((item: IndustriesPageData, index: number) => {
      const isEven = !(index % 2);
      const Attributes = item?.attributes;
      const title = isEnglish ? Attributes?.title : Attributes?.title_in_arabic
      const description = isEnglish ? Attributes?.description : Attributes?.description_in_arabic;
      const image = Attributes?.image;
      return <Paper key={title} className={isEven ? classes.oddFeatures : classes.oddFeatures2} elevation={0} square>
        <Container maxWidth="lg" className={isEven ? classes.EvenFeatureContainer : classes.OddFeatureContainer}>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            style={{ alignSelf: "center" }}
          >
            <Typography className={isEven ? classes.oddFeaturesTitle : classes.oddFeaturesTitle1}>
              {title}
            </Typography>
            <Typography className={isEven ? classes.bannerContent : classes.bannerContent1}>
              {description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            style={{ textAlign: "center" }}
          >
            <img src={image} className={classes.imageStyle} alt={title} />
          </Grid>
        </Container>
      </Paper>
    })
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loading } = this.state;
    return (
      <>
        <Loader loading={loading} />
        {this.renderLandingSection()}
        {this.renderSecondSection()}
        {this.renderThirdSection()}
      </>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(IndustrialPageWeb);
