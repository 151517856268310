import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AppBar, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../../packages/blocks/navigationmenu/src/NavigationMenu.web";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    color: "#0F172A",
  }
}));

const TopNav = ({ history, location }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const matches = useMediaQuery('(max-width:990px)');

  useEffect(() => {
    // Update selected tab based on the current route
    const currentPath = location.pathname;
    switch (currentPath) {
      case "/":
        setSelectedTab(0);
        break;
      case "/CreditoreWeb":
        setSelectedTab(1);
        break;
      case "/DebtBuyer":
        setSelectedTab(2);
        break;
      case "/IndustrialPageWeb":
        setSelectedTab(3);
        break;
      case "/About":
        setSelectedTab(4);
        break;
      case "/Service":
        setSelectedTab(5);
        break;
      case "/Contactus":
        setSelectedTab(6);
        break;
      case "/UserLoginWeb":
        setSelectedTab(7);
        break;
      default:
        setSelectedTab(0); // Default tab or when no match is found
        break;
    }
  }, [location.pathname]);

  const handleTabChange = async (event, newValue) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        history.push("/");
        break;
      case 1:
        history.push("/CreditoreWeb");
        break;
      case 2:
        history.push("/DebtBuyer");
        break;
      case 3:
        history.push("/Catalogue");
        break;
      case 4:
        history.push("/Customisableusersubscriptions");
        break;
      case 5:
        history.push("/Service");
        break;
      case 6:
        history.push("/Contactus");
        break;
      case 7:
        history.push("/UserLoginWeb");
        break;
      case 8:
        history.push("/SellerLoginWeb");
        break;
      case 9:
        history.push("/User/Profile");
        break;
      case 10:
        history.push("/SuperAdminLogin");
        break;
      default:
        history.push("/");
        break;
    }
  };

  return (
    <AppBar className={classes.appBar} position="static">
      <NavigationMenu smallScreen={matches} handleChange={handleTabChange} selectedTab={selectedTab}/>
    </AppBar>
  );
};

export default withRouter(TopNav);
