import React from "react";
// Customizable Area Start
import UserProfileWebController, {
    Props
} from "./UserProfileController.web";

import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { Container, Box, Grid, ListItemIcon, MenuItem, MenuList, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { getStorageData } from "framework/src/Utilities";
import { arrowIcon } from "./assets";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    activeTab: {
        color: "#0F172A",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "inter",
        "&:hover":{
            textDecoration:'underline'
        }
    },
    nonactiveTab: {
        color: "#94A3B8",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "inter",
        "&:hover":{
            textDecoration:'underline'
        }
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class UserProfileWeb extends UserProfileWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area
    MenuAccordionSummary = styled(AccordionSummary)({
        '& .MuiAccordionSummary-content': {
            margin: "0px !important"
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: "0px !important"
        },
        color: "white"
    })
    MenuAccordion = styled(Accordion)({
        '&::before': {
            display: "none"
        }
    })
    // Customizable Area End

    render() {
        // Customizable Area Start
        const selectedBreadcrumb = this.state.breadCrumb
        const { classes } = this.props
        const languageData = languageTranslationData[this.state.currentLanguage]
        return (
            <Paper elevation={0} style={{ backgroundColor: '#F1F5F9', paddingBottom: "30px", boxSizing: "border-box" }} >
                <Container maxWidth='lg' style={{ display: 'flex', flexDirection: 'column' }}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        style={{ margin: '26px 0px 74px 0px' }}
                    >
                        <span id="navigate-home" onClick={() => this.handleNavigation("Home")} className={classes.nonactiveTab} key="1">
                            {languageData.Home}
                        </span>
                        {
                            selectedBreadcrumb.map((crumb: string) => {
                                const currentTrack = this.navigationTrack.filter(track => track.title.replace(" ","")===crumb)[0];
                                return (
                                    <span
                                        key="2"
                                        id="handleNavigate-test"
                                        className={currentTrack?.link === this.state.currentPage ? classes.activeTab : classes.nonactiveTab}
                                        onClick={() => this.handleNavigation(currentTrack?.link)}
                                    >
                                        {this.getArabicTitle(crumb, this.state.currentLanguage)}
                                    </span>
                                )
                            })
                        }
                    </Breadcrumbs>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3}>
                            <MenuList style={{ borderRadius: '18px', backgroundColor: '#1E293B', color: 'white', padding: '0px', overflow: "hidden" }}>
                                {
                                    (this.state.type !== "provider" ? [...this.state.menu, ...this.state.providerMenu] : this.state.menu).map((menu) => {
                                        getStorageData("userType")
                                        return (
                                            menu.tabs.length === 0 ?
                                                <MenuItem id={`test-${menu.route}`} key={menu.title} onClick={() => this.handleNavigation(menu.route)} style={{ backgroundColor: this.state.currentPage === menu.route ? '#334155' : '#1E293B', padding: '24px' }}>
                                                    <ListItemIcon>
                                                        <img src={menu.icon} alt="icon" />
                                                    </ListItemIcon>
                                                    <span style={{ fontSize: '18px', fontWeight: 700 }}>{menu.title}</span>
                                                </MenuItem>
                                                :
                                                <this.MenuAccordion style={{ backgroundColor: this.doExpand(this.state.currentPage) ? '#334155' : '#1E293B', margin: "0px" }}>
                                                    <this.MenuAccordionSummary
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        expandIcon={<img src={arrowIcon} alt="Arrow Icon" />}
                                                        style={{ minHeight: "auto", padding: '24px' }}
                                                    >
                                                        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                                            <Box id={`test-menulist`} style={{ display: "flex", alignItems: "center", width: "100%" }} onClick={(event) => { event.stopPropagation(); this.handleNavigation(menu.route) }}>
                                                                <ListItemIcon>
                                                                    <img src={menu.icon} alt="icon" />
                                                                </ListItemIcon>
                                                                <span style={{ fontSize: '18px', fontWeight: 700 }}>{menu.title}</span>
                                                            </Box>
                                                        </Box>
                                                    </this.MenuAccordionSummary>
                                                    {
                                                        menu.tabs?.map(tabData => {
                                                            return (
                                                                <AccordionDetails id="test-accordionDeail" onClick={() => this.handleNavigation(tabData.route)} style={{ cursor: "pointer", color: "white", backgroundColor: this.state.currentPage === tabData.route ? '#42566b' : 'transparent', padding: '12px 12px 12px 80px' }} key={tabData.title}>
                                                                    <span style={{ fontSize: '18px', fontWeight: 400 }}>{tabData.title}</span>
                                                                </AccordionDetails>
                                                            )
                                                        })
                                                    }
                                                </this.MenuAccordion>
                                        )
                                    })
                                }
                                <MenuItem id={`test-Log Out`} onClick={this.handleLogout} style={{ backgroundColor: '#1E293B', padding: '24px' }}>
                                    <ListItemIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12M4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="#F0E5FF" />
                                        </svg>
                                    </ListItemIcon>
                                    <span style={{ fontSize: '18px', fontWeight: 700 }}>{languageData.LogOut}</span>
                                </MenuItem>
                            </MenuList>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} >
                            {this.props.children}
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(UserProfileWeb);
